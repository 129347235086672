import React from "react";
// Customizable Area Start
import {
    Box,
    Typography,
    styled,
    Button,
    Grid,
    MenuList,
    MenuItem,
    Paper,
    Dialog,
    Card,
    TextField,
    InputAdornment,
    Modal,
    OutlinedInput,
    IconButton,
    CircularProgress,Snackbar,
} from "@material-ui/core";
import 'react-phone-input-2/lib/style.css'
import PhoneInput from 'react-phone-input-2'
import UserProfileBasicController, {  CountryArry, Props, UserProfileDetails } from "./UserProfileBasicController";
import HeaderMenuBlock from "../../../blocks/navigationmenu/src/HeaderMenu.web";
import EditIcon from '@material-ui/icons/Edit';
import { editWhiteIcon, cancleIcon,editIcon, errorIcon,DeleteSuccessIcon,UploadSvgIcon } from "./assets";
import "react-datepicker/dist/react-datepicker.css";
import { ArrowBack, CheckCircleOutline, Visibility, VisibilityOff } from "@material-ui/icons";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import Loader from "../../../components/src/Loader";
const configJSON = require("./config");

// Customizable Area End

export default class UserProfileBasicBlock extends UserProfileBasicController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    editProfileModal = () => {
        return (
            <EditProfileContainer
                PaperProps={{
                    className: "dialog"
                }}
                onKeyPress={this.handleProfileSaveOnEnter}
                onClose={this.editProfileCloseModal}
                fullScreen
                data-testId="dialogModalopen"
                open={this.state.editProfileModal}
                scroll="paper"
            >
                <Box>
                    
                    <Card elevation={2} className="editTitle">
                        <Typography className="editTitleName">Edit Profile</Typography>
                        <img src={cancleIcon} className="cancleButton" style={{cursor:"pointer"}} data-testId = "cancleButton" onClick={this.editProfileCloseModal} />
                    </Card>
                </Box>
                <Box style={{ padding: "30px" }} className="yscrollwindowProfile editprofile">
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={6}>
                            <Box >
                                <Typography variant="body1" style={webStyle.labelText}>
                                    Full Name *
                                </Typography>
                                <input
                                    type="text"
                                    name="name"
                                    data-testId="fullNameInput"
                                    placeholder="Your full name"
                                    value={this.state.inputFullName}
                                    onChange={this.changeFullName}
                                    required
                                    style={{
                                        ...webStyle.inputText,
                                        border: '1px solid rgba(148,163,184,1)',
                                        color: this.state.fullName.toLowerCase().startsWith("Add") ? "grey" : "black"
                                    }}
                                />
                            </Box>
                        </Grid>
                        {console.log('country object',this.state.countryObject,this.state.inputContactNum)}
                        <Grid item xs={12} sm={12} md={6}>
                            <Box>
                                <Typography variant="body1" style={webStyle.labelText}>
                                    Gender *
                                </Typography>
                            
                                <select
                                    value={this.state.inputGender }
                                    id="demo-simple-select-outlined"
                                    onChange={this.handleGenderChange}
                                    data-testId="changeGender"
                                    className="gender-select"
                                    style={{
                                        ...webStyle.inputText,
                                        border: '1px solid rgba(148,163,184,1)',
                                        color: !this.state.inputGender ? "grey" : "black",
                                        background:"white"
                                    }}
                                >
                                    <option value="" hidden>Select your gender</option>
                                    <option value="male">Male</option>
                                    <option value="female">Female</option>
                                    <option value="other">Other</option>
                                </select>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                            <Box style={{position:'relative'}}>
                                <Typography variant="body1" style={webStyle.labelText}>
                                    Date Of Birth *
                                </Typography>
                                <input
                                    type="date"
                                    name="Date"
                                    data-test-id="date-of-birth"
                                    max={new Date().toISOString().split('T')[0]}
                                    value={this.state.inputDateOfBirth}
                                    onChange={(event) => this.setDob(event.target.value)}
                                    required
                                    style={{
                                        ...webStyle.inputText,
                                        color: this.state.inputDateOfBirth.toLowerCase().startsWith("Add") ? "grey" : "black"
                                    }}
                                /> 
                               
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                            <Box>
                                <Typography variant="body1" style={webStyle.labelText}>
                                    Address line 1 *
                                </Typography>
                                <input
                                    type="text"
                                    name="name"
                                    data-testId="addressInput"
                                    placeholder="Enter the Address"
                                    value={this.state.inputAddress}
                                    onChange={this.changeAddress}
                                    required
                                    style={{
                                        ...webStyle.inputText,
                                        border: '1px solid rgba(148,163,184,1)',
                                        color: this.state.inputAddress.toLowerCase().startsWith("Add") ? "grey" : "black"
                                    }}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                            <Box>
                                <Typography variant="body1" style={webStyle.labelText}>
                                    Address line 2
                                </Typography>
                                <input
                                name="address2"
                                    type="text"
                                    value={this.state.inputAddress2}
                                    data-testId="addressInput2"
                                    placeholder="Enter the Address 2"
                                    required
                                    onChange={this.changeAddress2}
                                    
                                    style={{
                                        ...webStyle.inputText,
                                        border: '1px solid rgba(148,163,184,1)',
                                        color: this.state.inputAddress2.toLowerCase().startsWith("Add") ? "grey" : "black"
                                    }}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                            <Box>
                                <Typography variant="body1" style={webStyle.labelText}>
                                    State *
                                </Typography>
                                <input
                                style={{
                                    ...webStyle.inputText, border: '1px solid rgba(148,163,184,1)',
                                    color: this.state.inputState.toLowerCase().startsWith("Add") ? "grey" : "black"
                                }}
                                    type="text"
                                    value={this.state.inputState}
                                    name="state"
                                    placeholder="State"
                                    data-test-id="State"
                                    onChange={this.handleInputState}
                                    required
                                   
                                    
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                            <Box>
                                <Typography variant="body1" style={webStyle.labelText}>
                                    City *
                                </Typography>
                                <input
                                    type="text"
                                    value={this.state.inputCity}
                                    name="name"
                                    data-test-id="city"
                                    
                                    placeholder="City"
                                    required
                                    onChange={this.handleChangeCity}
                                    style={{
                                        ...webStyle.inputText,
                                        border: '1px solid rgba(148,163,184,1)',color: this.state.inputCity.toLowerCase().startsWith("Add") ? "grey" : "black"
                                    }}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                            <Box>
                                <Typography variant="body1" style={webStyle.labelText}>
                                    Zip/Pincode *
                                </Typography>
                                <input
                                    type="text"
                                    onBlur={(event)=>this.zipcodeFunct(event.target.value)}
                                    value={this.state.inputPinCode}
                                    onChange={this.handleChangePincode}
                                    name="name"
                                    data-test-id="pincode"
                                    placeholder="Pincode"
                                    required
                                    style={{
                                        ...webStyle.inputText,
                                        border: '1px solid rgba(148,163,184,1)',
                                        color: this.state.inputPinCode.toLowerCase().startsWith("Add") ? "grey" : "black"
                                    }}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                            <Box>
                                <Typography variant="body1" style={webStyle.labelText}>
                                    Country *
                                </Typography>
                         
                                <select
                                    placeholder="Select the Country"
                                    value={this.state.inputCountry}
                                    id="demo-simple-select-outlined"
                                    onChange={this.handleCountryChange}
                                    data-testId="selectCountry"
                                    style={{
                                        ...webStyle.inputText,
                                        border: '1px solid rgba(148,163,184,1)',
                                        color: !this.state.inputCountry ? "grey" : "black",
                                        background:"white"
                                    }}
                                >
                                    <option value="" hidden>Select Country</option>
                                    {this.state.countryData?.map((item: CountryArry, index: number) => (
                                        <option key={index} value={item.attributes.name}> {item.attributes.name}</option>
                                    ))}
                                </select>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                            <Box>
                                <Typography variant="body1" style={webStyle.labelText}>
                                    Contact Details *
                                </Typography>
                                <Box style={{ display: 'flex', alignItems: 'center', position: "relative" }}>
                                    <PhoneInput
                                        autoFormat={true}
                                        enableLongNumbers={true}
                                        country={'in'}
                                        data-test-id="contactNum"
                                        placeholder="Enter your mobile number"
                                        value={this.state.inputContactNum}
                                        onChange={(value: string, countryObj: { dialCode: string; format: string; name: string }) => {
                                            this.setMobileNo(value,countryObj.dialCode)
                                            this.setState({countryObject:countryObj}) 
                                            this.validatePhoneNumber(value,countryObj)
                                          }}
                                        inputStyle={{
                                            ...webStyle.inputContactText,
                                            flex: 1,
                                            marginRight: '10px',
                                        }}
                                    />
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                            <Box>
                                <Typography variant="body1" style={webStyle.labelText}>
                                    Passport Number
                                </Typography>
                                <input
                                    type="text"
                                    required
                                    name="Passport Number"
                                    data-test-id="passportNum"
                                    placeholder="Passport Number"
                                    value={this.state.inputPassportNo}
                                    style={{
                                        ...webStyle.inputText,
                                        color: this.state.inputPassportNo.toLowerCase().startsWith("Add") ? "grey" : "black",
                                        border: '1px solid rgba(148,163,184,1)'
                                    }}
                                    onChange={this.handlePassportNumber}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                            <Box style={{position:'relative'}}>
                                <Typography variant="body1" style={webStyle.labelText}>
                                    Passport Expiry Date
                                </Typography>
                                <input
                                    type="date"
                                    name="Expiry Date"
                                    data-test-id="passportExp"
                                    min={new Date().toISOString().split('T')[0]}
                                    value={this.state.inputPassportExpiry}
                                    onChange={(event) => this.setPassportExDate(event.target.value)}
                                    required
                                    style={{
                                        ...webStyle.inputText,
                                        color: this.state.inputPassportExpiry.toLowerCase().startsWith("Add") ? "grey" : "black"
                                    }}
                                /> 
                            </Box>
                        </Grid>
                    </Grid>
                    <Box className="buttonContainer">
                    <Button className="submitButton CancleEditButton" data-testId= "cancleEditModalButton" onClick={this.editProfileCloseModal}>Cancel</Button>
                {    console.log(this.state.disableSaveWithCountryCode)}
                         <Button
  disabled={ 
    !this.state.inputFullName || this.state.inputFullName.toLowerCase().startsWith("add") ||
    !this.state.inputGender || this.state.inputGender.toLowerCase().startsWith("add") ||
    !this.state.inputCountry || this.state.inputCountry.toLowerCase().startsWith("add") ||
    !this.state.inputAddress || this.state.inputAddress.toLowerCase().startsWith("add") ||
    !this.state.inputCity || this.state.inputCity.toLowerCase().startsWith("add") ||
    !this.state.inputContactNum || this.state.disableSaveWithCountryCode ||
    !this.state.inputDateOfBirth || this.state.inputDateOfBirth.toLowerCase().startsWith("add") ||
    !this.state.inputPinCode || this.state.inputPinCode.toLowerCase().startsWith("add")
  }
  className="submitButton saveEditButton"
  data-test-id="save-profile"
  onClick={this.handleProfileDetails}
>
  Save
</Button>

                </Box>
                </Box>
                
            </EditProfileContainer>
        )
    };

    renderProfileTab = () => {
        return (
            <Box className="profileTabContainer">
                <Box style={{ width: "100%", margin: "auto", paddingTop: "48px" }}>
                    <Box className="uploadImageContainer" data-testId="openPhotaoModal" >
                        <img src={this.state.profilePic ? this.state.profilePic : UploadSvgIcon} className="uploadimage" />
                        <Box className="profileEditImageContainer">
                            <img src={editWhiteIcon} data-test-id="edit-profile-pic"  style={{cursor:'pointer'}} onClick={this.handleImageUpload} />
                            <input
                                type="file"
                                ref={this.fileInputRef}
                                onChange={this.handleFileChange}
                                accept="image/*"
                                style={{ display: 'none' }}
                            />
                        </Box>
                    </Box>
                    <Typography className="userName">{this.state.fullName}</Typography>
                </Box>
                <MenuList>
                    {this.state.userDetails.map((item: UserProfileDetails,index: number) => (
                        <MenuItem
                            key={item.status}
                            className={this.state.activeItem === item.status ? "active" : "unActive"}
                            data-testId= {`peofileDetails${index}`}
                            onClick={() => this.handleMenuItemClick(item.status)}
                            style={{display:"flex",justifyContent:'start',paddingLeft:'40px',gap:"12px",alignItems:"center"}}
                        >
                            <img src={item.icon} />
                            {item.status}
                        </MenuItem>
                    ))}
                </MenuList>
            </Box>
        );
    };

    renderReightUserProfile = () => {

        const renderProfileDetail = (label: string, value: string, required = false) => (
            <Box className="profileDetailsBox">
                <Typography className="textKey darkGray titleName">{label} {required && "*"}</Typography>
                <Typography className="textKey" style={this.getTextStyle(value)}>{value}</Typography>
            </Box>
        );
    
        return (
            <Box>
                <Paper elevation={3} style={{ paddingBottom: "30px" }}>
                    <Box className="profileHeaderContainer">
                        <Typography className="profileTitleName">{configJSON.profile}</Typography>
                        <Button
                            variant="contained"
                            style={{
                                color: 'white',
                                background: '#1764A0',
                                padding: '10px, 12px, 10px, 12px',
                                textTransform: 'none',
                                borderRadius: '8px',
                                fontFamily: 'Inter, sans-serif',
                                fontWeight: 600,
                                fontSize: '14px'
                            }}
                            startIcon={<img style={{ width: "18px", height: "18px" }} src={editIcon} />}
                            data-testId="openProfileModal"
                            onClick={this.editProfileOpenModal}
                        >
                            Edit
                        </Button>
                    </Box>
                    <Box>
                    {renderProfileDetail("Name", String(this.state.fullName), true)}
{renderProfileDetail("Gender", String(this.state.gender), true)}
{renderProfileDetail("Date of Birth", String(this.state.dateOfBirth), true)}
{renderProfileDetail("Address line 1", String(this.state.address), true)}
{renderProfileDetail("Address line 2", String(this.state.address2))}
{renderProfileDetail("Zip/Pin Code", String(this.state.pinCocde), true)}
{renderProfileDetail("State", String(this.state.state), true)}
{renderProfileDetail("City", String(this.state.city), true)}
{renderProfileDetail("Country", String(this.state.country), true)}
{renderProfileDetail("Passport Number", String(this.state.passportNo))}
{renderProfileDetail("Passport Expiry date", String(this.state.passportExpiry))}
{renderProfileDetail("Contact Number", String(this.state.contactNum), true)}

                    </Box>
                </Paper>
                <Paper elevation={3} className="loginDetailContainer">
                    <Typography className="profileTitleName loginName" variant="h5">{configJSON.loginDetails}</Typography>
                    <Box className="profileDetailsBox profileDetailsSpace login-details-container">
                        <Typography className="textKey darkGray">Email ID</Typography>
                        <Box className="detail-container">
                            <Typography className="textKey blackColor">{this.state.userEmail}</Typography>
                            <Button className="updateButton" endIcon={<EditIcon />} data-testId="emailOpenModal" onClick={this.changeEmailOpenModal}>
                                Update
                            </Button>
                        </Box>
                    </Box>
                    <Box className="profileDetailsBox profileDetailsSpace login-details-container">
                        <Typography className="textKey darkGray">Password</Typography>
                        <Box className="detail-container">
                            <Typography className="textKey blackColor">**********</Typography>
                            <Button className="updateButton" endIcon={<EditIcon />} data-testId="passwordOpenModal" onClick={this.verificationOtpOpenModal}>
                                Update
                            </Button>
                        </Box>
                    </Box>
                </Paper>
            </Box>
        );
    };
    

    changeEmailId = () => {
        return (
            <EditProfileContainer
                PaperProps={{
                    className: "changeEmailDialog"
                }}
                data-testId = "emailmodal"
                onKeyDown={this.EmailChangeEnterPress}
                onClose={this.changeEmailCloseModal}
                fullScreen
                open={this.state.changeEmailModal}
            >
                <Box>
                    <Card elevation={2} className="editTitle">
                        <Typography className="editTitleName">{this.state.showOtpUi ? 'Email ID Verification' :'Change Email ID' }  </Typography>
                        <img src={cancleIcon} className="cancleButton" style={{cursor:"pointer"}} data-testId = "updateEmailCloseModal" onClick={this.changeEmailCloseModal} />
                    </Card>
                </Box>
                <Box className="email-edit-container">
                {this.state.emailChangeError && <Grid item sm={12}>
                        <Box data-test-id='emailError' className="ErrorMsg" sx={webStyle.errorMsgforCount}>
                            <span className="imgError" style={{  marginRight: "5px" }}>
                                <React.Suspense fallback={<CircularProgress />}> 
                                    <img src={errorIcon} />
                                </React.Suspense>
                            </span> 
                            <span style={{ width: "95%" }}>
                                {this.state.emailChangeError}
                            </span>
                        </Box>
                    </Grid>}
                    <Typography className="emailverifyText">{this.state.emailMessageText ? this.state.emailMessageText : this.state.successOtpText}</Typography>
                    {this.state.showOtpUi ? <Box >
                        <Typography variant="body1" style={webStyle.labelText}>
                            OTP
                        </Typography>
                        <OutlinedInput
                         style={{width:'100%'}}
                         error={this.state.emailBtnDisable}
                         id="outlined-error"
                         data-test-id="otp-input"
                         placeholder="Enter OTP"
                         value={this.state.otpValue}
                         onChange={this.changeOtpValue}
                         required
                            endAdornment={
                                <InputAdornment position="end">
                                    {this.state.Seconds > 0 ? (
                                        <Typography style={{ ...webStyle.CodeTypography, textAlign: "left",display:'flex',alignItems:"center",gap:"10px" }}>
                                            <Button disabled={this.state.Seconds > 0} style={{ background:"none",textUnderlineOffset: "3px", textDecoration: 'underline', textTransform: 'none', color: "#1764A0", fontSize: '16px', fontWeight: 700 }}>
                                                Resend OTP in
                                            </Button>
                                            <span style={{ color: "rgba(148, 163, 184, 1)", }}>
                                                {`0${this.state.minutes}`}:
                                                {this.state.Seconds < 10 ? `0${this.state.Seconds}` : this.state.Seconds}
                                            </span>
                                        </Typography>
                                    ) : (
                                        <Typography style={{ ...webStyle.CodeTypography, textAlign: "left" }}>
                                            <Button data-test-id="ressetOtpTimer" onClick={this.handleResendOtp} style={{ textUnderlineOffset: "3px", textDecoration: 'underline', textTransform: 'none', color: "#1764A0", fontSize: '16px', fontWeight: 700 }}>
                                                Resend OTP
                                            </Button>
                                        </Typography>
                                    )
                                    }
      </InputAdornment>
                            }
                        
                        />
                
                     
                    </Box> : <Box >
                        <Typography variant="body1" className="email_id">
                            Email ID
                        </Typography>
                        
                        <TextField
                            style={{width:'100%'}}
                            variant="outlined"
                            error={this.state.showEmailError}
                            id="outlined-error"
                            data-test-id="email-input"
                            placeholder="Enter New Email ID"
                            value={this.state.changedEmail}
                            onChange={this.changeEmail}
                            required
                        />
                     
                    </Box>}

                </Box>
                <Box className="buttonContainer2">
                    <Button className="submitButton CancleEditButton" onClick={this.changeEmailCloseModal}>Cancel</Button>
                   {this.state.showOtpUi ? <Button disabled={!(this.state.otpValue && this.state.otpValue.length >= 4)}
 className="submitButton saveEditButton" data-test-id="verify_otp" onClick={this.handleVerifyOtp}> Verify</Button>
               :<Button  data-test-id ='email-verify-btn' disabled={this.state.email_verify} className="submitButton saveEditButton" onClick={this.handleverify}> Verify</Button>
            }  </Box>
            </EditProfileContainer>
        );
    };
    passwordForm = () => {
        return (
            <>
           < Box sx={{ padding: "5px 0px" }}>
                    <Typography
                     variant="body1" style={webStyle.labelsText}>
                        Old Password
                    </Typography>
                    <div style={{ position: "relative", width: "100%" }}>
                        <input
                             data-test-id="old-password"
                            type={this.state.showPassword ? "text" : "password"}
                            name="password"
                            placeholder="Your Old password"
                            value={this.state.oldPassword}
                            onChange={this.handleOldPasswordChange}
                            required
                            style={{
                                ...webStyle.inputText
                            }}
                        />
                        <IconButton
                            data-test-id="enablePassword"
                            aria-label="toggle password visibility"
                            onClick={this.handleDisplayPassword}
                            edge="end"
                            style={{ position: "absolute", right: "10px", 
                            top:'5px' }}
                        >
                            {this.state.showPassword ? (
                                <Visibility />
                            ) : (
                                <VisibilityOff />
                            )}
                        </IconButton>
                    </div>
                </Box>
                <Box sx={{ padding: "5px 0px" }}>
                    <Typography variant="body1" style={webStyle.labelsText}>
                        New Password
                    </Typography>
                    <div style={{ position: "relative", width: "100%" }}>
                        <input
                          data-test-id="new-password"
                            type={this.state.showNewPassword ? "text" : "password"}
                            name="password"
                            placeholder="Your New password"
                            value={this.state.newPassword}
                            onChange={this.handlePasswordChange}
                            className="input-outlined"
                            required
                            style={{
                                ...webStyle.inputText
                            }}
                        />
                        <IconButton
                            data-test-id="enablePassword"
                            aria-label="toggle password visibility"
                            onClick={this.handleDisplayNewPassword}
                            edge="end"
                            style={{ position: "absolute", right: "10px",top:'5px' }}
                        >
                            {this.state.showNewPassword ? (
                                <Visibility />
                            ) : (
                                <VisibilityOff />
                            )}
                        </IconButton>
                    </div>
                </Box>
                <Box sx={{ padding: "5px 0px" }}>
                    <Typography variant="body1" style={webStyle.labelsText}>
                        Confirm Password
                    </Typography>
                    <div style={{ position: "relative", width: "100%" }}>
                        <input
                            id="outlined-adornment-password"
                            value={this.state.confirmPassword}
                            data-test-id="confirm-new-password"
                            placeholder="Confirm Password"
                            onChange={this.handleConfirmPasswordChange}
                            className="input-outlined" type={this.state.showConfirmPassword ? 'text' : 'password'}
                            required
                            style={{
                                ...webStyle.inputText
                            }} />
                        <IconButton
                            onClick={this.handleDisplayConfirmPassword}
                            edge="end"
                            style={{
                                position: "absolute",
                                right: "10px",
                                top:'5px'
                            }}
                            aria-label="toggle password visibility"
                            data-test-id="enableConfirmPassword"
                        >
                            {this.state.showConfirmPassword ? (
                                <Visibility />
                            ) : (
                                <VisibilityOff />
                            )}
                        </IconButton>
                    </div>
                </Box>
            </>
        )
    }
    deletAccount = () => {
        return (
            <DeletStyles
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
                data-testId="update-delete-modal"
                onClose={this.handleDeleteClose} open={this.state.activeItem === "Delete Account"}>
                <Box className="deletAccountConatiner">
                    <Card elevation={2} className="editTitle deletTitle">
                        <Typography className="editDeleteTitle"> Delete Account </Typography>
                        <img src={cancleIcon} style={{ cursor: 'pointer' }} className="cancelButton" onClick={this.handleDeleteClose} />
                    </Card>
                    <Box className="inputContainer">

                        {this.state.deletError && <Grid
                         item sm={12}>
                            <Box 
                            data-test-id='emailError' className="ErrorMsg" 
                            sx={webStyle.errorMsgforCount} style={{ background: '#FEE2E2' }}>
                                <span className="imgError"
                                 style={{ marginRight: "5px" }}>
                                    <React.Suspense
                                     fallback={<CircularProgress />}>
                                        <img 
                                        src={errorIcon} />
                                    </React.Suspense>
                                </span>
                                <span>{this.state.deletError}
                                </span>
                            </Box></Grid>}
                        <Typography className="editDeleteTitle"> Are you sure you want to delete your account? </Typography>
                        <Box className="buttonContainer">
                            <Button className="submitButton CancleEditButton" data-test-id="deleteModalClose" onClick={this.handleDeleteClose}>Cancel</Button>
                            <Button
                            
                                variant="contained" 
                                style={{ 
                                color: 'white', 
                                background: '#1764A0', 
                                padding: '10px, 12px, 10px, 12px', 
                                textTransform: 'none', 
                                borderRadius: '8px', 
                                fontFamily: 'Inter, sans-serif', 
                                fontWeight: 700, 
                                fontSize: '16px' }}
                                data-test-id="delete-account"
                                className="submitButton saveEditButton"
                                onClick={this.handleAccountDelete}
                            >
                                Delete Account
                            </Button>
                        </Box>

                    </Box>

                </Box>
            </DeletStyles>
        )
    }
    changePassword = () =>{
        const hasNumber = this.checkForNumber(this.state.newPassword);
        const meetsMinLength = this.checkMinLength(this.state.newPassword);
        const hasSpacialChar = this.checkForSpecialChar(this.state.newPassword);
        const hasCapitalLetter = this.checkForCapitalLetter(this.state.newPassword);
        const hasLowerCase = this.checkLowerCaseLetter(this.state.newPassword)
        return (
            <PasswordStyles
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
                data-testId="update-password-modal"
                onKeyDown={this.handleChangePassowrdEnterPress}
                onClose={this.verificationOtpCloseModal} open={this.state.VerifyOtpModal}>
                <Box className="passwordContainer">
                    <Card elevation={2} className="editTitle passwordTitle">
                        <Typography className="editPassowrdTitle"> Change Password </Typography>
                        <img src={cancleIcon} style={{cursor:'pointer'}} className="cancelButton" onClick={this.verificationOtpCloseModal} />
                    </Card>
                    <Box className="inputContainer yscrollwindowProfile">
        
{this.state.passwordError && <Grid item sm={12}>
                        <Box data-test-id='emailError' className="ErrorMsg" sx={webStyle.errorMsgforCount} style={{background:'#FEE2E2'}}>
                            <span className="imgError" style={{ marginRight: "5px" }}>
                                <React.Suspense fallback={<CircularProgress />}> 
                                    <img src={errorIcon} />
                                </React.Suspense>
                            </span> 
                            <span >
                                {this.state.passwordError}
                            </span>
                        </Box>
                    </Grid>}
                     {this.passwordForm()}
                        {this.state.newPassword && <div style={{ display: 'flex', flexDirection: "column", gap: "10px" }}>
                            <Typography style={{ ...webStyle.conditionPre, margin: "0px" }} variant="body1">Your password must contain</Typography>
                            {this.conditionPassword(hasCapitalLetter, hasLowerCase, hasNumber, hasSpacialChar, meetsMinLength)}
                        </div>}
                    <Box className="buttonContainer">
                        <Button className="submitButton CancleEditButton" data-test-id= "passwordModalClose" onClick={this.verificationOtpCloseModal}>Cancel</Button>
                        <Button
                        data-test-id="update-password"
                            className="submitButton saveEditButton"
                            onClick={this.handleSubmitNewPassword}
                            disabled={
                                !(
                                    this.state.hasMaxLength &&
                                    this.state.hasMinLength &&
                                    this.state.hasNumber &&
                                    this.state.hasLowerCase &&
                                    this.state.hasUpperCase
                                ) ||
                                this.state.newPassword.length === 0 && this.state.confirmPassword.length === 0
                            }
                        >
                            Submit
                        </Button>
                    </Box>
                       
                    </Box>
                    
                </Box>
            </PasswordStyles>
        )
    }
    renderPasswordCondition = (condition:any, iconStyle:any, yesIconStyle:any) => {
        let icon;
      
        if (this.state.newPassword) {
          if (condition) {
            icon = <CheckCircleIcon style={{ ...iconStyle, color: "rgba(23, 100, 160, 1)" }} />;
          } else {
            icon = <CancelIcon style={webStyle.cancelIconCss} />;
          }
        }
      
        return icon;
      };
    conditionPassword = (hasCapitalLetter: boolean, hasLowerCase: boolean, hasNumber: boolean, hasSpacialChar: boolean, meetsMinLength: boolean) => {
        return (
            <Box sx={webStyle.conditionReset}>
                <Typography style={webStyle.conditionPre} variant="body1">
                    {this.renderPasswordCondition(hasCapitalLetter, webStyle.fontSize13px, webStyle.yesIcon)}
                    At least one capital letter
                </Typography>
                <Typography style={webStyle.conditionPre} variant="body1">
                    {this.renderPasswordCondition(hasLowerCase, webStyle.fontSize13px, webStyle.yesIcon)}
                    At least one lowercase letter
                </Typography>
                <Typography style={webStyle.conditionPre} variant="body1">
                    {this.renderPasswordCondition(hasNumber, webStyle.fontSize13px, webStyle.yesIcon)}
                    At least one number
                </Typography>
                <Typography style={webStyle.conditionPre} variant="body1">
                    {this.renderPasswordCondition(hasSpacialChar, webStyle.fontSize13px, webStyle.yesIcon)}
                    At least one special character
                </Typography>
                <Typography style={webStyle.conditionPre} variant="body1">
                    {this.renderPasswordCondition(meetsMinLength, webStyle.fontSize13px, webStyle.yesIcon)}
                    Minimum character length is 8 characters
                </Typography>
            </Box>
        )
    }
    handleAccountDeleteUI = ()=>{
        return(
            <DeleteStyles>
                <Box className="success-box">
                    <Box className="SuccessContainer">
                        <img src={DeleteSuccessIcon} />
                        <Typography className="delete-msg" >Your account has been successfully deleted </Typography>
                        <Button style={{
                            color: "#1764A0",
                            border: "none",
                            textTransform:'none',
                            marginTop:'20px',
                            fontWeight: 700,
                            fontSize: '16px',
                        }} startIcon={<ArrowBack />} data-test-id='redirect-login' onClick={this.logoutFunction}>Redirect to Login</Button>
                    </Box>
                </Box>
            </DeleteStyles>
        )
    }
    renderUserProfile = () => {
        return (
            <Box sx={webStyle.contentContainer}>
                
                <Box className="SuccessContentContainer">
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={4}>
                            {this.renderProfileTab()}
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            {this.renderReightUserProfile()}
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        );
    };
    // Customizable Area End

    render() {
        // Customizable Area Start
      
        return (
            <UserProfileContainer>
               <div style={{zIndex:1400}}> <Loader loading={this.state.loading}  /></div>
                <Snackbar open={this.state.toastOpen}  anchorOrigin={{ vertical: 'top', horizontal: 'right'}} onClose={()=> this.setState({toastOpen:false})} autoHideDuration={3000}>
                    <Typography style={{padding:'8px',background:"rgb(23, 100, 160)",color:'white',borderRadius:'4px',display:'flex',alignItems:'center',gap:'10px'}}>
                        <CheckCircleOutline /> {this.state.ApiResponseMessage}
                    </Typography>
                </Snackbar>
                <Box sx={webStyle.headerContainer}>
                    <HeaderMenuBlock navigation={this.props.navigation} id={""} />
                    
                    <Typography className="profileTitle">{configJSON.myProfile}
                    {this.state.profileApiError && <Grid item sm={12}>
                        <Box data-test-id='emailError' className="ErrorMsg" sx={webStyle.errorMsgforCount} style={{background:'#FEE2E2'}}>
                            <span className="imgError" style={{ marginRight: "5px" }}>
                                <React.Suspense fallback={<CircularProgress />}> 
                                    <img src={errorIcon} />
                                </React.Suspense>
                            </span> 
                            <span >
                                {this.state.profileApiError}
                            </span>
                        </Box>
                    </Grid>}</Typography>
                </Box>
                {this.state.deletedAccount
                    ? <Box style={{height:"300px",position:'relative'}}>
                        {this.handleAccountDeleteUI()}
                    </Box>                    : (
                        <>
                            {this.renderUserProfile()}
                            {this.editProfileModal()}
                            {this.changeEmailId()}
                            {this.changePassword()}
                            {this.deletAccount()}
                        </>
                    )
                }

              
               
            </UserProfileContainer>
        )
        // Customizable Area End
    }
}

// Customizable Area Start
const UserProfileContainer = styled(Box)({
    background: "#E9F3FC",
    height: "100%",
    overflowY: "scroll",
    "& .SuccessContentContainer": {
        width: "97%",
        position: "absolute",
        top: "-190px",
    },
    "& .profileEditImageContainer": {
        position: "absolute",
        right: "-13px",
        bottom: "6px",
        transform: "translateX(-50%)",
        width: "32px",
        height: "32px",
        borderRadius: "50%",
        border: "3px solid #FFFFFF",
        background: "#64748B",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    "& .uploadImageContainer": {
        position: "relative",
        border: "4px solid #E9F3FC",
        width: "132px",
        height: "132px",
        borderRadius: "100%",
        margin: "auto"
    },
    "& .uploadimage": {
        width: "130px",
        height: "130px",
        objectFit: "cover",
        borderRadius: "50%"
    },
    "& .profileTabContainer": {
        background: "#FFFFFF",
        width: "100%",
        borderRadius: "8px"
    },
    "& .userName": {
        fontSize: "24px",
        fontWeight: 700,
        fontFamily: "Inter",
        lineHeight: "24px",
        color: "#000000",
        textAlign: "center",
        paddingTop: "16px",
        paddingBottom: "88px",
        boxSizing: "border-box"
    },
    "& .profileTitle": {
        fontSize: "32px",
        fontWeight: 700,
        fontFamily: "Inter",
        display:"flex",
        width:"90%",
        flexDirection:"column",
        gap:"20px",
        lineHeight: "24px",
        color: "#FFFFFF",
        marginTop: "56px",
        marginLeft: "56px",
        "@media screen and (min-width: 260px) and (max-width: 600px)": {
            marginLeft: "32px",
        },
        "@media screen and (min-width: 600px) and (max-width: 768px)": {
            marginLeft: "38px",
        },
    },
    "& .active": {
        backgroundColor: "#E9F3FC",
        height: "64px",
        margin: "0px",
        borderBottom: "1px solid #CBD5E1",
        fontFamily: "inter",
        fontSize: "16px",
        fontWeight: 600,
        color: "#000000"
    },
    "& .unActive": {
        backgroundColor: "#FFFFFF",
        height: "64px",
        margin: "0px",
        borderBottom: "1px solid #CBD5E1",
        fontFamily: "inter",
        fontSize: "16px",
        fontWeight: 600,
        color: "#000000"
    },
    "& .MuiList-padding": {
        paddingBottom: "0px"
    },
    "& .profileDetailsBox": {
        display: "flex",
        alignItems: "center",
        borderBottom: "1px solid #CBD5E1",
        height: "64px",
        width: "100%",
        padding: "0px 48px 0px 48px",
        boxSizing:"border-box",
        gap:"20px",
        "@media screen and (min-width: 260px) and (max-width: 414px)": {
            padding: "0px 20px 0px 20px",
        },
        "@media screen and (min-width: 415px) and (max-width: 600px)": {
            padding: "0px 48px 0px 48px",
        },
        "@media screen and (min-width: 601px) and (max-width: 950px)": {
            padding: "0px 48px 0px 48px",
        },
    },
    
    "& .titleName": {
         width: "30%"    
    },
    "& .profileDetailsSpace":{
        justifyContent:"space-between"
    },
    "& .textKey": {
        fontFamily: "Inter",
        fontSize: "16px",
        "@media screen and (min-width: 260px) and (max-width: 414px)": {
            fontSize: "14px",
        },
        "@media screen and (min-width: 415px) and (max-width: 600px)": {
            fontSize: "14px",
        },
        fontWeight: 600,
        lineHeight: "24px"
    },
    "& .blackColor": {
        color: "#000000",
    },
    "& .darkGray": {
        color: "#64748B",
    },
    "& .profileHeaderContainer": {
        padding: "40px 48px 20px 48px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        "@media screen and (min-width: 260px) and (max-width: 414px)": {
            padding: "40px 20px 20px 20px",
         },
         "@media screen and (min-width: 415px) and (max-width: 600px)": {
             
         },
         "@media screen and (min-width: 601px) and (max-width: 950px)": {
             
         },
    },
    "& .updateButton": {
        color: "#1764A0",
        fontFamily: "Inter",
        fontWeight: 700,
        fontSize: "14px",
        textTransform: "capitalize"
    },
    "& .profileTitleName": {
        fontFamily: "inter",
        fontSize: "24px",
        fontWeight: 700,
    },
    "& .detail-container":{
        display:"flex",
        gap:"16px",
        width:"70%",
        alignItems:'center',
        justifyContent:'space-between',
        "@media screen and (min-width: 260px) and (max-width: 414px)": {
            flexWrap:'wrap',
            width:'100%'
         },
         "@media screen and (min-width: 415px) and (max-width: 600px)": {
            flexWrap:'wrap',
            width:'100%'
         },
         "@media screen and (min-width: 601px) and (max-width: 950px)": {
             flexWrap:"nowrap"
         },
    },
    "& .login-details-container":{
        height:"64px",
        "@media screen and (min-width: 260px) and (max-width: 414px)": {
            display:"flex",
            gap:"16px",
            flexWrap:'wrap',
            height:"fit-content"
         },
         "@media screen and (min-width: 415px) and (max-width: 600px)": {
            display:"flex",
            gap:"16px",
            flexWrap:'wrap',
            height:"fit-content"
         },
         "@media screen and (min-width: 601px) and (max-width: 950px)": {
             
         },
    },
    "& .loginName": {
        paddingLeft: "48px",
        "@media screen and (min-width: 260px) and (max-width: 414px)": {
            paddingLeft: "18px",
            marginBottom:"14px"
         },
         "@media screen and (min-width: 415px) and (max-width: 600px)": {
            paddingLeft: "18px",
            marginBottom:"14px"
         },
         "@media screen and (min-width: 601px) and (max-width: 950px)": {
             
         },
        boxSizing:"border-box"
    },
    "& .loginDetailContainer": {
        padding: "48px 0px 17px 0px", 
        marginTop: "8px" 
    }
});

const webStyle = {
    conditionPre:{
        display:"flex",
        fontSize:"14px",
        alignItems:"center",
        margin:"10px 0",
        gap:'4px'
    },
    conditionReset:{
        
    },
    cancelIconCss:{
        color:"red",
        fontSize:"15px",
        marginRight:"3px",
        marginBottom:'1px'
      },
      fontSize13px:{
        fontSize:"15px",
        marginRight:"3px",
        marginBottom: '1px',
      },
      yesIcon:{
        color:"rgba(136, 153, 136, 0.5)",
        fontSize:"15px",
        marginRight:"3px"
      },
    errorMsgforCount:{
        borderRadius: "5px",
        fontSize: "12px",
        borderLeft: "6px #c10f0ff0 solid",
        background:" #ed7e7e3d",
        fontFamily:"Inter, sans-serif",
        color: "#c12121",
        display:"flex",
        lineHeight:"24px",
        padding: "15px 15px",
        alignItems: "center"
    },
    labelsText:{
        fontSize: "16px",
        fontWeight: 700,
        color: "rgba(51, 65, 85, 1)",
        marginBottom: "2px",
        display: "block",
    },
    inputsText:{
        width: "100%", border: `1px solid rgba(148,163,184,1)`, borderRadius: "8px",
        color: "rgba(0,0,0,1)", height: "56px", fontFamily:"Inter, sans-serif", fontSize: "14px", padding: "4%",
        marginBottom: "10px",outline:"none"
    },
    CodeTypography: {
        color: "rgba(0, 0, 0, 1)",
        fontFamily: "Inter, sans-serif",
        fontWeight: 400,
        fontSize: "14px",
    },
    ResendButton: {
        color: "rgba(0, 0, 0, 1)",
        backgroundColor: "#fff",
        border: "none",
        fontWeight: 600,
        textDecoration: "underline",
        cursor: "pointer",
    },
    ResendButton1: {
        color: "rgba(100, 116, 139, 1)",
        backgroundColor: "#fff",
        border: "none",
        fontWeight: 600,
        textDecoration: "underline",
        cursor: "pointer",
    },
    headerContainer: {
        flexDirection: "row",
        flexWrap: "wrap",
        background: "linear-gradient(180deg, #013B68 84.25%, #0175D0 100%)",
        display: "flex",
        width: "100%",
        paddingBottom: "250px",
    },
    contentContainer: {
        width: "100%",
        flexWrap: "wrap",
        justifyContent: "space-around",
        background: "#E9F3FC",
        gap: "21px",
        display: "flex",
        flexDirection: "row",
        position: "relative",
    },
    labelText:{
        fontSize: "16px",
        fontWeight: 700,
        color: "rgba(51, 65, 85, 1)",
        marginBottom: "2px",
        display: "block",
    },
    inputText:{
        width: "100%",
        border: `1px solid rgba(148,163,184,1)`,
        borderRadius: "8px",
        color: "rgba(0,0,0,1)",
        height: "56px",
        fontFamily: "Inter, sans-serif",
        fontSize: "14px",
        paddingLeft: "16px",
        marginBottom: "10px",
        outline: "none",
    },
    inputContactText:{
        width: "100%",
        border: `1px solid rgba(148,163,184,1)`,
        borderRadius: "8px",
        color: "rgba(0,0,0,1)",
        height: "56px",
        fontFamily: "Inter, sans-serif",
        fontSize: "14px",
        marginBottom: "10px",
        outline: "none",
    },
    

};
const DeleteStyles = styled(Box)({
    "& .delete-msg":{
        textAlign:'center',
        fontSize: "24px",
        "@media screen and (min-width: 260px) and (max-width: 600px)":{
            fontSize: '14px !important',
        } ,
        "@media screen and (min-width: 600px) and (max-width: 1000px)":{
            fontSize: '16px !important',
        } ,
        "@media screen and (min-width: 1000px) ":{
            fontSize: '20px !important',
        } ,
        fontWeight: 700,
        
    },
    "& .success-box":{
        position: "absolute",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        bottom: "100px",
height: "400px",
    },
    "& .SuccessContainer":{
        background: "white",
        boxShadow: "0px 4px 10px 0px #00000029",
        borderRadius: "8px",
        width:'90%',
        height: "100%",
display: "flex",
flexDirection: "column",
justifyContent: "center",
alignItems: "center",
    }
})
const DeletStyles = styled(Modal)({
    "& .CancleEditButton": {
        color: "#1764A0 !important",
        border: "none",
        textTransform: "capitalize",
        fontWeight: 700, 
        fontSize: '16px',
        "@media screen and (min-width: 360px) and (max-width: 600px)":{
            fontSize: '14px',
        } 
    },
    "& .saveEditButton":{
        "@media screen and (min-width: 360px) and (max-width: 600px)":{
            fontSize: '14px !important',
        } 
    },
    "& .deletAccountConatiner":{
        width: "70%",
        "@media screen and (min-width: 260px) and (max-width: 600px)":{
            width: "90%",
        } ,
        borderRadius:"8px",
        background:"white"
    },
    "& .buttonContainer":{
        display: "flex",
        justifyContent:"flex-end",
        gap:"20px",

    },
    "& .inputContainer":{
        paddingTop:"32px",
        paddingLeft:"40px",
        paddingBottom:"32px",
        paddingRight:"48px",
        display:'flex',
        flexDirection:"column",
        gap:'40px',
        "@media screen and (min-width: 260px) and (max-width: 600px)":{
            fontSize: '14px',
            paddingLeft:"15px",
            paddingBottom:"15px",
            paddingRight:"15px",
        } 
    },
    "& .deletTitle": {
        borderBottom: "1px solid #E2E8F0 !important",
        padding: "26px",
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    "& .editTitle": {
        background: "#FFFFFF",
        borderBottom:"",
        width: "100%",
        height: "77px",
        display: "flex",
        borderRadius:"8px 8px 0 0",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "0px 22px 0px 40px",
        boxSizing:"border-box",
        "@media screen and (min-width: 260px) and (max-width: 600px)":{
          padding: "0px 15px 0px 15px",
        }
      },
      "& .editDeleteTitle":{
        fontFamily: "Inter",
        fontSize: "24px",
        fontWeight: 700,
        lineHeight: "24px",
        color: "#000000",
        "@media screen and (max-width: 414px)": {
            fontSize: "14px",
        },
        "@media screen and (min-width: 415px) and (max-width: 600px)": {
            fontSize: "16px",
        },
        "@media screen and (min-width: 601px) and (max-width: 950px)": {
            fontSize: "20px",
        }
      }
})
const PasswordStyles = styled(Modal)({
    "& .passwordTitle": {
        borderBottom: "1px solid #E2E8F0 !important",
        padding: "26px",
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    "& .input-outlined": {
        width: "100%",
    },
    "& .input-label": {
        fontWeight:700,
        fontSize: "16px",
        color:'black',
        marginBottom:"4px",
        "@media screen and (min-width: 260px) and (max-width: 414px)": {
            fontSize: "12px",
        },
        "@media screen and (min-width: 415px) and (max-width: 600px)": {
            fontSize: "14px",
        },
        "@media screen and (min-width: 601px) and (max-width: 950px)": {
            fontSize: "16px",
        }

    },
    "& .validation-text":{
        fontSize: "14px",
        color:'black',
        marginBottom:"4px",
        "@media screen and (min-width: 260px) and (max-width: 414px)": {
            fontSize: "12px",
        },
        "@media screen and (min-width: 415px) and (max-width: 600px)": {
            fontSize: "14px",
        },
        "@media screen and (min-width: 601px) and (max-width: 950px)": {
            fontSize: "14px",
        }
    },
    "& .passwordContainer": {
        "@media screen and (min-width: 200px) and (max-width: 1000px)": {
            width: "90%",
            maxHeight:'100%'
        },
        "@media screen and (min-width: 1000px)": {
            width: "50%",
            maxHeight:'100%',
        },
        maxHeight:'100%',
        background: "white",
        borderRadius: "8px"
    },
    "& .editPassowrdTitle": {
        fontFamily: "Inter",
        fontSize: "24px",
        fontWeight: 700,
        lineHeight: "24px",
        color: "#000000",
        "@media screen and (max-width: 414px)": {
            fontSize: "14px",
        },
        "@media screen and (min-width: 415px) and (max-width: 600px)": {
            fontSize: "16px",
        },
        "@media screen and (min-width: 601px) and (max-width: 950px)": {
            fontSize: "20px",
        }
    },
    "& .cancelButton": {
        cursor: "pointer"
    },
    "& .submitButton": {
        fontFamily: "Inter",
        fontSize: "16px",
        fontWeight: 700,
        textTransform: "capitalize"
    },
    "& .CancleEditButton": {
        color: "#1764A0",
        border: "none",
        textTransform: "capitalize"
    },
    "& .saveEditButton": {
        color: "#FFFFFF",
        background: "#1764A0",
        borderRadius: "8px",
    },
    "& .submitButton.MuiButtonBase-root.Mui-disabled":{
        background:"#1764A080 !important"
    },
    "& .buttonContainer": {
        display: "flex",
        justifyContent: "end",
        padding: "0px 0 20px 0",
        gap: "16px"
    },
    "& .inputContainer":{
        "@media screen and (min-width: 260px) and (max-width: 1000px)": {
            padding: "40px 22px 0px 22px",
        },
        "@media screen and (min-width: 1000px)": {
            padding: "40px 22px 0px 22px",
        },
        "@media screen and (min-height: 260px) and (max-height: 400px)": {
            maxHeight: "300px",
        },
        "@media screen and (min-height: 400px) and (max-height: 600px)": {
            maxHeight: "430px",
        },
        "@media screen and (min-height:600px)": {
            maxHeight: "430px",
        },
        overflowY: "auto",
        display:'flex',
        flexDirection:'column',
        gap:'16px',
    },
})

const EditProfileContainer = styled(Dialog)({
    "& .PhoneInput":{
        height:"54px !important",
    },
    "& .PhoneInputInput":{
        "@media screen and (min-width: 200px) and (max-width: 600px)": {
            width:"78% !important",
            left:"20% !important"
        }
    },
    "& .editprofile":{
        maxHeight:"480px !important",
        "@media screen and (min-width: 200px) and (max-width: 600px)": {
            maxHeight:"500px !important"
        }
    },
    "& .email-edit-container":{
        padding: "40px 40px 0px 40px" ,
        display:'flex',
        flexDirection:'column',
        gap:'14px',
        "@media screen and (min-width: 200px) and (max-width: 600px)": {
            gap: '0px',
            paddingLeft: "15px",
            paddingRight: "15px",
            paddingTop: '24px'
        }
    },
    "& .input-calender":{
        width:'100%',
        padding:"8px",
        paddingLeft:"16px",
        borderRadius:"8px",

    },
    "& .emailverifyText":{
        fontSize:'16px',
        fontWeight:500,
        color:'#64748B'
    },
    "& .dialog": {
      width: "75%",
        "@media screen and (min-width: 200px) and (max-width: 600px)": {
            width: "90%",
        },
      height: "auto",
      borderRadius: "8px",
      background: "#FFFFFF"
    },
    "& .submitButton.Mui-disabled":{
        background: "#1764A080 !important",
    },
    "& .editTitle": {
      background: "#FFFFFF",
      borderBottom:"",
      width: "100%",
      height: "77px",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "0px 22px 0px 40px",
      boxSizing:"border-box",
      "@media screen and (min-width: 360px) and (max-width: 600px)":{
        padding: "0px 15px 0px 15px",
      }
    },
    "& .email_id":{
        fontWeight: 700, 
        lineHeight:"24px",
        color:"#000000",
        fontSize:"16px",
        "@media screen and (min-width: 260px) and (max-width: 414px)":{
          fontSize:"12px",
        },
        "@media screen and (min-width: 415px) and (max-width: 600px)":{
          fontSize:"14px",
        },
    },
    "& .editTitleName": {
      fontFamily:"Inter", 
      fontSize:"24px", 
      fontWeight: 700, 
      lineHeight:"24px",
      color:"#000000",
      "@media screen and (min-width: 360px) and (max-width: 414px)":{
        fontSize:"14px",
      },
      "@media screen and (min-width: 415px) and (max-width: 600px)":{
        fontSize:"16px",
      },
      "@media screen and (min-width: 601px) and (max-width: 950px)":{
        fontSize:"20px",
      }
    }, 
    "& .cancleButton": {
      curcer: "pointer"
    },
    "& .medialContent": {
      height: 'calc(100vh - 64px - 64px)',
      overflowY: 'scroll',
      overflowX: 'scroll',
      '&::-webkit-scrollbar': {
        width: '12px',
      },
      '&::-webkit-scrollbar-track': {
        background: '#f1f1f1',
      },
      '&::-webkit-scrollbar-thumb': {
        background: '#1764A0', 
        borderRadius: '6px',
      },
      '&::-webkit-scrollbar-thumb:hover': {
        background: '#1e88e5',
      },
      scrollbarColor: '#1764A0 #f1f1f1',
      scrollbarWidth: 'auto',
    },
    "& .MuiDialogContent-root": {
       padding: "40px"
    },
    "& .inputDate,.input-calender": {
        border: "1px solid rgb(148, 163, 184)",
        borderRadius: "8px",
        width: "94%",
        color: "#94A3B8",
        fontFamily: "Inter",
        fontSize: "14px",
        fontWeight: "400",
        height: "33px",
        "&::placeholder": {
            color: "#94A3B8",
        },
        display:"flex",
        alignItems:'center',
        justifyContent:'space-between',
    },
    "& .react-datepicker__current-month": {
        color: "#1764A0"
    },
    "& .MuiPaper-root": {
        color: "#1764A0"
    },
    "& .react-datepicker": {
        border: "none",
        boxShadow: "0px 4px 10px 0px #00000029"
    },
    "& .react-datepicker-wrapper": {
        width: "100%",
        border: "none"
    },
    "& .submitButton": {
        fontFamily: "Inter",
        fontSize: "16px",
        fontWeight: 700,
        textTransform: "capitalize"
    },
    "& .CancleEditButton": {
        color: "#1764A0",
        border: "none",
        textTransform: "capitalize"
    },
    "& .saveEditButton": {
        color: "#FFFFFF",
        background: "#1764A0",
        width: "71px",
        height: "44px",
        borderRadius: "8px",
    },
    "& .buttonContainer": {
        display: "flex",
        justifyContent: "end",
        padding: "17px 0 0 0",
        gap: "16px"
    },
    "& .buttonContainer2": {
        display: "flex",
        justifyContent: "end",
        padding: "39px",
        gap: "16px",
        "@media screen and (min-width: 200px) and (max-width: 600px)":{
            padding: "20px",
        },
    },
    "& .changeEmailDialog": {
        width: "824px",
        "@media screen and (min-width: 200px) and (max-width: 1000px)":{
            width: "90% !important",
        },
        height: "fit-content",
        borderRadius: "8px",
        background: "#FFFFFF"
    }
  });
  const TextFieldContainer = styled(TextField)({
    "& .MuiOutlinedInput-root":{
        borderRadius: "8px",
        borderColor: "1px solid rgba(148,163,184,1)",
        color: "rgba(0,0,0,1)",
        height: "50px",
        fontFamily: "Inter",
        fontSize: "14px",
        padding: "4%",
        marginBottom: "10px",
        outline: "none",
    }
  })

// Customizable Area End
