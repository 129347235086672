import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  appliedCoupon:any;
  isLoading:boolean;
  singlePackageData:any;
  dayPlan:any;
  itenaryData:any;
  activeDay:any;
  expandedItems: any,
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class PackageDetailController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getSinglePackageApiCallId="";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      appliedCoupon:null,
      isLoading:false,
      singlePackageData:null,
      dayPlan:[],
      itenaryData:[],
      activeDay: '1',
      expandedItems: {},
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }
  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJson && !responseJson.errors) {
        if (apiRequestCallId === this.getSinglePackageApiCallId) {
          this.singlePackageRes(responseJson)
        }
        this.parseApiCatchErrorResponse(errorReponse);
      }
    }
    // Customizable Area End
  }
  async componentDidMount(): Promise<void> {
    let clientIDkey:string=this.props.navigation.getParam("id")
    if(clientIDkey!=""){
        this.getSinglePackage(clientIDkey);
    }
  }
  singlePackageRes=(responseJson:any)=>{
    if(!responseJson.errors && responseJson){
      this.setState({singlePackageData:responseJson.data, isLoading:false})
      let datePackage = localStorage.getItem("startDatePackage")
    if(datePackage!=null){
     this.dateGetInText(responseJson.data?.attributes?.day,datePackage)
    }else{
      this.dayArrayFun(responseJson.data?.attributes?.day)
    }
     
      let itenanriesData = responseJson.data.attributes.itenanries.data.map((item:any,index:any)=>{
      return {
        "id": item.attributes.id,
        "day":this.state.dayPlan[index],
        "title":item.attributes.title,
        "description": item.attributes.description,
        "image_url": item.attributes.image_url,
      }
      })
      this.setState({itenaryData:itenanriesData})
  }
  }
  dayArrayFun=(item:any)=>{
    const parts = item.split('/');
const firstElementAfterSlash = parts[1];
const numericPart = parseInt(firstElementAfterSlash, 10);
const resultArray = [];
for (let i = 1; i <= numericPart; i++) {
  resultArray.push({
    id: i.toString(),         
    value: `Day ${i}`        
  });
}
this.setState({dayPlan:resultArray})

  }
  formatDate = (dateString:any) => {
    const date = new Date(dateString);
    const day = date.getDate(); // 15
    const month = date.toLocaleDateString('en-US', { month: 'long' }); 

    return `${day} ${month}`;
  };
  getNextDates = (startDate:any, count:any) => {
    const dates = [];
    let currentDate = new Date(startDate);

    for (let i = 0; i < count; i++) {
      dates.push(this.formatDate(currentDate));
      currentDate.setDate(currentDate.getDate() + 1); 
    }

    return dates;
  };
  dateGetInText=(item:any, startDate:any)=>{
    const parts = item.split('/');
    const firstElementAfterSlash = parts[1];
    const numericPart = parseInt(firstElementAfterSlash, 10);
    const dates = this.getNextDates(startDate, numericPart);
    const resultArray = dates.map((date, index) => ({
      id: (index + 1).toString(),
      value: `day ${index + 1}`,
      date: date,
    }));
    this.setState({dayPlan:resultArray,})
    

  }
  scrollToItenary = (dayId: string) => {
    this.setState({ activeDay:dayId});
    const matchedItenary = this.state.itenaryData.find(
      (itenary: any) => itenary.day.id === dayId
    );
  
    if (matchedItenary) {
      const targetElement = document.getElementById(`itenary-${matchedItenary.id}`);
      const scrollableContainer = document.querySelector('.scrollable-container');
      if (targetElement && scrollableContainer) {
        const containerTop = scrollableContainer.getBoundingClientRect().top;
        const elementTop = targetElement.getBoundingClientRect().top;
        const scrollOffset = elementTop - containerTop + scrollableContainer.scrollTop;
        scrollableContainer.scrollTo({
          top: scrollOffset,
          behavior: "smooth"
        });
      }
    }
  };
  getSinglePackage = (paramId:string) => {
    this.setState({
      isLoading: true,
    });

    const PCheaders = {
      "Content-Type": configJSON.exampleApiContentType,
    };

    const getSingleBlogMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getSinglePackageApiCallId = getSingleBlogMsg.messageId;

    getSingleBlogMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.SinglePackageEndPoint+paramId
    );

    getSingleBlogMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(PCheaders)
    );
    getSingleBlogMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getSingleBlogMsg.id, getSingleBlogMsg);
    return true;
  };
  handleApplyCoupon = (couponCode:any) => {
    if (this.state.appliedCoupon === couponCode) {
     this.setState({appliedCoupon:null})
    } else {
        this.setState({appliedCoupon:couponCode})
    }
  };

  getGridSize = (numImages:any) => {
    if (numImages === 1) {
        return 12; 
    } else if (numImages === 2) {
        return 6; 
    } else {
        return 4; 
    }
};
handleChildData = (data:any) => {
  data.map((item:any)=>{
     return {
       "image":item.attributes.image,
       "price": item.attributes.price,
       "package_type": item.attributes.package_type,
       "name":item.attributes.name,
      
     }
   })
};

toggleReadMore = (idele:any) => {
  this.setState(prevState => ({
    expandedItems: {
      ...prevState.expandedItems,
      [idele]: !prevState.expandedItems[idele], 
    }
  }));
};

stripHtmlTags = (html:any) => {
  const tempDiv = document.createElement("div");
  tempDiv.innerHTML = html;
  return tempDiv.innerText || tempDiv.textContent || "";
};
  // Customizable Area End
}
