Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "cfapiintegrationforhimalayanbankapi";
exports.labelBodyText = "cfapiintegrationforhimalayanbankapi Body";

exports.btnExampleTitle = "CLICK ME";
exports.successText = "Congratulations! You are successfully booked your tickets";
exports.errorText = "An error occurred while processing your payment";
exports.bookTitle = "Complete your bookings";
// Customizable Area End