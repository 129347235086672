import React from "react";
import MyPackageController, { Props } from "./MyPackageController.web";
import HeaderMenuBlock from "../../../blocks/navigationmenu/src/HeaderMenu.web";
import { CompletedSvg, UpcomingSvg, TravelDateSvg, DownloadSvg, group_event_card } from "./assets";
import {
    Box,
    Button,
    Tab,
    Tabs,
    Typography,
    withStyles,
} from "@material-ui/core";
import TabDesign from "../../../components/src/TabCustom";

const CustomTabss = withStyles({
    scroller: {
        overflow: 'auto !important',
        scrollbarColor: "#1764A0 #f1f1f1 !important",
        scrollbarWidth: "thin"
    },
    indicator: {
        backgroundColor: '#1764A0',
    },
    root: {
        background: 'white',
    },
   
})(Tabs);

const CustomTab = withStyles({
    root: {
        textTransform: 'none', minWidth: 72, marginRight: '8px',
        width: "50%",
        fontWeight: 'bold',
       

        background: 'white',
        '&$selected': {
            backgroundColor: 'rgba(233, 243, 252, 1)',
            color: '#000',
        },
        '&:focus': {
            color: '#000',
        },
        '&:hover': {
            backgroundColor: 'rgba(233, 243, 252, 1)',
        },
      
    },
    selected: {},
})(Tab);



export default class MyPackage extends MyPackageController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    getUpcomingUI = () => {
        return <Box data-test-id="upcoming-container" sx={webStyleMyPackage.flightDetailContainer}>
            <Box sx={webStyleMyPackage.upComingDetails} style={{ display: "flex", flexDirection: "column", gap: "24px" }}>
                <img src={group_event_card} />
            </Box>
            <Box style={{ display: 'flex' }} sx={webStyleMyPackage.bookingBtnContainer}>
                <Button data-test-id='change-travel-date' style={{ height: '44px', textTransform: "none", fontWeight: 700, fontSize: '16px', display: 'flex', alignItems: "center", gap: "10px", justifyContent: "flex-start" }}><img src={TravelDateSvg} style={{ marginRight: "10px" }} />Change Travel Date</Button>
            </Box>
        </Box>
    }

    getCompletedUI = () => {
        return (
            <Box data-test-id="completed-container" sx={webStyleMyPackage.flightDetailContainer}>
                <Box sx={webStyleMyPackage.upComingDetails} style={{ display: "flex", flexDirection: "column", gap: "24px" }}>
                    <img src={group_event_card} />
                </Box>
            </Box>
        )
    }


    render() {
        return (
            // Customizable Area Start
            <div style={{ position: "relative" }}>
                <Box sx={webStyleMyPackage.headerContainer}>
                    <HeaderMenuBlock navigation={this.props.navigation} id={"MyTrips"} />
                </Box>
                <Box sx={webStyleMyPackage.mainContainer} style={{ position: "absolute", top: "100px", display: "flex", flexDirection: "column", gap: "58px" }}>
                    <Typography style={webStyleMyPackage.title}>Planned Packages</Typography>
                    <Box sx={webStyleMyPackage.boxContainer}>
                        <TabDesign state={this.state} onClickTab={this.redirectNab} />

                        <div style={{ borderBottom: "1px solid #E2E8F01", boxShadow: "0px 2px 10px 0px #0000001A", paddingLeft: '8px', paddingTop: "11px", paddingBottom: '11px' }}>
                            <CustomTabss data-test-id="mytrip-tabs" value={this.state.selectedTab} onChange={this.handleTabChange}>
                                <CustomTab label={<span style={{ display: "flex", alignItems: "center", gap: "10px" }}><img src={UpcomingSvg} />Upcoming</span>} value={'Upcoming'} />
                                <CustomTab label={<span style={{ display: "flex", alignItems: "center", gap: "10px" }}><img src={CompletedSvg} />Completed</span>} value={'Completed'} />
                            </CustomTabss>
                        </div>
                        <Box sx={webStyleMyPackage.dataContainer}>
                            {this.state.selectedTab && <Box sx={webStyleMyPackage.detailsContainer}>
                                <Box sx={webStyleMyPackage.ticketHeader} style={{ display: "flex", justifyContent: "space-between", boxShadow: "0px 2px 10px 0px #0000001A", flexWrap: 'wrap', gap: '20px' }}>
                                    <div>
                                        <Typography style={webStyleMyPackage.flight_heading}>Goa Delight Group Departure 2024, 6 June,2024</Typography>
                                        <Typography style={{ ...webStyleMyPackage.flight_content, marginTop: "8px" }}>Roundtrip Flight | Booking ID - NXERHUIOP1226TB556 </Typography>
                                    </div>
                                    <Box style={{ display: 'flex', gap: '16px', flexWrap: "wrap" }}>
                                        <Button variant="contained" style={{ height: '44px', backgroundColor: '#1764A0', textTransform: "none", color: 'white', fontWeight: 700, fontSize: '16px' }} startIcon={<img src={DownloadSvg} />}>
                                            Download Ticket
                                        </Button>
                                        <Button variant="contained" style={{ height: '44px', backgroundColor: '#1764A0', textTransform: "none", color: 'white', fontWeight: 700, fontSize: '16px' }} startIcon={<img src={DownloadSvg} />}>
                                            Download Invoice
                                        </Button>
                                    </Box>
                                </Box>
                                {this.state.selectedTab === 'Upcoming' && this.getUpcomingUI()}
                                {this.state.selectedTab === 'Completed' && this.getCompletedUI()}
                            </Box>
                            }
                        </Box>
                    </Box>
                </Box>
            </div>
            // Customizable Area End
        );
    }
}

const webStyleMyPackage = {
    dataContainer: {
        padding: {
            xs: "36px 8px 20px",
            sm: "36px 20px 20px",
            md: "36px 36px 20px",
            lg: "36px 56px 20px"
        }
    },
    ticketHeader: {
        padding: {
            xs: "8px",
            sm: "16px",
            md: "32px",
            lg: '32px',
        }

    },
    boxContainer: {
        boxShadow: "0px 4px 10px 0px #00000029",
        borderRadius: "8px",
        width: "100%",
        background: "white",
        minHeight: "500px"
    },
    detailsContainer: {
        boxShadow: "0px 4px 10px 0px #00000029",
        borderRadius: "8px",
        width: "100%",
        background: "white",
        minHeight: "300px"
    },
    mainContainer: {
        width: {
            xs: "96%",
            sm: "97%",
            md: "98%",
            lg: "97%"
        },
        padding: {
            xs: "10px",
            lg: "24px"
        }
    },
    headerContainer: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
        gap: "56px",
        overFlowX: 'hidden',
        height: {
            xs: '930px',
            sm: '800px',
            md: '580px',
            lg: '490px'
        },
        background: "linear-gradient(180deg, #013B68 84.25%, #0175D0 100%)",
    },


    flightDetailContainer: {
        paddingLeft: {
            sm: "10px",
            md: "20px",
            lg: "32px"
        },
        paddingTop: "40px",
        paddingBottom: "35px",
        display: 'flex',
        alignItems: "center",
        gridGap: {
            xs: "20px",
            md: "100px",
            lg: "100px"
        },
        flexWrap: "wrap"
    },
    bookingBtnContainer: {
        flexDirection: {
            xs: "column",
            sm: "row",
            md: "column",
            lg: "column",
        },
        padding: {
            xs: "8px"
        },
        gap: {
            xs: "0px",
            lg: '30px'
        }
    },
    upComingDetails: {
        width: {
            sm: "95%",
            md: "50%",
            lg: "50%",
        }
    },
    title: {
        fontWeight: 700,
        fontSize: 32,
        color: "white",
        marginLeft: "32px"
    },
    flight_heading: {
        fontSize: "16px",
        fontWeight: 700
    },
    flight_content: {
        fontSize: "14px",
        fontWeight: 500
    },
}