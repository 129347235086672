export const userIcon = require("../assets/profile-circle1.png");
export const SecureIcon = require("../assets/image_Secure.png");
export const indigoIcon = require("../assets/indigoIcon.png");
export const image_Vector = require("../assets/image_Vector.png");
export const image_trip = require("../assets/image_trip.png");
export const image_cloud = require("../assets/image_cloud_upload_24px.png")
export const UpcomingSvg = require("../assets/Upcoming.svg")
export const CancelledSvg = require("../assets/Cancelled.svg")
export const CompletedSvg = require("../assets/Completed.svg")
export const DownloadSvg = require("../assets/Download.svg")
export const TravelDateSvg = require("../assets/TravelDate.svg")
export const NoProfileSvg = require("../assets/Noprofile.svg")
export const CheckIcon = require("../assets/CheckIcon.svg")
export const backArrowIcon = require("../assets/back-arrow.svg")
export const successIcon = require("../assets/Success.svg")
export const heartIcon = require("../assets/HeartEmoji.svg")
export const errorIcon = require("../assets/error.png")
export const group_event_card = require("../assets/group_event_card_.png")