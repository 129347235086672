import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
const ERROR_MESSAGES:any= {
    required: 'Password is required',
    invalid: 'Password must meet the requirements',
};
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

export interface S {
  // Customizable Area Start
  successMsg: string;
  serverError:string;
  successPage:boolean;
  password: string;
  confirmPassword: string;
  reTypePassword: string;
  data: any[];
  passwordHelperText: string;
  enablePasswordField: boolean;
  enableConfirmPasswordField: boolean;
  enableReTypePasswordField: boolean;
  errors: {
    password?: string;
    confirmPassword?: string;
  };
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class ChangePasswordController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  changePwdApiCallId: any;
  validationApiCallId: string = ""
  

  currentCountryCode: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
      successPage:false,
      successMsg: "",
      serverError:"",
      password: "",
      confirmPassword: "",
      reTypePassword: "",
      data: [],
      passwordHelperText: "",
      enablePasswordField: false,
      enableConfirmPasswordField: false,
      enableReTypePasswordField: true,
      errors: {
        password: "",
        confirmPassword: "",
      },
      // Customizable Area End
    };

    // Customizable Area Start
    

    
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId && responseJson) {
         if (apiRequestCallId === this.changePwdApiCallId) {
            if (!responseJson.errors) {
              this.changePwdRes(responseJson);
            } else {
              //Check Error Response
              this.setState({serverError:"Invalid token"})
            }
  
            this.parseApiCatchErrorResponse(errorReponse);
          }
      }
    }

    // Customizable Area End
  }

  // Customizable Area Start
 



  handleClickShowPassword = () => {
    this.setState({
      enablePasswordField: !this.state.enablePasswordField,
    });
  };
  handleClickShowConfirmPassword = () => {
    this.setState({
      enableConfirmPasswordField: !this.state.enableConfirmPasswordField,
    });
  };
  

  validatePassword = (password: string) => {
    let errors:any = {};

    if (!password.trim()) {
        errors.password = ERROR_MESSAGES.required;
        this.setState({ errors });
        return false;
    }

   else if(
    !this.checkForCapitalLetter(password) ||
    !this.checkLowerCaseLetter(password) ||
    !this.checkForNumber(password) ||
    !this.checkForSpecialChar(password) ||
    !this.checkMinLength(password)
) {
    errors.password = ERROR_MESSAGES.invalid;
    this.setState({ errors });
    return false;
}


else if (this.state.errors.password) {
        delete this.state.errors.password;
        this.setState({ errors });
    }

    return true;
}

  validateConfirmPassword = (password: string, confirmPassword: string) => {
    if (!confirmPassword.trim()) {
      this.setState({errors: { ...this.state.errors, confirmPassword: 'Confirm Password is required',
      },}) 
      return false
    }else {
      if (password!= confirmPassword ){
        this.setState({errors: { ...this.state.errors,confirmPassword: 'Password and Confirm Password does not match',
        },}) 
        return false
      }
    }
    return true; 
  };


  setPassword = (text: string) => {
    this.setState({
      password: text,
      errors: { ...this.state.errors, password:'', confirmPassword:'' },
      serverError:"",
    });
  };

  setConfirmPassword(text: string) {
    this.setState({
      confirmPassword: text,
      errors: { ...this.state.errors, password:'', confirmPassword:'' },serverError:"",
    });
  }

  changePwdRes = (responseJson: any) => {
    if (responseJson) {

    this.emptyState();
    this.setState({successPage:true})

    }
  };
  handleContinue=()=>{
    localStorage.removeItem("authToken")
    this.props.navigation.navigate("EmailAccountLoginBlock");
  }
  handleSubmit = () => {
    const { password, confirmPassword } = this.state;
    if(!this.validatePassword(password)) return ;
    if(!this.validateConfirmPassword(password, confirmPassword)) return ;

    this.submitForm();
  };
  submitForm = () => {
    const header = {
      "Content-Type": configJSON.forgotPasswordAPiContentType,
      "token":localStorage.getItem("authToken"),
    };

    const attrs = {
        new_password: this.state.password,
        token: localStorage.getItem("authToken"),
    };
    const httpBody = {
      data: attrs,
    };

    const requestMessageEnum = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.changePwdApiCallId = requestMessageEnum.messageId;
    requestMessageEnum.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.changePwdAPiEndPoint
    );

    requestMessageEnum.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessageEnum.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessageEnum.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiVerifyOtpMethod
    );

    runEngine.sendMessage(requestMessageEnum.id, requestMessageEnum);
    return true;
  };
 
  
  emptyState = () => {
    this.setState({
      errors: {
        ...this.state.errors,
        password: "",
        confirmPassword: "",
      },
      password: "",
      confirmPassword: "",
    });
  };
  checkForSpecialChar = (strElemt: string) => {
    return /[!@#$%^&*(),.?":{}|<>]/.test(strElemt);
  };

  checkForNumber = (numElemt: string) => {
    return /\d/.test(numElemt);
  };

  checkMinLength = (text: string) => {
    const minLength = 8;
    return text.length >= minLength;
  };
  
  checkForCapitalLetter = (strkey: string) => {
    return /[A-Z]/.test(strkey);
  };

  checkLowerCaseLetter = (text: string) => {
    return /[a-z]/.test(text);
  };

  // Customizable Area End
}
