import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { isValidPhoneNumber } from 'react-phone-number-input';
import React,{RefObject} from "react"; 
export interface ProfileType{
  "data": {
    "id":string,
    "type":string,
  }
}
export interface StateType {
  [key: string]: string; // State codes are keys and state names are values
}
export interface CountryType{
  "data": CountryArry[]
}
export interface StateDataType{ code: string, name:string }
export interface CountryArry{

        "id": string,
        "type": string,
        "attributes": {
            "name": string,
            "emoji_flag":string,
            "country_code": string
        }
}
export interface ZipType{
  "country":string,"state":string,"city":string,
  "error":[{"msg":string}]
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

export interface S {
  // Customizable Area Start
  gender:string;

      dateOfBirth:string;
      addressLine1:string;
      addressLine2:string;
      country:string;
      stateCode:string;
      cityName:string;
      pincode:string;
      mobileNo: string;
      passportNo:string;
      passportExDate:string;
      countryData:CountryArry[];
      tabFirst:boolean;
      tabSecond:boolean;
      stateCodeData:StateDataType[];
      cityData:string[];
      countryObject: { dialCode: string; format: string; name: string }
  errors:{
    addressLine1:string;
    addressLine2:string;
    pincode:string;
    mobileNo:string;
    dateOfBirth:string;
    gender:string;
    country:string;
    stateCode:string;
    cityName:string;
    passportNo:string;
      passportExDate:string;
  }
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmailAccountDetailController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  countryCodeApiCallId ="";
  stateCodeApiCallId="";
  cityCodeApiCallId="";
  createAccountProfileApiCallId="";
  scrollRef: RefObject<HTMLDivElement> ;
  zipcodeCodeApiCallId="";
  skipApiCallId:string =''
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
    this.receive = this.receive.bind(this);
    this.scrollRef = React.createRef<HTMLDivElement>();
    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
      cityData:[],
      countryData:[],
      stateCodeData:[],
      gender: "",
      tabFirst: true,
      tabSecond:false,
      countryObject: { dialCode: "", format: "", name: "",},
      dateOfBirth: "",
      addressLine1:"",
      addressLine2:"",
      country:"",
      stateCode:"",
      cityName:"",
      pincode:"",
      mobileNo:"",
      passportNo:"",
      passportExDate:"",
      errors:{
        addressLine1:"",
        addressLine2:"",
        pincode:"",
        mobileNo:"",
        dateOfBirth:"",
        gender:"",
        country:"",
        stateCode:"",
        cityName:"",
        passportNo:"",
          passportExDate:"",
      }
      // Customizable Area End
    };

    // Customizable Area Start
    

    // Customizable Area End
  }

  receive = async (from: string, message: Message) => {

    runEngine.debugLog("Message Received", message);

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
     
 if (apiRequestCallId != null) {
  switch (true) {
    case apiRequestCallId === this.countryCodeApiCallId &&  this.countryCodeApiCallId != null:
      this.handleGetAllCountryList(responseJson);
      break;
      case apiRequestCallId === this.stateCodeApiCallId &&  this.stateCodeApiCallId != null:
        this.handleGetAllStateList(responseJson);
        break;
      case apiRequestCallId === this.cityCodeApiCallId &&  this.cityCodeApiCallId != null:
        this.handleGetAllCityList(responseJson);
        break;
     case apiRequestCallId === this.createAccountProfileApiCallId && this.createAccountProfileApiCallId!=null:
      this.profileResponse(responseJson)
      break;
      case apiRequestCallId === this.zipcodeCodeApiCallId && this.zipcodeCodeApiCallId!=null:
        this.zipcodeResponse(responseJson)
        break;
      // Customizable Area Start
      case apiRequestCallId === this.skipApiCallId &&this.skipApiCallId != null:
      this.skipResponse()
      // Customizable Area End
    default:
}
}
    }
  };

  // Customizable Area Start
  async componentDidMount() {
    
      this.countrylistFunct();
      if (this.scrollRef.current) {
        window.scrollTo(0, 0);
      }
  }
  handleGetAllCountryList=(responseJson:CountryType)=>{
this.setState({countryData:responseJson.data})
  }
  handleGetAllStateList=(responseJson:StateType)=>{
   if(!responseJson.error){
    const stateOptions = Object.entries(responseJson).map(([stateCode, stateName]) => ({
      code: stateCode,
      name: stateName
  }));
  this.setState({stateCodeData:stateOptions})
   }
      }
      handleGetAllCityList=(responseJson:string[])=>{
        if (!Array.isArray(responseJson)) {
          this.setState({ cityData: [] });
          return;
        }
    
        this.setState({ cityData: responseJson });
      }
  statelistFunct = (country:string) => {
    const httpBody = {
      country: country,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.stateCodeApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.apiEndPointGetState
    );

    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  validatePhoneNumber = (phoneNumber: string, country: { dialCode: string; format: string; name: string }) => {
    const { dialCode, format } = country;
    
    const numberWithCode = phoneNumber.trim();
    const numberWithoutCode = numberWithCode.startsWith(dialCode)
      ? numberWithCode.replace(dialCode, "").trim()
      : numberWithCode;
  
   
    const maxLength = format.replace(/[^.]/g, "").length - dialCode.trim().length; 
    if (numberWithoutCode.length < maxLength) {
      return {
        error: `Please add ${maxLength - numberWithoutCode.length} more digit(s).`,
      };
    }
  
    if (numberWithoutCode.length > maxLength) {
      return {
        error: `Please enter a valid ${maxLength}-digit number, including the country code.`,
      };
    }
  
    return { error: "" }; // No error
  };
  countrylistFunct = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.countryCodeApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.apiEndPointGetCountry
    );

    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  cityListFunct = (itemkey:string, countryItem:string) =>{
    const header = {"Content-Type": configJSON.contentTypeApiAddDetail, "Token": localStorage.getItem("token"),};
    const httpBody = {
      "state":itemkey,
      "country": countryItem,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.cityCodeApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.cityApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;

  }
  zipcodeFunct = (value:string) => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.zipcodeCodeApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.zipcodeEndPoint+value
    );

    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  goToRegistration=()=>{
    this.props.navigation.navigate("EmailAccountRegistration")
  }
  goToDetails=()=>{
    this.props.navigation.navigate("EmailAccountDetail")
  }
 


  
 
  validatePinCode(text: string) {
    
    if (!text.trim() || !/^\d{1,15}$/.test(text)) {
      this.setState({errors: {
        ...this.state.errors,
        pincode: 'Please enter your PIN code.',
      },}) 

      return false
    }
  
    return true; 
  }

validateNumber(text: string | undefined) {
  if (!text) {
    this.setState({errors: {
      ...this.state.errors,
      mobileNo: 'Please enter your mobile number',
    },}) 

    return false
  }else{
    if (Object.values(this.state.countryObject).every(value => value)) {
      let { error } = this.validatePhoneNumber(this.state.mobileNo, this.state.countryObject);
      if (error) {
        this.setState({
          errors: {
            ...this.state.errors,
            mobileNo: error,
          },
        });
        return false;
      }
    }
  }
  return true; 
}

setMobileNo(text:string) {
    this.setState({
        mobileNo: text,
        errors: { ...this.state.errors, mobileNo: '', pincode:"", addressLine1:"", addressLine2:"" }
    });
}
  setPincode(text:string) {
    const regexpin = /\D/g;
    const cleanedText = text.replace(regexpin, '');
    if (cleanedText.length > 15) {
      return; 
    }
    this.setState({
      pincode: cleanedText,
      errors: { ...this.state.errors, mobileNo: '', pincode:"", addressLine1:"", addressLine2:"" }
    });
  }
  setPassportNumber=(text:string)=>{
    const regex = /[!@#$%^&*(),.?":{}|<>]/;
    const cleanedText = text.replace(regex, '');

    // Get the first 15 characters
    const truncatedText = cleanedText.substring(0, 15);

    this.setState({ passportNo: truncatedText });
  }
  setDob(text:string){
    this.setState({
      dateOfBirth: text,
    });
  }
  validateAddress(text: string) {
    if (!text.trim()) {
      this.setState({errors: {
        ...this.state.errors,
        addressLine1: 'Please enter a full name',
      },}) 

      return false
    }
  
    return true;
  }
  
  setAdd1(text: string) {
    if (text.length > 40) {
      return; 
    }
    this.setState({
      addressLine1: text,
      errors: { ...this.state.errors, mobileNo: '', pincode:"", addressLine1:"", addressLine2:"" }
    });
  }
  
  setAdd2(text:string){
    this.setState({
      addressLine2: text,
      errors: { ...this.state.errors, addressLine2: '' }
    });
  }



  handleSubmitSave = () => {
    const { mobileNo, pincode, addressLine1,addressLine2 } = this.state;

    if(!this.validateNumber(mobileNo)) return ;
    if(!this.validatePinCode(pincode)) return ;
    if(!this.validateAddress(addressLine1)) return ;
    if(!this.validateAddress(addressLine2)) return ;
    this.submitFormProfile();
  };

  gotoLogin=()=>{
    this.props.navigation.navigate("EmailAccountLoginBlock")
  } 
  emptyState=()=>{
    this.setState({
      mobileNo:"",
      addressLine1:"",
      addressLine2:"",
      pincode:"",
      gender:"",
      passportNo:"",
      passportExDate:"",
      cityName:"",
      stateCode:"",
      country:"",
      dateOfBirth:""
     })
  }

  skipProfile = async ()=>{
    const header = {"Content-Type": configJSON.contentTypeApiAddDetail, "Token": localStorage.getItem("token"),};
    const attrs = {
      "is_skip": true
    };
    const dataProfile = {
      type: "email_account",
      attributes: attrs,
    };
    const httpBody = {
      data: dataProfile,
    };
    const requestMessageEnum = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.skipApiCallId = requestMessageEnum.messageId;
    requestMessageEnum.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.accountsProfileAPiEndPoint
    );

    requestMessageEnum.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessageEnum.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessageEnum.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );
    runEngine.sendMessage(requestMessageEnum.id, requestMessageEnum);
  }
  submitFormProfile = () => {
    
    const header = {"Content-Type": configJSON.contentTypeApiAddDetail, "Token": localStorage.getItem("token"),};
    const attrs = {
      "gender": this.state.gender,
      "date_of_birth": this.state.dateOfBirth,
      "address1":this.state.addressLine1,
      "address2": this.state.addressLine2,
      "city": this.state.cityName,
      "state":this.state.stateCode,
      "country": this.state.country,
      "zip_code": parseInt(this.state.pincode),
      "passport_number": this.state.passportNo,
      "passport_expiry_date": this.state.passportExDate,
      "phone_number":this.state.mobileNo,
      "is_skip": false
    };
    const dataProfile = {
      type: "email_account",
      attributes: attrs,
    };
    const httpBody = {
      data: dataProfile,
    };

    const requestMessageEnum = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createAccountProfileApiCallId = requestMessageEnum.messageId;
    requestMessageEnum.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.accountsProfileAPiEndPoint
    );

    requestMessageEnum.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessageEnum.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessageEnum.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );

    runEngine.sendMessage(requestMessageEnum.id, requestMessageEnum);
    return true;
  };
  zipcodeResponse=(responseJson:ZipType)=>{
  if(!responseJson.error){
    this.statelistFunct(responseJson.country)
    this.cityListFunct(responseJson.state, responseJson.country)
    this.setState({country:responseJson.country,cityName:responseJson.city, stateCode:responseJson.state})
  }else{
    this.setState({errors: {
      ...this.state.errors,
      pincode: responseJson.error[0].msg,
    }, country:"", stateCode:"", cityName:""}) 
  }
  }
  profileResponse=(responseJson:ProfileType)=>{
      if(responseJson?.data){
        this.emptyState();
        this.gotoLogin();
          }
      }

  gotoLandingPageHome=async ()=>{
    await this.skipProfile()
  }
  skipResponse = ()=>{
    this.props.navigation.navigate("LandingPage")
  }
  setGender(text: string) {
    this.setState({
      gender:text
    });
  }
  setCountry(text: string) {
    this.setState({
      country:text, pincode:""
    });
  }
  setStateCode(text: string) {
    this.setState({
      stateCode:text, pincode:""
    });
  }
  setCityName(text: string) {
    this.setState({
      cityName:text, pincode:""
    });
  }
  setPassportExDate(text: string) {
    this.setState({
      passportExDate:text
    });
  }
  navigateScreens = (pathName: string) => {
    const msg: Message = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), pathName);
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    const raiseMessage: Message = new Message(getName(MessageEnum.NavigationPayLoadMessage));
    msg.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(msg);
  };
  // Customizable Area End
}
