import React, { useState } from "react";
import Skeleton from "react-loading-skeleton";
const PackageLazyLoader = (props:any) =>  {
        const {state,src,type
        } = props
        const [imageLoaded, setImageLoaded] = useState(false);
        return (
            <div style={{ width: "100%", borderRadius: "15px", overflow: "hidden" }}>
      {!imageLoaded && type === "packageSlider" && (
        <Skeleton height={250} style={{ borderRadius: "15px" }} />
      )}
      {!imageLoaded && type === "packageSelling" && (
        <Skeleton height={350} style={{ borderRadius: "15px" }} />
      )}
       {!imageLoaded && type === "packageOffers" && (
        <Skeleton height={120} style={{ borderRadius: "10px" }} />
      )}
       {!imageLoaded && type === "packageAll" && (
        <Skeleton height={300} style={{ borderRadius: "10px" }} />
      )}
     
      

      <img
        src={src}
        onLoad={() => setImageLoaded(true)}
        style={{
          display: imageLoaded ? "block" : "none",
          width: "100%",
          borderRadius: type === "packageOffers"?"10px":"15px",
          filter: "brightness(0.7)",
        }}
        
        alt=""
      />
    </div>
            )
}
export default PackageLazyLoader;

