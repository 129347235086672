import React from "react";
import { Box} from "@material-ui/core";


const SignupNavigation = (props:any) =>  {
        const {state,
            goToRegistration,
            goToDetails,
        } = props
        return (

           <Box sx={webStyles.postionRel}>
             <Box sx={webStyles.RegistrationTabButton}>
                <button
                style={{fontSize:"15px",
                cursor:"pointer",
                borderRadius:"50%",
                border:"1px #4b5a93 solid",
                padding:"10px",
                margin:"0 10px",
                height:"50px",
                width:"50px",
                zIndex:"1",
                color: state.tabFirst ?"#fff"
                :"1px #4b5a93 solid",
                 background: state.tabFirst ?
                  "rgba(23, 100, 160, 1)"
                  : "#fff"}}
                onClick={!state.tabFirst?goToRegistration:null}
                >
                    <div>1</div>


                </button>
                <button
               style={{fontSize:"15px",
               cursor:"pointer",
               borderRadius:"50%",
               border:"1px #4b5a93 solid",
               padding:"10px",
               zIndex:"1",
               margin:"0 10px",
               height:"50px",
               width:"50px",

               color: state.registrationTab ?"#fff"
               :"1px #4b5a93 solid",
                background: state.registrationTab ?
                 "#d71f1fb3"
                 : "#fff"}}
                // onClick={goToDetails}
                >
                    <div>2</div>
                </button>
                </Box>
                <Box  className="borderSignupHeader" sx={{...webStyles.borderline,borderBottom:state.tabFirst ?"1px rgba(23, 100, 160, 1) solid":"1px #99a1c2 solid"}}></Box>
           </Box>
            )
}
const webStyles = {
    postionRel:{
        position:"relative"
    },
    RegistrationTabButton:{
        display:"flex",
        textAlign:"center",
        padding:"10px",
        margin:"20px 0px",
        borderRadius: "10px",
        background:"#fff",
        justifyContent: "space-between"
      },
      borderline:{
        position: "absolute",
        // borderBottom:" 1px #99a1c2 solid",
        width: "92%",
        top: "35px",
        left: "22px"
      }
}
export default SignupNavigation;

