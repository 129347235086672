import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

export interface S {
  // Customizable Area Start
  email:string;
  error:string
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class ForgetPasswordController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  requestEmailOTPCallId:string ="";
  stateCodeApiCallId="";
  cityCodeApiCallId="";
  createAccountProfileApiCallId="";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
     
      email:"",
      error:""
      // Customizable Area End
    };

    // Customizable Area Start
    

    // Customizable Area End
  }

  receive = async (from: string, message: Message) => {
  // Customizable Area Start
    runEngine.debugLog("Message Received", message);

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
     
 if (apiRequestCallId != null) {
  if(apiRequestCallId === this.requestEmailOTPCallId &&  this.requestEmailOTPCallId != null){
    this.sendEmailOtp(responseJson);
  }
}
    }
  };
  // Customizable Area End
  // Customizable Area Start

  setEmail=(emailValue:string)=>{
    this.setState({email:emailValue,error:""})
  }
  emailValidation=(email:any)=>{

        const emailRegex = /^[^\s@]+@[^\s@]+\.[a-zA-Z]+$/;
        if (!email.trim() || !emailRegex.test(email)) {
          
          this.setState({ error:"Invalid email format" });
          return false
        }
      
        return true; 
  }
  sendEmailOtp=(responseJson:any)=>{
    if(!responseJson.errors){
      localStorage.setItem("forgotEmail",this.state.email)
      localStorage.setItem("authToken",responseJson?.meta?.token)
      this.props.navigation.navigate("ForgotPasswordOTP")
    }else{
      this.setState({error:"Account not found"})
    }
    
  }

  goToOtpAfterEmailSubmit=()=>{
    if(!this.emailValidation(this.state.email)) return ;
    this.formOtpSubmit()
  }
  formOtpSubmit=()=>{
    const header = {
      "Content-Type": configJSON.forgotPasswordAPiContentType,
    };
    const dataObject = {
      type:"email_account",
      attributes: {
        email: this.state.email
      },
    };
    const httpBody = {
      data: dataObject,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    //GO TO REQUEST STATE
    this.requestEmailOTPCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.forgetPwdEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  gotoLoginHome= () => {
    this.props.navigation.navigate("EmailAccountLoginBlock");
  };

  navigateScreens = (pathName: string) => {
    const msg: Message = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), pathName);
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    const raiseMessage: Message = new Message(getName(MessageEnum.NavigationPayLoadMessage));
    msg.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(msg);
  };
  // Customizable Area End
}
