Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "LandingPage";
exports.labelBodyText = "LandingPage Body";

exports.btnExampleTitle = "CLICK ME";
exports.blogEndPoint ="bx_block_posts/posts";
exports.destinationEndPoint="bx_block_landingpage2/popular_destinations";
exports.whereToGoEndPoint="bx_block_location/where_to_go_suggestions";
exports.benefitsUserEndPoint="bx_block_landingpage2/travelling_benefits";
exports.offersForYouEndPoint="bx_block_landingpage2/offers";
exports.oneWayEndPoint="bx_block_cfgdsavinodeintegration/flights?"
exports.airportSearchEndPoint="bx_block_cfgdsavinodeintegration/get_airport_list?key="
// Customizable Area End