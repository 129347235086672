import React from "react";

import {
  // Customizable Area Start
  Button,
  Typography,
  Modal,
  Box,
  Tabs,
  Tab,
  withStyles,
  Radio,
  FormControl,RadioGroup,FormControlLabel, RadioProps
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import HeaderMenuBlock from "../../../blocks/navigationmenu/src/HeaderMenu.web";
import { createTheme, styled } from "@material-ui/core/styles";
import {backArrowIcon,SuccessIcon} from './assets'
import Close from '@material-ui/icons/Close';

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

const BpIcon = styled('span')(({ theme }) => ({
  borderRadius: '50%',
  width: 20,
  height: 20,
  boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
  backgroundColor: '#f5f8fa',
  backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
  '.Mui-focusVisible &': {
    outline: '2px auto rgba(19,124,189,.6)',
    outlineOffset: 2,
  },
  'input:hover ~ &': {
    backgroundColor: '#ebf1f5',
  },
  'input:disabled ~ &': {
    boxShadow: 'none',
    background: 'rgba(206,217,224,.5)',
  },
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: '#137cbd',
  backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
  '&::before': {
    display: 'block',
    width: 20,
    height: 20,
    backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
    content: '""',
  },
  'input:hover ~ &': {
    backgroundColor: '#106ba3',
  },
});

const BpRadio = (props: RadioProps) => {
  return (
    <Radio
      disableRipple
      color="default"
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      {...props}
    />
  );
}
import { AccountCircleOutlined } from "@material-ui/icons";
// Customizable Area End

import RefundManagementController, {
  Props,
} from "./RefundManagementController.web";

export default class RefundManagement extends RefundManagementController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  reviewCancellAtionUI = ()=>{
    return(
      <CancelContainer className="cancel-ui-container">
        <Box className="left-container">
          <header className="review-header">
            <Typography className="heading">Total Refund Amount</Typography>
            <Typography className="content">The refund amount is based on the traveller flight selection. You can view the detailed breakdown of the refund amount below.</Typography>
          </header>
          <Box sx={webstyle.upComingDetails} style={{ display: "flex", flexDirection: "column", gap: "24px" }}>
            <Box sx={webstyle.flightDataCont} style={{ display: "flex", flexDirection: "column", gap: "24px" }}>
              <Typography style={webstyle.flightTypeHeading}>Departure Flight -6 June, Fri</Typography>
              <Box
                sx={webstyle.upcomingDetailsCont} style={{
                  display: 'flex',
                  justifyContent: 'space-between', flexWrap: "wrap-reverse",
                  gap: "24px"
                }}>
                <div

                  style={{
                    display: 'flex', flexDirection: 'column',
                    gap: "8px", justifyContent: 'flex-start'
                  }}>
                  <Typography style=
                    {webstyle.flight_content}>
                    6 June 24, 12:20 PM</Typography>
                  <Typography style={webstyle.flight_heading}>
                    DEL - New Delhi</Typography>
                </div>
                <div style={{
                  display: 'flex', flexDirection: 'column',
                  gap: "8px", justifyContent: 'flex-start'
                }}>
                  <Typography style={webstyle.flight_content}>
                    6 June 24, 2:20 PM</Typography>
                  <Typography

                    style={webstyle.flight_heading}>
                    BOM - Mumbai</Typography>
                </div>
                <Box
                  sx={webstyle.flightDetailsCont} style={{ display: 'flex', gap: "8px", justifyContent: 'flex-start' }}>
                  <Typography

                    style={{ ...webstyle.flight_content, display: 'flex', alignItems: 'center', marginLeft: '3px' }}><img style={{
                      objectFit: 'cover',

                      width: '26px',
                      height: '26px',
                      marginRight: '8px',
                      borderRadius: '50%',
                    }} src="https://i.pinimg.com/1200x/2d/06/2c/2d062c935dde7754fa80bf011a9dbdc7.jpg" />Indigo</Typography>
                  <Typography style={{ ...webstyle.flight_content, display: 'flex', alignItems: 'center' }}><AccountCircleOutlined style={{ color: "#64748B", height: '28px', width: '28px', marginRight: '8px' }} /> Rahul Das</Typography>
                </Box>
              </Box>
            </Box>
            <Box sx={webstyle.flightDataCont}


              style={{ display: "flex", flexDirection: "column", gap: "24px" }}>
              <Typography style={webstyle.flightTypeHeading}>Departure Flight - 6 June, Fri</Typography>
              <Box

                sx={webstyle.upcomingDetailsCont}
                style={{
                  display: 'flex', justifyContent: 'space-between', flexWrap: "wrap-reverse",
                  gap: "24px"
                }}>
                <div style={{ display: 'flex', flexDirection: 'column', gap: "8px", justifyContent: 'flex-start' }}>
                  <Typography

                    style={webstyle.flight_content}>
                    6 June 24, 12:20 PM</Typography>
                  <Typography style={webstyle.flight_heading}>DEL - New Delhi

                  </Typography>
                </div>
                <div style={{
                  display: 'flex',

                  flexDirection: 'column', gap: "8px", justifyContent: 'flex-start'
                }}>
                  <Typography style={webstyle.flight_content}>
                    6 June 24, 2:20 PM</Typography>
                  <Typography style={webstyle.flight_heading}>


                    BOM - Mumbai</Typography>
                </div>
                <Box

                  sx={webstyle.flightDetailsCont} style={{ display: 'flex', gap: "8px", justifyContent: 'flex-start' }}>
                  <Typography

                    style={{ ...webstyle.flight_content, display: 'flex', alignItems: 'center', marginLeft: '3px' }}><img style={{
                      objectFit: 'cover', width: '26px',
                      height: '26px',


                      marginRight: '8px',
                      borderRadius: '50%',
                    }}
                      src="https://i.pinimg.com/1200x/2d/06/2c/2d062c935dde7754fa80bf011a9dbdc7.jpg" />Indigo</Typography>
                  <Typography style={{ ...webstyle.flight_content, display: 'flex', alignItems: 'center' }}><AccountCircleOutlined style={{
                    color: "#64748B", height: '28px',
                    width: '28px',
                    marginRight: '8px'
                  }} /> Rahul Das</Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box className="right-container">
          <header className="heading refund-head">Refund Details</header>
          <Box className="refund-payment-container">
            <Typography className="refund-sub-heading" style={{marginBottom:"24px"}}>Payment details</Typography>
            <Box className="total">
              <Box className="charges-box total">
                <Typography className="total-price">Total price</Typography>
                <Typography className="total-price">$ 7,037</Typography>
              </Box>
              <Box className="charges-box" style={{marginBottom:"4px"}}>
                <Typography className="refund-content">Airline Cancellation Charges</Typography>
                <Typography className="refund-content" style={{fontWeight:600}}>$-1000</Typography>
              </Box>
              <Box className="charges-box" style={{marginBottom:"4px"}}>
                <Typography className="refund-content">Trip Service Fee</Typography>
                <Typography className="refund-content" style={{fontWeight:600}}>$ -750</Typography>
              </Box>
              <Box className="charges-box">
                <Typography className="refund-content">Total Converience Fee</Typography>
                <Typography className="refund-content" style={{fontWeight:600}}>$ -200</Typography>
              </Box>
            </Box>
            <Box className="charges-box">
              <Typography className="refund-sub-heading">Refund Amount</Typography>
              <Typography  className="refund-sub-heading" >$3,109</Typography>
            </Box>

          </Box>
        </Box>
      </CancelContainer>
    )
  }
  refundModalUI = ()=>{
    return(
      <RefundModal data-test-id='refund-mode-modal' style={{display:'flex',justifyContent:'center',alignItems:'center'}}  onClose={this.handleCloseRefundModal} open={this.state.isRefundModalOpen}>
        <Box className="modal-data">
          <header className="modal-head-container">
            <Typography className="header">Flight Booking Cancellation</Typography>
           <span data-test-id='refund-close-icon' onClick={this.handleCloseRefundModal}> <Close style={{cursor:'pointer'}} /></span>
          </header>
          <Box className="refund-mode-container">
            <Typography className="mode-header">Please Choose Refund Mode</Typography>
            <FormControl className="radio-group-container">
              <RadioGroup data-test-id ='payment-options' value={this.state.refundOption} onChange={this.handleRefundOptionSelect}
              >
                <FormControlLabel data-test-id='orginal-mode' className="radio-value" value="orginal-mode" control={<BpRadio />} label="Refund into original payment mode" />
                <FormControlLabel className="radio-value" data-test-id='credit-mode' value="credit" control={<BpRadio />} label="Refund into Score Credit " />
              </RadioGroup>
            </FormControl>
          </Box>
          <Box style={{display:'flex',justifyContent:'flex-end',padding:'20px',gap:'15px'}}>
            <Button onClick={this.handleCloseRefundModal} className="CancleEditButton">Cancel</Button>
            <Button data-test-id='confirm-cancel-btn' style={{
              color: 'white',
              background: '#1764A0',
              padding: '10px, 12px, 10px, 12px',
              textTransform: 'none',
              borderRadius: '8px',
              fontFamily: 'Inter, sans-serif',
              fontWeight: 600,
              width: "190px",
              fontSize: '16px'
            }} variant="contained" onClick={this.handleConfirmCancel}>Confirm</Button>
          </Box>
        </Box>
      </RefundModal>
    )
  }
  cancelConfirmationUI = ()=>{
    return(
      <CancelSuccessContainer data-test-id='cancel-box' id="confirmation-container">
        <Box className="success-container">
            <img src ={SuccessIcon} />
            <Typography style={{textAlign:'center'}} className="heading">Flight Cancellation Initiated</Typography>
            <Typography className="success-content">Process Refund of 3,109 will be get back within 7-8 Days</Typography>
        </Box>
      </CancelSuccessContainer>
    )
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      // <ThemeProvider theme={theme}>
      <CancelContainer className="cancel-container" data-test-id='cancel-booking-ui'>
        <Box sx={webstyle.headerContainer}><HeaderMenuBlock navigation={this.props.navigation} id={"MyTrips"} /></Box>
        <Box sx={webstyle.mainContainer} style={{ position: "absolute", top: "100px", display: "flex", flexDirection: "column", gap: "58px" }}>
          <Typography className="booking-cancellation-heading" style={webstyle.title}>Booking Cancellation</Typography>
        </Box>
        <Box className="content-container">
          <Box sx={webstyle.boxContainer} >
            <div className="page-heading-container" style={{display:'flex',alignItems:'center',gap:'16px', borderBottom: "1px solid #E2E8F0", boxShadow: "0px 2px 10px 0px #0000001A" }}>
              <img data-test-id='back-navigate' onClick={this.handleMyTrips} src= {backArrowIcon} style={{cursor:'pointer'}}/>
              <Typography className="page-heading" style={webstyle.subTitle}>Your Flight Booking Cancellation </Typography>
            </div>
            <div style={{ borderBottom: "1px solid #E2E8F01", boxShadow: "0px 2px 10px 0px #0000001A", paddingLeft: '8px', paddingTop: "11px", paddingBottom: '11px' }}>
              <RefundTabs data-test-id="mytrip-tabs" value={this.state.selectedTab}>
                <RefundTab label={<span style={{ display: "flex", alignItems: "center", gap: "10px" }}>Review Cancellation</span>} value={'Review'} />
                <RefundTab label={<span style={{ display: "flex", alignItems: "center", gap: "10px" }} aria-disabled={true}>Confirmation</span>} value={'Confirmation'} />
              </RefundTabs>
            </div>
            {this.state.selectedTab === 'Confirmation' && this.cancelConfirmationUI()}
            {this.state.selectedTab === 'Review' && <Box>
              {this.reviewCancellAtionUI()}
              <Box style={{paddingLeft:"24px",paddingBottom:'24px'}}><Button variant="contained"
                onClick={this.handleShowModal}
                data-test-id='continue-btn'
                style={{
                  color: 'white',
                  marginTop:'10px',
                  background: '#1764A0',
                  padding: '10px, 12px, 10px, 12px',
                  textTransform: 'none',
                  borderRadius: '8px',
                  fontFamily: 'Inter, sans-serif',
                  fontWeight: 600,
                  width:"190px",
                  fontSize: '14px'
                }}>Continue</Button></Box>
              </Box>}
          </Box>
          
        </Box>
        
                {this.refundModalUI()}
      </CancelContainer>
      // </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const RefundModal = styled(Modal)({
  "& .modal-data":{
    background:"white",
    width:"70%",
    "@media screen and (min-width: 200px) and (max-width: 800px)": {
      width:"95%",
    },
  },
  "& .refund-mode-container":{
    padding:"10px"
  },
  "& .mode-header":{
    paddingBottom:"26px",
    borderBottom:"1px solid #E2E8F0",
    fontWeight:700,
    fontSize:"24px",
    padding:"30px",
    "@media screen and (min-width: 200px) and (max-width: 800px)": {
      fontSize:"16px",
      padding:"15px",
      paddingBottom:"15px",
    },
  },
  "& .header":{
    fontWeight:700,
    fontSize:"24px",
    "@media screen and (min-width: 200px) and (max-width: 800px)": {
      fontSize:"16px",
    },
  },
  "& .radio-value":{
    padding:"26px",
    borderBottom:"1px solid #E2E8F0",
    "@media screen and (min-width: 200px) and (max-width: 800px)": {
      padding:'15px'
    },
  },
  "& .radio-group-container":{
    marginTop:"20px",
    paddingLeft:"30px",
    width:'97%',
    "@media screen and (min-width: 200px) and (max-width: 800px)": {
      marginTop:"0px",
      paddingLeft:"0px",
    },
  },
  "& .CancleEditButton": {
    color: "#1764A0 !important",
    border: "none",
    textTransform: "capitalize",
    fontWeight: 600,
    width:"100px", 
    fontSize: '16px',
    "@media screen and (min-width: 360px) and (max-width: 600px)":{
        fontSize: '14px',
    } 
},
  "& .modal-head-container":{
    display:'flex',
    justifyContent:'space-between',
    alignItems:'center',
    borderBottom: "1px solid #E2E8F0",
    boxShadow:"0px 2px 4px 0px #0000001A",
    padding:'26px 40px',
    "@media screen and (min-width: 200px) and (max-width: 800px)": {
      padding:'26px 25px',
    },
  },
})
const CancelContainer =styled(Box)({
"& .cancel-container":{
  postion:"relative"
},
"& .cancel-ui-container":{
  padding:"24px",
  display:'flex',
  alignItems:'flex-start',
  gap:"16px",
  "@media screen and (min-width: 200px) and (max-width: 1000px)": {
    flexWrap:'wrap-reverse'   ,          
  },
  "@media screen and (min-width: 200px) and (max-width: 420px)": {
    padding:'8px'
  },

},
"& .content-container":{
  position:'absolute',
  top:"195px",
  display:'flex',
  justifyContent:'center',
  width:'100%'
},
"& .success-container":{
  display:"flex",
  justifyContent:'center',
  alignItems:'center',
  flexDirection:'column',
  width:'100%',
  boxShadow: "0px 4px 10px 0px #00000029",
  padding:"56px 0px"
},

"& .review-header":{
  borderBottom: "1px solid #E2E8F0",
  boxShadow: "box-shadow: 0px 2px 4px 0px #0000001A",
  padding:'30px',
  "@media screen and (min-width: 200px) and (max-width: 420px)": {
    padding:'16px'
  },
},
"& .right-container":{
  "@media screen and (min-width: 200px) and (max-width: 1000px)": {
    width:'100%'           
  },
  width:"30%",
  boxShadow: "0px 4px 10px 0px #00000029"
},
"& .heading":{
  "@media screen and (min-width: 200px) and (max-width: 420px)": {
    fontSize: 20,
  },
  fontWeight:700,
  fontSize:'24px'
},
"& .total-price":{
  fontWeight:600,
  fontSize:'14px',
},
"& .charges-box":{
  display:'flex',
  justifyContent:"space-between",
  alignItems:"center"
},
"& .refund-sub-heading":{
  fontWeight:600,
  fontSize:"16px"
},
"& .refund-payment-container":{
  padding:'16px'
},
"& .refund-head":{
  paddingLeft:'24px',
  paddingTop:'32px',
  paddingBottom: "32px",
  borderBottom:"1px solid #94A3B8",
  "@media screen and (min-width: 200px) and (max-width: 420px)": {
    paddingLeft:'15px',
    paddingTop:'24px',
    paddingBottom: "24px",
   
  },
},
"& .content":{
  fontWeight:400,
  fontSize:'14px',
  "@media screen and (min-width: 200px) and (max-width: 420px)": {
    fontSize: 12,
  },
},
"& .success-content":{
  fontWeight:400,
  fontSize:'24px',
  textAlign:'center'
},
"& .total":{
  marginBottom:"16px"
},
"& .refund-content":{
  fontWeight:400,
  fontSize:'12px'
},
"& .booking-cancellation-heading":{
  "@media screen and (min-width: 200px) and (max-width: 420px)": {
    fontSize: 26,
  },
  "@media screen and (min-width: 200px) and (max-width: 600px)": {
    marginLeft: "20px",
  },
  "@media screen and (min-width: 600px) and (max-width: 768px)": {
    marginLeft: "30px",
  },
  "@media screen and (min-width: 1000px) and (max-width: 1141px)": {
    marginLeft: "40px",
  },
  "@media screen and (min-width: 1141px) and (max-width: 1278px)": {
    marginLeft: "56px",
  },
  marginLeft: "45px",
  fontSize: 32,
},
  "& .page-heading-container": {
    paddingLeft: "32px", paddingBottom: "27px", paddingTop: "42px",
    "@media screen and (min-width: 200px) and (max-width: 420px)": {
      paddingLeft: "15px",
      paddingBottom: "20px",
      paddingTop: "30px",
    },
  },
"& .page-heading":{
  "@media screen and (min-width: 200px) and (max-width: 420px)": {
    fontSize:'16px'
  },
  "@media screen and (min-width: 420px) and (max-width: 720px)": {
    fontSize:'18px'
  },
  fontSize: '24px'
},
"& .left-container":{
  "@media screen and (min-width: 200px) and (max-width: 1000px)": {
    width:'100%'           
  },
  width:'70%',
  boxShadow: "0px 4px 10px 0px #00000029",
}
});
const CancelSuccessContainer = styled(Box)({
  
  padding: "56px !important",
  "@media screen and (min-width: 200px) and (max-width: 600px)": {
    padding: "10px !important",
  },

  "& .success-container":{
    display:"flex",
    justifyContent:'center',
    alignItems:'center',
    flexDirection:'column',
    width:'100%',
    boxShadow: "0px 4px 10px 0px #00000029",
    padding:"56px 0px"
  },
  "& .success-content":{
    fontWeight:400,
    fontSize:'16px',
    textAlign:'center'
  },
})
const RefundTabs = withStyles({
  scroller
  : 
  {
      overflow: 'auto !important',scrollbarColor: "#1764A0 #f1f1f1 !important",scrollbarWidth: "thin"},
  root
  : 
  {
      background: 'white'},indicator: {backgroundColor: '#1764A0',},
})(Tabs);

const RefundTab = withStyles({
  selected: {},
  root: 
  {
    background:
     'white',
    '&:hover': 
    {
        
      backgroundColor: 'rgba(233, 243, 252, 1)',
    },
      width: "50%",
      textTransform: 'none',
      minWidth: 72,
      fontWeight: 'bold',
      '&$selected': {backgroundColor: 'rgba(233, 243, 252, 1)',color: '#000',
    },
    '&:focus': {color: '#000',
    },
      marginRight: '8px',
      
     
  },
  
})(Tab);
const webstyle= {
  upcomingDetailsCont: {
    width: "100%"
},
flightDetailsCont: {
  flexDirection: {
      xs: "row",
      sm: 'column',
      md: "column",
      lg: "column",
  }
},
flight_heading: {
  fontSize: "16px",
  fontWeight: 700
},
flight_content: {
  fontSize: "14px",
  fontWeight: 500
},
upComingDetails: {
  padding:{
    xs:"16px",
    sm:"20px",
    md:"20px",
    lg:"30px"
  },
    width: {
        sm: "95%",
        md: "50%",
        lg: "50%",
    }
},
flightDataCont: {
    padding: {
        xs: "8px"
    }
},
flightTypeHeading: {
  fontWeight: 700,
  fontSize: "16px",
  color: "#64748B"
},
  headerContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    gap: "56px",
    overFlowX: 'hidden',
    height: {
        xs: '930px',
        sm: '800px',
        md: '580px',
        lg: '490px'
    },
    background: "linear-gradient(180deg, #013B68 84.25%, #0175D0 100%)",
},
subTitle: {
  fontWeight: 700,
},
boxContainer: {
  boxShadow: "0px 4px 10px 0px #00000029",
  borderRadius: "8px",
  width: "95%",
  background: "white",
  minHeight: "500px"
},
mainContainer: {
  width: {
      xs: "96%",
      sm: "97%",
      md: "98%",
      lg: "97%"
  },
  padding: {
      xs: "10px",
      lg: "24px"
  }
},
title: {
  fontWeight: 700,
  color: "white",

},
}
// Customizable Area End
