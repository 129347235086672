import React from "react";
// Customizable Area Start
import {
  Box,
  Typography,
  styled,
  Button,
  Drawer,
  Divider,
  Grid,
  Menu,
  Dialog,
  DialogContent,
  DialogActions,
  Card,
  CardContent,
  Checkbox,
  Slider
} from "@material-ui/core";
import { Tune } from "@material-ui/icons";

import MultiCitySearchingController, {
  FlightObject,
  PopularFilterProps,
  Props, TripData,
} from "./MultiCitySearchingController";
import HeaderMenuBlock from "../../../blocks/navigationmenu/src/HeaderMenu.web";
import MulticyFlightHeader from '../../../components/src/MulticityHeader'
import {
  flightImg,
  colorOpenIcon,
  cancelIcon,
  greenTrue,
  yellowCheck
} from "./assets";
import "../assets/style.css";
const configJSON = require("./config");
interface TimePriceProps {
  time: string;
  price: string;
}
const BpIcon = styled('span')(({ theme }) => ({
  
  borderRadius: 6,
  border: '1px solid #64748B',
  width: 32,
  height: 32,
  boxShadow:
    'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
  backgroundColor: '#f5f8fa',
  backgroundImage:
    'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
  '.Mui-focusVisible &': {
    outline: '2px auto rgba(19,124,189,.6)',
    outlineOffset: 2,
  },
  'input:hover ~ &': {
    backgroundColor: '#ebf1f5',
  },
  'input:disabled ~ &': {
    boxShadow: 'none',
    background:
      'rgba(206,217,224,.5)',
  },
  [theme.breakpoints.up('xs')]: {
    width: 18,
    height: 18,
  },
  [theme.breakpoints.up('sm')]: {
    width: 18,
    height: 18,
  },
  [theme.breakpoints.up('md')]: {
    width: 24,
    height: 24,
  },
  [theme.breakpoints.up('lg')]: {
    width: 32,
    height: 32,
  },
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: '#137cbd',
  backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
  '&::before': {
    display: 'block',
    width: '100%',
    height: '100%',
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""',
  },
  'input:hover ~ &': {
    backgroundColor: '#106ba3',
  },
});

const StyledSlider = styled(Slider)(({ theme }) => ({
  color: "#1764A0",
  height: 12,
  padding: "20px 0",
  "& .MuiSlider-thumb": {
      height: 25,
      width: 25,
      backgroundColor: "#fff",
      border: "1px solid #f7f7f7",
      boxShadow: "4px 4px 10px 0px #00000029",
      "&:focus, &:hover, &.Mui-active": {
          boxShadow: "4px 4px 10px 0px #00000029",
          "@media (hover: none)": {
              boxShadow: "0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)",
          },
      },
      "&:before": {
          boxShadow:
              "0px 0px 1px 0px rgba(0,0,0,0.2), 0px 0px 0px 0px rgba(0,0,0,0.14), 0px 0px 1px 0px rgba(0,0,0,0.12)",
      },
  },
  "& .MuiSlider-valueLabel": {
      fontSize: 12,
      fontWeight: "normal",
      top: -6,
      backgroundColor: "unset",
      color: theme.palette.text.primary,
      "&::before": {
          display: "none",
      },
      "& *": {
          background: "transparent",
          color: "#000",
      },
  },
  "& .MuiSlider-track": {
      border: "none",
      height: 12,
      borderRadius: "10px"
  },
  "& .MuiSlider-rail": {
      opacity: 0.5,
      height: 12,
      boxShadow: "inset 0px 0px 4px -2px #000",
      backgroundColor: "#1764A0",
      borderRadius: "10px"
  },
}));

interface AirlinesProps {
  id: number;
  AirlineName: string;
  Count:number;
  checked:boolean;
}
// Customizable Area End

export default class MultiCity extends MultiCitySearchingController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  horizontalLine = () => {
    return <Divider className="horizentalLine" />;
  };

  flightAndStopDetail = (trip: TripData,tripExpanded:boolean) => {
    return (
      <Box className="flightScheduleTimeBox">
        <Box style={{ textAlign: 'start', width: '100px' }}>
          <Typography className="flightNameText">{trip.startTime}</Typography>
          {tripExpanded && <Typography className="tripDate startCity" style={{marginTop:'10px'}}>4 July,24</Typography>}
          <Typography className="startCity"  style={{marginTop:'10px'}}>{trip.departure}</Typography>
        </Box>
        <Box className="flightTime">
          <Typography className="durationTime blackColor">
            {trip.duration}
          </Typography>
          <Divider className="horizental" />
          <Typography className="durationTime blueColor">
            {trip.stop_detail}
          </Typography>
        </Box>
        <Box style={{ textAlign: 'end', width: '100px' }}>
          <Typography className="flightNameText">{trip.endTime}</Typography>
          {tripExpanded && <Typography  style={{marginTop:'10px'}} className="tripDate startCity">4 July,24</Typography>}
          <Typography  style={{marginTop:'10px'}} className="startCity">{trip.destination}</Typography>
        </Box>
      </Box>
    )
  }
  renderFlightSchedule = (trip: TripData[]) => {

    return (
      trip.map((trip, index) => (
        <Box className="flightSchuduleContainer">
          <Box className="tripContainer">
            <Typography className="tripName">Trip {index+1}</Typography>
            <Box>
              <Typography className="travelTime">
                {trip.departure} to {trip.destination} | {trip.date}
              </Typography>
              <Box className="flightNameBox marginTop">
                <img src={flightImg} className="flightIcon" />
                
                <Typography className="flightNameText marginLeft">
                  {trip.flightName}
                </Typography>
                <Typography className="flightNumber">{configJSON.flightNumber}- {trip.flight_num}</Typography>
            
              </Box>
            </Box>
          </Box>
         {this.flightAndStopDetail(trip,false)}
        </Box>
      ))

    );
  };

  renderCard = () => {
    return (
      <Box className="cardContentContainer">
        {this.state.flightDesignation.map((designation: string,index: number) => {
          return (
            <Grid item data-test-id={`price-block${index}`} onClick={()=> this.handleSelectPrice(designation,index) } style={{cursor:'pointer'}}  className={this.state.selectedFareIndex === index ? "selectedOption" : ""}>
              <Card elevation={5} className="option-card">
                <CardContent>
                  <Box className={this.state.selectedFareIndex === index ? "price-block cardContainerBox" : " cardContainerBox"} >
                    <Box className="pricePersionContainer">
                      <Typography className="text categoryPrice">$5100</Typography>
                      <Typography  className="option-type persionType">per adult</Typography>
                    </Box>
                    <Typography  className="option-type categortType" >{designation}</Typography>
                  </Box>
                  <Divider className="cardHorizentalLine" />
                  <Box className="medialContent " id="option-modal">
                    <Box className="baggageContainer">
                      <Typography className="cardTitleBold">{configJSON.baggage}</Typography>
                      <Box className="quantityContainer">
                        <img src={greenTrue} />
                        <Typography className="baggageQuantity">7 kgs Cabin Baggage</Typography>
                      </Box>
                      <Box className="quantityContainer">
                        <img src={greenTrue} />
                        <Typography className="baggageQuantity">15 kgs Check-in  Baggage</Typography>
                      </Box>
                      <Typography className="cardTitleBold">{configJSON.baggage}</Typography>
                      <Box className="quantityContainer">
                        <img src={yellowCheck} />
                        <Typography className="baggageQuantity">Cancellation fee starts at $3200 (upto 3 hrs
                          before departure).</Typography>
                      </Box>
                      <Box className="quantityContainer">
                        <img src={yellowCheck} />
                        <Typography className="baggageQuantity">Date change fee starts at $3500 (upto 3 hrs
                          before departure).
                        </Typography>
                      </Box>
                      <Box className="BookNowContainer">
                        <Button className="bookButton" data-test-id={`book-now${index}`} onClick={this.handleBookNow}>
                          {configJSON.bookNow}
                        </Button>
                      </Box>
                    </Box>
                    
                  
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          )
        })}
      </Box>
    );
  };
  AirlineFilter(item: AirlinesProps, index: number) {
    const { AirlineName, Count } = item;
    return (
        <Box data-test-id={`Airline${index}`} sx={popularFilterItemStyle.container} key={AirlineName} className="popularFilters-list">
            <Checkbox
                color="default"
                checkedIcon={<BpCheckedIcon />}
                icon={<BpIcon />}
                data-test-id={`AirlineCheckbox${index}`}
            />
            <Box sx={popularFilterItemStyle.content} className="popularFilters-list-item">
                <Box className="popular-filters-img-parent" sx={popularFilterItemStyle.imageContent}>
                    {AirlineName && <img className="popular-filters-img" src={"https://i.pinimg.com/1200x/2d/06/2c/2d062c935dde7754fa80bf011a9dbdc7.jpg"}  style={{ width: "24px", height: "24px", borderRadius: "50%", objectFit: "cover" }} alt="icon" />}
                    <Typography className="popular-filters-content" variant="body1">{AirlineName}</Typography>
                </Box>
                <Typography className="popular-filters-content" variant="body1" >${Count}</Typography>
            </Box>
        </Box>
    );
}
  renderFlightDetailsModal = () => {
    return (
      <DialogFlightContainer
        PaperProps={{
          className: "dialog"
        }}
        fullScreen
        data-test-id = "dialogModalopen"
        open={this.state.flightDetailsDialog}
        scroll= "paper"
        onClose={this.flightDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
         <Box>
            <Card elevation={4} className="fareOptionContainer">
              <Typography className="fareOPtionTitleText">{configJSON.fareOption}</Typography>
              <img src={cancelIcon} data-test-id='close-dialog' className="cancleButton" style={{cursor:'pointer'}} onClick={this.flightDialogClose}/>
            </Card>
          </Box>
          <Box className="fareOPtionTitleText travelDuration">
            <span>New Delhi - Mumbai-Bangaluru-New Delhi</span>
              <img src={flightImg} className="flightIcon2"/>
              <span>Indigo</span>
              <span>|16 Apr 24, Tue | Departure at 12:20 PM - Arrival at 2:20 PM </span>
          </Box>
        <DialogContent style={{flex:'1 1 1'}}>
          {this.renderCard()}
        </DialogContent>
        <Divider className="cardHorizentalLine"/>
        <DialogActions className="dialogActionsStyle">
          <Box className="typeCityContainer">
            <Typography className="fareOPtionTitleText">$22,200</Typography>
            <Typography className="MultiCityAdult">Multicity for 1 Adult</Typography>
          </Box>
          <Button className="bookButton" onClick={this.flightDialogClose}>
            {configJSON.continue}
          </Button>
        </DialogActions>
      </DialogFlightContainer>
    )
  };
  PopularList(item: PopularFilterProps, index: number) {
    const { name } = item;
    return (
        <Box data-test-id={`PopularFilter${index}`} sx={popularFilterItemStyle.container} key={name} className="popularFilters-list">
            <Checkbox
                data-test-id={`PopularFilterCheckbox${index}`}
                color="default"
                checkedIcon={<BpCheckedIcon />}
                icon={<BpIcon />}
            />
            <Box sx={popularFilterItemStyle.content} className="popularFilters-list-item">
                <Box sx={popularFilterItemStyle.imageContent} className="popular-filters-img-parent">
                    <Typography variant="body1" className="popular-filters-content">{name}</Typography>
                </Box>
            </Box>
        </Box>
    );
}
StopsUiList = ()=>{
  return (
    this.state.stopsArray.map((item,index) => (
      <Box key={index} sx={popularFilterItemStyle.Pricecontainer} className="popularFilters-list filters-padding">
        <Checkbox
          data-test-id={`PopularFilterCheckbox`}
          color="default"
          checkedIcon={<BpCheckedIcon />}
          icon={<BpIcon />}
        />
        <Box sx={popularFilterItemStyle.content} className="popularFilters-list-item">
          <Box sx={popularFilterItemStyle.imageContent} style={{ width: '100%', justifyContent: 'space-between' }} className="popular-filters-img-parent">
            <Typography variant="body1" className="popular-filters-content">{item.type}</Typography>
            <Typography className="popular-filters-content">{item.cost}</Typography>
          </Box>
        </Box>
      </Box>
    ))
    
  )
}
TimeCardList(item: TimePriceProps, index: number) {
  const { time } = item
  const deptCardSelected = this.state.departureTimePrice?.time === time;
  return (
      <Box data-test-id={`DepartureTime${index}`} sx={{
          border: deptCardSelected ? "2px solid #1764A0" : "2px solid #64748B",
          borderRadius: "16px",
          width: "90px",
          height: "90px",
          display: "flex",
          flexDirection: "column",
          gridRowGap: "5px",
          alignItems: "center",
          justifyContent: "center"
      }} style={{ cursor: "pointer" }} onClick={() => this.handleDepartureTimeSelect(item)} key={index} className="time-card">
          <Typography style={{
              fontFamily: "Inter, sans-serif",
              fontWeight: 600,
              fontSize: "10px",
              color: deptCardSelected ? "#1764A0" : "#94A3B8",
              width: "92px", display: "flex", justifyContent: "center"
          }}>{time}</Typography>
      </Box>
  )

}
ArrivalTimeCardList(item: TimePriceProps, index: number) {
  const { time } = item;
  const cardSelected = this.state.arrivalTimePrice?.time === time;
  return (
      <Box data-test-id={`ArrivalTime${index}`} sx={{
          border: cardSelected ? "2px solid #1764A0" : "2px solid #64748B",
          borderRadius: "16px",
          width: "90px",
          height: "90px",
          display: "flex",
          flexDirection: "column",
          gridRowGap: "5px", alignItems: "center",
          justifyContent: "center"
      }} style={{ cursor: "pointer" }} key={time} className="time-card" onClick={() => this.handleArrivalTimeSelect(item)}>
          <Typography style={{
              fontFamily: "Inter, sans-serif",
              fontWeight: 600,
              fontSize: "10px",
              color: cardSelected ? "#1764A0" : "#94A3B8",
              width: "92px", display: "flex", justifyContent: "center"
          }}>{time}</Typography>
      </Box>
  );
}
  renderFiltersUi = () => {
    return (
      <div data-test-id="filter-ui" style={{ width: "100%" }} className={`filter-container`}>
        <Box sx={{
          display: "flex",
          flexDirection: "column",
          flexWrap: "wrap",
          width: "auto",
          height: "fit-content",
          bgcolor: "white",
          marginLeft: "21px",
          borderRadius: "8px",
          paddingBottom: "32px",
          gridGap: "40px"
        }} className="filter-container-box" data-test-id="filters">
          <Box sx={{ justifyContent: "space-between", display: "flex", alignItems: "center" }}>
            <Typography data-test-id="popular-filters-heading" style={{
              fontFamily: "Inter, sans-serif",
              fontWeight: 700,
              fontSize: "24px",
              marginLeft: "32px",
              marginTop: "32px"
            }} className="popular-filters-heading">Popular Filters</Typography>
            <Box sx={{
              marginTop: "32px", marginRight: "32px", fontFamily: "Inter, sans-serif",
              fontWeight: 700,
            }}>
              <span style={{ cursor: "pointer", color: "rgb(23, 100, 160)" }}> Clear All</span>
            </Box>
          </Box>
          <Box data-test-id="popular-filters-options" style={{ marginTop: "10px" }}>

            {this.state.shownPopularFilters.map((item, index: number) =>
              this.PopularList(item, index)
            )}
            {this.state.showPopularMore ? <div data-test-id="popular-filters-showLess" style={{ cursor: "pointer", display: "none" }} onClick={() => this.showLessPopularFiltersList()}>
              <Typography style={{
                fontFamily: "Inter, sans-serif",
                fontWeight: 700,
                fontSize: "14px",
                color: "#1764A0",
                marginLeft: "24",
                cursor: "pointer"
              }}>Show Less</Typography></div> :
              <div style={{ cursor: "pointer", display: "none" }} data-test-id="popular-filters-showmore" onClick={() => this.showMorePopularFiltersList()}>
                <Typography style={{
                  fontFamily: "Inter, sans-serif",
                  fontWeight: 700,
                  fontSize: "14px",
                  color: "#1764A0",
                  marginLeft: "24",
                  cursor: "pointer"
                }}>+{this.state.popularFilters.length} More</Typography>
              </div>}
          </Box>
         {this.state.cities.map((item,index)=>(
           <div className="filter-middle-block" data-test-id="middle-filters" style={{ display: "flex", flexDirection: "column", gap: "32px"}}>
           <Box data-test-id="price-filter" sx={{
             display: "flex",
             flexDirection: "column",
           }} className="filters-padding">

             <Typography style={{
               fontFamily: "Inter, sans-serif",
               fontWeight: 700,
               fontSize: "24px",
               marginBottom: "-4px",
             }}> Price</Typography>
             <StyledSlider data-test-id="slider" min={typeof this.state.minPrice === "number" ? this.state.minPrice : 0}
               max={typeof this.state.maxPrice === "number" ? this.state.maxPrice : 100}
               onChange={this.handlePriceChange} value={this.state.selectedPrice} />
             <Box sx={{
               display: "flex",
               justifyContent: "space-between",
               marginTop: "-2px"
             }}>
               <Typography style={{
                 fontFamily: "Inter, sans-serif",
                 fontWeight: 400,
                 fontSize: "14px",
               }} className="price-range-content">INR {this.state.minPrice}</Typography>
               <Typography style={{
                 fontFamily: "Inter, sans-serif",
                 fontWeight: 400,
                 fontSize: "14px",
               }} className="price-range-content">INR {this.state.selectedPrice}</Typography>
             </Box>
           </Box>
           <Typography className="filters-padding" data-test-id="departure-heading-filter" style={{
             fontFamily: "Inter, sans-serif",
             fontWeight: 700,
             fontSize: "24px",
           }}>Stops from  {item.airportFrom.state} </Typography>
           {this.StopsUiList()}
           <Typography className="filters-padding" data-test-id="departure-heading-filter" style={{
             fontFamily: "Inter, sans-serif",
             fontWeight: 700,
             fontSize: "24px",
           }}>Departure from {item.airportFrom.state}</Typography>
           <Box className="filters-padding" data-test-id="departure-filter" sx={{
             display: "flex",
             flexWrap: "wrap",
             alignItems: "center",
             gridGap: "10px",
           }}>
             {this.state.timePriceArray.map((item, index: number) =>
               this.TimeCardList(item, index)
             )}
           </Box>
           <Typography className="filters-padding" data-test-id="arrival-heading-filter" style={{
             fontFamily: "Inter, sans-serif",
             fontWeight: 700,
             fontSize: "24px",
           }}>Arrival at {item.airportTo.state}</Typography>
           <Box className="filters-padding" data-test-id="arrival-filter" sx={{
             display: "flex",
             flexWrap: "wrap",
             alignItems: "center",
             gridGap: "10px",
           }}>
             {this.state.timePriceArray.map((item, index: number) =>
               this.ArrivalTimeCardList(item, index)
             )}
           </Box>
         </div>
         ))}
          <Typography className="filters-padding filter-middle-block" data-test-id="airlines-heading-filter" style={{
              fontFamily: "Inter, sans-serif",
              fontWeight: 700,
              fontSize: "24px",
             
            }}>Airlines</Typography>
          <Box data-test-id="airline-filter" style={{ marginTop: "10px" }}>
            {this.state.shownAirLineList.map((item, index: number) =>
              this.AirlineFilter(item, index)
            )}
            {this.state.showAirlineMore ?
              <div data-test-id="airline-filters-showLess" style={{ cursor: "pointer" }} onClick={this.showLessAirlineFiltersList}>
                <Typography
                  style={{
                    fontFamily: "Inter, sans-serif",
                    fontWeight: 700,
                    fontSize: "14px",
                    color: "#1764A0",
                    marginLeft: "24",
                    cursor: "pointer"
                  }}>Show Less</Typography>
              </div> :
              <div style={{ cursor: "pointer" }} data-test-id="airline-filters-showmore" onClick={this.showMoreAirlineFiltersList}>
                <Typography style={{
                  fontFamily: "Inter, sans-serif",
                  fontWeight: 700,
                  fontSize: "14px",
                  color: "#1764A0",
                  marginLeft: "24",
                  cursor: "pointer"
                }}>+{this.state.airLinesData.length - 2} More</Typography>
              </div>
            }

          </Box>
        </Box>
      </div>
    )
  }

  renderFlightsList = () => {
    return (
      this.state.flightArray.map((flight: FlightObject, index: number) => (
        <>
          <Box className="container">
            <Box className="topRow">
              <Box>
                <Typography className="multiAirLince">Multiple Airlines</Typography>
                <Typography className="flightName">
                  {flight.flight_name}
                </Typography>
              </Box>
              <Box>
                <Typography className="timeDuration">
                  Duration: {flight.duration}
                </Typography>
              </Box>
              <Box className="price-container">
                <Typography className="flightPrice">{flight.cost_per_adult}</Typography>
                <Typography className="multiAirLince">
                  Per adult
                </Typography>
              </Box>
              <Button className="priceButton" data-test-id={`openDialog${index}`} onClick={this.flightDialogOpen}>
                View Prices
              </Button>
            </Box>
            {this.horizontalLine()}
            {this.renderFlightSchedule(flight.tripDetails)}
            {this.horizontalLine()}
            <Box className="flightDetails">
              <Button className="flightDetailsButton" data-test-id={`toggleFlightDetails${index}`} onClick={() => this.handleFlightDetails(index)}>
                Flight Details
                <img src={colorOpenIcon} style={{ marginLeft: "8px" }} />
              </Button>
            </Box>
          </Box>
          {this.state.ShowFlightDetails && this.state.flightDetailsIndex === index && this.flightDetails(flight.tripDetails)}</>
      ))
    );
  };

  flightDetails = (tripDetails: TripData[]) => {
    return (
      <Box className="container" data-test-id='trip-details-container'>
        {tripDetails.map((trip, index) => (
          <Box className="tripBox" key={index}>
            <Typography className="flightPrice">TRIP {index + 1}</Typography>
            <Box className="flightTravelDetails">
              <Typography className="flightListName">{trip.flightName} </Typography>
              <Typography className="cityNameText">
                {trip.departure}{configJSON.icon} {trip.destination}
              </Typography>
            </Box>
            <Box className="flightContainerDetails">
              <Box className="flightLayoverContainer">
                <Box className="flightimageBox">
                 <img src={flightImg} style={{ borderRadius: '50%',width:'20px',height:'20px',marginTop:'-12px' }} />
                  <Box className="trip-details-flightBox"> <Typography className="flightNameText marginLeft">
                    {trip.flightName}
                  </Typography>
                    <Typography className="flightNameText marginLeft">
                      {trip.flight_num}
                    </Typography>
                  </Box>
                </Box>
                {this.flightAndStopDetail(trip,true)}
              </Box>
            </Box>
          </Box>
        ))}
      </Box>
    );
  };

  renderSearchInput = () => {
    return (
      <Grid
        container
        className="searchFrom searchInput"
        style={{
          position: "relative",
          paddingLeft: "10px",
          paddingRight: "10px",
        }}
      >
        <MulticyFlightHeader data-test-id='multicity-catalogue-header'  handleOpenTripType={this.handleOpenTripType} state={this.state} handleClick={this.handleClick} handleClickTo={this.handleClickTo}
          handleClickNationality={this.handleClickNationality}
          handleNationalityClass={this.handleNationalityClass}
          handleSelectNationality={this.handleSelectNationality}
          handleSelectTriptype={this.handleSelectTriptype} handleClosePopup={this.handleClosePopup}
          handleInputChangeTo={this.handleInputChangeTo}
          handleInputChange={this.handleInputChange}
          handleSingleClose={this.handleSingleClose}
          handleCloseTo={this.handleCloseTo}
          reverseFunct={this.reverseFunct}
          togglePicker={this.togglePicker}
          handleRemoveCity={this.handleRemoveCity}
          handleAddCity={this.handleAddCity}
          handleCloseCustomAi={this.handleCloseCustomAi}
          handleCancel={this.handleCancel}
          handleApply={this.handleApply}
          closeTripTypeMenu={this.closeTripTypeMenu}
          openTripOption={this.openTripOption}
          openClassCategory={this.openClassCategory}
          closeClassCategory={this.closeClassCategory}
          handleClickStatus={this.handleClickStatus}
          handleDateChange1={this.handleDateChange1}
          totalTravellers={this.totalTravellers}
          handleDateChange={this.handleDateChange}
          countDecrease={this.countDecrease}
          countIncrease={this.countIncrease}
          countChildDecrease={this.countChildDecrease}
          countChildIncrease={this.countChildIncrease}
          countChildInfantDecrease={this.countChildInfantDecrease}
          countChildInfantIncrease={this.countChildInfantIncrease}
          handleCancelTravelButton={this.handleCancelTravelButton}
          handleDoneButton={this.handleDoneButton}
        />
        {this.state.showTrip && (
          <Grid item xs={12}>
            <Box style={{ display: "flex", justifyContent: "center",marginTop:'10px' }}>
              <Button
                type="submit"
                variant="contained"
                data-test-id="search-btn"
                className="searchFlight"
                style={{
                  backgroundColor:  '#1764A0',
                  fontFamily: "Inter, sans-serif",
                  fontWeight: '700',
                  fontSize: '16px',
                  textTransform: 'none',
                  color: "white",
                  width: '327px',
                  height: '56px',
                  marginTop:'20px'
                }}
              >
                Search Flights
              </Button>
            </Box>
          </Grid>
        )}
      </Grid>
    );
  };

  renderHeader = () => {
    return (
      <Box sx={webStyle.contentContainer}>
        <div className="subContentContainer">
          <Button
            className="filter-icon-btn"
            data-test-id="filterIcon"
            style={{ display: "none" }}
            onClick={this.handleFiltersToggle}
          >
            <Tune className="tuneStyle" />
          </Button>
          <div style={{ width: '30%' }} className="desktop-filter" data-test-id="filter-block">
            {this.renderFiltersUi()}
          </div>
          <Box sx={webStyle.detailsContainer} className="details-container">
            <Box className="flightList">
              {this.renderFlightsList()}
            </Box>
          </Box>
        </div>
      </Box>
    );
  };
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <MultiCityContainer>
        <Box sx={webStyle.headerContainer}>
          <HeaderMenuBlock navigation={this.props.navigation} id={""} />
          {this.renderSearchInput()}
        </Box>
        {this.renderHeader()}
        <Drawer
          PaperProps={{
            style: {
              height: "90vh",
            },
          }}
          anchor={"bottom"}
          variant="temporary"
          open={this.state.filtersDrawer}
          onClose={() => this.handleFiltersToggle()}
          data-test-id="filtersDrawer"
        >
          <div data-test-id="filter-block" >
            {this.renderFiltersUi()}
          </div>
        </Drawer>
        {this.renderFlightDetailsModal()}
      </MultiCityContainer>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const MultiCityContainer = styled(Box)({
  background: "#E9F3FC",
  height: "100%",
  overflowY: "scroll",
  "& .MuiInputBase-input": {
    color: "#FFFFFF",
  },
  "& .selectresponsStyle": {
    marginTop: "10px",
    fontFamily: "inter",
    fontWeight: '400 !important',
    fontSize: "18px",
    color: "#FFFFFF",
    lineHeight: "18px",
    textTransform:'uppercase'
  },
  "& .from-heading": {
    display: 'flex',
    alignItems: 'center',
    gap: '4px',
    fontWeight: "400 !important",
    
    "@media screen and (min-width: 260px) and (max-width: 1100px)":{
      flexWrap:'wrap',
    },
  },
  "& .trip-details-flightBox":{
    display:'flex',
    flexDirection:'column',
    alignItems:'start'
  },
  "& .side-destinations": {
    color: "#64748B",
    fontWeight: "400 !important"
  },
  "& .multiAirLince": {
    fontFamily: "Inter",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "18px",
    color: "#000000",
    "@media screen and (min-width: 260px) and (max-width: 830px)":{
      fontSize: "14px",
    },
    "@media screen and (min-width: 830px) and (max-width: 1000px)":{
      fontSize: "14px",
    },
  },
  "& .marginTop": {
    marginTop: "16px",
    "@media screen and (min-width: 260px) and (max-width: 700px)":{
      marginTop: "10px",
    },
  },
  "& .flightName": {
    fontFamily: "Inter",
    fontWeight: 500,
    fontSize: "24px !important",
    color: "#000000",
    "@media screen and (min-width: 601px) and (max-width: 800px)":{
      fontSize: "20px !important",
    },
    "@media screen and (min-width: 830px) and (max-width: 1000px)":{
      fontSize: "20px !important",
    },
    "@media screen and (min-width: 260px) and (max-width: 414px)":{
      fontSize: "16px",
    },
    "@media screen and (min-width: 415px) and (max-width: 700px)":{
      fontSize: "18px",
    }
  },
  "& .flightPrice": {
    fontFamily: "Inter",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "18px",
    color: "#000000",
    "@media screen and (min-width: 260px) and (max-width: 1000px)":{
      fontSize: "14px",
    },
  },
  "& .flightimageBox": {
    display: "flex",
    alignItems: "center",
    width:'250px',
    gap:'6px',
    "@media screen and (min-width: 260px) and (max-width: 414px)":{
      justifyContent: "start",
    },
    "@media screen and (min-width: 415px) and (max-width: 640px)":{
      justifyContent: "start",
    },
    "@media screen and (min-width: 830px) and (max-width: 950px)":{
      justifyContent: "start",
    }
  },
  "& .timeDuration": {
    fontFamily: "Inter",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "18px",
    color: "#64748B",
    border: "1px solid #1764A0",
    borderRadius: "100px",
    height: "32px",
    width: "189px",
    alignItems: "center",
    textAlign: "center",
    boxSizing: "border-box",
    paddingTop: "5px",
    "@media screen and (min-width: 260px) and (max-width: 830px)":{
      marginTop: "0px",
      height:'30px',
      width:'150px',
      fontSize:'14px',
    },
    "@media screen and (min-width: 830px) and (max-width: 1000px)":{
      marginTop: "0px",
      height:'30px',
      width:'130px',
      fontSize:'14px',
    }
  },
  "& .fliteCard": {
    display: "flex",
    flexDirection: "column" as "column",
    borderRadius: "5px",
    marginBottom: "10px",
    background: "white",
    boxShadow: "0px 4px 10px 0px #00000029",
  },
  "& .subContentContainer": {
    position: "absolute",
    top: "-50px",
    width: "100%",
    display: "flex",
    gap: "21px",
    justifyContent: "space-between",
    "@media screen and (min-width: 260px) and (max-width: 399px)": {
      left: '4px'
    },
    "@media screen and (min-width: 400px) and (max-width: 501px)": {
      left: '6px'
    },
    "@media screen and (min-width: 501px) and (max-width: 630px)": {
      left: '8px'
    },
    "@media screen and (min-width: 631px) and (max-width: 700px)": {
      left: '10px'
    },
    "@media screen and (min-width: 700px) and (max-width: 830px)": {
      left: '12px'
    }
  },
  "& .tuneStyle": {
    color: "white",
  },
  "& .horizentalLine": {
    background: "#1764A0",
    margin: "20px 0px",
  },
  "& .flightSchuduleContainer": {
    display: "flex",
    marginBottom:'20px',
    justifyContent: "space-between",
    alignItems: "center",
    padding: " 0px 25px",
    marginTop:'10px',
    "@media screen and (min-width: 830px) and (max-width: 1226px)":{
      display: "block",
      padding: "0px 15px",
      marginBottom:'10px'
    },
    "@media screen and (min-width: 260px) and (max-width: 830px)":{
      display: "flex",
      flexWrap:'wrap',
      padding: "0px 15px",
      marginBottom:'10px'
    }
  },
  "& .tripContainer": {
    display:"flex", 
    gap:"24px", 
    justifyContent:"start",
    "@media screen and (min-width: 260px) and (max-width: 700px)":{
      display: "flex",
      justifyContent:'flex-start',
      gap:"10px",
      flexWrap:'wrap'
    },
  },
  "& .flightContainerDetails": {
    display: "flex",
    gap: "25px",
    marginTop: "16px",
    "@media screen and (min-width: 260px) and (max-width: 414px)":{
      display: "block",
    },
    "@media screen and (min-width: 415px) and (max-width: 640px)":{
      display: "block",
    },
    "@media screen and (min-width: 830px) and (max-width: 950px)":{
      display: "block",
    },
    
  },
  "& .flightLayoverContainer": {
    display: "flex",
    gap: "120px",
    width: "100%",
    "@media screen and (min-width: 260px) and (max-width: 640px)":{
      flexWrap:'wrap',
      gap:'18px'
    },

    "@media screen and (min-width: 830px) and (max-width: 950px)":{
      display: "block",
    },
    
  },
  "& .flightNameBox": {
    display: "flex",
    alignItems: "center",
  },
  "& .flightIcon": {
    width: "20px",
    height: "20px",
    borderRadius: "50%",
  },
  "& .flightScheduleTimeBox": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: "24px",
    width:'50%',
    "@media screen and (min-width: 260px) and (max-width: 1226px)":{
     marginTop: "30px",
     width:'100%',
    },
  },
  "& .flightTime": {
    alignItems: "center",
    display: "block",
    justifyContent: "center",
    width: '40%',
    "@media screen and (min-width: 260px) and (max-width: 700px)": {

      width: '50%',
    },
  },
  "& .priceButton": {
    background: "#1764A0",
    color: "#FFFFFF",
    width: "148px",
    height: "40px",
    borderRadius: "8px",
    fontFamily: "Inter",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "24px",
    cursor: "pointer",
    textTransform: "capitalize",
    "@media screen and (min-width: 260px) and (max-width: 1000px)":{
      fontSize:'14px',
      height:'35px',
      width:'120px'
    },
  },
  "& .filters-padding":{
    paddingLeft: "28px", paddingRight: "28" 
  },
  "& .flightList": {
    width: "100%",
  },
  "& .tripName": {
    color: "#64748B",
    fontFamily: "Inter",
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "18px",
    width:'50px',
    "@media screen and (min-width: 260px) and (max-width: 414px)": {
      fontSize: "14px",
      width:'100px'
    }
  },
  "& .travelTime": {
    fontFamily: "Inter",
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "18px",
    color: "#000000",
    "@media screen and (min-width: 260px) and (max-width: 414px)": {
      fontSize: "14px",
    }
  },
  "& .flightNameText": {
    fontFamily: "Inter",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "18px",
    color: "#000000",
    "@media screen and (min-width: 260px) and (max-width: 500px)": {
      fontSize: "14px",
    }
  },
  "& .layoverContainer": {
    borderLeft: "1px dashed #64748B",
    paddingLeft: "20px",
    boxSizing: "border-box",
    alignContent: "center",
    marginTop: "16px"
  },
  "& .marginLeft": {
    marginLeft: "6px",
    boxSizing: "border-box",
  },
  "& .flightNumber": {
    FontFamily: "Inter",
    fontWaight: 600,
    fontSize: "12px",
    lineHeight: "18px",
    marginLeft: "16px",
    boxSizing: "border-box",
    color: "#000000"
  },
  "& .startCity": {
    fontFamily: "Inter",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "18px",
    color: "#000000",
    "@media screen and (min-width: 260px) and (max-width: 500px)": {
      fontSize: "12px",
    }
  },
  "& .durationTime": {
    fontFamily: "Inter",
    fontWeight: 400,
    fontSize: "13px",
    lineHeight: "18px",
    textAlign: "center",
  },
  "& .blackColor": {
    color: "#000000",
  },
  "& .blueColor": {
    color: "#1764A0",
  },
  "& .horizental": {
    background: "#64748B",
    height: "2px",
    width: "100%",
  },
  "& .flightDetails": {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    margin: "0px 20px 20px 0px",
  },
  "& .flightDetailsButton": {
    fontFamily: "Inter",
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "24px",
    textAlign: "center",
    color: "#1764A0",
    cursor: "pointer",
    textTransform: "capitalize",
    gap: "8px",
  },
  "& .ticketList": {
    background: "#E9F3FC",
    height: "46px",
  },
  "& .listName": {
    fontFamily: "Inter",
    fontWeight: 700,
    fontSize: "12px",
    lineHeight: "28px",
    textAlign: "center",
    color: "#000000",
  },
  "& .flightListName": {
    fontFamily: "Inter",
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "18px",
    textAlign: "center",
    color: "white",
    marginLeft: "24px",
    boxSizing: "border-box",
  },
  "& .cityNameText": {
    fontFamily: "Inter",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "18px",
    textAlign: "center",
    color: "white",
    marginLeft: "4px",
    boxSizing: "border-box",
  },
  "& .flightTravelDetails": {
    background: "#1764A0",
    width: "100%",
    height: "42px",
    display: "flex",
    alignItems: "center",
    marginTop: "16px",
    "@media screen and (min-width: 260px) and (max-width: 414px)": {
      width: "100%",
    }
  },
  "& .saverContainer": {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    marginTop: "8px",
  },
  "& .saverText": {
    fontFamily: "Inter",
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "18px",
    textAlign: "center",
    color: "#000000",
  },
  "& .fareOfferedText": {
    fontFamily: "Inter",
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "18px",
    textAlign: "center",
    color: "#000000",
  },
  "& .selectButton": {
    width: "120px",
    height: "40px",
    fontFamily: "Inter",
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "24px",
    textAlign: "center",
    color: "#1764A0",
    border: "1px solid #1764A0",
    background: "white",
    borderRadius: "8px",
  },

  "& .flightPrices": {
    fontFamily: "Inter",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "18px",
    textAlign: "center",
    color: "#000000",
  },
  "& .baggagesText": {
    fontFamily: "Inter",
    fontWeight: 700,
    fontSize: "12px",
    lineHeight: "18px",
    textAlign: "center",
    color: "#000000",
    "@media screen and (min-width: 260px) and (max-width: 700px)": {
      marginTop: "10px",
    }
  },
  "& .adultText": {
    fontFamily: "Inter",
    fontWeight: 500,
    fontSize: "12px",
    lineHeight: "18px",
    textAlign: "center",
    color: "#64748B",
  },
  "& .searchFlight": {
    backgroundColor: "white",
    color: "rgb(23, 100, 160)",
    width: "327px",
    height: "56px",
    fontFamily: "Inter, sans-serif",
    fontWeight: "700",
    fontSize: "16px",
    textTransform: "none",
  },
  "& .tripTypeContainer": {
    display: "flex",
    width: "100%",
    cursor: "pointer",
    height: "100",
    backgroundColor: "#1764A080",
    borderRadius: "8px",
    fontSize: "14px",
    fontFamily: "Inter",
    fontWight: 400,
    color: "white",
    justifyContent: "flex-start",
    paddingInlineStart:'15px',
    alignItems: "center",
    boxSizing: "border-box",

  },
  "& .headerBox":{
    height:'90%',
    "@media screen and (min-width: 260px) and (max-width: 700px)": {
      height: "110px",
    },
    "@media screen and (min-width: 700px) and (max-width: 1000px)": {
      height: "101px",
    },
    "@media screen and (min-width: 1000px) and (max-width: 1280px)": {
      height: "100px",
    }
  },
  "& .tripFilterContainer": {
    width: "100%",
    padding: "35px 23px 0px 23px",
  },
  "& .travellersLableContainer": {
    display: "flex",
    flexDirection: "column",
    gap: "5px",
  },
  "& .price-container":{
    "@media screen and (min-width: 260px) and (max-width: 700px)": {
    display:'flex',
    alignItems:'center',
    gap:'6px'
    }
  },
  "& .traveller-container":{
    paddingLeft:'15px',
    height:'90%',
    gap:'15px',
    paddingTop:"12px",
    justifyContent:'start !important'
  },
  "& .travellersLableBox": {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
  },
  "& .expendMoreIcon": {
    color: "white",
  },
  "& .fromLableContainer": {
    display: "flex",
    alignItems: "center",
    gap: "80px",
  },
  "& .catalogueHeaderBox": {
    backgroundColor: "#1764A080",
    borderRadius: "8px",
    fontSize: "24px !important",
    fontFamily: "inherit",
    fontWeight: 400,
    color: "white",
    cursor:'pointer',
    display:'flex',
    flexDirection: "column",
    justifyContent: "start",
    paddingTop:"12px",
    gap: "8px",
    paddingInlineStart: "16px",
  },
  "& .tripDate":{
    color:'#64748B',
    fontWeight:700
  },
  "& .tripText": {
    fontSize: "24px",
    fontWeight: 700,
    color: "white",
    fontFamily: "inherit",
    marginTop: "10px",
    textAlign: "end",
    textTransform:'uppercase',
    "@media screen and (min-width: 260px) and (max-width: 959px)": {
      textAlign: "start",
    }
  },
  "& .addCityButton": {
    fontSize: "16px",
    border: "1px solid #FFFFFF",
    fontWeight: 700,
    color: "white",
    fontFamily: "inherit",
    borderRadius: "8px",
    cursor: "pointer",
    marginLeft: "10px",
  },
  "& .calenderContainer": {
    borderRadius: "10px",
    width: "auto",
    top: "22%",
    left: "50%",
    transform: "translate(-50%, 0)",
    boxShadow: "rgba(0, 0, 0, 0.25) 0px 0px 10px",
    position: "absolute",
    zIndex: 9999,
    background: "#fff",
  },
  "& .closeCalenderButton": {
    color: "rgba(23, 100, 160, 1)",
    fontSize: "16px",
    textTransform: "capitalize",
    fontWeight: "700",
  },
  "& .dateApplyButton": {
    background: "rgba(23, 100, 160, 1)",
    color: "#fff",
    fontSize: "16px",
    textTransform: "capitalize",
    fontWeight: "700",
  },
  "& .removeImg": {
    cursor: "pointer",
  },
  "& .errorMsgForCount": {
    margin: "0px 10px",
    borderRadius: "5px",
    fontSize: "12px",
    borderLeft: "6px #c10f0ff0 solid",
    background: " #ed7e7e3d",
    fontFamily: "Inter, sans-serif",
    color: "#c12121",
    display: "flex",
    lineHeight: "24px",
    padding: "20px 15px",
    alignItems: "center",
  },
  "& .container": {
    display: "flex",
    flexDirection: "column" as "column",
    borderRadius: "5px",
    marginBottom: "10px",
    background: "white",
    boxShadow: "0px 4px 10px 0px #00000029",
    "@media screen and (min-width: 830px) and (max-width: 414px)": {

    }
  },
  "& .tripBox": {
    padding: "20px"
  },
  "& .topRow": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "25px 25px 0px 25px",
    "@media screen and (min-width: 830px) and (max-width: 1226px)": {
      padding: "15px 15px 0px 15px",
    },
    "@media screen and (min-width: 415px) and (max-width: 700px)": {
      padding: "25px 25px 0px 25px",
     display:'flex',
     alignItems:'center',
     flexWrap:'wrap',
     gap:'14px'
    },
    "@media screen and (min-width: 601px) and (max-width: 700px)": {
      padding: "15px 15px 0px 15px",
    },
    "@media screen and (min-width: 260px) and (max-width: 414px)": {
      padding: "15px 15px 0px 15px",
     display:'flex',
     alignItems:'center',
     flexWrap:'wrap',
     gap:'14px'
    }
  },
});

const DialogFlightContainer = styled(Dialog)({
  "& .dialog": {
    width: "90%",
    height: "fit-content",
    borderRadius: "8px",
    background: "#FFFFFF",
    "@media screen and (min-width: 260px) and (max-width: 700px)": {
      height: "80%",
      width: "95%",
    },

  },
  "& .fareOptionContainer": {
    background: "#FFFFFF",
    borderBottom:"",
    width: "100%",
    height: "77px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0px 22px 0px 55px",
    boxSizing:"border-box",
    "@media screen and (min-width: 260px) and (max-width: 700px)":{
      padding: "0px 15px 0px 15px",
    }
  },
  "& .fareOPtionTitleText": {
    fontFamily:"Inter", 
    fontSize:"24px", 
    fontWeight: 700, 
    lineHeight:"24px",
    color:"#000000",
    "@media screen and (min-width: 260px) and (max-width: 414px)":{
      fontSize:"14px",
    },
    "@media screen and (min-width: 415px) and (max-width: 700px)":{
      fontSize:"16px",
    },
    "@media screen and (min-width: 601px) and (max-width: 950px)":{
      fontSize:"20px",
    }
  }, 
  "& .travelDuration": {
    padding: "24px",
    textJustify:"auto",
    "@media screen and (min-width: 260px) and (max-width: 700px)":{
      padding: "15px",
    }
  },
  "& .cancleButton": {
    curcer: "pointer"
  },
  "& .MuiDialogContent-root": {
    padding: "0px"
  },
  "& .pricePersionContainer": {
    display: "flex",
  },
  "& .cardContainer": {
    width: "446px",
    height: "590px",
    borderRadius: "8px",
  },
  "& .MuiCardContent-root": {
    padding:"0px"
  },
  "& .flightIcon2": {
    borderRadius: "50%",
    height: "18px",
    width: "18px",
    margin:"0px 6px"
  },
  "& .cardHorizentalLine": {
    background: "#94A3B8",
  },
  "& .cardTitleBold": {
    fontSize: "16px",
    fontWeight: 700,
    fontFamily: "Inter",
    color: "#000000",
  },
  "& .categoryPrice": {
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: 700,
    color: "#000000",
  },
  "& .persionType": {
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 500,
    color: "#64748B",
    marginLeft:"8px"
  },
  "& .MultiCityAdult": {
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 400,
    color: "#64748B",
    marginTop: "8px",
  },
  "& .categortType": {
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 700,
    color: "#64748B",
    marginTop: "5px"
  },
  "& .baggageQuantity": {
    fontFamily: "Inter",
    fontSize: " 14px",
    fontWeight: 500,
    lineHeight: "18px",
    color: "#000000",
    marginLeft: "16px",
    boxSizing: "border-box"
  },
  "& .quantityContainer": {
    display: "flex",
    alignItems: "center",
    margin: "16px 0px"
  },
  "& .bookButton": {
    width: "120px",
    height: "46px",
    borderRadius: "8px",
    background: "#1764A0",
    color: "#FFFFFF",
    fontFamily: "inter",
    fontSize: "16px",
    fontWeight: 600,
    cursor:"pointer",
    "@media screen and (min-width: 260px) and (max-width: 1000px)":{
      width: "120px",
      height: "36px",
    },
    textTransform: "capitalize"
  },
  "& .BookNowContainer": {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  "& .baggageContainer": {
    padding: "24px"
  },
  "& .dialogActionsStyle": {
    padding:"17px 24px 26px 24px",
    "@media screen and (min-width: 260px) and (max-width: 700px)":{
      padding:'15px',
      justifyContent:'space-between'
    }
  },
  "& .typeCityContainer": {
    marginRight:"43px", 
    boxSizing:"border-box",
    "@media screen and (min-width: 260px) and (max-width: 414px)":{
      marginRight:"77px", 
    }
  },
  "& .cardContainerBox":{
    padding: "24px", 
    boxSizing: "border-box", 
    gap:"8px",
    "@media screen and (min-width: 260px) and (max-width: 414px)":{
      padding: "15px", 
    }
  },
  "& .cardContentContainer": {
    padding: "24px",
    boxSizing: "border-box",
    display: "flex",
    gap: "8px",
    "@media screen and (min-width: 260px) and (max-width: 414px)":{
      padding: "15px", 
    }
  }
});

const ClassTypeContainer = styled(Box)({
  padding: "10px",
  "& .classStatus": {
    fontSize: "12px",
    color: "rgba(100, 116, 139, 1)",
  },
  "& .adultText": {
    fontSize: "12px",
    color: "#000",
    fontWeight: 700,
  },
  "& .horizontalLine": {
    width: "100%",
    borderBottom: "1px solid rgba(226, 232, 240, 1)",
    marginTop: "10px",
  },
  "& .classInputBox": {
    width: "100%",
  },
  "& .categaryText": {
    fontSize: "24px",
    color: "#000",
    fontWeight: "700",
  },
  "& .categaryChange": {
    fontSize: "12px",
    color: "rgba(100, 116, 139, 1)",
    padding: "10px 0 20px 0",
  },
  "& .yearCategory": {
    fontSize: "12px",
    color: "rgba(100, 116, 139, 1)",
    padding: "10px 0 20px 0",
  },
  "& .travelClassBox": {
    width: "100%",
    textAlign: "center",
    padding: "0px 0px 0px 0px",
  },
  "& .travelClassText": {
    fontSize: "24px",
    color: "#000",
    fontWeight: "700",
  },
  "& .buttonBoxapply": {
    display: "flex",
    justifyContent: "end",
    width: "100%",
    margin: "12px 10px",
    padding: "10px",
  },
  "& .cancleButton": {
    color: "rgba(23, 100, 160, 1)",
    fontSize: "16px",
    textTransform: "capitalize",
    fontWeight: "700",
    cursor: "pointer"
  },
  "& .doneButton": {
    background: "rgba(23, 100, 160, 1)",
    color: "#fff",
    fontSize: "16px",
    textTransform: "capitalize",
    fontWeight: "700",
  },
  "& .selectTravelType": {
    fontFamily: "inter",
    color: "#000000",
    cursor: "pointer"
  },
  
});

const MenuContainer = styled(Menu)({
  "& .fromSerchInput": {
    textAlign: "center",
    fontSize: "16px",
    boxShadow: " 0px 2px 4px 0px rgba(0, 0, 0, 0.1)",
    border: "none",
  },
  "& .popularCityText": {
    fontSize: "16px",
    color: "rgba(100, 116, 139, 1)",
    padding: "20px",
    fontWeight: "600",
  },
  "& .popularCityTextTo": {
    color: "rgba(100, 116, 139, 1)",
    fontWeight: "600",
    padding: "20px",
    fontSize: "16px",
  },
  "& .menuItemContainer": {
    borderBottom: "1px solid rgba(226, 232, 240, 1)",
  },
  "& .cityNameListContainer": {
    paddingTop: "10px",
    width: "100%",
  },
  "& .cityNameListContainerTo": {
    width: "100%",
    paddingTop: "10px",
  },
  "& .menuItemContainerTo": {
    borderBottom: "1px solid rgba(226, 232, 240, 1)",
  },
  "& .stateNameText": {
    fontWeight: "600",
    fontSize: "12px",
    paddingBottom: "12px",
  },
  "& .stateNameTextTo": {
    paddingBottom: "12px",
    fontSize: "12px",
    fontWeight: "600",
  },
  "& .stateColor": {
    color: "#00000",
  },
  "& .cityColor": {
    color: "rgba(100, 116, 139, 1)",
  },
  "& .flightIconColor": {
    color: "rgba(148, 163, 184, 1)",
  },
});
const popularFilterItemStyle = {
  container: {
      display: "flex",
      alignItems: "center",
      marginBottom: "16px",
      marginTop: "16px",
      paddingLeft: "24px",
      gap: "6px",
      borderBottom: "1px solid #CBD5E1"
  },
  Pricecontainer:{
    display: "flex",
    alignItems: "center",
    gap: "6px",
    borderBottom: "1px solid #CBD5E1"
  },
  content: {
      display: "flex",
      justifyContent: "space-between",
      width: "100%",
      paddingRight: "30px",
  },
  imageContent: {
      display: "flex",
      gap: "16px"
  },
};
const flightItemStyle = {
  durationColumn: {
    display: "flex",
    flexDirection: "column" as "column",
    alignItems: "flex-start",
    gap: "16px",
  },
  bottomRow: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingLeft: "24",
    paddingTop: "14",
    paddingBottom: "14",
    paddingRight: "25",
  },
  flightHeading: {
    fontFamily: "Inter, sans-serif",
    fontWeight: "700",
    fontSize: "16px",
  },
  column: {
    display: "flex",
    flexDirection: "column" as "column",
    alignItems: "flex-start",
    gap: "16px",
  },
  flightDetails: {
    display: "flex",
    alignItems: "center",
  },
  flightLogo: {
    width: "24px",
    height: "24px",
    marginRight: "8px",
    borderRadius: "50%",
  },
  priceDetails: {
    display: "flex",
    flexDirection: "column" as "column",
    alignItems: "flex-start",
    gap: "16px",
  },
  placeText: {
    fontFamily: "Inter, sans-serif",
    fontWeight: "600",
    fontSize: "16px",
    color: "#64748B",
  },
};

const webStyle = {
  detailsContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    flexWrap: "wrap",
    width: "75%",
    height: "100%",
    background: "transparent",
    borderRadius: "8px",
    marginRight: "21px",
  },
  contentContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-around",
    width: "100%",
    height: "100%",
    background: "#E9F3FC",
    gap: "21px",
    position: "relative",
  },
  headerContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    width: "100%",
    background: "linear-gradient(180deg, #013B68 84.25%, #0175D0 100%)",
    paddingBottom: "100px",
  },
};

// Customizable Area End
