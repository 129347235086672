import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React,{ ChangeEvent } from 'react';
import moment from "moment";
import momentX from "moment-timezone";
import { Box } from "@material-ui/core";
import AccessTimeIcon from '@material-ui/icons/AccessTime';

export interface CountryType{
  "data": CountryArray[]
}
export interface CountryArray{

  "id": string,
  "type": string,
  "attributes": {
      "name": string,
      "emoji_flag":string,
      "country_code": string
  }
}
// Customizable Area End

export const configJSON = require("./config");



export interface Props {
  // Customizable Area Start
  navigation: object;
  id: string;
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  editingTraveller:any;
  errorInfo:string;
  seatSelectionBox:boolean;
  checkboxData:any;
  inputValues: { [key: string]: {prefix:string; firstName:string; lastName:string;gender:string;emailId:string;age:string;passportNo:string;nationality:string;countryCode: string;image:string; mobileNo: string,country:{ dialCode: string; format: string; name: string } } };
  inputErrors:  { [key: string]: {prefix:string; firstName:string; lastName:string;gender:string;emailId:string;age:string;passportNo:string;nationality:string;countryCode: string;image:string; mobileNo: string,country:{ dialCode: string; format: string; name: string } } };
  checkedItems: { [key: string]: boolean };
  savedData:any;
  seatAirItenanry:any;
  country:{ dialCode: string; format: string; name: string } 
  setFile:string;
      setFileName:string;
      errors:any;
      prefixData:any;
      countryData:CountryArray[];
      countrycode:string;
      mobile:string;
      email:string;
      reviewModal:boolean;
      travellerObjData:any;
      AirSegment:any;
      xml_res:string;
      bookingDataAll:any;
      AirPricingSolution:any;
      xDateAir:any;
      topErrorMessage:any;
      travellerInfoData:any;
      wholeDataMatrix:any;
      toastOpen:boolean;
      ApiResponseMessageRound:string;
      seatsData:any;
      seatMatrix:any;
      loading:boolean;
      isOpen:any;
      disableState:boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: string | number;
  // Customizable Area End
}

export default class OrderManagementRoundTripController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  pdfApiCallId: string | Message = "";
  countryCodeApiCallId:string="";
  createSeatSelectionApiCallId="";
  createBookingApiCallId="";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area Start
      getName(MessageEnum.NavigationPayLoadMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      editingTraveller:null,
      disableState:true,
      isOpen:false,
      loading:false,
      toastOpen:false,
      ApiResponseMessageRound:"",
      seatsData:[],
      seatMatrix:[],
      country:{ dialCode: "", format: "", name: "",} ,
      errorInfo:"",
      wholeDataMatrix:[],
      seatAirItenanry:[],
      seatSelectionBox:false,
      travellerInfoData:[],
      topErrorMessage:"",
      AirSegment:[],
      AirPricingSolution:{},
      xml_res:"",
      travellerObjData:{},
      reviewModal:false,
      countrycode:"",
      mobile:"",
      email:"",
      checkboxData:[],
      inputValues: {},
      inputErrors: {},
      checkedItems:{},
      savedData:[],
      setFile:"",
      setFileName:"",
      errors:{
        countryCode:"",
        mobile:"",
        email:'',
        checkboxAll:''
      },
      prefixData:[
        'Mr', 'Ms', 'Miss', 'Mrs'
      ],
      countryData:[],
      bookingDataAll:null,
      xDateAir:[],
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start

    
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Received", message);

    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const promoCodeDataRound = message.getData(
        getName(MessageEnum.OrderManagementRoundDataInfo)
      );
      localStorage.setItem('promoCodeData', JSON.stringify(promoCodeDataRound));
     
      }
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(

        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId != null) {
        switch (true) {
          case apiRequestCallId === this.countryCodeApiCallId &&  this.countryCodeApiCallId != null:
            this.GetAllCountryList(responseJson);
            break;
         
          case apiRequestCallId===this.createSeatSelectionApiCallId && this.createSeatSelectionApiCallId!=null:
            this.handleGetSeatRes(responseJson);
          break;
          case apiRequestCallId === this.createBookingApiCallId &&  this.createBookingApiCallId != null:
            this.handleGetPnrResRound(responseJson);
            break;
         
         default:
        }
      }
      }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    
    this.countrylistDataApi();
let userData:any = localStorage.getItem("userData")
let valueObj = JSON.parse(userData)
if(userData){
 this.setState({mobile:valueObj?.attributes?.phone_number, email:valueObj?.attributes?.email})
}
  setTimeout(() => {
    const travellerObj = this.getTotalTraveller();
        if (travellerObj) {
          this.setState({travellerObjData:travellerObj})
          this.gettravellerData(travellerObj)
        } 
    const promoCodeData = this.getPromoCodeData();
    if (promoCodeData) {
        this.getBookingDataJson(promoCodeData.bookingData,promoCodeData.totalTimeDuration);
        this.getAirItenanryData(promoCodeData.AirItenanryData)
    } 
   
}, 1000);

}

getBookingDataJson=(dataObj:any, totalTimeDuration:any)=>{                                             
  this.setState({AirSegment:dataObj.air_pricing.json_response.AirItinerary.AirSegment, 
    AirPricingSolution:dataObj.air_pricing.json_response.AirPricingSolution,
    xml_res:dataObj.air_pricing.xml_response.air_pricing,
  })
  let xDateFare = dataObj.air_pricing.json_response.fare_summary
  let xDateAir = dataObj.air_pricing.json_response.airport_name_list
  let importantData = dataObj.air_pricing.json_response.important_notice_with_brand
  let xData = dataObj.air_pricing.json_response.AirItinerary.AirSegment.map((item:any)=>{
    return {
      "Origin": item.Origin,
      "Destination":item.Destination,
      "Group":item.Group,
      "TripNumber":'Trip ' + (Number(item.Group) + 1),
      "DepartureTime": item.DepartureTime,
      "ArrivalTime": item.ArrivalTime,
      "FlightTime": item.ArrivalTime,
      "TravelTime":item.TravelTime,
      "LayoverTime": this.diffTime(item.ArrivalTime,item.DepartureTime)
          }
    })
    let airlineNames = dataObj.air_pricing.json_response.AirItinerary.AirSegment
    .map((item: any) => item.CodeshareInfo) 
    .reduce((uniqueNames: string[], name: string) => {
        if (!uniqueNames.includes(name)) {
            uniqueNames.push(name);
        }
        return uniqueNames;
    }, []);
    const trip1 = xData.filter((flight:any) => flight.TripNumber === 'Trip 1');
    const trip2 = xData.filter((flight:any) => flight.TripNumber === 'Trip 2');
    const finalOutput = {
      list1: trip1,
      list2: trip2,
        connection:(trip2.length-1) + (trip1.length-1),
        startOrigin:trip1[0].Origin,
        endDestination:trip2[0].Origin,
        fare_summary:xDateFare,
        importantData:importantData,
        airlineNames:airlineNames.join(", "),
        layovertotal:totalTimeDuration
    }
    this.setState({ bookingDataAll: finalOutput, xDateAir:xDateAir });
}
diffTimeAll = (ArrivalTime:any, DepartureTime:any) => {
  const arrivalMoment = moment(ArrivalTime);
  const departureMoment = moment(DepartureTime);
  const duration = moment.duration(arrivalMoment.diff(departureMoment));
  const days = duration.days();
  const hours = duration.hours();
  const minutes = duration.minutes(); 
  let result = '';
  if (days > 0) {
    result += `${days} day${days > 1 ? 's' : ''} `;
  }
  if (hours > 0 || days > 0) { 
    result += `${hours} hour${hours > 1 ? 's' : ''} `;
  }
  result += `${minutes} minute${minutes > 1 ? 's' : ''}`;
  return result.trim();
}
 customLayoverTime = (Arrival: any, Departure: any) => {
  const arrivalMoment = moment(Arrival);
  const departureMoment = moment(Departure);
  const duration = moment.duration(departureMoment.diff(arrivalMoment));
  const hours = duration.hours();
  const minutes = duration.minutes();
  return (
    <Box className="textlayfontsize" sx={{ display: 'flex', alignItems: 'center', fontSize:"14px" }}>
      <AccessTimeIcon style={{fontSize:"15px"}} />&nbsp;
      <span>Layover</span>&nbsp; {hours} hours {minutes} minutes
    </Box>
  );
};

convertFormatDayss = (dateString: string | number | Date) => {
  if (dateString !== undefined) {
    let formattedDate = momentX.parseZone(dateString).format('DD MMM dddd');
    return formattedDate;
  }
};
getAirItenanryData=(AirItenanry:any)=>{
  this.setState({seatAirItenanry:AirItenanry})
}
formattedData =(item:any)=>{
  if(Array.isArray(item)){
  return item?.map((item:any) => item.replace(/\n/g, "<br/>"))
.join("<br/><br/>");
}
}
cityNameFunct=(item:string)=>{
  const airport = this.state.xDateAir.find((airport:any) => airport?.Code === item);
  return airport ? airport.Name : null;
}
convertFormatTime = (isoString: moment.MomentInput) => {
  const formattedDate = momentX.parseZone(isoString).format('hh:mm A');
  return formattedDate
}
diffTime=(ArrivalTime:string, DepartureTime:string)=>{
  const arrivalMoment = moment(ArrivalTime);
  const departureMoment = moment(DepartureTime);

  const duration = moment.duration(arrivalMoment.diff(departureMoment));
  const hours = duration.hours();
  const minutes = duration.minutes();
 return `${hours} hours ${minutes} minutes`;
}
getPromoCodeData() {
  const promoCodeData = localStorage.getItem('promoCodeData');
  return promoCodeData ? JSON.parse(promoCodeData) : null;
}
convertFormatDay = (dateString: string | number | Date) => {
  if(dateString!=undefined){
    let formattedDate = momentX.parseZone(dateString).format('DD MMM dddd');
    return formattedDate;
  }
 };
getTotalTraveller() {
  const objectData = localStorage.getItem('totalTraveller');
  return objectData ? JSON.parse(objectData) : null;
}
  gettravellerData=(item:any)=>{
    let totalcount = item.adult + item.childrens + item.infant;
    let newTravellers = [];
    let newCheckedItems:any = {};
  
  for (let i = 1; i <= totalcount; i++) {
    newTravellers.push({
      id: i,
      Traveller: `ADD TRAVELLER ${i}`
    });
    newCheckedItems[`checkbox${i}`] = false;
  }
  
  
                                    
  this.setState({checkboxData:newTravellers})
  }
 

  handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, checked } = event.target;
    const travellerId = name.replace('checkbox', '');
    this.setState((prevState) => ({
      checkedItems: {
        ...prevState.checkedItems,
        [name]: checked,
      },
      savedData: {
        ...prevState.savedData,
        [travellerId]: {
          ...prevState.savedData[travellerId], editingTraveller: null,
          isSaved: !checked ? false : prevState.savedData[travellerId]?.isSaved,
        },
      },
     
    }));    
  };
  handleFileChange = (event: ChangeEvent<HTMLInputElement>, idFile: number) => {
    const file = event.target.files?.[0];
    const maxFileSize = 10 * 1024 * 1024;
  
    if (file) {  
      if (file.size > maxFileSize) {
        this.setState((prevState) => ({
          inputErrors: {
            ...prevState.inputErrors,
            [idFile]: { ...prevState.inputErrors[idFile], image: "File size exceeds 10 MB" },
          },setFileName: file.name,
          inputValues: {
            ...prevState.inputValues,
            [idFile]: { ...prevState.inputValues[idFile], image: file.name },
          },
          topErrorMessage: "File size exceeds 10 MB"
        }));
        return;
      }
  
      this.setState((prevState) => ({
        inputValues: {
          ...prevState.inputValues,
          [idFile]: { ...prevState.inputValues[idFile], image: file.name },
        },
        inputErrors: {
          ...prevState.inputErrors,
          [idFile]: { ...prevState.inputErrors[idFile], image: false },
        },
        setFileName: file.name,
        topErrorMessage:""
      }));
    }
  };
  

  handleBrowseClick = (idElemt: number): void => {
    const element = document.getElementById(`upload-file-${idElemt}`) as HTMLInputElement | null;
  if (element) {
    element.click();
  }
  };
  validateEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[a-zA-Z]+$/;
    if (!email.trim() || !emailRegex.test(email)) {
      return false
    }
  
    return true; 
  };
  validatePhoneNumber = (phoneNumber: string, country: { dialCode: string; format: string; name: string }) => {
    const { dialCode, format } = country;

      const numberWithCode = phoneNumber.trim();
      const numberWithoutCode = numberWithCode.startsWith(dialCode)
        ? numberWithCode.replace(dialCode, "").trim()
        : numberWithCode;
    
     
      const maxLength = format.replace(/[^.]/g, "").length - dialCode.trim().length;; 
      console.log(maxLength,numberWithoutCode.length ,numberWithoutCode)
      if (numberWithoutCode.length < maxLength) {
        return true
      }
    
      if (numberWithoutCode.length > maxLength) {
        return true
      }
    
      return false;

  };
  validateMobile = (mobile: string) => {
    const numericPattern = /^\d+$/;
return mobile && numericPattern.test(mobile);
  };
  calculateAge = (dob: string) => {
    const birthDate = new Date(dob);
    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();
    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  };
  validateAge = (agekey: string) => {
    const numericPattern = /^\d+$/;
return agekey && numericPattern.test(agekey);
  };
  
  validateCountryCode = (countrycode: string) => {
    return countrycode ? "" : "Country code is required";
  };
  handleSaveClick = (idSave: number) => {
    const { inputValues } = this.state;
    const {
      prefix = '',
      firstName = '',
      lastName = '',
      gender = '',
      countryCode = '',
      mobileNo = '',
      image = '',
      emailId = '',
      age = '',
      passportNo = '',
      nationality = '',country
    } = inputValues[idSave] || {};
    let calculatedAge: any = this.calculateAge(age);
    const isValidAge = !isNaN(calculatedAge) && calculatedAge >= 0;
  
    const isEmpty = (value: string) => !value || value.trim() === '';
    const inputErrors: any = {
      prefix: isEmpty(prefix),
      firstName: isEmpty(firstName),
      lastName: isEmpty(lastName),
      gender: isEmpty(gender),
      countryCode: isEmpty(countryCode),
      mobileNo: isEmpty(mobileNo) || this.validatePhoneNumber(mobileNo,country),
      emailId: isEmpty(emailId) || !this.validateEmail(emailId),
      age: !isValidAge,
      nationality: isEmpty(nationality),
    };
    const invalidFields = Object.keys(inputErrors).filter((key) => inputErrors[key]);
  
    let topErrorMessage = '';
   
    if (invalidFields.length === Object.keys(inputErrors).length) {
      topErrorMessage = 'Please fill all required fields.';
    } else if (invalidFields.length === 1) {
      const lastInvalidField = invalidFields[0];
      switch (lastInvalidField) {
        case 'prefix':
          topErrorMessage = 'Please select Prefix';
          break;
        case 'firstName':
          topErrorMessage = 'Please Enter your first name and middle name';
          break;
        case 'lastName':
          topErrorMessage = 'Please Enter your Last Name';
          break;
        case 'mobileNo':
          topErrorMessage = 'Please Enter Mobile Number';
          break;
        case 'emailId':
          topErrorMessage = 'Please Enter your Email Id';
          break;
        case 'gender':
          topErrorMessage = 'Please select gender';
          break;
        case 'age':
          topErrorMessage = 'Please Enter your Date Of Birth';
          break;
          case 'countryCode':
            topErrorMessage = 'Please Select Country Code';
            break;
        case 'nationality':
          topErrorMessage = 'Please Select Nationality';
          break;
        default:
          topErrorMessage = 'Please fill all required fields.';
          break;
      }
    } else {
      topErrorMessage = 'Please fill all required fields.';
    }
    this.setState((prevState) => ({
      inputErrors: {
        ...prevState.inputErrors,
        [idSave]: inputErrors,
      },
      topErrorMessage, 
    }));
    if (!invalidFields.length) {
      this.setState((prevState) => ({
        inputErrors: {
          ...prevState.inputErrors,
          [idSave]: {
            prefix: false,
            firstName: false,
            lastName: false,
            gender: false,
            countryCode: false,
            mobileNo: false,
            emailId: false,
            age: false,
            nationality: false,
          },
        },
        savedData: {
          ...prevState.savedData,
          [idSave]: {
            prefix,
            firstName,
            lastName,
            gender,
            countryCode,
            mobileNo,
            image,
            emailId,
            age:calculatedAge,
            passportNo,
            nationality,
            isSaved: true,
          },
        },
        topErrorMessage: '', 
      }), () => {
        const updatedCheckboxData = this.state.checkboxData.map((traveller: any) => ({
          ...traveller,
          firstName: this.state.savedData[traveller.id]?.firstName || '',
          email: this.state.savedData[traveller.id]?.emailId || '',
          mobile: this.state.savedData[traveller.id]?.mobileNo || ''
        }));
  
        this.setState({ checkboxData: updatedCheckboxData, editingTraveller: null, });
      });
    }
  };
 handleInputChange = (event: ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>, idIput: number, inputType: string) => {
    let { value } = event.target;
    if (inputType === 'firstName' || inputType === 'lastName') {
      value = value.slice(0, 30); 
  }
    this.setState((prevState) => ({


      inputValues: {
        ...prevState.inputValues,[idIput]: { ...prevState.inputValues[idIput], [inputType]: value },
      },
      inputErrors: {
        ...prevState.inputErrors, [idIput]: { ...prevState.inputErrors[idIput], [inputType]: value.trim() === '' },
      },
    }));
  };
  validateAllCheckboxes = () => {
    const areAllChecked1 = this.state.checkboxData.every((traveller:any) => traveller.firstName!="" && traveller.firstName!=undefined);
    return areAllChecked1 ? "" : "All travellers must be selected.";
  };
  GetAllCountryList=(responseJson:CountryType)=>{
    if(responseJson){

      this.setState({countryData:responseJson.data})
    }
      }
    
    countrylistDataApi = () => {
        const requestMessageCountry = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );


        this.countryCodeApiCallId = requestMessageCountry.messageId;

        requestMessageCountry.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),

          configJSON.apiEndPointGetCountry
        );
    
        const header = {
          "Content-Type": configJSON.apiContentType,
        };
    
        requestMessageCountry.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
    
        requestMessageCountry.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),

          configJSON.listOfOrdersMethod
        );
    
        runEngine.sendMessage(requestMessageCountry.id, requestMessageCountry);
      };
      setMobile= (text: string) => {
        this.setState({
          mobile: text, errors: { ...this.state.errors, mobile: '' },
        });
      }; 

      setCountryCode = (text: string) => {
        this.setState({
          countrycode: text, errors: { ...this.state.errors, countrycode: '' },
        });
      };
      
      setEmail= (text: string) => {
        this.setState({
          email: text, errors: { ...this.state.errors, email: '' },
        });
      };

      handleSubmitOrderMgmt=()=>{
        const { email, countrycode, mobile ,country} = this.state;
        console.log(this.validatePhoneNumber(String(mobile),country))
        const errors: any = {
                                             
          email: this.validateEmailSec(email),
          mobile: this.validatePhoneNumber(String(mobile),country),
          checkboxAll: this.validateAllCheckboxes(),
          countrycode: this.validateCountryCode(countrycode),
         
        };
        console.log(this.state.country)
        const isValid1 = Object.values(errors).every((error) => !error);
    console.log(isValid1)
        if (isValid1) {
           this.confirmReview()
        } else {
          this.setState({ errors });
        }
      }
      validateEmailSec=(email:string)=>{


        const emailRegex = /^[^\s@]+@[^\s@]+\.[a-zA-Z]+$/;
    
        return emailRegex.test(email) ? "" : "Invalid email format";
      }
      validateMobileSec = (mobile: string) => {


        const numericPattern = /^\d+$/;
         return numericPattern.test(mobile)?"":"Invalid mobile number";
      };
      resetReviewModal=()=>{
        this.setState({reviewModal:false})
      }
      genDerFunction=(age:string)=>{
        if (age >= "0" && age <= "1") {
          return 'INF'; 
      } else if (age >= "2" && age <= "12") {
          return 'CNN'; 
      } else {
          return 'ADT'; 
      }
      }
      confirmReview=()=>{
        let travellerInfos =Object.values(this.state.savedData).map((item:any,index:number)=>{
          return {
           
            "gender":item.gender,
            "prefix": item.prefix,
            "first_name": item.firstName,
            "last_name": item.lastName,
            "email": item.emailId,
            "traveler_id": index+1,
            "nationality":item.nationality,
            "passport_number":item.passportNo,
            "traveller_type": this.genDerFunction(item.gender),
            "country_code":item.countryCode,
            "number": item.mobileNo,
           
            "age":parseInt( item.age)
          }
        })
        this.setState({travellerInfoData:travellerInfos,reviewModal:true})
      }
      confirmSeatSelection=()=>{
        this.setState({reviewModal:false, seatSelectionBox:true})
        this.seatSelectionCallFunction()
      }
      seatSelectionCallFunction=()=>{
        this.setState({loading:true})
        const header = {
          "Content-Type": configJSON.apiContentType,
          "Token": localStorage.getItem("authToken"),
        };
        const httpBody = {
          "AirItenanry": this.state.seatAirItenanry,
          "adult": this.state.travellerObjData.adult, 
          "children": this.state.travellerObjData.children, 
          "infant":  this.state.travellerObjData.infant
        };
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
        this.createSeatSelectionApiCallId = requestMessage.messageId;
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.seatApiEndPoint
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          JSON.stringify(httpBody)
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.createOrderMethod
        );                            
        runEngine.sendMessage(requestMessage.id, requestMessage); 
      }

      handleGetSeatRes = (responseJson: any) => {
        if(responseJson){
          const airSegments = responseJson.data.AirSegment;
          let mat = responseJson.data.Rows.map((item: any) => {
            let dataMatrix = this.convertRowsToMatrix(item.Row);
            let airSegment = responseJson.data.AirSegment.find((segment:any) =>  item.SegmentRef === segment.Key);
            return {
              ...item,
               "Group":airSegment.Group,
              "AirSegment":airSegment,
              "SegmentRef": item.SegmentRef,
              Row: dataMatrix,
              "Traveldata":this.state.travellerInfoData
            };
          });
          mat.sort((a:any, b:any) => {
            if (a.Group !== b.Group) {
              return a.Group - b.Group; 
            } else {
              return airSegments.findIndex((segment:any) => segment.Key === a.SegmentRef) - 
                     airSegments.findIndex((segment:any) => segment.Key === b.SegmentRef);
            }
          });

      this.setState({ wholeDataMatrix:mat, loading:false})
        }
    
      }

      convertRowsToMatrix=(rows: any)=> {
        const { maxRowNumber, maxSeatLetter } = this.getMaxRowAndSeat(rows);
        const maxSeatIndex = maxSeatLetter.charCodeAt(0) - 'A'.charCodeAt(0);
        const matrix = this.createEmptyMatrix(maxRowNumber, maxSeatIndex);
    
        rows.forEach((rowObj: any) => {
            const rowNumber = parseInt(rowObj.Number, 10);
            const rowArray = this.createRowArray(maxSeatIndex);
    
            rowObj.Facility.forEach((facility: any) => {
               this.processFacility(facility, rowArray);
            });
    
            matrix[rowNumber] = rowArray;
        });
    
        return matrix;
    }
    getMaxRowAndSeat=(rows: any[])=> {
      let maxRowNumber = 0;
      let maxSeatLetter = '';
  
      rows.forEach((rowObj) => {
          const rowNumber = parseInt(rowObj.Number, 10);
          if (rowNumber > maxRowNumber) {
              maxRowNumber = rowNumber;
          }
          rowObj.Facility.forEach((facility: any) => {
              if (facility.Type === "Seat" && facility.SeatCode) {
                  const seatLetter = facility.SeatCode.split('-')[1];
                  if (seatLetter > maxSeatLetter) {
                      maxSeatLetter = seatLetter;
                  }
              }
          });
      });
  
      return { maxRowNumber, maxSeatLetter };
  }
  createEmptyMatrix=(maxRowNumber: number, maxSeatIndex: number)=>{
    const matrix = [];

    for (let i = 1; i <= maxRowNumber; i++) {
        matrix[i] = Array(maxSeatIndex + 1).fill({
            availability: 'booked',
            seatCode: '',
            windowSeat: false,
            side: ''
        });
    }

    return matrix;
}

 createRowArray=(maxSeatIndex: number)=>{
    return Array(maxSeatIndex + 1).fill({
        availability: 'booked',
        seatCode: '',
        windowSeat: false,
        side: ''
    });
}

 processFacility=(facility: any, rowArray: any[])=> {
    if (facility.Type === "Seat") {
        const seatCode = facility.SeatCode;
        const seatLetter = seatCode.split('-')[1];
        const seatIndex = seatLetter.charCodeAt(0) - 'A'.charCodeAt(0);
        const { windowSeat, side } = this.getSeatCharacteristics(facility.Characteristic);

        rowArray[seatIndex] = {
            availability: facility.Availability.toLowerCase(),
            seatCode: seatCode,
            windowSeat: windowSeat,
            side: side
        };
    } else if (facility.Type === "Aisle") {
        const aisleIndex = 3;
        rowArray[aisleIndex] = {
            availability: 'booked',
            seatCode: '',
            windowSeat: false,
            side: ''
        };
    }
}
getSeatCharacteristics = (characteristics: any): { windowSeat: boolean, side: string } => {
  let windowSeat = false;
  let side = "";

  if (Array.isArray(characteristics)) {
      characteristics.forEach((characteristic) => {
          if (characteristic.PADISCode === "W") {
              windowSeat = true;
          }
      });
  } else if (typeof characteristics === 'object' && characteristics !== null) {
      if (characteristics.PADISCode === "W") {
          windowSeat = true;
      }
  } 

  return { windowSeat, side };
}
dataSelectedSeat=(flightItems:any,rowIndex:any, seatIndex:any)=>{
  return flightItems.map((row: any, rIndex: any) => {
    if (rIndex === rowIndex) {
      return row.map((col: any, cIndex: any) => {
        if (cIndex === seatIndex) {
          const newAvailability = col.availability === 'available' ? 'selectedByUser' : 'available';
          if (newAvailability === 'selectedByUser') {
            this.setState({ ApiResponseMessageRound:"Seat has been selected.", toastOpen: true })
          } else {
            this.setState({ ApiResponseMessageRound:"Seat is now available.", toastOpen: true })
          }
          return {
            ...col,
            availability: newAvailability
          };
        }
        return col;
      });
    }
    return row;
  })
}
availableFlight = (rowIndex: any, seatIndex: any, index: any, Traveldata: any) => {
  const travelDataLength = Traveldata.length;

  const selectedSeatsCount = this.state.wholeDataMatrix[index].Row.flat().filter((seat: any) => seat.availability === 'selectedByUser').length;
  if (selectedSeatsCount < travelDataLength || this.state.wholeDataMatrix[index].Row[rowIndex][seatIndex].availability === 'selectedByUser') {
    this.setState((prevState: any) => {
      const newWholeDataMatrix = prevState.wholeDataMatrix.map((flightItems: any, flightIndex: any) => {
        if (flightIndex === index) {
          return {
            ...flightItems,
            Row: this.dataSelectedSeat(flightItems.Row, rowIndex, seatIndex)
          };
        }
        return flightItems;
      });
      const seatData = newWholeDataMatrix[index].Row[rowIndex][seatIndex];
      const seatId = seatData.seatCode;
      const segmentRef = prevState.wholeDataMatrix[index].SegmentRef;
      const travelerId = Traveldata[selectedSeatsCount]?.traveler_id;


      let newSeatData = [...prevState.seatsData];
      const seatIndexInData = newSeatData.findIndex(seat => seat.SeatId === seatId && seat.SegmentRef === segmentRef);
      if (seatData.availability === 'selectedByUser') {
        if (seatIndexInData === -1 && travelerId) {
          newSeatData.push({ traveler_id: travelerId, SegmentRef: segmentRef, SeatId: seatId });
        }
      } else {
        if (seatIndexInData !== -1) {
          newSeatData.splice(seatIndexInData, 1);
        }
      }
      return {
        wholeDataMatrix: newWholeDataMatrix,
        seatsData: newSeatData
      };
    });
  }
};
viewDetailFunct=()=>{
  this.setState({ seatSelectionBox:false, errorInfo:"", seatsData:[]})
}
handleSubmitContinue=()=>{
  let travellerInfo =Object.values(this.state.savedData).map((item:any,index:number)=>{
    return {
      "prefix": item.prefix,
      "first_name": item.firstName,
      "last_name": item.lastName,
      "email": item.emailId,
      "gender":item.gender,
      "country_code":item.countryCode,
      "number": item.mobileNo,
      "nationality":item.nationality,
      "passport_number":item.passportNo,
      "traveler_id": index+1,
      "age":parseInt( item.age),
      "traveller_type": this.genDerFunction(item.gender),
      
    }
  })
  let booking_sent_detail = {
    "phone_number":this.state.mobile,
    "email":this.state.email,
   "countrycode":this.state.countrycode,
   
  }
  const header = {
    "Content-Type": configJSON.apiContentType,
    "Token": localStorage.getItem("authToken"),
  };
  const httpBody = {
    "traveler_info": travellerInfo,
    "booking_sent_detail":booking_sent_detail,
    "AirPricingSolution":this.state.AirPricingSolution,
    "xml_res":this.state.xml_res,
    "seats":this.state.seatsData,
    "AirSegment":this.state.AirSegment,
   
  };
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.createBookingApiCallId = requestMessage.messageId;
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.bookingAPiEndPoint
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    JSON.stringify(httpBody)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.createOrderMethod
  );


                               
  runEngine.sendMessage(requestMessage.id, requestMessage); 
}
handleGetPnrResRound=(responseJson:any)=>{
  if(!responseJson.data.ErrorInfo){
    this.setState({errorInfo:""})
  }else{
    this.setState({errorInfo:responseJson.data.ErrorInfo.Description})
  }
}
handleToggle=()=>{
this.setState({isOpen:!this.state.isOpen})
}
editFunctionBooking=()=>{
this.setState({disableState:!this.state.disableState})
}
editTravellerFunct=(traveller:any)=>{
this.setState({ editingTraveller: traveller });
}
  // Customizable Area End
}
