import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
const ERRORS_MESSAGES= {
  required: 'Password is required',
  invalid: 'Password must meet the requirements',
};
interface EmailAccountAttributes {
  full_name: string;
  phone_number: string | null;
  email: string;
}

interface EmailAccountData {
  id: string;
  type: string;
  attributes: EmailAccountAttributes;
}

interface Meta {
  token: string;
}

interface EmailAccountResponse {
  data: EmailAccountData;
  meta: Meta;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

export interface S {
  // Customizable Area Start
  successMsg: string;
  serverError:string;
  firstName: string;
  lastName: string;
  email: string;
  name: string;
  password: string;
  confirmPassword: string;
  otpAuthToken: string;
  reTypePassword: string;
  checkedRememberMe: boolean;
  passwordHelperText: string;
  enablePasswordField: boolean;
  termsAndConditionText: string
  enableConfirmPasswordField: boolean;
  enableReTypePasswordField: boolean;
  loading:boolean
  countryCodeSelected: string;
  signUploading: boolean
  phone: string;
  tarmsConditionCheck: boolean;
  errors: {
    name?:string;
    email?: string;
    password?: string;
    confirmPassword?: string;
  };
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmailAccountRegistrationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  arrayholder: any[];
  passwordReg: RegExp;
  emailReg: RegExp;
  createAccountApiCallId:string | Message;
  validationApiCallId: string = "";
  termsandconditionApiId: string = "";
  imgPasswordVisible: boolean;
  imgPasswordInVisible: boolean;

  labelHeader: string;
  labelFirstName: string;
  lastName: string;
  labelEmail: string;
  labelPassword: string;
  labelRePassword: string;
  labelLegalText: string;
  labelLegalTermCondition: string;

  labelLegalPrivacyPolicy: string;
  btnTextSignUp: string;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
    this.receive = this.receive.bind(this);
    this.isStringNullOrBlank = this.isStringNullOrBlank.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
      successMsg: "",
      serverError:"",
      firstName: "",
      lastName: "",
      email: "",
      name: "",
      signUploading: false,
      termsAndConditionText: "",
      password: "",
      tarmsConditionCheck: false,
      confirmPassword: "",
      reTypePassword: "",
      loading:false,
      otpAuthToken: "",
      checkedRememberMe: false,
      passwordHelperText: "",
      enablePasswordField: false,
      enableConfirmPasswordField: false,
      enableReTypePasswordField: true,
      countryCodeSelected: "",
      phone: "",
      errors: {
        name:"",
        email: "",
        password: "",
        confirmPassword: "",
      },
      // Customizable Area End
    };

    // Customizable Area Start
    this.createAccountApiCallId = '';
    this.arrayholder = [];
    this.passwordReg = new RegExp("\\w+");
    this.emailReg = new RegExp("\\w+");

    this.imgPasswordVisible = imgPasswordVisible;
    this.imgPasswordInVisible = imgPasswordInVisible;

    this.labelHeader = configJSON.labelHeader;
    this.labelFirstName = configJSON.labelFirstName;
    this.lastName = configJSON.lastName;
    this.labelEmail = configJSON.labelEmail;
    this.labelPassword = configJSON.labelPassword;
    this.labelRePassword = configJSON.labelRePassword;
    this.labelLegalText = configJSON.labelLegalText;
    this.labelLegalTermCondition = configJSON.labelLegalTermCondition;
    this.labelLegalPrivacyPolicy = configJSON.labelLegalPrivacyPolicy;
    this.btnTextSignUp = configJSON.btnTextSignUp;
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.validationApiCallId) {
          this.arrayholder = responseJson.data;

          if (this.arrayholder && this.arrayholder.length !== 0) {
            let regexData = this.arrayholder[0];

            if (regexData.password_validation_regexp) {
              this.passwordReg = new RegExp(
                regexData.password_validation_regexp
              );
            }

            if (regexData.password_validation_rules) {
              this.setState({
                passwordHelperText: regexData.password_validation_rules,
              });
            }

            if (regexData.email_validation_regexp) {
              this.emailReg = new RegExp(regexData.email_validation_regexp);
            }
          }
        } else if (apiRequestCallId === this.createAccountApiCallId) {
          if (!responseJson.errors) {
            this.signupRes(responseJson);
          } else {
            this.setState({signUploading:false})
            this.setState({serverError:"Email already exists"})
          }

          this.parseApiCatchErrorResponse(errorReponse);
        }
        else if (apiRequestCallId === this.termsandconditionApiId){
          this.handletermsandConditionsResponse(responseJson)
        }
      }
    }

    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const otpAuthTkn = message.getData(
        getName(MessageEnum.AuthTokenDataMessage)
      );
      if (otpAuthTkn && otpAuthTkn.length > 0) {
        this.setState({ otpAuthToken: otpAuthTkn });
        runEngine.debugLog("otpAuthTkn", this.state.otpAuthToken);
        runEngine.unSubscribeFromMessages(this as IBlock, [message.id]);
      }
    }

    if (getName(MessageEnum.CountryCodeMessage) === message.id) {
      var selectedCode = message.getData(
        getName(MessageEnum.CountyCodeDataMessage)
      );

      if (selectedCode !== undefined) {
        this.setState({
          countryCodeSelected:
            selectedCode.indexOf("+") > 0
              ? selectedCode.split("+")[1]
              : selectedCode,
        });
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  goToPrivacyPolicy() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationPrivacyPolicyMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  goToTermsAndCondition() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationTermAndConditionMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  isStringNullOrBlank(str: string) {
    return str === null || str.length === 0;
  }

  isValidEmail(email: string) {
    return this.emailReg.test(email);
  }

  createAccount(): boolean {
    if (
      this.isStringNullOrBlank(this.state.firstName) ||
      this.isStringNullOrBlank(this.state.lastName) ||
      this.isStringNullOrBlank(this.state.email) ||
      this.isStringNullOrBlank(this.state.password) ||
      this.isStringNullOrBlank(this.state.reTypePassword)
    ) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorAllFieldsAreMandatory
      );
      return false;
    }

    var phoneNumberError = this.validateCountryCodeAndPhoneNumber(
      this.state.countryCodeSelected,
      this.state.phone
    );

    if (phoneNumberError) {
      this.showAlert(configJSON.errorTitle, phoneNumberError);
      return false;
    }

    if (!this.isValidEmail(this.state.email)) {
      this.showAlert(configJSON.errorTitle, configJSON.errorEmailNotValid);
      return false;
    }

    if (!this.passwordReg.test(this.state.password)) {
      this.showAlert(configJSON.errorTitle, configJSON.errorPasswordNotValid);
      return false;
    }

    if (this.state.password !== this.state.reTypePassword) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorBothPasswordsNotSame
      );
      return false;
    }

    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
    };

    const attrs = {
      first_name: this.state.firstName,
      last_name: this.state.lastName,
      email: this.state.email,
      password: this.state.password,
      full_phone_number:
        "+" + this.state.countryCodeSelected + this.state.phone,
    };

    const data = {
      type: "email_account",
      attributes: attrs,
    };

    const httpBody = {
      data: data,
      token: this.state.otpAuthToken,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createAccountApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.accountsAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getValidations() {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.validationApiCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.urlGetValidations
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  isNonNullAndEmpty(value: String) {
    return (
      value !== undefined &&
      value !== null &&
      value !== "null" &&
      value.trim().length > 0
    );
  }

  validateCountryCodeAndPhoneNumber(countryCode: string, phoneNumber: string) {
    let error = null;

    if (this.isNonNullAndEmpty(phoneNumber)) {
      if (!this.isNonNullAndEmpty(String(countryCode))) {
        error = configJSON.errorCountryCodeNotSelected;
      }
    } else if (this.isNonNullAndEmpty(countryCode)) {
      if (!this.isNonNullAndEmpty(phoneNumber)) {
        error = "Phone " + configJSON.errorBlankField;
      }
    }

    return error;
  }

  imgEnableRePasswordFieldProps = {
    source: imgPasswordVisible,
  };

  btnConfirmPasswordShowHideProps = {
    onPress: () => {
      this.setState({
        enableReTypePasswordField: !this.state.enableReTypePasswordField,
      });
      this.txtInputConfirmPasswordProps.secureTextEntry =
        !this.state.enableReTypePasswordField;
      this.imgEnableRePasswordFieldProps.source = this
        .txtInputConfirmPasswordProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  imgEnablePasswordFieldProps = {
    source: imgPasswordVisible,
  };

  btnPasswordShowHideProps = {
    onPress: () => {
      this.setState({ enablePasswordField: !this.state.enablePasswordField });
      this.txtInputPasswordProps.secureTextEntry =
        !this.state.enablePasswordField;
      this.imgEnablePasswordFieldProps.source = this.txtInputPasswordProps
        .secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnSignUpProps = {
    onPress: () => this.createAccount(),
  };

  btnLegalPrivacyPolicyProps = {
    onPress: () => this.goToPrivacyPolicy(),
  };

  btnLegalTermsAndConditionProps = {
    onPress: () => this.goToTermsAndCondition(),
  };

  txtInputEmailWebPrpos = {
    onChangeText: (text: string) => {
      this.setState({ email: text });
      //@ts-ignore
      this.txtInputEmailPrpos.value = text;
    },
  };

  txtInputEmailMobilePrpos = {
    ...this.txtInputEmailWebPrpos,
    keyboardType: "email-address",
  };

  txtInputEmailPrpos = this.isPlatformWeb()
    ? this.txtInputEmailWebPrpos
    : this.txtInputEmailMobilePrpos;

  txtPhoneNumberWebProps = {
    onChangeText: (text: string) => {
      this.setState({ phone: text });

      //@ts-ignore
      this.txtPhoneNumberProps.value = text;
    },
  };

  txtPhoneNumberMobileProps = {
    ...this.txtPhoneNumberWebProps,
    autoCompleteType: "tel",
    keyboardType: "phone-pad",
  };

  txtPhoneNumberProps = this.isPlatformWeb()
    ? this.txtPhoneNumberWebProps
    : this.txtPhoneNumberMobileProps;

  txtInputLastNamePrpos = {
    onChangeText: (text: string) => {
      this.setState({ lastName: text });

      //@ts-ignore
      this.txtInputLastNamePrpos.value = text;
    },
  };

  txtInputFirstNamePrpos = {
    onChangeText: (text: string) => {
      this.setState({ firstName: text });

      //@ts-ignore
      this.txtInputFirstNamePrpos.value = text;
    },
  };

  txtInputConfirmPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ reTypePassword: text });

      //@ts-ignore
      this.txtInputConfirmPasswordProps.value = text;
    },
    secureTextEntry: true,
  };

  txtInputPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ password: text });

      //@ts-ignore
      this.txtInputPasswordProps.value = text;
    },
    secureTextEntry: true,
  };
  handleClickShowPassword = () => {
    this.setState({
      enablePasswordField: !this.state.enablePasswordField,
    });
  };
  handleClickShowConfirmPassword = () => {
    this.setState({
      enableConfirmPasswordField: !this.state.enableConfirmPasswordField,
    });
  };
  validateEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[a-zA-Z]+$/;
    if (!email.trim() || !emailRegex.test(email)) {
      this.setState({errors: {
        ...this.state.errors,
        email: 'Invalid email format',
      },}) 
      return false
    }
  
    return true; 
  };

  validatePassword = (password: string) => {
    let errors:{password:string} = {
      password: ""
    };

    if (!password.trim()) {
        errors.password = ERRORS_MESSAGES.required;
        this.setState({ errors });
        return false;
    }

   else if(
    !this.checkForCapitalLetter(password) ||
    !this.checkLowerCaseLetter(password) ||
    !this.checkForNumber(password) ||
    !this.checkForSpecialChar(password) ||
    !this.checkMinLength(password)
) {
    errors.password =  ERRORS_MESSAGES.invalid;
    this.setState({ errors });
    return false;
}

    else if (this.state.errors.password) {
        delete this.state.errors.password;
        this.setState({ errors });
    }

    return true;
}

  validateConfirmPassword = (password: string, confirmPassword: string) => {
    if (!confirmPassword.trim()) {
      this.setState({errors: {
        ...this.state.errors,
        confirmPassword: 'Confirm Password is required',
      },}) 
      return false
    }else {
      if (password!= confirmPassword ){
        this.setState({errors: {
          ...this.state.errors,
          confirmPassword: 'Password and Confirm Password does not match',
        },}) 
        return false
      }
    }
    return true; 
  };

  // setState functions
  setEmail(text: string) {
    this.setState({
      email: text,
      errors: { ...this.state.errors, email: '', password:'', confirmPassword:'' },
      serverError:"",
    });
  }
  validateFullName(fullName: string) {
    
    if (!fullName.trim() || !/^[a-zA-Z\s]+$/.test(fullName)) {
      this.setState({errors: {
        ...this.state.errors,
        name: 'Please enter a full name',
      },}) 

      return false
    }
  
    return true; 
  }
  

  setName(text: string) {
    this.setState({
      name: text,
      errors: { ...this.state.errors, name:"", email: '', password:'', confirmPassword:'' },
      serverError:""
    });
  }

  setPassword = (text: string) => {
    this.setState({
      password: text,
      errors: { ...this.state.errors, name:"", email: '', password:'', confirmPassword:'' },
      serverError:"",
    });
  };

  setConfirmPassword(text: string) {
    this.setState({
      confirmPassword: text,
      errors: { ...this.state.errors,name:"", email: '', password:'', confirmPassword:'' },
      serverError:"",
    });
  }
  setRememberMe = (value: boolean) => {
    this.setState({ checkedRememberMe: value });
  };
 
  signupRes = (responseJson:EmailAccountResponse) => {
    this.setState({signUploading:false})
    if (responseJson) {
      this.emptyState();
      this.setState({ successMsg: "Account create successfully" });
      localStorage.setItem("token", responseJson.meta.token)
      this.goToDetails();
    }
  };
  handleSubmit = () => {
    const { name, email, password, confirmPassword } = this.state;
    if(!this.validateFullName(name)) return ;
    if(!this.validatePassword(password)) return ;
    if(!this.validateEmail(email)) return ;
   
    if(!this.validateConfirmPassword(password, confirmPassword)) return ;

    this.submitForm();
  };
  submitForm = () => {
    this.setState({signUploading:true})
    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
    };

    const attrs = {
      password: this.state.password,
      password_confirmation: this.state.confirmPassword,
      full_name: this.state.name,
      email: this.state.email,
    };
    const dataObj = {
      type: "email_account",
      attributes: attrs,
    };
    const httpBody = {
      data: dataObj,
    };

    const requestMessageEnum = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createAccountApiCallId = requestMessageEnum.messageId;
    requestMessageEnum.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.accountsAPiEndPoint
    );

    requestMessageEnum.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessageEnum.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessageEnum.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );

    runEngine.sendMessage(requestMessageEnum.id, requestMessageEnum);
    return true;
  };

  getTermsandConditions = ()=>{
    this.setState({loading:true})
    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
    };

    const requestMessageEnum = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.termsandconditionApiId = requestMessageEnum.messageId;
    requestMessageEnum.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.termsandcontionsApiEndpoint
    );

    requestMessageEnum.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessageEnum.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiGetMethod
    );

    runEngine.sendMessage(requestMessageEnum.id, requestMessageEnum);
  }
 
  goToDetails = () => {
    this.props.navigation.navigate("EmailAccountDetail");
  };
  emptyState = () => {
    this.setState({
      errors: {
        ...this.state.errors,
        email: "",
        password: "",
        confirmPassword: "",
      },
      password: "",
      confirmPassword: "",
      email: "",
      name: "",
      checkedRememberMe: false,
    });
  };
  checkForCapitalLetter = (str: string) => {
    return /[A-Z]/.test(str);
  };

  checkLowerCaseLetter = (text: string) => {
    return /[a-z]/.test(text);
  };

  checkForNumber = (num: string) => {
    return /\d/.test(num);
  };

  checkMinLength = (text: string) => {
    const minLength = 8;
    return text.length >= minLength;
  };
  checkForSpecialChar = (str: string) => {
    return /[!@#$%^&*(),.?":{}|<>]/.test(str);
  };
  agreeBtn = () => {
    this.setState({
      tarmsConditionCheck: false,
      checkedRememberMe: true,
    });
  };
  cancelBtn = () => {
    this.setState({
      tarmsConditionCheck: false,
    });
  };
  tarmsConFunct = () => {
    this.getTermsandConditions()
  };
  handletermsandConditionsResponse = (response: any) => {
    if (!response.errors) {
      const { data } = response
      let value = data[0]
      const { description } = value
      this.setState({
        tarmsConditionCheck: true,
        loading: false,
        termsAndConditionText: description
      });
    }
  }
  navigateScreens = (pathName: string) => {
   if(pathName!=""){
    const msg: Message = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), pathName);
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    const raiseMessage: Message = new Message(getName(MessageEnum.NavigationPayLoadMessage));
    msg.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(msg);
   }
  };

  // Customizable Area End
}
