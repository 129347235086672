import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import momentX from "moment-timezone";
import { createRef, RefObject } from "react";
import { cheapestIcon, discountIcon, nonStopIcon, starIcon } from "./assets";
import changeMoment from "moment";

interface ErrorResponse {
  errors: string[];
}
export interface FlightData {
  adult_per_price: string;
  flight_number: string;
  origin: string;
  destination: string;
  connection: number;
  departure_time: string;
  arrival_time: string;
  per_adult_price: string;
  duration: string;
}
interface URLSearchParamsType {
  departure_date: Date | null | string;
  origin: string | null;
  originFlight: string | null;
  destination: string | null;
  destinationFlight: string | null;
  cabin_class: string | null;
  adult: string | null;
  children: string | null;
  infant: string | null;
  trip_type: string | null;
  return_date: Date | null | string;
}
export interface Stop {
  0: number;
  1: string;
}

export interface SegmentKey {
  Key: string;
}


export interface TaxInfo {
  Category: string;
  Amount: string;
  Key: string;
}

export interface BaggageAllowance {
  MaxWeight: {
    Value: string;
    Unit: string;
  };
}

export interface FareSurcharge {
  Key: string;
  Type: string;
  Amount: string;
}

export interface FareInfo {
  Key: string;
  FareBasis: string;
  PassengerTypeCode: string;
  Origin: string;
  Destination: string;
  EffectiveDate: string;
  DepartureDate: string;
  Amount: string;
  NegotiatedFare: string;
  NotValidBefore: string;
  NotValidAfter: string;
  FareSurcharge: FareSurcharge;
  BaggageAllowance: BaggageAllowance;
  FareRuleKey: string;
  Brand: {
    Key: string;
    BrandID: string;
    UpSellBrandID: string;
    BrandTier: string;
    UpsellBrand: {
      FareBasis: string;
      FareInfoRef: string;
    };
  };
}

export interface AirPricingSolution {
  air_pricing_key: string;
  total_price: string;
  base_price: string;
  taxes: string;
  connection: number;
  travel_time: string;
  currency_type: string;
  adult_per_price: string;
  segment_ref_key: SegmentKey[];
  air_segment_refs: AirSegmentRef[];
  air_pricing_info: AirPricingInfo;
}

interface FlightDetailsRef {
  Key: string;
}

export interface FlightDetail {
  travel_time: string;
  connection: number;
  flight_info: FlightInfo[];
}

interface AirSegmentRef {
  Key: string;
}

interface Journey {
  TravelTime: string;
  AirSegmentRef: AirSegmentRef | AirSegmentRef[];
}

interface BookingInfo {
  BookingCode: string;
  BookingCount: string;
  CabinClass: string;
  FareInfoRef: string;
  SegmentRef: string;
}

interface AirPricingInfo {
  TotalPrice: string;
  BasePrice: string;
  Taxes: string;
  CancelPenalty: {
    PenaltyApplies: string;
    Amount: string;
  };
  ChangePenalty: {
    PenaltyApplies: string;
    Amount: string;
  };
  PassengerType: {
    Code: string;
  };
}

interface ViewPrice {
  air_pricing_sol_key: string;
  total_price: string;
  base_price: string;
  taxes: string;
  currency_type: string;
  journey: Journey[];
  adult_per_price: string;
  segment_ref_key: (AirSegmentRef | AirSegmentRef[])[];
  BookingInfo: BookingInfo[][];
  BrandName: string;
  BaggageInfo: string[];
  CancelPenalty: string;
  ChangePenalty: string;
  air_pricing_info: AirPricingInfo[];
}

export interface Flight {
  flight_details: FlightDetail[]
  air_segment_keys: SegmentKey[];
  adult_per_price: string;
  connection: number;
  air_pricing_solution: AirPricingSolution[];
  AirlineName: string;
}

export interface PriceRange {
  min: number;
  max: number;
}

export interface AirData {
  stops: Stop[];
  price_range: PriceRange;
  flight_list: FlightList[];
  error: ErrorSec;
  errors: string;
}
export interface ErrorSec {
  Description: string
}

interface SearchText {
  id: string,
  state: string,
  country: string,
  flight: string,
  stateCode: string
}
type DateRange = {
  selection: {
    startDate: Date;
    endDate: Date;
  };
};

interface PopularCityProp {
  id: string
  city: string,
  airportName: string,
  airportCode: string
  country: string
}
export interface Airport {
  Code: string;
  Name: string;
  CityCode: string;
  CountryCode: string;
}

interface AirportList {
  airport_list: Airport[];
}
interface OptionType {
  id: string,
  price: string,
  optionType: string,
  cabinType: {
    cabinWeight: number,
    cabinBaggageAllow: boolean,
  }
  checkinType: {
    checkinWeight: number,
    checkinbaggageAllow: boolean,
  }
  cancellationType: {
    cancellation: boolean,
    cancellationMsg: string
  }
  dateChangeType: {
    dateChange: boolean,
    dateChangeMsg: string
  }
  seatType: {
    seat: string,
    aboutSeatType: boolean
  }
  mealType: {
    meal: string,
    aboutMealType: boolean
  }
}

interface TimePrice {
  time: string;
  price: string;
}

interface Airline {
  checked: boolean;
  name: string;
  price: string;
  img: string;
}
interface PopularFilterProps {
  checked: boolean;
  name: string;
}
export interface ListCityDummyType {
  "id": string, "country": string, "state": string, "flight": string, "stateCode": string
}

interface OfferButtonProps {
  src: string;
  heading: string;
  content: string;
}

interface DatePrice {
  date: string;
  price: string;
  isHighlighted: boolean;
}
interface TripType {
  tripType: string
  tripSelected: boolean
}
export interface Trip {
    travel_time:string
  FlightNumber: string
  AirlineName: string
  origin: string
  price: string
  destination: string
  trip1: string
  trip2: string
  trip1Duration: string
  trip2Duration: string
  trip1StartTime: string
  trip2StartTime: string
  trip1EndTime: string
  trip2EndTime: string
  trip1FlightType: string
  trip2FlightType: string
  trip1Date: Date | string
  trip2Date: Date | string
}
export interface RoundTripFlightData {
  trip1: Trip,
  trip2: Trip
}

interface Stops {
  0: string;
  1: string;
}

interface FlightDetailsRef {
  Key: string;
}

export interface FlightInfo {
  Key: string;
  FlightNumber: string;
  Origin: string;
  Destination: string;
  DepartureTime: string;
  ArrivalTime: string;
  FlightTime: string;
  Distance: string;
  ETicketability: string;
  Equipment: string;
  FlightDetailsRef: FlightDetailsRef;
  AirlineName: string;
}

export interface FlightDetails {
  travel_time: string;
  connection: number;
  flight_info: FlightInfo[];
}

interface AirSegmentRef {
  Key: string;
}

interface Journey {
  TravelTime: string;
  AirSegmentRef: AirSegmentRef | AirSegmentRef[];
}

interface CancelPenalty {
  PenaltyApplies: string;
  Amount: string;
}

interface ChangePenalty {
  PenaltyApplies: string;
  Amount: string;
}

interface PassengerType {
  Code: string;
}

interface AirPricingInfo {
  TotalPrice: string;
  BasePrice: string;
  Taxes: string;
  CancelPenalty: CancelPenalty;
  ChangePenalty: ChangePenalty;
  PassengerType: PassengerType;
}

interface AirAvailInfo {
  ProviderCode: string;
}

interface FlightDetailsRef {
  Key: string;
}

interface AirItinerary {
  Key: string;
  Group: string;
  Carrier: string;
  FlightNumber: string;
  Origin: string;
  Destination: string;
  DepartureTime: string; 
  ArrivalTime: string; 
  FlightTime: string; 
  Distance: string; 
  ETicketability: string;
  Equipment: string;
  ChangeOfPlane: string;
  ParticipantLevel: string;
  LinkAvailability: string;
  PolledAvailabilityOption: string;
  OptionalServicesIndicator: string;
  AvailabilitySource: string;
  AvailabilityDisplayType: string;
  AirAvailInfo: AirAvailInfo;
  FlightDetailsRef: FlightDetailsRef;
  ClassOfService: string;
  FareBasis: string;
  CodeshareInfo?: string; 
}

export interface ViewPrices {
  air_pricing_sol_key: string;
  total_price: string;
  base_price: string;
  taxes: string;
  currency_type: string;
  journey: Journey[];
  adult_per_price: string;
  segment_ref_key: (AirSegmentRef | AirSegmentRef[])[];
  BookingInfo: BookingInfo[][];
  BrandName: string;
  BaggageInfo: string[];
  CancelPenalty: string;
  ChangePenalty: string;
  air_pricing_info: AirPricingInfo[];
  AirItenanry: Array<AirItinerary>;
}

interface BookingInfo {
  BookingCode: string;
  BookingCount: string;
  CabinClass: string;
  FareInfoRef: string;
  SegmentRef: string;
}

export interface FlightList {
  flight_details: FlightDetails[];
  adult_per_price: string;
  connection: string;
  AirlineName: string;
  FlightNumber: string;
  view_prices: ViewPrices[];
}

interface TimeResponse {
  hours: number;
  minutes: number;
}

interface AirportErrorResponse{
  errors: string
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  errorPriceInfo:string;
  errorRoundTripMessage: string;
  setPropover : Element | null;
  selectAirportTo: Airport;
  priceClickIndex: number | null
  selectAirportFrom: Airport;
  airportNameTo : Array<Airport>;
  searchAirpotTo: string;
  airportNameFrom : Array<Airport>;
  anchorElNationalClass:any;
  selectedNationality: any,
  searchAirport: string;
  roundTripResponse:Array<FlightList>;
  searchInput:URLSearchParamsType;
  loading: boolean;
  roundTripFlights: {trip1: Trip,trip2: Trip}[]
  cabin_class: string;
  visibleDetailIndex:null | number
  infant: number;
  inputCityTo:string;
  inputCityFrom:string;
  trip_type: string;
  searchTextFrom: string;
  searchToText: string;
  listCityDummy: ListCityDummyType[]
  arrayHolder: string[];
  token: string;
  priceModal: boolean
  selectedFlightPrice: FlightList;
  tripType: string;
  authToken: string | null;
  isOpen: boolean;
  startDate: Date | null;
  endDate: Date | null;
  endDate1: Date | null;
  activePicker: string;
  selectedOption: string;
  fareOptions: OptionType[]
  travelClassSelected: boolean
  searchText: {
    state: string,
    country: string,
    flight: string,
    stateCode: string
  };
  toText: {
    state: string,
    country: string,
    flight: string,
    stateCode: string
  }
  filteredItems: SearchText[];
  filteredItemsTo: SearchText[];
  anchorEl: Element | null;
  anchorElTo: Element | null;
  anchorElFrom: Element | null;
  searchTextClass: {
    classNumber: string;
    title: string;
    travelClass: string;
  }
  anchorElClass: Element | null;
  countAdult: number;
  countChild: number;
  travelStatus: string;
  fullTravelObj: {
    countAdult: number,
    countChild: number,
    travelStatus: string,
    countInfant: number
  }
  error: string;
  popularFilters: PopularFilterProps[]
  timePriceArray: TimePrice[]
  airlinesList: Airline[]
  datePrices: DatePrice[]
  offersList: OfferButtonProps[]
  filtersDrawer: boolean
  filterCheckBox: boolean
  windowWidth: number
  tripOpen: boolean
  tripOptions: TripType[]
  fromModalOpen: boolean
  popularCities: PopularCityProp[]
  toModalOpen: boolean
  currentIndex: number;
  searchField: string;
  anchorElTripType: Element | null
  selectedTripType: string | null;
  errorResSearch: string;
  flightAllDataArray: FlightData[];
  flightAllDataDummy:any;
  enableSearch: boolean;
  price_range: {
    "min": number,
    "max": number
  }
  selectedPrice: number,
  minPrice: number,
  maxPrice: number,
  showError:boolean
  fareOptionSelected: number | null;
  fareOption: OptionType | null
  countInfant: number;
  selectedTripOption: string | null
  showIndex: number | null;
  shownPopularFilters: PopularFilterProps[];
  selectedPopularFilter:PopularFilterProps[];
  airLinesData:any;
  selectedAirlines:any;
  departureTimePrice:TimePrice | null;
  arrivalTimePrice:TimePrice | null;
  inputApiSearchFrom: string
  inputApiSearchTo: string
  currentPage: number,
      itemsPerPage: number,
      AirItenanryData:any;
      totalTimeDuration:any;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class RoundTripController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getProductApiCallId = "";
  datePricesRef: RefObject<Element> = createRef();
  oneWaySearchApiCallId: string = "";
  changeRoundTripApiCallId: string = "";
  getAirportApiCallId: string = "";
  getAirportToApiCallId: string = "";
  changeOneWayApiCallId: string = "";
  createPriceApiCallId: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area Start
   


         
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      totalTimeDuration:"",
      errorPriceInfo:"",
      AirItenanryData:[],
      departureTimePrice:null,
      currentPage: 1,
      itemsPerPage: 5,
      timePriceArray: [
        { time: "Before 6AM", price: "$4200" },
        { time: "6AM to 12PM", price: "$5200" },
        { time: "12PM to 6PM", price: "$6200" },
        { time: "After 6PM", price: "$6500" }
      ],
      arrivalTimePrice:null,
      selectedAirlines: [],
      airLinesData:[],
      anchorElNationalClass:null,
      selectedNationality:"",
      inputApiSearchFrom: "",
      priceClickIndex: null,
      inputApiSearchTo: "",
      selectedPopularFilter:[],
      shownPopularFilters: [
        { checked: false, name: "Non-stop" },
        { checked: false, name: "1 Stop"},
        { checked: false, name: "2 Stop"},
        { checked: false, name: "Morning Departures"},
        { checked: false, name: "Late Departures"},
      ], 
      errorRoundTripMessage: "",
      setPropover: null,
      selectAirportTo : {
        Code: "",
        Name: "",
        CityCode: "",
        CountryCode: ""
      },
      selectAirportFrom : {
        Code: "",
        Name: "",
        CityCode: "",
        CountryCode: ""
      },
      airportNameTo: [],
      searchAirpotTo: "",
      airportNameFrom: [],
      searchAirport: "",
      roundTripResponse: [],
      searchInput :{
        departure_date: "",
        origin: "",
        originFlight: "",
        destination: "",
        destinationFlight: "",
        cabin_class: "",
        adult: "",
        children: "",
        infant: "",
        trip_type: "",
        return_date: "",
      },
      // Customizable Area Start
      selectedPrice: 50,
      showIndex: null,
      minPrice: 0,
      maxPrice: 100,
      price_range: {
        min: 0,
        max: 0
      },
      loading: false,
      selectedTripOption: "",
      flightAllDataArray: [],
      flightAllDataDummy:[],
      errorResSearch: '',
      showError:false,
      cabin_class: "",
      inputCityTo:"",
      inputCityFrom:"",
      infant: 0,
      countInfant: 0,
      trip_type: "",
      searchTextFrom: "",
      searchToText: "",
      arrayHolder: [],
      token: "",
      fareOption: null,
      fareOptionSelected: null,
      travelClassSelected: false,
      currentIndex: 0,
      priceModal: false,
      selectedFlightPrice: {
        flight_details: [],
        adult_per_price: "",
        connection: "",
        AirlineName: "",
        FlightNumber: "",
        view_prices: []
      },
      searchField: "",
      selectedTripType: "",
      visibleDetailIndex: null,
      fareOptions: [{
        id: '1',
        price: '$5400',
        optionType: 'Saver',
        cabinType: {
          cabinWeight: 5,
          cabinBaggageAllow: true,
        },
        checkinType: {
          checkinWeight: 1,
          checkinbaggageAllow: true,
        },
        cancellationType: {
          cancellation: true,
          cancellationMsg: 'Cancellation fee starts at $3200 (upto 3 hrs before departure.'
        },
        dateChangeType: {
          dateChange: false,
          dateChangeMsg: 'Date change fee starts at $3500 (upto 3 hrs before departure.'
        },
        seatType: {
          seat: 'Chargeable Seats',
          aboutSeatType: false
        },
        mealType: {
          meal: 'Chargeable Meals',
          aboutMealType: true
        }
      }, {
        "id": "2",
        price: "$7200",
        "optionType": "Flex",
        "cabinType": {
          "cabinWeight": 7,
          "cabinBaggageAllow": true
        },
        "checkinType": {
          "checkinWeight": 2,
          "checkinbaggageAllow": true
        },
        "cancellationType": {
          "cancellation": true,
          "cancellationMsg": "Cancellation fee starts at $2000 up to 4 hours before departure."
        },
        "dateChangeType": {
          "dateChange": true,
          "dateChangeMsg": "Date change fee starts at $1500 up to 4 hours before departure."
        },
        "seatType": {
          "seat": "Free Standard Seats",
          "aboutSeatType": true
        },
        "mealType": {
          "meal": "Complimentary Meals",
          "aboutMealType": true
        }
      }, {
        "id": "3",
        "price": "$6500",
        "optionType": "Super Saver",
        "cabinType": {
          "cabinWeight": 6,
          "cabinBaggageAllow": true
        },
        "checkinType": {
          "checkinWeight": 2,
          "checkinbaggageAllow": true
        },
        "cancellationType": {
          "cancellation": true,
          "cancellationMsg": "Cancellation fee starts at $2800 up to 2 hours before departure."
        },
        "dateChangeType": {
          "dateChange": false,
          "dateChangeMsg": "Date change fee starts at $3200 up to 2 hours before departure."
        },
        "seatType": {
          "seat": "Standard Seats",
          "aboutSeatType": false
        },
        "mealType": {
          "meal": "Paid Meals",
          "aboutMealType": true
        }
      }, {
        "id": "4",
        "price": "$8000",
        "optionType": "Premium",
        "cabinType": {
          "cabinWeight": 8,
          "cabinBaggageAllow": true
        },
        "checkinType": {
          "checkinWeight": 3,
          "checkinbaggageAllow": true
        },
        "cancellationType": {
          "cancellation": true,
          "cancellationMsg": "Cancellation fee starts at $1500 up to 5 hours before departure."
        },
        "dateChangeType": {
          "dateChange": true,
          "dateChangeMsg": "Date change fee starts at $1000 up to 5 hours before departure."
        },
        "seatType": {
          "seat": "Premium Seats",
          "aboutSeatType": true
        },
        "mealType": {
          "meal": "Gourmet Meals",
          "aboutMealType": true
        }
      }
      ],
      filteredItems: [],
      roundTripFlights:[],    
      anchorElTripType: null,
      filtersDrawer: false,
      fromModalOpen: false,
      enableSearch: false,
      searchText: {
        state: "",
        country: "",
        flight: "",
        stateCode: ""
      },
      toText: {
        state: "",
        country: "",
        flight: "",
        stateCode: ""
      },
      tripOpen: false,
      tripType: "",
      anchorEl: null,
      anchorElTo: null,
      anchorElFrom: null,
      filteredItemsTo: [],
      selectedOption: "One Way",
      activePicker: "",
      authToken: "",
      isOpen: false,
      startDate: null,
      endDate: null,
      endDate1: null,
      toModalOpen: false,
      searchTextClass: {
        classNumber: "",
        title: "",
        travelClass: ""
      },
      popularCities: [
        { id: '1', country: 'India', city: 'New Delhi', airportName: 'Indira Gandhi International Airport', airportCode: 'DEL' },
        { id: '1', country: 'India', city: 'Mumbai', airportName: 'Shivaji International Airport', airportCode: 'BOM' },
        { id: '1', country: 'India', city: 'Mumbai', airportName: 'Shivaji International Airport', airportCode: 'BOM' },
        { id: '1', country: 'India', city: 'Bangkok', airportName: 'Indira Gandhi International Airport', airportCode: 'BKK' },
        { id: '1', country: 'India', city: 'New Delhi', airportName: 'Indira Gandhi International Airport', airportCode: 'DEL' },
        { id: '1', country: 'India', city: 'New Delhi', airportName: 'Indira Gandhi International Airport', airportCode: 'DEL' },
      ],
      listCityDummy: [{ "id": "1", "country": "india", "state": "New Delhi", "flight": " Indira Gandhi International Airport", "stateCode": "DEL" }
        , { "id": "2", "country": "india", "state": "Mumbai", "flight": "Shivaji International Airport", "stateCode": "BOM" },
      { "id": "3", "country": "Bangkok", "state": "Bangkok", "flight": " Indira Gandhi International Airport", "stateCode": "BKK" },
      { "id": "4", "country": "india", "state": "New Delhi", "flight": " Indira Gandhi International Airport", "stateCode": "DEL" }
        , { "id": "2", "country": "india", "state": "Mumbai", "flight": "Shivaji International Airport", "stateCode": "BOM" },
      { "id": "3", "country": "Bangkok", "state": "Bangkok", "flight": " Indira Gandhi International Airport", "stateCode": "BKK" }],
      anchorElClass: null,
      countAdult: 1,
      countChild: 0,
      travelStatus: "ECONOMY",
      fullTravelObj: {countChild: 0,
        countAdult
        : 
        1,
        travelStatus: "ECONOMY",
        countInfant: 0,
      },
      error: "",
      filterCheckBox: false,
      tripOptions: [{ tripType: 'One Way', tripSelected: false }, { tripType: 'Round Trip', tripSelected: false }, { tripType: 'Multicity', tripSelected: false }],
      popularFilters: [
        { checked: false, name: "Non-stop",},
                { checked: false, name: "1 Stop"},
                { checked: false, name: "2 Stop"},
                  { checked: false, name: "Morning Departures"},
                  { checked: false, name: "Late Departures",},
      ],
      
      windowWidth: 1440,
      airlinesList: [
        { checked: false, name: 'Indigo', price: '$5352', img: 'https://i.pinimg.com/1200x/2d/06/2c/2d062c935dde7754fa80bf011a9dbdc7.jpg' },
        { checked: false, name: 'Indigo', price: '$5352', img: 'https://i.pinimg.com/1200x/2d/06/2c/2d062c935dde7754fa80bf011a9dbdc7.jpg' },
        { checked: false, name: 'Indigo', price: '$5352', img: 'https://i.pinimg.com/1200x/2d/06/2c/2d062c935dde7754fa80bf011a9dbdc7.jpg' },
        { checked: false, name: 'Indigo', price: '$5352', img: 'https://i.pinimg.com/1200x/2d/06/2c/2d062c935dde7754fa80bf011a9dbdc7.jpg' }
      ],
      datePrices: [],
      offersList: [
        { src: cheapestIcon, heading: 'Cheapest', content: '$5232 | 02h 05min' },
        { src: nonStopIcon, heading: 'NON STOP FIRST', content: '$5232 | 02h 05min' },
        { src: starIcon, heading: 'YOU MAY PREFER', content: '$5232 | 02h 05min' },
        { src: discountIcon, heading: 'Discounted Price', content: '$5232 | 02h 05min' }
      ]
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    // Customizable Area Start
    super.componentDidMount();
    this.getToken();


    const urlSearch = new URLSearchParams(window.location.search);
    const urlParams: URLSearchParamsType = {
      departure_date: urlSearch.get('departure_date'),
      origin: urlSearch.get('origin'),
      originFlight: urlSearch.get('originFlight'),
      destination: urlSearch.get('destination'),
      destinationFlight: urlSearch.get('destinationFlight'),
      cabin_class: urlSearch.get('cabin_class'),
      adult: urlSearch.get('adult'),
      children: urlSearch.get('children'),
      infant: urlSearch.get('infant'),
      trip_type: urlSearch.get('trip_type'),
      return_date: urlSearch.get("return_date")
    };
    this.roundTripResponse(urlParams)

    if (urlParams.trip_type === "round_trip" || urlParams.trip_type === "Round Trip") {
      this.setState({ selectedTripType: "Round Trip" })
    }
    if (urlParams.departure_date) {
      const departureDate = new Date(urlParams.departure_date);
      const dates = this.handleGenerateDates(new Date(), 10)
      const date_options: Intl.DateTimeFormatOptions = { month: 'short', day: 'numeric', weekday: 'short' }
      let formatted = departureDate.toLocaleDateString('en-US', date_options)
      const updatedDatePrices = dates.map((price_val) => {
        if (price_val.date === formatted) {
          return { ...price_val, isHighlighted: true };
        } else {
          return { ...price_val, isHighlighted: false };
        }
      });

      this.setState({ datePrices: updatedDatePrices });
    }

    let departureDate = new Date();
    const dates = this.handleGenerateDates(new Date(), 10);
    const date_options: Intl.DateTimeFormatOptions = { month: 'short', day: 'numeric', weekday: 'short' };
    let formatted = departureDate.toLocaleDateString('en-US', date_options);

    const updatedDatePrices = dates.map((price_val) => {
      let priceDateFormatted = new Date(price_val.date).toLocaleDateString('en-US', date_options);
      if (priceDateFormatted === formatted) {
        return { ...price_val, isHighlighted: true };
      } else {
        return { ...price_val, isHighlighted: false };
      }
    });

    this.setState({ datePrices: updatedDatePrices });

    if (urlParams.adult || urlParams.children || urlParams.infant || urlParams.cabin_class) {
      let obj = {
        countAdult: Number(urlParams.adult),
        countChild: Number(urlParams.children),
        travelStatus: String(urlParams.cabin_class),
        countInfant: Number(urlParams.infant)
      }
      this.setState({ fullTravelObj: obj, travelClassSelected: true ,countInfant: Number(urlParams.infant),  countAdult: Number(urlParams.adult), travelStatus: String(urlParams.cabin_class),
        countChild: Number(urlParams.children),})
    }
    if (urlParams.origin) {
      let origin_obj = {
        state: urlParams.origin,
        country: "",
        flight: "",
        stateCode: urlParams.origin
      }
      this.setState({ searchText: origin_obj,inputCityFrom: urlParams.origin })
    }
    if (urlParams.destination) {
      let dest_obj = {
        state: urlParams.destination,
        country: "",
        flight: "",
        stateCode: urlParams.destination
      }
      this.setState({ toText: dest_obj,inputCityTo: urlParams.destination })
    }
    this.setState({ selectedTripOption: urlParams.trip_type })
    // if (urlParams) {
    //   this.urlParamFunct(urlParams);
    // }
    // Customizable Area End
  }

  // Customizable Area Start

  roundTripResponse = (urlParams:URLSearchParamsType) => {
    if (urlParams.departure_date && urlParams.return_date && urlParams.origin && urlParams.destination && urlParams.originFlight && urlParams.destinationFlight) {
      const departureDate = new Date(urlParams.departure_date);
      const returnDate = new Date(urlParams.return_date);
      this.setState({searchInput:urlParams, startDate: departureDate, endDate1: returnDate ,inputCityFrom:urlParams.origin,
      inputCityTo: urlParams.destination,countAdult: Number(urlParams.adult),countChild: Number(urlParams.children),countInfant: Number(urlParams.infant),travelStatus: String(urlParams.cabin_class),selectAirportFrom : {
        Code: urlParams.origin,
        Name: urlParams.originFlight,
        CityCode: urlParams.origin,
        CountryCode: ""
      },selectAirportTo : {
        Code: urlParams.destination,
        Name: urlParams.destinationFlight,
        CityCode: urlParams.destination,
        CountryCode: ""
      },
      fullTravelObj: {
        countAdult: Number(urlParams.adult),
        countChild: Number(urlParams.children),
        travelStatus: String(urlParams.cabin_class),
        countInfant: Number(urlParams.infant),
      },
      },()=> this.roundTripSearchApi())
    }
  };

  getToken = () => {
    const tokenMsg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(tokenMsg);
  };
  // Customizable Area End

  // Customizable Area Start
  getListRequest = (token: string) => {
    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getProductApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.productAPiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getProductApiCallId != null &&
      this.getProductApiCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      this.getListRequest("");
      this.setState({ filterCheckBox: true })
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.errors && responseJson.data) {
        this.setState({ arrayHolder: responseJson.data });
        runEngine.debugLog("arrayHolder", this.state.arrayHolder);
      }
    }
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.errors) {
        this.responseSucessCell(apiRequestCallId, responseJson)
      }
      else if (responseJson && responseJson.errors) {
        this.responseFailureCell(apiRequestCallId, responseJson)
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  componentDidUpdate(prevProps: Props, prevState: Readonly<S>) {
    const { travelClassSelected, selectedTripType, countChild,inputCityFrom,inputCityTo, startDate, endDate1 } = this.state;
    if (
      travelClassSelected !== prevState.travelClassSelected ||
      countChild !== prevState.countChild ||
      selectedTripType !== prevState.selectedTripType ||
      inputCityFrom !== prevState.inputCityFrom ||
      inputCityTo !== prevState.inputCityTo ||
      startDate !== prevState.startDate ||
      endDate1 !== prevState.endDate1
    ) {
      this.handleCheckDetailsFilled()
    }
  }
  handleFiltersToggle = () => {
    this.setState({ filtersDrawer: !this.state.filtersDrawer })
  }

  handleGenerateDates = (startDate: Date, numberofDates: number) => {
    const generated_dates = [];
    const date_options: Intl.DateTimeFormatOptions = { month: 'short', day: 'numeric', weekday: 'short' }
    for (let date_num = 0; date_num < numberofDates; date_num++) {
      const date = new Date(startDate)
      date.setDate(date.getDate() + date_num)
      generated_dates.push({ date: date.toLocaleDateString('en-US', date_options), price: '$5400', isHighlighted: false })
    }
    return generated_dates
  }
  handleNextClick = () => {
    const lastDateString = this.state.datePrices[this.state.datePrices.length - 1].date;
    const [dayOfWeek, month] = lastDateString.split(', ')[1].split(' ');
    const year_val = new Date().getFullYear();
    const lastDate = new Date(`${month} ${dayOfWeek}, ${year_val}`);
    const newDates = this.handleGenerateDates(lastDate, 4);
    this.setState({ datePrices: [...this.state.datePrices, ...newDates] });

    if (this.datePricesRef.current) {
      this.datePricesRef.current.scrollBy({ left: 300, behavior: 'smooth' });
    }
  }

  handlePrevClick = () => {
    const firstDateString = this.state.datePrices[0].date;
    const [dayOfWeek, month] = firstDateString.split(', ')[1].split(' ');
    const currentDate = new Date();
    const year_val = currentDate.getFullYear();
    const firstDate = new Date(`${month} ${dayOfWeek}, ${year_val}`);
    const threeDaysAgo = new Date(firstDate);
    threeDaysAgo.setDate(threeDaysAgo.getDate() - 3);
    const pastDates = [];
    for (let date_num = 0; date_num < 3; date_num++) {
      const date = new Date(threeDaysAgo);
      date.setDate(date.getDate() + date_num);
      const options: Intl.DateTimeFormatOptions = { month: 'short', day: 'numeric', weekday: 'short' };
      pastDates.push({
        date: date.toLocaleDateString('en-US', options),
        price: "$5400",
        isHighlighted: false
      });
    }

    this.setState({ datePrices: [...pastDates, ...this.state.datePrices] });
    if (this.datePricesRef.current) {
      this.datePricesRef.current.scrollBy({ left: -300, behavior: 'smooth' });
    }
  }
  handleDateSelect = (datePrice: DatePrice) => {
    const updatedDatePrices = this.state.datePrices.map((price_val) => {
      if (price_val.date === datePrice.date) {
        return { ...price_val, isHighlighted: true };
      } else {
        return { ...price_val, isHighlighted: false };
      }
    });

    this.setState({ datePrices: updatedDatePrices });
  }
  handleTripClick = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({ anchorElTripType: event?.currentTarget });
    this.setState({ tripOpen: !this.state.tripOpen })
  };

  handleSelectTriptype = (tripType:string,selectedIndex: number) => {
    const updatedTripOptions = this.state.tripOptions.map((option, index) => {
      if (index === selectedIndex) {
        return { ...option, tripSelected: !option.tripSelected };
      }
      return { ...option, tripSelected: false };
    });
    this.setState({ selectedTripType: tripType})
    this.setState({ tripOptions: updatedTripOptions });
    this.handleClose()
    this.handleCloseTripOptions()
  };
  handleCloseTripOptions = () => {
    this.setState({ tripOpen: false })
  }

  reverseFunct = () => {
    this.setState({ selectAirportFrom: this.state.selectAirportTo, selectAirportTo: this.state.selectAirportFrom })
  }

  handleClickClass = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({ anchorElClass: event.currentTarget });
  };
  handleCloseClass = () => {
    this.setState({ anchorElClass: null });
  };
  increaseCount = () => {
    this.setState(prevState => {
      const total = prevState.countChild + prevState.countAdult;
      if (total < 9) {
        return {
          countAdult: prevState.countAdult + 1,
          error: ""
        };
      } else {
        this.errorFunct("If you want to add more than 9 travellers please contact email www.doolwa.com");
        return null;
      }
    });
  };

  decreaseCount = () => {
    this.setState(prevState => ({
      countAdult: prevState.countAdult === 1 ? prevState.countAdult : prevState.countAdult - 1,
      error: ""
    }));
  };

  increaseCountChild = () => {
    this.setState(prevState => {
      const total = prevState.countChild + prevState.countAdult;
      if (total < 9) {
        return {
          countChild: prevState.countChild + 1,
          error: ""
        };
      } else {
        this.errorFunct("If you want to add more than 9 travellers please contact email www.doolwa.com");
        return null;
      }
    });
  };

  decreaseCountChild = () => {
    this.setState(prevState => ({
      countChild: prevState.countChild === 0 ? prevState.countChild : prevState.countChild - 1,
      error: ""
    }));
  };

  errorFunct = (errorMsg: string) => {
    this.setState({
      error: errorMsg
    });
  };
  handleClickStatus = (buttonName: string) => {
    this.setState({
      travelStatus: buttonName,
      cabin_class: buttonName,
    });
  };
  handleSelectTo = (event:React.MouseEvent<HTMLElement>, index = 0) => {

    this.setState({ currentIndex: index,inputApiSearchTo:"",anchorElTo: event?.currentTarget, selectAirportTo: {
      Code: "",
      Name: "",
      CityCode: "",
      CountryCode: ""
    }, });
  };
  handleCancelTravel = () => {
    this.setState({
      travelStatus: "Economy", countAdult: 1,
       countChild: 0,countInfant:0,infant:0,cabin_class: "ECONOMY",
      fullTravelObj: {
       
        countChild: 0,
        countInfant: 0,
        travelStatus: "ECONOMY", countAdult: 1,
      },
      travelClassSelected: false,
      anchorElClass: null,
      error: ""
    })
  }
  handleDone = () => {
    this.setState({
      fullTravelObj: {
        countAdult: this.state.countAdult,
        countChild: this.state.countChild,
        countInfant: this.state.countInfant,
        travelStatus: this.state.travelStatus
      },
      travelClassSelected: true,
      anchorElClass: null
    })

  }
  handleCheckDetailsFilled = () => {
    const { travelClassSelected, selectedTripType,inputCityTo,inputCityFrom, startDate, endDate1 } = this.state;

    const anyDetailsEmpty = (
      !travelClassSelected ||
      !selectedTripType ||
      !inputCityTo  ||
      !inputCityFrom ||
      !(startDate instanceof Date) ||
      (selectedTripType === "Round Trip" && !(endDate1 instanceof Date))
    );

    this.setState({ enableSearch: !anyDetailsEmpty });
  }

  handleClose = () => {
    this.setState({ anchorEl: null, currentIndex: 0, searchField: "", filteredItems: [] });
  };

  togglePicker = (picker: string,event: React.MouseEvent<HTMLElement>) => {

    this.setState({
      isOpen: true,
      activePicker: picker,
      setPropover: event?.currentTarget
    });
    if (picker === "endDate") {
      this.setState({ selectedOption: "Roundtrip" }) 
    }

  };
  formatDate = (date: Date) => {
    if (date) {

      const dayaa = date.getDate();
      const month = date.toLocaleString('default', { month: 'short' });
      const year = date.getFullYear().toString().slice(-2);
      const weekday = date.toLocaleString('default', { weekday: 'long' });
      return `${dayaa} ${month}'${year} ${weekday}`;
    }
  };
  formatDatePart = (value: Date, part: string) => {
    let dateValue = this.formatDate(value)
    if (dateValue) {
      const parts = dateValue.split(' ');
      switch (part) {
        case "day":
          return parts[0];
        case "month":
          return parts[1];
        case "year":
          return parts[2];
      }
    }
  };
  handleDateChange = (ranges: DateRange) => {
    if (this.state.activePicker === 'startDate') {
      let determinedDate = ranges.selection.startDate;
      if ((!this.state.startDate && !this.state.endDate) || (this.state.startDate == ranges.selection.startDate && this.state.endDate == ranges.selection.startDate)) {
        determinedDate = ranges.selection.endDate;
      }
      this.setState({
        startDate: determinedDate,
        endDate: determinedDate,
        endDate1: null,

      });

    } else {
      this.setState({
        startDate: ranges.selection.startDate,
        endDate: ranges.selection.endDate,
        endDate1: ranges.selection.endDate,
      });
    }
  };
  handleApply = () => {
    this.setState({ isOpen: false, activePicker: "", setPropover: null });
  };

  handleCancel = () => {
    this.setState({ startDate: null, activePicker: "", endDate: null, endDate1: null, isOpen: false, setPropover: null});
  };
  handleShowPriceModal=(item: FlightList) => {
    this.setState({ priceModal: true,selectedFlightPrice: item,
      totalTimeDuration:this.addTimes(item.flight_details[0].travel_time, item.flight_details[1].travel_time)
     })
  }
  handleClosePriceModal = () => {
    this.setState({ priceModal: false , fareOption: null, fareOptionSelected: null,priceClickIndex: null})
  }
  handleFareOptionSelect = (indexNumber:number) => {
    this.setState({ fareOptionSelected: indexNumber})
  }

  bookFlight = (item:ViewPrices,index: number) => {
    this.createPriceApi(item.AirItenanry)
    this.setState({priceClickIndex: index,AirItenanryData:item.AirItenanry})
  }

  handleSearch = () => {
    if(this.state.selectedTripType == "Round Trip"){
      if (this.roundTripCheckValidation()) {
        this.roundTripSearchApi()
      }
    }else {
      if(this.oneWayCheckValidation()){
        this.oneWaySearchApi()
      }
    }
  };
  
  roundTripCheckValidation = () => {
    const { selectAirportTo,selectAirportFrom, endDate1,startDate,fullTravelObj} = this.state
    if (!selectAirportFrom.Code) {
      this.setState({ errorResSearch: 'Origin city is required.' });
      return false;
    } else if (!selectAirportTo.Code) {
      this.setState({ errorResSearch: 'Destination city is required.' });
      return false;
    } else if (!startDate) {
      this.setState({ errorResSearch: 'Departure Date is required.' });
      return false;
    } else if (!endDate1) {
      this.setState({ errorResSearch: 'Return Date is required.' });
      return false;
    }else if (fullTravelObj.countAdult <= 0) {
      this.setState({ errorResSearch: 'Travel count and class is required' });
      return false;
    } else {
      this.setState({ errorResSearch: '' });
      return true;
    }
  };

  oneWayCheckValidation = () => {
    const { selectAirportFrom,selectAirportTo,startDate,fullTravelObj} = this.state
    if (!selectAirportFrom.Code) {
      this.setState({ errorResSearch: 'Origin city is required.' });
      return false;
    } else if (!selectAirportTo.Code) {
      this.setState({ errorResSearch: 'Destination city is required.' });
      return false;
    } else if (!startDate) {
      this.setState({ errorResSearch: 'Departure Date is required.' });
      return false;
    } else if (fullTravelObj.countAdult <= 0) {
      this.setState({ errorResSearch: 'Travel count and class is required' });
      return false;
    } else {
      this.setState({ errorResSearch: '' });
      return true;
    }
  };

  SearchDataOneWay = () => {
    const { selectedTripType, fullTravelObj, searchText, toText,inputCityFrom,inputCityTo, startDate } = this.state;
    let formattedDeptDate = ""
    if (startDate) {
      const year = startDate.getFullYear();
      const month = String(startDate.getMonth() + 1).padStart(2, '0');
      const day = String(startDate.getDate()).padStart(2, '0');
      formattedDeptDate = `${year}-${month}-${day}`;
      const date_options: Intl.DateTimeFormatOptions = { month: 'short', day: 'numeric', weekday: 'short' }
      let formatted = startDate.toLocaleDateString('en-US', date_options)
      const updatedDatePrices = this.state.datePrices.map((price_val) => {
        if (price_val.date === formatted) {
          return { ...price_val, isHighlighted: true };
        } else {
          return { ...price_val, isHighlighted: false };
        }
      });

      this.setState({ datePrices: updatedDatePrices });
    }
    this.setState({ loading: true });
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.oneWaySearchApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.oneWayEndPoint + `departure_date=${formattedDeptDate}&origin=${inputCityFrom}&destination=${inputCityTo}&cabin_class=${fullTravelObj.travelStatus}&adult=${fullTravelObj.countAdult}&children=${fullTravelObj.countChild}&infant=${fullTravelObj.countInfant}&trip_type=${selectedTripType}`
    );

    const header = {
      "Content-Type": configJSON.productApiContentType,
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    if (typeof window !== 'undefined') {
      const url = `${window.location.origin}${window.location.pathname}?departure_date=${formattedDeptDate}&origin=${searchText.stateCode}&destination=${toText.stateCode}&cabin_class=${fullTravelObj.travelStatus}&adult=${fullTravelObj.countAdult}&children=${fullTravelObj.countChild}&infant=${fullTravelObj.countInfant}&trip_type=${selectedTripType}`;
      window.history.pushState({ path: url }, '', url);
    }
  }

  handlePriceRangeChange = (maxValue: number) => {
    this.setState({ selectedPrice: maxValue })
    const filteredData = this.state.flightAllDataDummy.filter((item: FlightData) => {
      return item !== undefined && parseInt(item.adult_per_price) <= maxValue;
    });
    this.setState({ roundTripResponse: filteredData });
  }
  decreaseCountChildInfant = () => {
    this.setState(prevState => ({
      countInfant: prevState.countInfant === 0 ? prevState.countInfant : prevState.countInfant - 1,
      infant:prevState.countInfant === 0 ? prevState.countInfant : prevState.countInfant - 1,
      error: ""
    }));
  };
  increaseCountChildInfant = () => {
    this.setState(prevState => {
      const total = prevState.countChild + prevState.countInfant + prevState.countAdult;
      if (total < 9) {
        return {
          countInfant: prevState.countInfant + 1,
          infant:prevState.countInfant + 1,
          error: ""
        };
      } else {
        this.errorFunct("If you want to add more than 9 travellers please contact email www.doolwa.com");
        return null;
      }
    });
  };
  inputCityFuncFrom=(value: string)=>{   
    this.setState({inputCityFrom:value}) 
  }
  inputCityFunc=(value: string)=>{
    this.setState({inputCityTo:value})
  }
  handleShowDetail = (event: React.MouseEvent<HTMLElement>,indexKey:number) => {
    event?.stopPropagation()
    this.setState({visibleDetailIndex: this.state.visibleDetailIndex === indexKey ? null : indexKey})
  }

  roundTripSearchApiCall = async (valueData: {
    contentType?: string;
    method?: string;
    endPoint?: string;
    body?: {};
    apiType?: string;
    type?: string;
  }) => {
    let { contentType, method, endPoint, body, apiType } = valueData;
    let header = {
      "Content-Type": contentType
    };
    let roundTripRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    roundTripRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    roundTripRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    body &&
      roundTripRequestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        apiType === "" ? JSON.stringify(body) : body
      );
    roundTripRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(roundTripRequestMessage.id, roundTripRequestMessage);
    if (typeof window !== 'undefined') {
      const urlName = `${window.location.origin}${window.location.pathname}?departure_date=${changeMoment(this.state.startDate).format('YYYY-MM-DD')}&origin=${this.state.selectAirportFrom.Code}&originFlight=${this.state.selectAirportFrom.Name}&destination=${this.state.selectAirportTo.Code}&destinationFlight=${this.state.selectAirportTo.Name}&cabin_class=${this.state.travelStatus}&adult=${this.state.countAdult}&children=${this.state.countChild}&trip_type=round_trip&infant=${this.state.countInfant}&return_date=${changeMoment(this.state.endDate1).format('YYYY-MM-DD')}`;
      window.history.pushState({ path: urlName }, '', urlName);
    }
    return roundTripRequestMessage.messageId;
  };

  responseSucessCell = async (apiRequestCallId: string, responseJson: AirData & AirportList) => {
    if (apiRequestCallId === this.changeRoundTripApiCallId) {
      this.roundTripSearchSucessCallBack(responseJson);
    }
    if(apiRequestCallId === this.getAirportApiCallId){
      this.getAirportSucessCallBack(responseJson);
    }
    if(apiRequestCallId === this.getAirportToApiCallId){
      this.getAirportToSucessCallBack(responseJson);
    }
    if(apiRequestCallId === this.changeOneWayApiCallId){
      this.oneWaySearchSucessCallBack(responseJson);
    }
    if(apiRequestCallId === this.createPriceApiCallId){
      this.createPriceSucessCallBack(responseJson);
    }
  };
 
  responseFailureCell = async (apiRequestCallId: string, responseJson: ErrorResponse & AirportErrorResponse) => {
    if (apiRequestCallId === this.changeRoundTripApiCallId) {
      this.roundTripSearchFailureCallBack(responseJson);
    }
    if (apiRequestCallId === this.getAirportApiCallId) {
      this.getAirportFailureCallBack(responseJson);
    }
    if (apiRequestCallId === this.getAirportToApiCallId) {
      this.getAirportToFailureCallBack(responseJson);
    }
    if (apiRequestCallId === this.changeOneWayApiCallId) {
      this.oneWaySearchFailureCallBack(responseJson);
    }
    if(apiRequestCallId === this.createPriceApiCallId){
      this.createPriceFailureCallBack(responseJson);
    }
  };
 
  roundTripSearchApi = async () => {
    this.setState({ loading: true });
    this.changeRoundTripApiCallId = await this.roundTripSearchApiCall({
      contentType: configJSON.productApiContentType,
      method: configJSON.apiMethodTypeGet,
      endPoint: `${configJSON.oneWayEndPoint}departure_date=${changeMoment(this.state.startDate).format('YYYY-MM-DD')}&origin=${this.state.selectAirportFrom.Code}&destination=${this.state.selectAirportTo.Code}&cabin_class=${this.state.travelStatus}&adult=${this.state.countAdult}&children=${this.state.countChild}&trip_type=round_trip&infant=${this.state.countInfant}&return_date=${changeMoment(this.state.endDate1).format('YYYY-MM-DD')}`,
    })
  };

  roundTripSearchSucessCallBack = (response: AirData) => {
    if (!response.errors && !response.error) {
      this.handleSelectedPopularFilter()
      this.setState({ roundTripResponse: response.flight_list , flightAllDataDummy:response.flight_list, airLinesData:this.mapAirlins(response.flight_list),
        price_range: response.price_range,
          minPrice: response.price_range.min,
          maxPrice: response.price_range.max,errorPriceInfo:"", departureTimePrice: null,arrivalTimePrice: null,
          selectedPrice: response.price_range.max,loading: false,visibleDetailIndex:null})
    } else {
      this.setState({ errorResSearch: response.error.Description, loading: false ,roundTripResponse:[],visibleDetailIndex:null});
    }
  };

  roundTripSearchFailureCallBack = (response: ErrorResponse) => {
    this.setState({ errorResSearch:response.errors[0], loading: false });
  };

  getAirportApi = async () => {
    this.getAirportApiCallId= await this.roundTripSearchApiCall({
      contentType: configJSON.productApiContentType,
      method: configJSON.apiMethodTypeGet,
      endPoint: `${configJSON.airportSearchEndPoint}${this.state.searchAirport}`,
    })
  };

  getAirportSucessCallBack = (response: AirportList & AirData) => {
    if (response.airport_list != null) {
      
      this.setState({ airportNameFrom: response.airport_list ,loading: false});
    } else {
      this.setState({ errorResSearch: response.error.Description, loading: false });
    }
  };

  getAirportFailureCallBack = (response: AirportErrorResponse) => {
    this.setState({ errorResSearch:response.errors, loading: false });
  };

  getAirportToApi = async () => {
    this.getAirportToApiCallId = await this.roundTripSearchApiCall({
      contentType: configJSON.productApiContentType,
      method: configJSON.apiMethodTypeGet,
      endPoint: `${configJSON.airportSearchEndPoint}${this.state.searchAirpotTo}`,
    })
  };

  getAirportToSucessCallBack = (response: AirportList & AirData) => {
    if (response.airport_list != null) {
      this.setState({ airportNameTo: response.airport_list ,loading: false});
    } else {
      this.setState({ errorResSearch: response.error.Description, loading: false });
    }
  };

  getAirportToFailureCallBack = (response: AirportErrorResponse) => {
    this.setState({ errorResSearch:response.errors, loading: false });
  };

  oneWaySearchApi = async () => {
    this.setState({ loading: true });
    this.changeOneWayApiCallId = await this.roundTripSearchApiCall({
      contentType: configJSON.productApiContentType,
      method: configJSON.apiMethodTypeGet,
      endPoint: `${configJSON.oneWayEndPoint}departure_date=${changeMoment(this.state.startDate).format('YYYY-MM-DD')}&origin=${this.state.selectAirportFrom.Code}&destination=${this.state.selectAirportTo.Code}&cabin_class=${this.state.travelStatus}&adult=${this.state.countAdult}&children=${this.state.countChild}&trip_type=one_way&infant=${this.state.countInfant}}`,
    })
  };

  oneWaySearchSucessCallBack = (response: AirData) => {
    if (!response.errors && !response.error) {
      const urlName = `Catalogue?departure_date=${changeMoment(this.state.startDate).format('YYYY-MM-DD')}&origin=${this.state.selectAirportFrom.Code}&originFlight=${this.state.selectAirportFrom.Name}&destination=${this.state.selectAirportTo.Code}&destinationFlight=${this.state.selectAirportTo.Name}&cabin_class=${this.state.travelStatus}&adult=${this.state.countAdult}&children=${this.state.countChild}&infant=${this.state.countInfant}&trip_type=one_way`;
      window.location.href = urlName;
    } else {
      this.setState({ errorResSearch: response.error.Description, loading: false });
    }
  };

  oneWaySearchFailureCallBack = (response: ErrorResponse) => {
    this.setState({ errorResSearch:response.errors[0], loading: false });
  };

  createPriceApi = async (AirItenanry:AirItinerary[]) => {
    const totalTraveller= {
      "adult":this.state.countAdult,
      "childrens":this.state.countChild,
      "infant":this.state.countInfant,
    }
    localStorage.setItem('totalTraveller', JSON.stringify(totalTraveller));
    const body = {
      AirItenanry,
      "adult": this.state.countAdult,
      "children": this.state.countChild,
      "infant": this.state.countInfant
    };
    this.createPriceApiCallId = await this.roundTripSearchApiCall({
      contentType: configJSON.productApiContentType,
      method: configJSON.apiMethodTypePost,
      endPoint: configJSON.priceAPiEndPoint,
      body: JSON.stringify(body)

    })
  };

  createPriceSucessCallBack = (response: any) => {
    if (!response.air_pricing.ErrorInfo && response) {
      this.setState({errorPriceInfo:""})
    const promoCodeMessage: Message = new Message(
      getName(MessageEnum.OrderManagementRoundDataMessage)
    );
    promoCodeMessage.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    promoCodeMessage.addData(getName(MessageEnum.OrderManagementRoundDataInfo),{bookingData: response,
       AirItenanryData:this.state.AirItenanryData, totalTimeDuration:this.state.totalTimeDuration});
    this.send(promoCodeMessage);
  } else{
    this.setState({errorPriceInfo:response.air_pricing.ErrorInfo.Description})
  }
  };

  createPriceFailureCallBack = (response: ErrorResponse) => {
    this.setState({ errorResSearch:response.errors[0], loading: false });
  };

  layOver = (flightInfo: FlightInfo[]) => {
    return (flightInfo?.slice(0, -1).map((layover: FlightInfo) => {
      return (layover.Destination)
    }))
  };

 
  parseTime = (time: string) => {
    const timeRegex = configJSON.reguxDuration;
    const match:any = time.match(timeRegex);
    return {
      days: parseInt(match[1] || '0', 10),
      hours: parseInt(match[2] || '0', 10),
      minutes: parseInt(match[3] || '0', 10)
    };
  };
  
  truncateString = (str:string, maxLength:number) => {
    if (str.length > maxLength) {
      return str.slice(0, maxLength) + "...";
    }
    return str;
  };
  addTimes = (time1: string, time2: string): string => {
      const parsedTime1:any = this.parseTime(time1);
      const parsedTime2:any = this.parseTime(time2);
  
      const totalMinutes =
        (parsedTime1.days * 24 * 60 + parsedTime1.hours * 60 + parsedTime1.minutes) +
        (parsedTime2.days * 24 * 60 + parsedTime2.hours * 60 + parsedTime2.minutes);
  
      const resultDays = Math.floor(totalMinutes / (24 * 60));
      const resultHours = Math.floor((totalMinutes % (24 * 60)) / 60);
      const resultMinutes = totalMinutes % 60;
  
      return `${resultDays > 0 ? resultDays + " d, " : ""}${resultHours} h ${resultMinutes} min`;
  };

  travelTimeDifference = (departure: string, arrival: string) => {
    const departureTime = changeMoment(departure);
    const arrivalTime = changeMoment(arrival);
    const duration = changeMoment.duration(arrivalTime.diff(departureTime));
    const hours = duration.hours();
    const minutes = duration.minutes();
    return `${hours} h ${minutes} min`;
  };

  calculateTimeDifference = (startDate: Date, endDate: Date) => {
    const timeDifferenceMs = endDate.getTime() - startDate.getTime();
    const timeDifferenceHours = Math.floor(timeDifferenceMs / (1000 * 60 * 60));
    const timeDifferenceMinutes = Math.floor((timeDifferenceMs % (1000 * 60 * 60)) / (1000 * 60));
    return { hours: timeDifferenceHours, minutes: timeDifferenceMinutes };
  };

  layoverTime = (arrivalTime: string, departureTime: string, destination: string) => {
    const lapse = this.calculateTimeDifference(new Date(arrivalTime), new Date(departureTime));
    return `${lapse.hours} h ${lapse.minutes} min Layover in ${destination}`
  };

  formateTime = (changeTime: string) => {
    return changeMoment(changeTime).format('hh:mm A')
  };

 
  convertFormatTime = (isoString: moment.MomentInput) => {
    const formattedDate = momentX.parseZone(isoString).format('hh:mm A');
    return formattedDate
  }
  
  formateDateMonth = (changeDateMonth: string) => {
    return momentX.parseZone(changeDateMonth).format('ddd, DD MMM');
  };

  tripRoute = (tripRoute:FlightDetails) =>{
    return tripRoute.flight_info.map((cityName:FlightInfo,indexNumber: number) => {
      return `${cityName.Origin} > ${tripRoute.flight_info.length-1 == indexNumber ? cityName.Destination:""}` 
    })
  };

  formattedData = (dataBg: string[]) => {
    return dataBg.map((item) => item.replace(/\n/g, "<br/>"))
      .join("<br/><br/>")
  };


  handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    this.setState({searchText: { ...this.state.searchText, state: value },searchAirport : value,inputApiSearchFrom:value,}, () => {this.getAirportApi()
    });
  };

  handleInputChangeTo = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({searchAirpotTo: event.target.value,inputApiSearchTo:event.target.value},() => {this.getAirportToApi()})
  }

  handleFromDataSelect = (option: Airport) => {
    this.setState({ anchorEl: null, currentIndex: 0, selectAirportFrom: option });
  }
  handleSelectDataTo = (selectedItem:Airport) => {
    this.setState({  selectAirportTo: selectedItem ,anchorElTo: null, currentIndex: 0,
      });
  };
  handleClickFrom = (event:React.MouseEvent<HTMLElement>, index = 0) => {
    this.setState({selectAirportFrom: {
      Code: "",
      Name: "",
      CityCode: "",
      CountryCode: ""
    },inputApiSearchFrom:"", anchorEl: event.currentTarget, currentIndex: index });
  };

  oneWayDateChange = (ranges: DateRange) => {
    if (this.state.activePicker === 'startDate') {
      let determinedDate = ranges.selection.startDate;
      this.setState({
        startDate: determinedDate,
        endDate: determinedDate,
        endDate1: null,
      });
    } 
  };

  handleChangeDate = (ranges: DateRange) => {
    const { startDate, endDate } = ranges.selection;
    this.setState({startDate,endDate, endDate1:endDate });
  };

  propoverClose = () => {
    this.setState({ setPropover: null, isOpen: false });
  };
  handleSelectedPopularFilter=()=>{
    this.setState({ shownPopularFilters: [
      { checked: false, name: "Non-stop" },
      { checked: false, name: "1 Stop"},
      { checked: false, name: "2 Stop"},
      { checked: false, name: "Morning Departures"},
      { checked: false, name: "Late Departures"},
    ]});
  }
  handlePopulerFunct = (items: PopularFilterProps) => {
    items.checked = !items.checked;

    let updatedSelectedFilters = [...this.state.selectedPopularFilter];

    if (items.checked) {
        updatedSelectedFilters.push(items);
    } else {
        updatedSelectedFilters = updatedSelectedFilters.filter(
            filter => filter.name !== items.name
        );
    }
        this.setState({ selectedPopularFilter: updatedSelectedFilters }, () => {
          this.datafilterCustom();
      });
};
datafilterCustom=()=>{
  let selectedAirlines = this.state.selectedAirlines.filter((item:any) => item.checked).map((item:any) => item.AirlineName);
    let customArray:any = this.state.flightAllDataDummy;
    let filteredArray = customArray;
    if (selectedAirlines.length > 0) {
      filteredArray = filteredArray.filter((flight: any) => {
        const airlineNames = flight.AirlineName.split(',').map((name: string) => name.trim());
        return selectedAirlines.some((selected: string) => airlineNames.includes(selected));
      });
  }
    const filterConditions = [
        { name: "Non-stop", func: this.nonStopFunction },
        { name: "1 Stop", func: this.oneStopFunction },
        { name: "2 Stop", func: this.twoStopFunction },
        { name: "Morning Departures", func: this.morningFunction },
        { name: "Late Departures", func: this.eveningFunction },
    ];

    let anyItemChecked = false;
    let filteredResults = new Set<FlightData>();

    filterConditions.forEach(condition => {
        const item = this.state.selectedPopularFilter.find(
            (item: PopularFilterProps) => item.name === condition.name && item.checked
        );

        if (item) {
            const result = condition.func(item, filteredArray);
            result.forEach(flight => filteredResults.add(flight));
            anyItemChecked = true;
        }
    });

    if (anyItemChecked) {
        filteredArray = Array.from(filteredResults);
    }

    this.setState({ roundTripResponse: filteredArray });
}
handleClickNationality = (event:any) => {
  this.setState({ anchorElNationalClass: event.currentTarget });
};


handleNationalityClass = () => {
  this.setState({ anchorElNationalClass: null });
};

handleSelectNationality = (nationality:any) => {
  this.setState({ selectedNationality: nationality, anchorElNationalClass: null });
};

  nonStopFunction = (item: any, dataArray: FlightData[]) => {
   
    if (item.checked) {
      return dataArray.filter((flight: FlightData) => String(flight.connection) === "0,0");
    }
    return dataArray;
  };
  twoStopFunction = (item: PopularFilterProps, dataArray: FlightData[]) => {
    if (item.checked) {
      return dataArray.filter((flight: FlightData) => String(flight.connection) === "2,2");
    }
    return dataArray;
  };
  oneStopFunction = (item: PopularFilterProps, dataArray: FlightData[]) => {
    if (item.checked) {
      return dataArray.filter((flight: FlightData) => String(flight.connection) === "1,1");
    }
    return dataArray;
  };
  morningFunction = (item: PopularFilterProps, dataArray: FlightData[]) => {
    if (item.checked) {
      const minTimeMorning = { start: "00:00", end: "06:00", hStart: 0, hEnd: 5 }; 
      return dataArray.filter((flight: any) => {
       let deprtureTime = momentX.parseZone(flight.flight_details[0].flight_info[0].DepartureTime)
       return deprtureTime.hour() >= minTimeMorning.hStart && deprtureTime.hour() <= minTimeMorning.hEnd
    });
  }
    return dataArray;
  };
  eveningFunction = (item: PopularFilterProps, dataArray: FlightData[]) => {
    if (item.checked) {
      const minTime = { start: "18:00", end: "00:00", hStart: 18, hEnd: 23 }; 
      return dataArray.filter((flight: any) => {
       let deprtureTime = momentX.parseZone(flight.flight_details[0].flight_info[0].DepartureTime)
       return deprtureTime.hour() >= minTime.hStart && deprtureTime.hour() <= minTime.hEnd
    });
    }
    return dataArray;
  };
  convertToComparableTime = (time: string) => {
    const [timePart, modifier] = time.split(" ");
    let [hours, minutes] = timePart.split(":").map(Number);
  
    if (modifier === "PM" && hours !== 12) {
      hours += 12;
    }
    if (modifier === "AM" && hours === 12) {
      hours = 0;
    }
  
    const date = new Date();
    date.setHours(hours, minutes, 0, 0);
  
    return date;
  };
  
  selectDepartureDate = (selectDate:Date) => {
    return changeMoment (selectDate) . format ( 'DD MMM, ddd YYYY')
    }
    handleDepartureTimeSelect = (time: TimePrice) => {
      let updatedArray: any[] = this.state.flightAllDataDummy;
      const timeRanges: any = {
          "Before 6AM": { start: "00:00", end: "06:00", hStart: 0, hEnd: 5 },
          "6AM to 12PM": { start: "06:00", end: "12:00", hStart: 6, hEnd: 11 },
          "12PM to 6PM": { start: "12:00", end: "18:00", hStart: 12, hEnd: 17 },
          "After 6PM": { start: "18:00", end: "00:00", hStart: 18, hEnd: 23 },
      };
      const { hStart, hEnd } = timeRanges[time.time];
      const aData = updatedArray.filter((x:any) => {
       const departureDateTime = momentX.parseZone(x.flight_details[0].flight_info[0].DepartureTime);
        if(departureDateTime.hour() >= hStart && departureDateTime.hour() <= hEnd)return (departureDateTime.hour() >= hStart && departureDateTime.hour() <= hEnd); 
      })
      this.setState({ 
          departureTimePrice: time,
          roundTripResponse: aData,
          currentPage:1,
      });
  };
    handleArrivalTimeSelect = (time: TimePrice) => {
      let updatedArray: any = this.state.flightAllDataDummy;
      const timeRanges: any = {
        "Before 6AM": { start: "00:00", end: "06:00", hStart: 0, hEnd: 5 },
        "6AM to 12PM": { start: "06:00", end: "12:00", hStart: 6, hEnd: 11 },
        "12PM to 6PM": { start: "12:00", end: "18:00", hStart: 12, hEnd: 17 },
        "After 6PM": { start: "18:00", end: "00:00", hStart: 18, hEnd: 23 },
      };
      const { hStart, hEnd } = timeRanges[time.time];
      const aDataa = updatedArray.filter((x:any) => {
       const departureDateTime = momentX.parseZone(x.flight_details[0]?.flight_info[x.flight_details[0]?.flight_info.length - 1]?.ArrivalTime);
        if(departureDateTime.hour() >= hStart && departureDateTime.hour() <= hEnd)
       return (departureDateTime.hour() >= hStart && departureDateTime.hour() <= hEnd); 
      })
      this.setState({ 
        arrivalTimePrice: time,
        currentPage:1,
          roundTripResponse: aDataa 
      });
  };
    airlinsFunct = (items: any) => {
      items.checked = !items.checked;
  
      let updatedSelectedAirlines = [...this.state.selectedAirlines];
  
      if (items.checked) {
          updatedSelectedAirlines.push(items);
      } else {
          updatedSelectedAirlines = updatedSelectedAirlines.filter(
              airline => airline.AirlineName !== items.AirlineName
          );
      }
      this.setState({ selectedAirlines: updatedSelectedAirlines }, () => {
          this.datafilterCustom();
      });
  };

  mapAirlins = (responseJson: any)=> {
    const duplicateData = responseJson.reduce((acc: { [x: string]: any }, item: { AirlineName:any}) => {
      item.AirlineName?.split(',').forEach((name: string) => {
        const trimmedName = name.trim();
        acc[trimmedName] = (acc[trimmedName] || 0) + 1;
      });
      return acc;
    }, {});
    const dataAllFlight = responseJson.flatMap((item:any, index:any) =>
      item.AirlineName.split(',').map((name: string) => ({
        id: index+1,
        Count:duplicateData[name.trim()],
        AirlineName: name.trim(),
        checked:false,
      }))
    );



  
    const uniqueAirlines = dataAllFlight.reduce((acc: any[], current: { AirlineName: any; }) => {
      if (!acc.find((item: { AirlineName: any; }) => item.AirlineName === current.AirlineName)) {
        acc.push(current);
      }
      return acc;
    }, []);
  
    return uniqueAirlines;
  };
  SeparateBullets(data: string) {
    const lines = data.split('\n');
    let heading = lines[0].trim();
    let content = lines.slice(1).map(line => line.trim().replace('"', ''));
  
    let cleared_content = content
    .map(char => char.includes("•") ? char.replace("•", "").trim() : char.trim())
    .filter(char => char !== "");

    if(cleared_content[0].toLowerCase().includes("includes:")){
      let text = cleared_content[0]
      cleared_content.shift()
      heading = heading+text
    }
  

    return {
        heading,
        cleared_content
    };
}
handlePageChange = (event:any, page:any) => {
  this.setState({ currentPage: page });
};
clearAllFunctRound=()=>{
  const updatedAirlinesData1 = this.state.airLinesData.map((item:any) => ({
    ...item, checked: false
}));
  this.handleSelectedPopularFilter()
   
  this.setState({
    selectedAirlines: [],
    departureTimePrice: null,arrivalTimePrice: null,
    roundTripResponse: this.state.flightAllDataDummy,selectedPrice: this.state.maxPrice
}, () => {
    this.setState({ airLinesData: updatedAirlinesData1 });
});
}
  // Customizable Area End
}