
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import React,{ RefObject } from "react";

interface NotificationRes{
  errors?:  {message: string}[]
  data:  Notification[]
}
export interface Notification {
  id: string;
  type: string;
  attributes: {
    id: number;
    created_by: string | null;
    headings: string | null;
    contents: string;
    app_url: string | null;
    is_read: boolean;
    read_at: string | null;
    created_at: string;
    updated_at: string;
    account: Account;
    notifiable_id: number,
    notifiable_type: string,
    notifiable: Notifiable;
    unread_count: number;
  };
}

interface Account {
  id: number;
  first_name: string | null;
  last_name: string | null;
  full_phone_number: string | null;
  country_code: number | null;
  phone_number: number | null;
  email: string;
  activated: boolean;
  device_id: string | null;
  unique_auth_id: string;
  password_digest: string;
  created_at: string;
  updated_at: string;
  user_name: string | null;
  platform: string | null;
  user_type: string | null;
  app_language_id: number | null;
  last_visit_at: string | null;
  is_blacklisted: boolean;
  suspend_until: string | null;
  status: string;
  role_id: number | null;
  full_name: string;
  gender: string | null;
  date_of_birth: string | null;
  age: number | null;
  credit: number | null;
  passport_number: string | null;
  passport_expiry_date: string | null;
  address1: string | null;
  address2: string | null;
  city: string | null;
  country: string | null;
  zip_code: number | null;
  state: string | null;
  deleted_at: string | null;
}

interface Notifiable {
  object: {
    id: number;
    title: string;
    offer_code: string;
    travel_date: string | null;
    travel_city: string;
    expiry_date: string;
    offer_type: string;
    discount_amount: number;
    created_at: string;
    updated_at: string;
    tagline1: string;
    tagline2: string;
    description: string;
    status: string;
  };
  image_url: string;
}


interface UserProfile {
  errors: {token: string}[] | string[]
  data: {
    id: string;
    type: string;
    attributes: {
      image: string| null
      activated: boolean;
      country: string | null;
      email: string;
      phone_number: string | null;
      gender: string | null;
      date_of_birth: string | null;
      address1: string | null;
      address2: string | null;
      passport_expiry_date: string | null;
      passport_number: string | null;
      zip_code: string | null;
      city: string | null;
      state: string | null;
      country_code: string | null;
      full_name: string | null
    };
  };
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  menuOpen:boolean;
  authToken:string | null;
  notificationEL: Element | null
  anchorEl:Element | null
  notificationCount: number
  notifications: Notification[]
  userName: string
  recordInfo: string
  notificationMobileEL: Element | null
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class HeaderMenuController extends BlockComponent<
  Props,
  S,
  SS
> {
  scrollRef: RefObject<HTMLDivElement> ;
  // Customizable Area Start
  getNotificationsApiId: string = ''
  getUserProfileApiCallId: string =""
  readNotificationApiCallId : string =''
  readAllNotificationApiCallId: string =''
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.scrollRef = React.createRef<HTMLDivElement>();
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];

    this.state = {
      menuOpen:false,
      authToken:"",
      anchorEl:null,
      notificationEL: null,
      notificationCount:0,
      notifications:[],
      userName: '',
      recordInfo: '',
      notificationMobileEL: null
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
  }

  

  // Customizable Area Start
  async componentDidMount() {
    this.tokenGetFunct()
    if (this.scrollRef.current) {
      window.scrollTo(0, 0);
    }
    this.handleGetNotifications()
    this.handleGetProfileDetails()
  }


  tokenGetFunct=()=>{
    const token = localStorage.getItem('authToken');
    if(token){
      this.setState({authToken:token})
    }
  }
  handleMenuToggle = () => {
   this.setState({menuOpen:!this.state.menuOpen})
};
  handleGetNotifications = () => {
    const token = localStorage.getItem("authToken");
    if (token) {
      const header = {
        "Content-Type": configJSON.jsonApiContentType,
        token: token
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.getNotificationsApiId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.getNotificationEndpoint
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );


      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.getApiMethodType
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }else{
      this.setState({notificationCount:0,notifications:[]})
    }
  }
  truncateString = (str:string, maxLength:number) => {
    if (str.length > maxLength) {
      return str.slice(0, maxLength) + "...";
    }
    return str;
  };
  handleNotificationsRes = (response:NotificationRes)=>{
    const {data} = response
    if(data){
      let count = this.getUnreadCount(data)
      this.setState({notifications: data,notificationCount:count})
    }else{
      this.setState({notifications:[],notificationCount: 0})
    }
  }
  handleReadNotification = (id: string)=>{
    const token = localStorage.getItem("authToken");
    if (token) {
      const header = {
        "Content-Type": configJSON.jsonApiContentType,
        token: token
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.readNotificationApiCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.readNotificationApiEndpoint+id
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify("")
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.putApiMethodType
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  }

  handleLearnMoreClick= (item:Notification)=>{
    const baseUrl = window.location.origin;
    if(item.attributes.notifiable_type.includes(':Offer')){
      window.location.href = baseUrl + `/Offers/${item.attributes.notifiable_id}`
    }
    else if(item.attributes.notifiable_type.includes(':Package')){
      window.location.href = baseUrl + `/PackageDetail/${item.attributes.notifiable.object.id}`
    }
    else if (item.attributes.notifiable_type.includes(':Account')){
      this.props.navigation.navigate('UserProfileBasicBlock')
    }
  }

  checkNotificationType = (item: Notification) => {
    const notifiableType = item.attributes.notifiable_type;
    if (notifiableType) {
      const lowercasedType = notifiableType.toLowerCase();
      return notifiableType.includes(':Offer') || lowercasedType.includes('package') || item.attributes.notifiable_type.includes(':Destination') || notifiableType.includes(':Account')
    }
    return false;
  }
  
  getUnreadCount = (data:Notification[])=>{
    let unreadCount = 0;
    for (let notification of data) {
      if (!notification.attributes.is_read) {
        unreadCount++;
      }
    }
    return unreadCount;
  }
  handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleMenuClose = () => {
    this.setState({ anchorEl: null });
  };
  handleNotificationClose = () => [
    this.setState({ notificationEL: null })
  ]
  logoutFunct = () => {
    localStorage.removeItem("authToken")
    localStorage.removeItem("userData")
    this.setState({ authToken: "" })
    this.props.navigation.navigate("Home");

  }
  loginRedirect = () => {
    this.props.navigation.navigate("EmailAccountLoginBlock");
  }
  handleHome = (itemKey: string) => {
    this.props.navigation.navigate(itemKey);
  }
  handleProfile = () => {
    this.props.navigation.navigate('UserProfileBasicBlock');
  }
  handleNotification = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({ notificationEL: event.currentTarget })
  }
  handleMobileNotification = (event: React.MouseEvent<HTMLElement>)=>{
    this.setState({ notificationMobileEL: event.currentTarget,notificationEL: null })
  }
  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      switch (true) {
        case apiRequestCallId === this.getUserProfileApiCallId:
          this.handleProfileSuccess(responseJson)
          break;
        case apiRequestCallId === this.getNotificationsApiId:
          this.handleNotificationsRes(responseJson);
          break;
        case apiRequestCallId === this.readNotificationApiCallId:
          this.handleReadNotificationRes(responseJson);
          break
        case apiRequestCallId === this.readAllNotificationApiCallId:
          this.handleReadAllResponse(responseJson)
          break;
      }
    }
  }
  handleGetProfileDetails = () => {
    const token = localStorage.getItem("authToken");
    const userDataString = localStorage.getItem("userData");
    if (token) {
      if (userDataString) {
        const userData: { id: string; type: string } = JSON.parse(userDataString);
        const id = userData.id;

        const header = {
          "Content-Type": configJSON.contentTypeApiGetUserProfile,
          token: token
        };

        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getUserProfileApiCallId = requestMessage.messageId;
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.getProfileApiEndpoint + id
        );

        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );

        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.validationApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
      }
    } else {
      this.logoutFunction()
    }
  };

  handleReadNotificationRes = (response: NotificationRes) => {
    if(!response.errors)
      this.handleGetNotifications()
  }
  logoutFunction = () => {
    if (this.state.recordInfo != '') {
      window.location.reload()
      this.setState({ authToken: '' })
      localStorage.clear()
    }
    else {
      this.setState({ authToken: '' })
    }
  }

  handleProfileSuccess = (response: UserProfile) => {
    const { errors, data } = response;
    if (errors && Array.isArray(errors)) {
      const error = errors[0];
      if (typeof error === 'object' && error.token) {
        if (error.token) {
          this.setState({ recordInfo: error.token })
          this.logoutFunction();
        }
      } else if (error === 'Record not found') {
        this.setState({ recordInfo: error })
        this.logoutFunction();
      }
      return;
    }

    if (data && data.attributes) {
      const {
        full_name,
      } = data.attributes;
      this.setState({
        userName: full_name ? full_name : '',
      });
    }
  };
  handleMarkAllRead = ()=>{
    const token = localStorage.getItem("authToken");
    if (token) {
      const header = {
        "Content-Type": configJSON.jsonApiContentType,
        token: token
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.readAllNotificationApiCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.readAllNotificationEndpoint
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify("")
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.putApiMethodType
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  }
  handleReadAllResponse = (response: {count: number, errors?: {message: string}[]}) => {
    if (response && response.errors && response.errors.length > 0) {
      if (response.errors[0].message === 'Invalid token') {
        this.logoutFunct();
      }
    } else {
      this.setState({ notificationCount: 0 });
    }
  };
  
  // Customizable Area End
}
