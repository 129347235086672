import React, { useState, useEffect } from "react";
import Skeleton from "react-loading-skeleton";
import "../../blocks/landingpage/assets/style.css"

const PackageSlider = ({ oceanbackground,packageType }:any) => {
  const [backgroundLoaded, setBackgroundLoaded] = useState(false);

  useEffect(() => {
    const img = new Image();
    img.src = oceanbackground;

    img.onload = () => {
      setBackgroundLoaded(true);
    };
  }, [oceanbackground]);

  return (
    <div>
      {!backgroundLoaded && (
        <Skeleton  className="bgSectionHeader"
        style={{

                                 
          backgroundColor:" #bfbdbd",
          backgroundImage:"linear-gradient( 90deg,#bfbdbd,#eceaea,#bfbdbd)",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
          width: "100%",
          height: "600px", 
          opacity: 1, 
          position:"absolute"
        }} />
      )}

      {backgroundLoaded &&( 
       <div
       className="bgSectionHeader"
       style={
         packageType === "AllPackage"
           ? {
               background: "rgba(1, 59, 104, 1)",
               width: "100%",
               height: "500px",
               opacity: 1,
               position:"absolute"
             }
           : {
               backgroundImage: `url(${oceanbackground})`,
               backgroundRepeat: "no-repeat",
               backgroundSize: "cover",
               backgroundPosition: "center",
               width: "100%",
               height: "600px",
               position:"absolute",
               opacity: 1,
             }
       }
     />
      )}
       
    </div>
  );
};

export default PackageSlider;
