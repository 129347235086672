Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "cfitinerarymanagement";
exports.labelBodyText = "cfitinerarymanagement Body";

exports.btnExampleTitle = "CLICK ME";
exports.apiEndPointGetPackage="/bx_block_landingpage2/best_selling_package"
exports.apiEndPointGetPackageIn="bx_block_landingpage2/show_international_package"
exports.offersEndPoint="bx_block_landingpage2/offers"
exports.sliderEndPoint ="bx_block_carouseldisplay2/slider_images"
exports.offerApiContentType = "application/json";
exports.offerApiMethodType = "GET";
exports.airportpSearchEndPoint="bx_block_cfgdsavinodeintegration/get_airport_list?key="
exports.packageSearchEndPoint="bx_block_landingpage2/packages?"
exports.SinglePackageEndPoint="bx_block_landingpage2/packages/"

                                
// Customizable Area End