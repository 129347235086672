import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import React from 'react';
import PackageSearch from "./PackageSearch.web";

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  packageSellingData: any;
  inDestinationData: any;
  offersData:any;
  sliderData:any;
  transformedData:{},
  loading:boolean,
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CfitinerarymanagementController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  public childRef: React.RefObject<PackageSearch>;
  offerApiCallId="";
  packageApiCallId = "";
  packageInApiCallId="";
  sliderApiCallId="";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage)
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      transformedData:{},
      // Customizable Area Start
      loading:false,
      packageSellingData: [],
      sliderData:[],

      inDestinationData: [

      ],
      offersData:[
       
      ]
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.childRef = React.createRef();
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const promoCodeData = message.getData(
        getName(MessageEnum.itaCodeDataInfo)
      );
     this.bookNowFunc(promoCodeData.iata_object)
      }
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(

        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId != null) {
       
        switch (true) {
         
                case apiRequestCallId === this.offerApiCallId &&  this.offerApiCallId != null:
                  this.handleGtAllOffersList(responseJson);
                  break;
                case apiRequestCallId === this.packageApiCallId && this.packageApiCallId!=null:
                  this.GetAllPackagesList(responseJson);
                  break;
                  case apiRequestCallId === this.packageInApiCallId && this.packageInApiCallId!=null:
                    this.GetAllPackagesInList(responseJson);
                    break;
                  case apiRequestCallId === this.sliderApiCallId && this.sliderApiCallId!=null:
                    this.GetAllSliderList(responseJson);
                    break;
                 
          default:
      }
    }
    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let message = new Message(getName(MessageEnum.AccoutLoginSuccess));
    message.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(message);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
  async componentDidMount() {

    this.packagelistDataApi();
this.packageInlistDataApi();
this.offersForFunct();
this.sliderFunct();

  }
  packagelistDataApi = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );


    this.packageApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),

      configJSON.apiEndPointGetPackage
    );

    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),

      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  packageInlistDataApi = () => {
    this.setState({ loading: true });
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );


    this.packageInApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),

      configJSON.apiEndPointGetPackageIn
    );

    const header = { "Content-Type": configJSON.validationApiContentType,};

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  sliderFunct = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.sliderApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.sliderEndPoint
    );

    const header = { "Content-Type": configJSON.offerApiContentType,
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.offerApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  offersForFunct = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.offerApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.offersEndPoint
    );

    const header = {
      "Content-Type": configJSON.offerApiContentType,
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.offerApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  GetAllPackagesList = (responseJson: any) => {
    if (!responseJson.errors) {
     let dataY = responseJson.data.map((item: any) => {
        return {
          "title": item.attributes.name,
          "id":item.id,
          "price": item.attributes.price,
          "image": item.attributes.image,
          "other": "Per Person",
          "iata_object":item.attributes.iata_object
        }
      })
      this.setState({packageSellingData:dataY})
    }
  }
  GetAllSliderList=(responseJson:any)=>{
    if (responseJson.data.length>0) {
      let dataYYSlider = responseJson.data.map((item: any) => {
         return {
           "id":item.id,
           "image": item.attributes.image_urls[0],
           "title": item.attributes.name,
         }
       })
       this.setState({sliderData:dataYYSlider})
     }
  }
  GetAllPackagesInList= (responseJson: any) => {
    if (responseJson.data.length>0) {
     let dataYY = responseJson.data.map((item: any) => {
        return {
          "id":item.id,
          "image": item.attributes.image,
          "other": "Per Person",
          "title": item.attributes.name,
          "price": item.attributes.price,
          "iata_object":item.attributes.iata_object
        }
      })
      this.setState({inDestinationData:dataYY,loading: false})
    }
  }
  handleGtAllOffersList=(responseJson: any) => {
    if (responseJson.data.length>0) {
      let packageOffers = responseJson.data.filter((item: any) => item.attributes.offer_type === "package");
     let dataOffers = packageOffers.map((item: any) => {
        return {
          "id":item.id,
          "image": item.attributes.image,
          "title": item.attributes.title,
          "offer_code": item.attributes.offer_code,
        }
      })
      this.setState({offersData:dataOffers})
    }
  }
  truncateString = (str:string, maxLength:number) => {
    if (str.length > maxLength) {
      return str.slice(0, maxLength) + "...";
    }
    return str;
  };
 
  handleClickBestDetails = (itemEle:string) => {
    localStorage.removeItem("startDatePackage");
    this.props.navigation.navigate("PackageDetail", { id: itemEle})
  };
  handleOffersDetails = (idOffers:string) => {
    if(idOffers){
     this.props.navigation.navigate("SingleOffers", { id: idOffers})
    }
   };
   handleChildData = (data:any) => {
    data.map((item:any)=>{
       return {
         "image":item.attributes.image,
         "name":item.attributes.name,
         "price": item.attributes.price,
         "package_type": item.attributes.package_type,
       }
     })
 };
 handleViewAll = (item:string) => {
  this.props.navigation.navigate(item)
};
bookNowFunc=(itemSell:any)=>{
const transformedData = {
  country: itemSell.CountryCode,
  state: itemSell.Code,
  flight: itemSell.Name,
  stateCode: itemSell.CityCode
};

if (this.childRef.current) {
  this.childRef.current.handleDataUpdate(transformedData);
}
this.setState({ transformedData: transformedData }, () => {
  window.scrollTo({ top: 0, behavior: 'smooth' });
});
}
  // Customizable Area End
}
