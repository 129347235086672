import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  appliedCoupon: any;

  packageSellingData: any;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class BestSellingViewAllController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  packageApiCallId = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      packageSellingData: [],
      appliedCoupon: null,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }
  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(

        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId != null) {
        if (apiRequestCallId === this.packageApiCallId && this.packageApiCallId != null) {
          this.GetAllPackagesList(responseJson);
        }

      }
    }
    // Customizable Area End
  }

  async componentDidMount() {

    this.packagelistDataApi();

  }
 
  handleChildData = (data: any) => {
    data.map((item: any) => {
      return {
        "image": item.attributes.image,
        "name": item.attributes.name,
        "price": item.attributes.price,
        "package_type": item.attributes.package_type,
      }
    })
  };
  GetAllPackagesList = (responseJson: any) => {
    if (responseJson.data.length > 0) {
      let dataY = responseJson.data.map((item: any) => {
        return {
          "title": item.attributes.name,
          "id": item.id,
          "price": item.attributes.price,
          "image": item.attributes.image,
          "other": "Per Person"
        }
      })
      this.setState({ packageSellingData: dataY })
    }
  }
  packagelistDataApi = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );


    this.packageApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),

      configJSON.apiEndPointGetPackage
    );

    const header = {
      "Content-Type": configJSON.exampleApiContentType,
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),

      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  handleClickSellingDetails = (item:string) => {
    this.props.navigation.navigate("PackageDetail", { id: item})
  };
  // Customizable Area End
}
