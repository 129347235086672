import React from "react";

import {
  Box,
  Button,
  Typography,
  // Customizable Area Start
  Grid,
  CircularProgress
  // Customizable Area End


} from "@material-ui/core";
// Customizable Area Start
import PackageLazyLoader from "../../../components/src/PackageLazyLoader";
export const baseURL = require("../../../framework/src/config.js").baseURL;
// Customizable Area End

import ViewAllOfferPackageController, {
  Props,
} from "./ViewAllOfferPackageController.web";
import PackageSearch from "../../cfitinerarymanagement/src/PackageSearch.web";

export default class ViewAllOfferPackage extends ViewAllOfferPackageController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start




  // Customizable Area End

  render() {
    
    return (
      // Customizable Area Start
      <>
        <PackageSearch data-test-id="PackageSearch" package={"AllPackage"}
         navigation={this.props.navigation} id={""} onSendData={this.handleChildData} />

        <Box style={{ position: "relative" }}>
          <div className="content-container" style={{ top: '-100px',
            width: '100%',
            position: 'absolute',
           
          }}>

            <Box sx={{ padding: { xs: "10px 10px", sm: "10px 10px",
             md: "10px 30px", lg: "10px 30px" }, }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12}>
                  <Box className="boxShadowDetail" style={{ background: "#fff" }}>
                    <Grid item className="textH5" style={webStyleOffers.textAlignStart}>
                      <Typography className="marginleft10px" variant="h5">Offers</Typography>
                    </Grid>
                    <Grid container spacing={2}>
                     

{this.state.offersData.map((item:any, index: number) => (
    <Grid item xs={12} sm={6} md={4}>
                <div className="hover-card-box" key={index} style={{cursor:"pointer"}} data-test-id={`handleNavigateXPackage${item.id}`} 
                onClick={()=>this.handleOffersDetails(item.id)}>
                <Box style={{...webStyleOffers.cardStyle}}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={4} lg={4}>
                    <React.Suspense fallback={<CircularProgress />}>
                    <div className="imgOffers">
                    <PackageLazyLoader data-test-id="oceanbackground" src={item.image} type="packageOffers" />
                  </div>
                    </React.Suspense>
                    </Grid>
                    <Grid item xs={12} md={8} lg={8}>
                    <Typography style={webStyleOffers.secTwoTitleOffers}>{this.truncateString(item.title, 29)}</Typography>
                   <Typography style={webStyleOffers.secTwoTitle6}>{item.offer_code}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Box sx={{display:"flex", justifyContent:"end"}}>
                      <Button data-test-id="handleClickBlogDetails" style={webStyleOffers.secTwoTitle7} >Book Now</Button>
                      </Box>
                    </Grid>
                  </Grid>
                 
                </Box>
              </div>
              </Grid>
             
             ))}


                    </Grid>
                  </Box>
                </Grid>
              </Grid>

            </Box>
          </div>
        </Box>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const webStyleOffers: any = {
    secTwoTitle7:{
        fontSize: "14px",
        width:"fit-content",
        background: "rgba(23, 100, 160, 1)",
        borderRadius: "8px",
        padding: "10px 12px 10px 12px",
        fontWeight: "700",
        color: "#fff",
        textTransform: "capitalize" as "capitalize",
        marginTop: "-10px",
        marginRight:"10px",
        cursor: "pointer"
      },
  textAlignStart: {
    textAlign: "start" as "start",
    marginTop: "30px",
    marginBottom: "30px",
    fontWeight: 700,
  },
  secTwoTitle6:{
    color:"#000",
    padding: "5px 10px",
    fontSize: "16px",
    fontWeight: "700",
    display: "flex",
  alignItems: "center"
  },
  secTwoTitleOffers:{
    padding: "5px 10px",
    fontSize: "24px",
    color:"#000",
    fontWeight: "700"
  },
  secTwoTitle: {
    padding: "5px 10px",
    fontSize: "24px",
    color: "#fff",
    fontWeight: "700"
  },
  secTwoTitle2: {
    color: "#fff",
    padding: "5px 10px",
    fontSize: "14px",
    fontWeight: "700",
    display: "flex",
    alignItems: "center"
  },
  cardStyle: {
    boxShadow: "rgba(0, 0, 0, 0.25) 0px 0px 10px",
    borderRadius: "15px",
    margin: "7px",
    overflow:"hidden",
    padding:"15px"

  },
};
// Customizable Area End

