import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { BlockComponent } from "../../../framework/src/BlockComponent";

// Customizable Area Start
type DateRange = {
    selection: {
        startDate: Date;
        endDate: Date;
    };
};

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    isModalOpen: boolean
    showCalendar: boolean
    startDate: Date | null ;
    endDate: Date | null;
    endDate1: Date | null
    showBookUi: boolean
    showSuccessUi: boolean
    // Customizable Area End
}

interface SS {
    id: any;

}

export default class ChangeTravelDateController extends BlockComponent<
    Props,
    S,
    SS
> {
    airportSearchApiCallId: string = ''
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionResponseMessage),
        ];

        this.state = {
            isModalOpen: false,
            showBookUi: false,
            showCalendar: false,
            startDate:   null ,
            endDate:  null,
            showSuccessUi: false,
            endDate1: null
        };

        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
        super.componentDidMount();
        // Customizable Area Start
        const params = new URLSearchParams(window.location.search);
        const isReschedule = params.get('reschedule-book') === 'true';
        if (isReschedule) {
            this.setState({showBookUi: true})
        }
        // Customizable Area End
    }


    async receive(from: string, message: Message) {
        // Customizable Area Start
        runEngine.debugLog("Message Received", message);
        // Customizable Area End
    }

    // Customizable Area Start
    handleModalOpen = () => {
        this.setState({ isModalOpen: true })
    }
    handleModalClose = () => {
        this.setState({ isModalOpen: false })
    }

    toggleCalendar = (e: React.MouseEvent) => {
        e.stopPropagation()
        this.setState(prevState => ({
            showCalendar: !prevState.showCalendar
        }));
    }
    closeCalendar = () => {
        setTimeout(() => {
            this.setState({ showCalendar: false })
        }, 100)

    }
    handleDateChange1 = (ranges: DateRange) => {
            let determinedDate = ranges.selection.startDate;
            this.setState({
                startDate: determinedDate,
                endDate: determinedDate,
                endDate1: null,
            });
    };
    formatDate = (date:Date) => {
        if (date) {
          const dayaa = date.getDate();
          const month = date.toLocaleString('default', { month: 'short' });
          const year = date.getFullYear().toString().slice(-2);
          const weekday = date.toLocaleString('default', { weekday: 'long' });
          return `${dayaa} ${month}’${year} ${weekday}`;
        } 
      };
      handleConfirmReschedule = ()=>{
        let formattedDate1 = this.setDateFunct();
        const baseUrl = window.location.origin;
        const url = `/Catalogue?departure_date=${formattedDate1}&origin=DEL&originFlight=Delhi%20Indira%20Gandhi%20Int&destination=BOM&destinationFlight=Chhatrapati%20S%20Maharaj&cabin_class=PremiumEconomy&adult=1&children=0&infant=0&trip_type=one_way&reschedule=true`;
        window.location.href = baseUrl+url;
      }
      setDateFunct = (): string | null => {
        if (this.state.startDate === null) {
          return null;
        }
      
        let year = this.state.startDate.getFullYear();
        let month = (this.state.startDate.getMonth() + 1).toString().padStart(2, '0');
        let day = this.state.startDate.getDate().toString().padStart(2, '0');
        
        const stDate = `${year}-${month}-${day}`;
        return stDate;
      }
      handleShowSuccessUi = ()=>{
        this.setState({showSuccessUi: true,showBookUi:false})
      }
      handleNavigate = ()=>{
        this.props.navigation.navigate('MyTrips')
      }
    // Customizable Area End
}
