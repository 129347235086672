export const cheapestIcon = require("../../../components/src/cheapest.svg");
export const starIcon = require("../../../components/src/star.svg");
export const nonStopIcon = require("../../../components/src/nonStop.svg");
export const discountIcon = require("../../../components/src/discountIcon.svg");
export const flightIcon = require("../../../components/src/flightIcon.svg");
export const flight = require("../assets/flight.svg");
export const errorIcon = require("../assets/error.png");
export const closeIcon = require("../assets/closeIcon.svg");
export const flightImg = require("../assets/flightImg.png");
export const colorOpenIcon = require("../assets/colorOpenIcon.svg");
export const cancleCity = require("../assets/cancleCity.svg");
export const redErrorIcon = require("../assets/redErrorIcon.png");
export const cancelIcon = require("../assets/cancelIcon.svg");
export const greenTrue = require("../assets/greenTrue.svg");
export const yellowCheck = require("../assets/yellowCheck.svg");
export const loaderGif = require("../assets/loaderGif.gif")
export const Included = require("../assets/successCheck.svg")