import { Typography } from "@material-ui/core";
import React, { useState } from "react";

const TabDesign = (props:any) => {
    const {state,
        onClickTab
    } = props
  const tabContainerStyle = {
    borderBottom: "1px solid #E2E8F0",
    boxShadow: "0px 2px 10px 0px #0000001A",
    paddingLeft: "32px",
    paddingBottom: "15px",
    paddingTop: "15px",
    display: "flex",
  };

  const tabStyle = {
    marginRight: "15px",
    paddingTop:"12px",
    paddingBottom: "12px",
    cursor: "pointer",
    fontSize: "16px",
    width: "200px",
display: "flex",
justifyContent: "center",
alignItems: "center"
  };

  const activeTabStyle = {
    background:" #207abf1f",
    borderBottom: "2px solid #1764A0", 
    color: "#000", 
    fontWeight: 900,
  };

  const inactiveTabStyle = {
    color: "#7E7E7E", 
  };

  return (
    <div style={tabContainerStyle}>
      <div
        onClick={()=>onClickTab("MyTrips")}
        style={{ ...tabStyle, ...(state.activeTab === 0 ? activeTabStyle : inactiveTabStyle) }}
      >
        <Typography style={{fontSize:"22px", fontWeight: 700,}}>My Trips</Typography>
      </div>
      <div
        onClick={()=>onClickTab("MyPackage")}
        style={{ ...tabStyle, ...(state.activeTab === 1 ? activeTabStyle : inactiveTabStyle) }}
      >
        <Typography style={{fontSize:"22px", fontWeight: 700,}}>My Packages</Typography>
      </div>
    </div>
  );
};

export default TabDesign;
