import React from "react";

import {noOffers} from "../../blocks/landingpage/src/assets"
import { Box, Typography } from "@material-ui/core";


export default function NotFound() {
  
  return (
    <>
 <Box sx={{display:"flex", justifyContent:"center", width:"100%"}}>
      <Box style={{background:"#fff", width:"100%", display:"block", textAlign:"center", paddingTop:"30px",borderRadius: "8px",boxShadow: "rgba(0, 0, 0, 0.16) 0px 4px 10px 0px"}}>
                  <img src={noOffers} style={{width:"200px"}} />
         <Typography variant="body1" className="fontSizeNoData" style={{margin:"20px 0", fontSize:"24px", fontWeight:700}}>No Flight is Available</Typography>
       </Box>
      </Box>

    </>
  )
}
