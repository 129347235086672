import React from "react";
// Customizable Area Start
import {
    Box,
    Button,
    Grid,
    Typography,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import CarouselDisplay from "../../carouseldisplay/src/CarouselDisplay.web";
import { logoImage ,errorIcon} from "./assets";
// Customizable Area End
import ForgotPasswordController, {
    Props,
} from "./ForgetPasswordController.web";
const theme = createTheme({
    palette: {
        primary: {
            main: "#0000ff",
            contrastText: "#fff",
        },
    },
});
export default class ForgotPassword extends ForgotPasswordController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    // Customizable Area End
    render() {
        return (
            // Customizable Area Start
            < ThemeProvider theme={theme} >
                <Grid container>
                    <Grid item xs={12} md={12} style={{ display: "flex", justifyContent: "center", height: "fit-content" }} lg={6}>
                        <CarouselDisplay id={"CarouselDisplay"} navigation={""}  />
                    </Grid>
                    <Grid item xs={12} style={{ display: "flex", justifyContent: "center", alignItems: "flex-start" }} md={12} lg={6} >
                        <Box
                            sx={{
                                width: "75%",padding: 20
                            }}
                        > <div style={Webstyle.logoDiv}>
                                <img data-test-id="navigateScreens"  onClick={()=>this.navigateScreens("LandingPage")} style={{cursor:"pointer"}} src={logoImage}></img>
                            </div>
                            <Box sx={{ width: "100%" }}>
                            {
                            this.state.error &&
                                    <Box className="ErrorMsg" sx={Webstyle.errorMsgfor}>
                                    <img src={errorIcon} /> &nbsp; {this.state.error}
                                    </Box>
                           }
                                <Box sx={{ padding: "20px 0px" }}>
                                    <Typography
                                        style={{ ...Webstyle.Typography, textAlign: "left", fontSize: "24px",fontWeight:700, marginBottom:"13px" }}
                                    >
                                        Forget Password?
                                    </Typography>
                                    <Typography
                                        style={{ ...Webstyle.Typography, textAlign: "left", fontSize: "16px",fontWeight:400 }}
                                    >
                                        No worries, we'll send an OTP on your email for verification.
                                    </Typography>
                                </Box>
                                <Box sx={{ padding: "20px 0px" }}>
                                    <label style={{
                                        ...Webstyle.Label, textAlign: "left"
                                    }}>
                                        Email
                                    </label >
                                    <input
                                        data-test-id={"txtForgotInputEmail"}
                                        type="email"
                                        name="email"
                                        placeholder="Your Email"
                                        value={this.state.email}
                                        onChange={(e) => this.setEmail(e.target.value)}
                                        required
                                        style={{ ...Webstyle.Input, boxSizing: "border-box", border: `1px solid ${this.state.error ? "rgba(220,38,38,1)" : "rgba(148,163,184,1)"}` }}
                                    />
                                </Box>
                                <Button
                                    data-test-id={"btnEmailLogIn"}
                                    variant="contained"
                                    disabled={this.state.email === "" || this.state.error != ""}
                                    fullWidth
                                    style={{
                                        ...Webstyle.SendButton,
                                        backgroundColor: this.state.email === "" || this.state.error != "" ? "rgba(23,100,160,0.5)" : "rgba(23,100,160,1)",
                                    }}
                                    type="submit"
                                    onClick={this.goToOtpAfterEmailSubmit}
                                >
                                    Send Email OTP {/*UI Engine::From Sketch*/}
                                </Button>
                                <div style={{ display: "flex", justifyContent: "center", gap: "5px", paddingTop: "1rem" }}>
                                    <Typography style={{ color: "#0F172A", fontSize: "14px", fontFamily: "Inter, sans-serif", fontWeight: 400, textAlign: "center" }}>Remember Password?{" "}</Typography>
                                    <Box
                                        data-test-id={"btnLogin"}
                                        sx={{
                                            fontSize: "14px",
                                            color: "rgba(23,100,160,1)",
                                            fontWeight: 700,
                                        }}
                                        onClick={this.gotoLoginHome}
                                        style={{ textDecorationLine: "underline",cursor:"pointer" }}
                                    >
                                        Login
                                    </Box>
                                </div>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </ThemeProvider>
        )
    }
}

// Customizable Area Start
const Webstyle = {
    logoDiv: {
        display: "flex",
        justifyContent: "center",
        padding: "2rem"
    },
    errorMsgfor:{
        borderRadius: "5px",
       
        fontSize: "16px",
        color: "#c12121",
        display:"flex",
        lineHeight:"24px",
        padding: "20px 15px",
        borderLeft: "6px #c10f0ff0 solid",
        background:" #ed7e7e3d",
        
    },
    Typography: {
        color: "rgba(15, 23, 42, 1)",
        fontFamily: "Inter, sans-serif",
    },
    Label: {
        fontFamily: "Inter, sans-serif",
        fontSize: "16px",
        fontWeight: 700,
        letterSpacing: "0em",
        color: "rgba(51, 65, 85, 1)",
        marginBottom: "2px",
        display: "block",
    },
    Input: {
        width: "100%",
        borderRadius: "8px",
        color: "rgba(0,0,0,1)",
        height: "50px",
        fontFamily: "Inter, sans-serif",
        fontSize: "14px",
        padding: "4%",
        marginBottom: "10px",
        outline: "none"
    },
    SendButton: {
        fontSize: "16px",
        fontWeight: 700,
        color: "#FFFFFF",
        height: "56px",
        borderRadius: "8px",
        fontFamily: "Inter, sans-serif",
        textTransform: "capitalize" as "capitalize"
    }
}
// Customizable Area End