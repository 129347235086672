import React from "react";
import ViewallPopularDestinationController, { MulticityArryType, Props } from "./ViewallPopularDestinationController";
import HeaderMenuBlock from "../../navigationmenu/src/HeaderMenu.web";
import Calender from "../../../components/src/Calender";
import Footer from "../../../components/src/Footer";
import MultiTravel from "../../../components/src/MultiCityTravel";
import { oceanbackground, umbrella, airoplane, errorIcon, noDestination } from "./assets";
import RemoveIcon from '@material-ui/icons/Remove';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import SingleCalender from "../../../components/src/SingleCalender";
import SearchIcon from '@material-ui/icons/Search';
import SyncAltIcon from '@material-ui/icons/SyncAlt';
import FlightTakeoffIcon from '@material-ui/icons/FlightTakeoff';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import BGComponent from "../../../components/src/BackgroundHeader"
import ImageWithSkeleton from "../../../components/src/ImageWithSkeleton";
import MultiCityHeaderLandingPage from "../../../components/src/MultiCityHeaderLandingPage";
import {
    Box,
    Button,
    FormControlLabel,
    Grid,
    InputAdornment,
    Menu,
    MenuItem,
    Radio,
    RadioGroup,
    TextField,
    Typography,
    CircularProgress,
    Popover,
} from "@material-ui/core";
import Loader from "../../../components/src/Loader";

interface SearchTextObj {
    id: string,
    state: string,
    country: string,
    flight: string,
    stateCode: string
}
interface SearchTextM {
    id: string,
    state: string,
    country: string,
    flight: string,
    stateCode: string
}

export default class ViewallPopularDestination extends ViewallPopularDestinationController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start

        // Customizable Area End
    }
    popularDestiUI = () => {
        return (
            <>
                {this.state.popularDestination.length > 0 ?
                    <Grid container spacing={4} justifyContent="center">
                        {this.state.popularDestination.map((item, index) => (
                            <Grid item xs={12} sm={6} md={4} lg={4} key={index}>
                                <div
                                    style={{
                                        display: 'flex',
                                        borderRadius: '16px',
                                        flexDirection: 'column',
                                        gap: '40px',
                                        margin: '16px',
                                        padding: '24px',
                                        boxShadow: '0px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03)'
                                    }}
                                >
                                    <div style={{ position: 'relative', overflow: 'hidden', borderRadius: '15px' }}>
                                        <React.Suspense fallback={<CircularProgress />}>
                                        <div className="offerimgSizeheightws">
                                        <ImageWithSkeleton data-test-id="offerimgSizeheightws" src={item.attributes?.image} type={"blogPage"} />
                                       </div>
                                        </React.Suspense>
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
                                        <Typography className="desti-title" style={webStyleLanding.secTwoTitleBlog}>
                                            {item.attributes.title}
                                        </Typography>
                                        <Typography
                                            data-test-id={`handleClickBookNowDetails${index}`}
                                            style={webStyleLanding.secTwoTitle3}
                                         onClick={() => this.handleClickBookNowDetails(item.attributes?.iata_object)}
                                        >
                                            Book Now
                                        </Typography>
                                    </div>
                                </div>
                            </Grid>
                        ))}
                    </Grid>
                    :
                    <>
                        <Box sx={{ display: "flex", justifyContent: "center", width: "100%" }}>
                            <Box sx={{ display: "block", textAlign: "center" }}>
                                <React.Suspense fallback={<CircularProgress />}>
                                    <img src={noDestination} />
                                </React.Suspense>
                                <Typography variant="body1" className="fontSizeNoData" style={{ margin: "20px 0", fontSize: "24px", fontWeight: 700 }}>No Destination is Available</Typography>
                            </Box>
                        </Box>
                    </>
                }
            </>
        )
    }
    fromInputMultiText = (trip: SearchTextM | null) => {
        return (
            <>
                {trip ? (
                    <div style={{ position: 'absolute', width: '100%', background: '#fff', padding: '5px 0' }}>
                        <span className="textcalenderday" style={{ fontWeight: 700, fontSize: '32px' }}>{trip.state}</span>
                        <span className="textcalendername" style={{ fontWeight: 500, fontSize: '16px', display: 'block', marginTop: '10px', color: 'rgba(100, 116, 139, 1)' }}>{trip.stateCode},{trip.flight}</span>
                    </div>
                ) : ''}
            </>
        );
    };

    toInputMultiText = (tripTo: SearchTextM | null) => {
        return (
            <>
                {tripTo ? (
                    <div style={{
                        position: "absolute", width: "92%",
                        background: "#fff", left: "10px",
                        padding: "5px 0"
                    }}>
                        <span className="textcalenderday" style={{ fontWeight: 700, fontSize: "32px" }}>{tripTo.state}</span>
                        <span className="textcalendername" style={{ fontWeight: 500, fontSize: "16px", display: "block", marginTop: "10px", color: "rgba(100, 116, 139, 1)" }}>{tripTo.stateCode},{tripTo.flight}</span>
                    </div>
                ) : ''}
            </>
        )
    }
    multicityFunctionUi = () => {
        return (
            <>
                {this.state.selectedOption === 'Multicity' ?
                    <>{this.multicityInputSearchBox()}</> : <>{this.oneWaySearchBar()}</>
                }
            </>
        )
    }

    travellerClassBoxUi = () => {
        return (
            <Box style={{ padding: "10px" }}>
                <Box className="classTravelHead" >
                    <Typography style={{ color: "#000", fontWeight: 700, fontSize: "12px", }} variant="body1">{this.state.countAdult} {this.state.countAdult <= 1 ? "Adult" : "Adults"} , {this.state.countChild} {this.state.countChild <= 1 ? "Child" : "Children"}, {this.state.countInfant}  {this.state.countInfant <= 1 ? "Infant" : "Infants"}</Typography>
                    <Typography variant="body1" style={{ color: "rgba(100, 116, 139, 1)", fontSize: "12px", }} >{this.state.TravelStatus}
                    </Typography>
                </Box>
                <Box style={{ width: "100%", borderBottom: "1px solid rgba(226, 232, 240, 1)", marginTop: "10px" }}></Box>
                <Box style={{ width: "100%" }}>
                    <Grid spacing={2} container>
                        <Grid xs={6} sm={4} item >
                            <Box sx={{ textAlign: "center", padding: { xs: "10px", sm: "10px", md: "19px 10px 0px 10px" } }}>
                                <Typography style={{ fontWeight: 700, fontSize: "24px", color: "#000" }} variant="body1">  {this.state.countAdult} {this.state.countAdult <= 1 ? "Adult" : "Adults"}</Typography>
                                <Typography style={{ color: "rgba(100, 116, 139, 1)", fontSize: "12px", padding: "10px 0 20px 0" }} variant="body1">(Aged 18+years) </Typography>
                                <Box sx={{ padding: { xs: "6px 12px", sm: "6px 16px", md: "6px 20px" } }} className="countDivBox">
                                    <button data-test-id="decreaseCountFunct" className="borderNoneCount" onClick={this.decreaseCountFunct}><RemoveIcon /></button>
                                    <div className="countShowText">{this.state.countAdult}</div>
                                    <button data-test-id="increaseCount" className="borderNoneCount" onClick={this.increaseCount}><AddIcon /></button>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid xs={6} sm={4} item >
                            <Box sx={{ padding: { xs: "10px", sm: "10px", md: "19px 10px 0px 10px" }, textAlign: "center", }}>
                                <Typography style={{ fontSize: "24px", color: "#000", fontWeight: 700 }} variant="body1">  {this.state.countChild} {this.state.countChild <= 1 ? "Child" : "Children"}</Typography>
                                <Typography style={{ fontSize: "12px", color: "rgba(100, 116, 139, 1)", padding: "10px 0 20px 0" }} variant="body1">(Aged 2-12 years)  </Typography>
                                <Box sx={{ padding: { xs: "6px 12px", sm: "6px 16px", md: "6px 20px" } }} className="countDivBox">
                                    <button data-test-id="decreaseCountChildFunct" className="borderNoneCount" onClick={this.decreaseCountChildFunct}>
                                        <RemoveIcon />
                                    </button>
                                    <div className="countShowText">{this.state.countChild}
                                    </div>
                                    <button data-test-id="increaseCountChildFunct" className="borderNoneCount" onClick={this.increaseCountChildFunct}>
                                        <AddIcon />
                                    </button>

                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Box sx={{ padding: { xs: "10px", sm: "10px", md: "19px 10px 0px 10px" }, textAlign: "center", }}>
                                <Typography style={{ fontWeight: 700, fontSize: "24px", color: "#000", }} variant="body1">  {this.state.countInfant} {this.state.countInfant <= 1 ? "Infant" : "Infants"}</Typography>
                                <Typography style={{ color: "rgba(100, 116, 139, 1)", fontSize: "12px", padding: "10px 0 20px 0" }} variant="body1">(Under 2 years)
                                </Typography>
                                <Box sx={{ padding: { xs: "6px 12px", sm: "6px 16px", md: "6px 20px" } }} className="countDivBox">
                                    <button className="borderNoneCount" data-test-id="decreaseCountChildInfantFunct" onClick={this.decreaseCountChildInfantFunct}><RemoveIcon /></button>
                                    <div className="countShowText">{this.state.countInfant}</div>
                                    <button className="borderNoneCount" data-test-id="increaseCountChildInfantFunct" onClick={this.increaseCountChildInfantFunct}><AddIcon /></button>

                                </Box>
                            </Box>
                        </Grid>

                        {
                            this.state.error &&
                            <Grid item sm={12}>
                                <Box className="ErrorMsg" sx={webStyleLanding.errorMsgforCount}>
                                    <span className="imgError" style={{ width: "5%", marginRight: "5px" }}>
                                        <React.Suspense fallback={<CircularProgress />}>
                                            <img src={errorIcon} />
                                        </React.Suspense>
                                    </span> <span style={{ width: "95%" }}>
                                        If you want to add more than 9 travellers please contact email <span style={{ textDecoration: "underline" }}>info@doolwa.com</span>
                                    </span>
                                </Box>
                            </Grid>
                        }

                        <Box sx={{
                            width: "100%", textAlign: "center", padding: "0px 0 0px 0",
                        }}>
                            <Typography style={{ fontSize: "24px", color: "#000", fontWeight: 700 }} variant="body1"> Travel Class</Typography>
                        </Box>
                        <Grid item sm={12}>
                            {this.allButtonClassList()}
                        </Grid>

                        <Box className="buttonBoxapply" style={{ padding: "10px", margin: "0px 10px" }}>
                            <Button style={{ color: "rgba(23, 100, 160, 1)", fontSize: "16px", textTransform: "capitalize", fontWeight: 700 }} data-test-id="handleCancelClassTravel" onClick={this.handleCancelTravelClass}>Cancel</Button>&nbsp;&nbsp;&nbsp;&nbsp;
                            <Button style={{ background: "rgba(23, 100, 160, 1)", color: "#fff", fontSize: "16px", textTransform: "capitalize", fontWeight: 700 }} data-test-id="handleSaveTravelClass" onClick={this.handleSaveTravelClass}>&nbsp;Done&nbsp;</Button>
                        </Box>
                    </Grid>
                </Box>
            </Box>
        )
    }
    allButtonClassList = () => {
        return (
            <Box style={{ padding: "12px" }}>
                <Grid container spacing={1}>
                    <Grid xs={12}>
                        <div className="buttonContainer">
                            <button   data-test-id="handleSelectStatus"
                                className={`buttonEconomy ${this.state.TravelStatus === 'PremiumEconomy' ? 'active' : ''}`}
                              
                                onClick={() => this.handleSelectStatus('PremiumEconomy')}
                            >
                                Premium /
                                Economy
                            </button>
                            <button  data-test-id="handleClickStatus2"
                                className={`buttonEconomy ${this.state.TravelStatus === 'PremiumFirst' ? 'active' : ''}`}
                               
                                onClick={() => this.handleSelectStatus('PremiumFirst')}
                            >
                                Premium /
                                First
                            </button>
                            <button   onClick={() => this.handleSelectStatus('First')}
                                className={`buttonEconomy ${this.state.TravelStatus === 'First' ? 'active' : ''}`}
                                data-test-id="handleClickStatus3"
                               
                            >
                                First
                            </button>
                            <button
                            onClick={() => this.handleSelectStatus('Business')}
                                className={`buttonEconomy ${this.state.TravelStatus === 'Business' ? 'active' : ''}`}
                                data-test-id="handleClickStatus4"
                                
                            >
                                Business
                            </button>
                            <button
                              data-test-id="handleClickStatus5"
                                className={`buttonEconomy ${this.state.TravelStatus === 'Economy' ? 'active' : ''}`}
                              
                                onClick={() => this.handleSelectStatus('Economy')}
                            >
                                Economy
                            </button>
                        </div> </Grid>
                </Grid>
            </Box>
        )
    }
    
    multicityInputSearchBox = () => {
        return (
            <MultiCityHeaderLandingPage data-test-id='multi-city-header' state={this.state} handleClose={this.handleClose}
               
                increaseCountChild={this.increaseCountChildFunct}
                increaseCount={this.increaseCount}
                handleCancel={this.handleCancel}
                iconCloseRemoveItem={this.iconCloseRemoveItem}
                handleCloseCustomAi={this.handleCloseCustomAi}
                decreaseCount={this.decreaseCountFunct}
                decreaseCountChild={this.decreaseCountChildFunct}
                increaseCountChildInfant={this.increaseCountChildInfantFunct}
                inputRef={this.inputRef}
                handleCloseClass={this.handleCloseClass}
                handleDone={this.handleSaveTravelClass}
                handleMulticityApply={this.handleMulticityPopularApply}
                handleFromInputChange={this.handlFromChange}
                handleFromCity={this.handleFromCityFunction}
                handleMultiCityInputChangeTo={this.handleChangeTo}
                handleMulticityClickTo={this.handleMulticityClickToFunction}
                handleFromDropdown={this.handleFromDropdownFunction}
                handleMulticityCloseTo={this.handleMulticityCloseToFunction}
                reverseFunct={this.reverseFunctMultiCityArray}
                togglePickerM={this.togglePickerMulti} decreaseCountChildInfant={this.decreaseCountChildInfantFunct}
                handleClickStatus={this.handleSelectStatus}
                handleCancelTravel={this.handleCancelTravelClass}
                handleClickClass={this.handleClickClass}
               
                handleDateChange1={this.handleDateChange1} handleClickNationality={this.handleClickNationality} handleNationalityClass={this.handleNationalityClass} handleSelectNationality={this.handleSelectNationality} 
                
                addAnotherCity={this.addAnotherCity}
                />
        )
    }
    calenderBoxConditionUI = () => {
        return (
            <Popover
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                data-test-id="popover"
                anchorEl={this.state.setAnchorAi}
                onClose={this.handleCloseCustomAi}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                open={this.state.isOpen}

            >
                {this.state.selectedOption === "One Way" ?
                    <div style={{ width: "auto", boxShadow: "rgba(0, 0, 0, 0.25) 0px 0px 10px", borderRadius: "10px", zIndex: 9999, background: "#fff" }} className="boxCalenderDone111" >
                        <div className="datepikerTop111">
                            <div className="calTitle" style={{ width: "auto", padding: "12px 20px", }}>
                                <Typography variant="h4" >
                                    <span className="font14px">{this.state.startDate ? this.formatDateFunc(this.state.startDate) : "Departure Date"}</span>
                                </Typography>
                            </div>

                            <div className="calenderBoox singleCalender">
                                <SingleCalender data-test-id="calenderBlock" state={this.state} month={1} handleDateChange={this.handleDateChange1} />
                            </div>
                            <Box className="buttonBoxapply111">
                                <Button style={{ color: "rgba(23, 100, 160, 1)", fontSize: "16px", textTransform: "capitalize", fontWeight: 700 }} data-test-id="handleCancel" onClick={this.handleCancel}>Cancel</Button>&nbsp;&nbsp;&nbsp;&nbsp;
                                <Button style={{ background: "rgba(23, 100, 160, 1)", color: "#fff", fontSize: "16px", textTransform: "capitalize", fontWeight: 700 }} data-test-id="handleApply" onClick={this.handleApply}>&nbsp;Apply&nbsp;</Button>
                            </Box>
                        </div>

                    </div>
                    : <div className="boxCalenderDone111" style={{ borderRadius: "10px", width: "auto", boxShadow: "rgba(0, 0, 0, 0.25) 0px 0px 10px", background: "#fff" }}>
                        <div className="datepikerTop111">
                            <div className="calTitle" style={{ width: "auto" }}>
                                <Typography variant="h4" ><span className="font14px">{this.state.startDate ? this.formatDateFunc(this.state.startDate) : "Departure Date"}</span>&nbsp;-&nbsp;<span className="font14px">{this.state.endDate1 ? this.formatDateFunc(this.state.endDate1) : <>Select Return Date <span className="fontWee400">(save more on two ways)</span></>}</span></Typography>
                            </div>

                            <div className="calenderBoox">
                                <Calender state={this.state} data-test-id="calenderBlock" handleDateChange={this.handleDateChange} />
                            </div>

                            <Box className="buttonBoxapply111">
                                <Button style={{ color: "rgba(23, 100, 160, 1)", fontSize: "16px", textTransform: "capitalize", fontWeight: 700 }} data-test-id="handleCancel" onClick={this.handleCancel}>Cancel</Button>&nbsp;&nbsp;&nbsp;&nbsp;
                                <Button style={{ background: "rgba(23, 100, 160, 1)", color: "#fff", fontSize: "16px", textTransform: "capitalize", fontWeight: 700 }} data-test-id="handleApply" onClick={this.handleApply}>&nbsp;Apply&nbsp;</Button>
                            </Box>
                        </div>

                    </div>}
            </Popover>
        )
    }

    fromInputTextUI = () => {
        return(
            <>
             {this.state.searchText.state 
             && this.state.searchText.flight ? (
                                        <div style={{position: "absolute",width:"100%", background: "#fff",
                                          padding: "5px 0"}}>
                                          <span
                                           className="textcalenderday"
                                            style={{fontWeight:"700", fontSize: "32px" }}>{
                                            this.state.searchText.state}</span>
                                          <span 
                                          className="textcalendername" style={{fontWeight:"500", fontSize: "16px", display:"block", marginTop:"10px",color:"rgba(100, 116, 139, 1)", width:"96%" }}>{this.state.searchText.stateCode},{this.state.searchText.flight}</span>
                                        </div>
                                      ) : 
                                      ''}
            </>
          )
    }
    toInputTextUI = () => {
        return (
            <>
                {this.state.toText.state && this.state.toText.flight ? (
                    <div style={{
                        position: "absolute", width: "92%",
                        background: "#fff", left: "10px",
                        padding: "5px 0"
                    }}>
                        <span className="textcalenderday" style={{ fontWeight: 700, fontSize: "32px" }}>{this.state.toText.state}</span>
                        <span className="textcalendername" style={{ fontWeight: 500, fontSize: "16px", display: "block", marginTop: "10px", color: "rgba(100, 116, 139, 1)", }}>{this.state.toText.stateCode},{this.state.toText.flight}</span>
                    </div>
                ) : ''}
            </>
        )
    }
    travelClassInputText = () => {
        return (
            <>
                {this.state.fullTravelObj.countAdult &&
                    this.state.fullTravelObj.TravelStatus ? (
                    <div style={{
                        position: "absolute", width: "134%",
                        background: "#fff", left: "0px", top: "21px",
                        padding: "5px 0"
                    }}>
                        <span className="textcalenderday"
                         style={{ fontWeight: 700, fontSize: "32px" }} data-test-id="formatDay">{this.state.fullTravelObj.countAdult + this.state.fullTravelObj.countChild + this.state.fullTravelObj.countInfant} </span>
                        <span className="textcalendermonth" 
                        style={{ fontWeight: 500, fontSize: "24px" }} 
                        data-test-id="formatMonth">{ this.state.fullTravelObj.countChild+this.state.fullTravelObj.countAdult  + this.state.fullTravelObj.countInfant === 1? "Traveller":"Travellers"}</span><br />
                        <span className="textcalendername" style={{ fontWeight: 500, fontSize: "16px", display: "block", marginTop: "10px", color: "rgba(100, 116, 139, 1)" }} data-test-id="formatYear">{this.state.fullTravelObj.TravelStatus}</span>
                    </div>
                ) : ''}
            </>
        )
    }

    oneWaySearchBar = () => {
        return (
            <>
              <Grid item xs={12} sm={6} md={6} lg={3} style={{position:"relative",}}>
        <TextField
       
          onBlur={()=> setTimeout(()=>{ this.handleClose()
          }, 300)}
          autoComplete="off"
          name="from"
          onChange={
            this.handleInputChange
        }
          style={{
             cursor: 
             "pointer" }}
          label={(
            <>
              <span>From</span>
              {this.fromInputTextUI()}
            </>
          )}
          variant="outlined"
          inputRef={this.inputRef}
          placeholder={"Enter city/ Airport name"}
          value={
            this.state.inputCityFrom}
          data-test-id="inputCityFrom"
          
          onClick={this.handleClick}
          fullWidth
          InputProps={{
            className: "borderCustom",
            style: {  paddingTop: "10px",
            cursor: "pointer",
              borderRadius: "10px 0 0 10px",
              textAlign: "center",
              fontSize: "16px",
},
            classes: 
            {
              input: 
              "landing-header-box",
            }
          }}
          InputLabelProps={{
            className: "fontFrom fontLabel",
            shrink: true,
            style: 
            { transform: "translate(14px, 7px)", 
            cursor: "pointer", color: "#000", fontWeight: "700", fontSize: "16px", width: "100%" 
        }
          }}
        />
        {(this.state.filteredItems.length> 0 
        && Boolean(this.state.anchorEl))
         &&
         <Box className="yscrollwindow fromDropdown"
          sx={{ 
            width: { xs: "94%", sm: "94%", md: "95%", lg: "96%" }
             }}    
             style={{ padding: "10px" }}>
          {this.state.filteredItems.map((itemObj: SearchTextObj, index: number) => (
            <Box data-test-id = {`fromMenu${index}`}
             style={{ borderBottom: "1px solid rgba(226, 232, 240, 1)" }} key={index}
              onClick={() => this.handleSingleClose(itemObj)}>
              <Box className="highlight-item" 
              style={{ paddingTop: "10px", width: "100%",cursor:'pointer' }}>
                <Grid container
                 spacing={2} 
                style={{display:'flex',
                justifyContent:"space-between",padding:"12px"}}>
                  <Box 
                  style={{display:'flex',gap:'10px'}}> 
                  <Grid item> <FlightTakeoffIcon style={{ color: "rgba(148, 163, 184, 1)" }} /> </Grid>
                              <Grid item >
                                  <Typography style={{ color: "#000",
                                   paddingBottom: "12px", fontWeight: "600", fontSize: "12px", }} variant="body1">{itemObj.state}</Typography>
                                  <Typography style={{ color: "rgba(100, 116, 139, 1)",
                                   fontSize: "12px",
                                   paddingBottom: "10px", fontWeight: "600" }} variant="body1">{itemObj.flight}</Typography>

                              </Grid></Box>
                 
                  <Grid item>
                    <Typography style={{ color: "rgba(100, 116, 139, 1)", fontSize: "12px", fontWeight: "600" }}
                      variant="body1">{itemObj.stateCode}</Typography>
                  </Grid>
                </Grid>
              </Box>
    </Box>
          ))}
        </Box>} 
    <Box 
    data-test-id="reverseFunct" 
    onClick={this.reverseFunct} className="reversChangeIcon">
              <SyncAltIcon 
              style={{color:"rgb(23, 100, 160)", cursor:"pointer"}} />
    </Box></Grid>
        <Grid className="inputCity" xs={12} sm={6} md={6} lg={3} item  style={{position:'relative'}}>
          <TextField
            value={this.state.inputSearchTo}
            name="to"
            onBlur={() => 
                setTimeout(()=>{
              this.handleClose()},300)}
            
            label={(
              <>
              <span>To</span>
             {this.toInputTextUI()}
              </>
            )}      placeholder=
            {"Enter city/ Airport name"}
            fullWidth
            onChange={this.handleInputChangeToFunction}
      
            onClick={this.handleClickToFunction}
            variant="outlined"
            autoComplete="off"
      
                
            data-test-id="inputCityTo"
            InputProps={{
              classes:
              {
                input: 
                "landing-header-box"},
              style: {
                borderRadius: "0",       cursor:"pointer",
                textAlign: "center",
                fontSize: "16px",
    paddingTop:
    "10px",
         
              }
            }}
            InputLabelProps={{
              className:"fontFrom fontLabel", shrink: true,
             
              style: 
              {overflow:"hidden", height:"100%", 
              transform: "translate(14px, 7px)", color:"#000", fontWeight:"700",fontSize: "16px",width:"100%"}
          }}
          />
          {(this.state.filteredItemsTo.length >0 && Boolean(this.state.anchorElTo)) &&  <Box
           sx={{ width: { 
            xs: "94%", sm: "94%", md: "95%",
           lg: "96%" } }}
            className="yscrollwindow fromDropdown" 
            style={{ padding: "10px" }}>
            {this.state.filteredItemsTo.map((item: SearchTextObj, index: number) => (
              <Box 
              data-test-id = {`toMenu${index}`} 
              style={{ borderBottom: "1px solid rgba(226, 232, 240, 1)" }} key={index} onClick={() => this.handleCloseToFunct(item)}>
                <Box style={{ width: "100%", paddingTop: "10px" }} 
                className="highlight-item">
                  <Grid container spacing={2} 
                  style={{display:'flex',
                  justifyContent:"space-between",padding:"12px"}}>
                    <Box style={{display:'flex',
                    gap:'10px'}}>
                      <Grid item>
                        <FlightTakeoffIcon style={{ color: "rgba(148, 163, 184, 1)" }} />
                      </Grid>
                      <Grid item>
                        <Typography 
                        style={{ color: "#000", fontSize: "12px", paddingBottom: "12px", fontWeight: "600" }} variant="body1">{item.state}</Typography>
                        <Typography style={{ color: "rgba(100, 116, 139, 1)", fontSize: "12px", paddingBottom: "10px", fontWeight: "600" }}
                         variant="body1">{item.flight}</Typography>
                      </Grid>
                    </Box>
                    <Grid item>
                      <Typography
                       style={{ color: "rgba(100, 116, 139, 1)", fontSize: "12px", fontWeight: "600" }} 
                       variant="body1">{item.stateCode}</Typography>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            ))}
          
                </Box>
          
          
          
          }
        </Grid>
                <Grid item xs={12} sm={12} md={this.state.selectedOption === 'Roundtrip' ? 4 : 6} lg={2}>
                    <TextField
                        InputLabelProps={{
                            className: "fontLabel",
                            shrink: true,
                            style: { transform: "translate(14px, 7px)", color: "#000", fontWeight: 700, fontSize: "16px" }
                        }}
                        onClick={(event: React.MouseEvent<HTMLDivElement>) => this.togglePicker(event, 'startDate')}
                        data-test-id="startDate"
                        placeholder={this.state.startDate ? "" : "Select the departure date"}
                        variant="outlined"
                        fullWidth
                        name="departureDate"
                        label={(
                            <>
                                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                    <span>Departure Date</span>
                                    <ExpandMoreIcon style={{ color: "rgba(23, 100, 160, 1)" }} />

                                </div>
                                {this.state.startDate ? (
                                    <div style={{
                                        position: "absolute",
                                        background: "#fff",
                                        padding: "10px 0"
                                    }}>
                                        <span className="textcalenderday" style={{ fontWeight: 700, fontSize: "32px" }}>{this.formatDatePartFunc(this.state.startDate, "day")}</span>
                                        <span className="textcalendermonth" style={{ fontWeight: 500, fontSize: "24px" }}>{this.formatDatePartFunc(this.state.startDate, "month")}</span><br />
                                        <span className="textcalendername" style={{ fontWeight: 500, fontSize: "16px", display: "block", marginTop: "10px", color: "rgba(100, 116, 139, 1)" }}>{this.formatDatePartFunc(this.state.startDate, "year")}</span>
                                    </div>
                                ) : ''}
                            </>

                        )}
                        InputProps={{
                            style: {
                                borderRadius: "0",
                                textAlign: "center",
                                fontSize: "16px",
                                paddingBottom: "10px"
                            }
                        }}
                    />

                </Grid>
                {this.calenderBoxConditionUI()}
                {(this.state.selectedOption !== 'Multicity' && this.state.selectedOption !== 'One Way') && (
                    <Grid md={4} lg={2} style={{ position: "relative" }} item xs={12} sm={12}>
                        <TextField
                            name="returnDate"
                            onClick={(event) => this.togglePicker(event, 'endDate')}
                            data-test-id="endDate"
                            placeholder={this.state.endDate1 ? "" : `Select the return date
                  (save more on two ways)`}
                            InputProps={{
                                style: {
                                    borderRadius: "0",
                                    textAlign: "center",
                                    fontSize: "16px",
                                    paddingBottom: "10px"
                                }
                            }}
                            variant="outlined"
                            fullWidth
                            label={(
                                <>
                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                        <span>Return Date</span>
                                        <ExpandMoreIcon style={{ color: "rgba(23, 100, 160, 1)" }} />

                                    </div>
                                    {this.state.endDate1 ? (
                                        <div>
                                            <div style={{
                                                position: "absolute",
                                                background: "#fff",
                                                padding: "10px 0"
                                            }}>
                                                <span className="textcalenderday" style={{ fontWeight: 700, fontSize: "32px" }} data-test-id="formatDay">{this.formatDatePartFunc(this.state.endDate1, "day")}</span>
                                                <span className="textcalendermonth" style={{ fontWeight: 500, fontSize: "24px" }} data-test-id="formatMonth">{this.formatDatePartFunc(this.state.endDate1, "month")}</span><br />
                                                <span className="textcalendername" style={{ fontWeight: 500, fontSize: "16px", display: "block", marginTop: "10px", color: "rgba(100, 116, 139, 1)" }} data-test-id="formatYear">{this.formatDatePartFunc(this.state.endDate1, "year")}</span>
                                            </div>

                                        </div>
                                    ) : ''}
                                </>
                            )}

                            InputLabelProps={{
                                className: "fontLabel",
                                shrink: true,
                                style: { transform: "translate(14px, 7px)", color: "#000", fontWeight: 700, fontSize: "16px" }
                            }}
                        />
                        {
                            this.state.endDate1 && <div className="iconCloseCale"
                                data-test-id="iconClose"
                                onClick={this.iconClose}>
                                <CloseIcon />
                            </div>
                        }
                    </Grid>
                )}
                <Grid
                    item
                    lg={this.state.selectedOption === 'One Way' ? 4 : 2}
                    xs={12}
                    sm={12}
                    md={this.state.selectedOption === 'One Way' ? 6 : 4} >
                    <TextField
                        InputProps={{
                            
                            className: "borderCustom",
                            style: {
                                borderRadius: "0 10px 10px 0",
                                textAlign: "center",
                                fontSize: "16px",
                                paddingBottom: "10px"
                            }
                        }}
                        name="travellers & class"
                        placeholder={"Select no.of travellers\n& Class."}
                        variant="outlined"
                        fullWidth
                        value={""}
                        data-test-id="handleClickClass"
                        onClick={this.handleClickClass}
                        label={(
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                <span>Travellers & Class</span>
                                <ExpandMoreIcon style={{ color: "rgba(23, 100, 160, 1)" }} />
                                {this.travelClassInputText()}
                            </div>

                        )}

                        style={{ width: "100%", overflow: "hidden" }}

                        InputLabelProps={{
                            className: "fontLabel",
                            shrink: true,
                            style: { transform: "translate(14px, 7px)", color: "#000", fontWeight: 700, fontSize: "16px" }
                        }}
                    />
                    <Menu
                        marginThreshold={72}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left'
                        }}
                        data-test-id="handleCloseClass"
                        className="menuFrom"
                        id="simple-menu"
                        anchorEl={this.state.anchorElClass}
                        PaperProps={{
                            style: {
                                width: 600,
                                borderRadius: "10px"
                            }
                        }}
                        open={Boolean(this.state.anchorElClass)}
                        onClose={this.handleCloseClass}
                        keepMounted

                    >
                        {this.travellerClassBoxUi()}
                    </Menu>
                </Grid>
            </>
        )
    }
    render() {
        return (
            // Customizable Area Start
            <div style={{ marginTop: "-10px" }}>
                <Loader loading={this.state.loading} />
                <BGComponent data-test-id="oceanbackground" oceanbackground={oceanbackground} />
                    <div style={{ width: "100%" }} data-test-id="header-menu"><HeaderMenuBlock navigation={this.props.navigation} id={""} /></div>
                    <Box className="boxHeaderpadding" style={{ position: "relative", display: "flex", flexDirection: "column", alignItems: "center" }}>
                        <Box className="flaticonBox" style={{
                            borderRadius: "15px",
                            zIndex: 9992,
                            boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.25)",
                            display: "flex",
                            backgroundColor: '#FFFFFF',
                            width: "35%",
                            justifyContent: "space-evenly",
                            position: "absolute",
                        }}>
                            <Box style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: '130px',
                                flexDirection: 'column',
                                cursor:"pointer"
                            }}>
                                <Box className="flitIcon">
                                    <React.Suspense fallback={<CircularProgress />}>
                                        <img src={airoplane} />
                                    </React.Suspense>
                                </Box>
                                <Typography style={{ fontWeight: 700, fontFamily: 'Inter, sans-serif', color: 'rgba(23, 100, 160, 1)' }}>
                                    Flight Booking
                                </Typography>
                            </Box>
                            <Box style={{
                                display: 'flex',
                                alignItems: 'center',
                                height: '130px',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                cursor:"pointer"
                            }} onClick={()=>this.handleNavigate("Cfitinerarymanagement")} data-test-id="handleNavigate">
                                <Box className="flitIcon1">
                                    <React.Suspense fallback={<CircularProgress />}>
                                        <img src={umbrella} />
                                    </React.Suspense>
                                </Box>
                                <Typography style={{ color: 'rgba(23, 100, 160, 1)', fontFamily: 'Inter, sans-serif', fontWeight: 700, }}>
                                    Packages
                                </Typography>
                            </Box>
                        </Box>
                        <Box style={{
                            borderRadius: "10px",
                            width: "91%",

                            height: "50%",
                            zIndex: 4,
                            marginTop: 50,
                            backgroundColor: "#fff",
                            boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.25)",

                        }} className="boxSizeSearchDiv">
                            <Box style={{ marginTop: "83px", padding: "23px" }}>
                                <RadioGroup aria-label="options" name="options" style={{ display: "flex", flexDirection: "row" }} value={this.state.selectedOption}>
                                    <FormControlLabel data-test-id="handleSelectRadio" style={{ color: "#000" }} onClick={() => this.handleSelectRadio("One Way")} value={"One Way"} control={<Radio style={{ color: 'rgba(23, 100, 160, 1)' }} />} label="One Way" />
                                    <FormControlLabel data-test-id="handleRadioClick2" style={{ color: "#000" }} onClick={() => this.handleSelectRadio("Roundtrip")} value={"Roundtrip"} control={<Radio style={{ color: 'rgba(23, 100, 160, 1)' }} />} label="Roundtrip" />
                                    <FormControlLabel data-test-id="handleRadioClick3" style={{ color: "#000" }} onClick={() => this.handleSelectRadio("Multicity")} value={"Multicity"} control={<Radio style={{ color: 'rgba(23, 100, 160, 1)' }} />} label="Multicity" />
                                </RadioGroup>
                            </Box>
                            {
                                (this.state.errorMessage || this.state.errorResSearch) &&
                                <span className="imgErrorBox" data-test-id="error-msg">
                                    {this.state.errorMessage || this.state.errorResSearch}
                                </span>
                            }
                            <Box style={{ margin: "0 20px" }}>
                                <Grid container spacing={0} className="searchFrom searchInput" style={{ position: "relative" }}>
                                    {this.multicityFunctionUi()}
                                    <Grid item xs={12}>
                                        <Box style={{ display: "flex", justifyContent: "center", margin: "20px 0" }}>
                                            <Button
                                                data-test-id="searchFlight"
                                                className="buttonSubmitSearch"
                                                type="submit"
                                                variant="contained"
                                                color="primary"
                                                onClick={this.searchFlight}
                                            >
                                                Search Flights
                                            </Button>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                    </Box>
                <Box sx={{ padding: { xs: "10px 10px", sm: "10px 10px", md: "10px 30px", lg: "10px 60px" }, }}>
                    <Grid container>
                        <Grid item xs={12} md={12} lg={12} className="textH5" style={webStyleLanding.textAlignStart}>
                            <Box style={webStyleLanding.blogContent}>
                                <Typography variant="h5" style={{ marginBottom: "18px" }}>Popular Destination</Typography>
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        {this.popularDestiUI()}
                    </Grid>
                </Box>
                <Footer />
            </div>
            // Customizable Area End
        );
    }
}

const webStyleLanding = {
    errorMsgforCount: {
        margin: "0px 10px",
        borderRadius: "5px",
        fontSize: "12px",
        borderLeft: "6px #c10f0ff0 solid",
        background: " #ed7e7e3d",
        fontFamily: "Inter, sans-serif",
        color: "#c12121",
        display: "flex",
        lineHeight: "24px",
        padding: "20px 15px",
        alignItems: "center"
    },
    whareImg: {
        width: "100%",
        borderRadius: "50%",
        border: "6px rgb(240, 229, 255) solid",
        height: "150px"
    },
    titleh3w: {
        fontSize: "24px" as "24px",
        fontWeight: 700
    },
    titlebodyw: {
        fontSize: "16px",
        color: "rgba(100, 116, 139, 1)",
        marginTop: "0px",
        wordBreak: "break-all" as "break-all"
    },
    Typographytitle: {
        textAlign: "start" as "start",
        fontWeight: 500,
        fontSize: "16px"
    },
    titleh3: {
        fontSize: "16px",
        fontWeight: 700,
        padding: "16px 0"
    },
    titlebody: {
        fontSize: "16px",
        fontWeight: 400,
        minHeight: "48px"
    },
    imgWight300: {
        width: "123px",
        height: "108px"
    },
    textAlignStart: {
        textAlign: "start" as "start",
        marginTop: "30px",
        marginBottom: "30px",
        fontWeight: 700,
        display: "flex",
        justifyContent: "center",
        width: "100%"

    },
    viewAllStyle: {
        textAlign: "end" as "end",
        marginTop: "30px",
        marginBottom: "30px",
        fontWeight: 700,

    },
    TypographyHead: {
        fontSize: "16px" as "16px",
        fontFamily: "Inter, sans-serif",
        textAlign: "center" as "center",
        backgroundColor: "rgba(250, 224, 224, 1)",
        padding: "5px",
        fontWeight: 700,
        margin: "0px 60px"
    },
    TypographyrowII: {
        fontSize: "14px" as "14px",
        textAlign: "center" as "center",
        fontWeight: 700,
        padding: "5px",
        fontFamily: "Inter, sans-serif"
    },
    cardStyle1: {
        margin: "12px",
    },
    cardStyle: {
        boxShadow: "rgba(0, 0, 0, 0.25) 0px 0px 10px",
        borderRadius: "15px",
        margin: "7px",
        overflow: "hidden",

    },
    TypographyStylelast: {
        fontSize: "14px" as "14px",
        textAlign: "center" as "center",
        fontWeight: 400,
        fontFamily: "Inter, sans-serif"
    },
    imgBox: {
        justifyContent: "center",
        alignItems: "center",
        padding: "20px",
        flexWrap: "nowrap",
        gap: "10px",
    },

    DetailsStyle: {
        fontSize: "16px" as "16px",
        color: "rgba(23, 100, 160, 1)",
        textAlign: "center" as "center",
        fontWeight: 700,
        fontFamily: "Inter, sans-serif",
        paddingBottom: "15px",
        cursor: "pointer"
    },
    secTwoTitle3: {
        fontSize: "16px",
        fontWeight: 700,
        color: "#1764A0",
        textTransform: "uppercase" as "uppercase",
        cursor: "pointer"
    },
    secTwoTitleBlog: {
        fontWeight: 700,
        textTransform: "capitalize" as "capitalize",
        color: "black",
    },
    secTwoTitle: {
        background: "#fff",
        padding: "1px 10px",
        fontSize: "16px",
        fontWeight: 700
    },
    secTwoTitle2: {
        color: "#fff",
        padding: "7px 0",
        fontSize: "14px",
        fontWeight: 700,
        display: "flex",
        alignItems: "center"
    },
    blogDesc: {
        fontSize: "24px",
    },
    blogDesBox: {
        width: {
            lg: "800px",
            xs: "100%",
            sm: "100%",
            md: "100%",
        }
    }
    , blogContent: {
        textAlign: "center" as "center"
    },
    backgroundImage: {
        backgroundImage: `url(${oceanbackground})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
        width: "100%",
        opacity: 2.5,
    },
}