import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { BlockComponent } from "../../../framework/src/BlockComponent";

// Customizable Area Start

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  // Customizable Area Start
  flightDetails: any
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  selectedTab: string
  displayCancelledIndex: number | null
  cancelledTripArr: string[]
  refundSteps: {label:string,status:boolean,date:string}[],
  activeTab:any;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class MyPackageController extends BlockComponent<
  Props,
  S,
  SS

> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),

      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
        selectedTab:"Upcoming",
        cancelledTripArr:['1','2','3'],
        refundSteps:[],
        activeTab:1,
        displayCancelledIndex: null,
        
    };

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getTokens();
    // Customizable Area Start
    // Customizable Area End
  }

  getTokens = () => {
    const msgConst: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msgConst);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  redirectNab=(tab:any)=>{
    this.props.navigation.navigate(tab)
}
    handleTabChange = (event: React.ChangeEvent<{}>, newValue: string) => {
        this.setState({ selectedTab: newValue });
    };
   
    
   
  // Customizable Area End
}
