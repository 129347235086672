import React from "react";
import { Box, Button, Modal, Typography, styled } from "@material-ui/core";
import ChangeTravelDateController, { Props } from "./ChangeTravelDateController"
import HeaderMenuBlock from "../../../blocks/navigationmenu/src/HeaderMenu.web";
import { AccountCircleOutlined, CalendarToday, Close } from "@material-ui/icons";
import { backArrowIcon, heartIcon, successIcon } from "./assets";
import SingleCalender from "../../../components/src/SingleCalender";

export default class ChangeTravelDate extends ChangeTravelDateController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    PaymentSuccessUi = () => {
        return (
            <ParentContainer data-test-id='success-parent-container'>
                <Box className="content-container">
                    <Box style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: '20px' }} sx={webstyle.boxContainer} >
                        <img style={{ width: '150px', height: '150px' }} src={successIcon} />
                        <Typography
                            style={{
                                ...webstyle.flight_heading,
                                textAlign: 'center',
                                display: 'flex',
                                alignItems: 'center',
                                gap: '4px',
                            }}
                        >
                           <span style={{ flexGrow: 1,lineHeight:2 }}>
  Congratulations! You have successfully booked your tickets&nbsp;
  <img 
    src={heartIcon} 
    alt="heart" 
    style={{ verticalAlign: 'middle' }}
  />
</span>

                            
                        </Typography>
                    </Box>
                </Box>
            </ParentContainer>
        )
    }
    bookNowUi = () => {
        return (
            <ParentContainer>
                <Box className="content-container">
                    <Box sx={webstyle.boxContainer} >
                        <div className="page-heading-container" style={{ display: 'flex', alignItems: 'center', gap: '16px', borderBottom: "1px solid #E2E8F0", boxShadow: "0px 2px 10px 0px #0000001A" }}>
                            <img data-test-id='back-navigate' onClick={this.handleNavigate} src={backArrowIcon} style={{ cursor: 'pointer' }} />
                            <Typography className="page-heading" style={webstyle.subTitle}>Select Flight</Typography>
                        </div>
                        <Box className="data-container">
                            <Box className="left-container left-ui">
                                <Box className="flight-head-container">
                                    <Typography
                                        style={webstyle.flight_heading}>
                                        New Delhi - Mumbai</Typography>
                                    <Typography
                                        style={webstyle.flight_content}>
                                        Onway Flight | Booking ID - NXERHUIOP1226TB556
                                    </Typography>
                                </Box>
                                <Box sx={webstyle.flightDataBookNowCont}
                                    style={{ display: "flex", flexDirection: "column", gap: "24px" }}>
                                    <Typography
                                        style={webstyle.flightTypeHeading}>
                                        Departure Flight - 6 June, Fri</Typography>
                                    <Box sx={webstyle.upcomingDetailsCont} style={{
                                        display: 'flex',
                                        flexWrap: "wrap-reverse",
                                        gap: '25px'
                                    }}>
                                        <div style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            gap: "8px",
                                            justifyContent: 'flex-start'
                                        }}>
                                            <Typography style={webstyle.flight_content}>
                                                6 June 24, 12:20 PM</Typography>
                                            <Typography
                                                style={webstyle.flight_heading}>
                                                DEL - New Delhi
                                            </Typography>
                                        </div>
                                        <div
                                            style={{
                                                display: 'flex', flexDirection: 'column',
                                                gap: "8px", justifyContent: 'flex-start'
                                            }}>
                                            <Typography
                                                style={webstyle.flight_content}>
                                                6 June 24, 2:20 PM
                                            </Typography>
                                            <Typography style={webstyle.flight_heading}>
                                                BOM - Mumbai
                                            </Typography>
                                        </div>
                                        <Box sx={webstyle.flightDetailsCont}
                                            style={{
                                                display: 'flex',
                                                gap: "8px",
                                                justifyContent: 'flex-start'
                                            }}>
                                            <Typography style={{
                                                ...webstyle.flight_content,
                                                display: 'flex', alignItems: 'center', marginLeft:
                                                    '3px'
                                            }}>
                                                <img style={{
                                                    height: '26px',
                                                    marginRight: '8px',
                                                    borderRadius: '50%',
                                                    objectFit: 'cover',
                                                    width: '26px',
                                                    
                                                }}
                                                    src="https://i.pinimg.com/1200x/2d/06/2c/2d062c935dde7754fa80bf011a9dbdc7.jpg" />
                                                Indigo</Typography>
                                            <Typography
                                                style={{ ...webstyle.flight_content,
                                                 display: 'flex',
                                                  alignItems: 'center' }}>
                                                <AccountCircleOutlined style={{
                                                    color: "#64748B",
                                                    height: '28px', width: '28px', marginRight: '8px'
                                                }} />
                                                Rahul Das
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                            <Box className="right-container right-ui">
                                <Typography className="page-heading fare-difference-head " style={{ ...webstyle.subTitle }}>Fare Difference</Typography>
                                <Box className="fare-value">
                                    <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <Typography style={{ ...webstyle.flight_heading, fontSize: '14px' }}>Fare Difference (A)</Typography>
                                        <Typography style={{ ...webstyle.flight_heading, fontSize: '14px' }}>$ 656</Typography>
                                    </Box>
                                    <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <Typography style={{ ...webstyle.flight_heading, fontSize: '14px' }}>Date Change Charge (B)</Typography>
                                        <Typography style={{ ...webstyle.flight_heading, fontSize: '14px' }}>$ 600</Typography>
                                    </Box>
                                    <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <Typography style={{ ...webstyle.flight_heading }}>Amount Payable </Typography>
                                        <Typography style={{ ...webstyle.flight_heading }}>$1,256</Typography>
                                    </Box>
                                    <Typography style={{ ...webstyle.flight_heading }}>(A+B)</Typography>
                                    <Button data-test-id='paynow-btn' style={{
                                        color: 'white',
                                        background: '#1764A0',
                                        padding: '12px, 12px, 12px, 12px',
                                        textTransform: 'none',
                                        borderRadius: '8px',
                                        fontFamily: 'Inter, sans-serif',
                                        fontWeight: 600,
                                        width: '100%',
                                        fontSize: '16px', marginTop: '10px'
                                    }} variant="contained" onClick={this.handleShowSuccessUi}>Pay Now</Button>
                                </Box>
                            </Box>
                        </Box>
                    </Box>

                </Box>
            </ParentContainer>
        )
    }
    rescheduleModal = () => {
        return (
            <RescheduleModalContainer data-test-id='date-modal' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} open={this.state.isModalOpen} onClose={this.handleModalOpen}>
                <Box data-test-id='close-calender' onClick={this.closeCalendar} className="modal-parent">
                    <Box className="modal-data">
                        <header className="modal-head-container">
                            <Typography className="header">Flight Booking Cancellation</Typography>
                            <span data-test-id='close-modal' onClick={this.handleModalClose}> <Close style={{ cursor: 'pointer' }} /></span>
                        </header>
                    </Box>
                    <Box className="modal-content">
                        <Box className="parent-content">
                            <Box className="location">
                                <Typography style={webstyle.flight_heading}>From</Typography>
                                <Typography className="location-content">New Delhi</Typography>
                            </Box>
                            <Box className="location">
                                <Typography style={webstyle.flight_heading}>To</Typography>
                                <Typography className="location-content">Mumbai</Typography>
                            </Box>
                            <Box className="location">
                                <Typography style={webstyle.flight_heading}>New Departure Date</Typography>
                                <div data-test-id='date-select-input' onClick={this.toggleCalendar}> <Typography className="location-content departure">
                                    {this.state.startDate
                                        ? this.formatDate(this.state.startDate)
                                        : (
                                            <>
                                                Select New Departure Date
                                            </>
                                        )
                                    }<CalendarToday />
                                </Typography>
                                </div>
                                {this.state.showCalendar && <div className="singleCalender calenderBoox calendar">
                                    <SingleCalender state={this.state} month={1} data-test-id="calenderBlock" handleDateChange={this.handleDateChange1} />
                                </div>}
                            </Box>
                        </Box>
                        <div className="confirm-btn-container">
                            <Button data-test-id='confirm-reschedule-btn' onClick={this.handleConfirmReschedule} className="confirm-btn" disabled={!this.state.startDate} style={{
                                color: 'white',
                                background: '#1764A0',
                                padding: '10px, 12px, 10px, 12px',
                                textTransform: 'none',
                                borderRadius: '8px',
                                fontFamily: 'Inter, sans-serif',
                                fontWeight: 600,
                                width: '140px',
                                fontSize: '16px'
                            }} variant="contained">Confirm</Button>
                        </div>
                    </Box>
                </Box>
            </RescheduleModalContainer>
        )
    }
    render() {
        return (
            <ParentContainer className="cancel-container" data-test-id='cancel-booking-ui'>
                <Box sx={webstyle.headerContainer}><HeaderMenuBlock navigation={this.props.navigation} id={"MyTrips"} /></Box>
                <Box sx={webstyle.mainContainer} style={{ position: "absolute", top: "100px", display: "flex", flexDirection: "column", gap: "58px" }}>
                    {this.state.showSuccessUi ? <Typography className="booking-cancellation-heading" style={webstyle.title}>Complete your bookings</Typography> : <Typography className="booking-cancellation-heading" style={webstyle.title}>Reschedule Your Flight Booking</Typography>}
                </Box>
                {this.state.showBookUi ? this.bookNowUi() : this.state.showSuccessUi ? this.PaymentSuccessUi() : <Box className="content-container">

                    <Box data-test-id='reschedule-ui' sx={webstyle.boxContainer} >
                        <div className="page-heading-container" style={{ display: 'flex', alignItems: 'center', gap: '16px', borderBottom: "1px solid #E2E8F0", boxShadow: "0px 2px 10px 0px #0000001A" }}>
                            <img data-test-id='back-navigate' onClick={this.handleNavigate} src={backArrowIcon} style={{ cursor: 'pointer' }} />
                            <Typography className="page-heading" style={webstyle.subTitle}>Select Flight booking </Typography>
                        </div>
                        <Box className="data-container">
                            <Box className="left-container">
                                <Box className="flight-head-container">
                                    <Typography style={webstyle.flight_heading}>
                                        New Delhi - Mumbai
                                    </Typography>
                                    <Typography style={webstyle.flight_content}>Onway Flight | Booking ID - NXERHUIOP1226TB556 </Typography>
                                </Box>
                                <Box sx={webstyle.flightDataCont} style={{ display: "flex", flexDirection: "column", gap: "24px" }}>
                                    <Typography style={webstyle.flightTypeHeading}>Departure Flight - 6 June, Fri</Typography>
                                    <Box
                                        sx={webstyle.upcomingDetailsCont} style={{
                                            display: 'flex',
                                            gap: '25px',
                                            flexWrap: "wrap-reverse",

                                        }}>
                                        <div

                                            style={{
                                                display: 'flex', flexDirection: 'column',
                                                gap: "8px", justifyContent: 'flex-start'
                                            }}>
                                            <Typography style=
                                                {webstyle.flight_content}>
                                                6 June 24, 12:20 PM</Typography>
                                            <Typography style={webstyle.flight_heading}>
                                                DEL - New Delhi</Typography>
                                        </div>
                                        <div style={{
                                            display: 'flex', flexDirection: 'column',
                                            gap: "8px", justifyContent: 'flex-start'
                                        }}>
                                            <Typography style={webstyle.flight_content}>
                                                6 June 24, 2:20 PM</Typography>
                                            <Typography

                                                style={webstyle.flight_heading}>
                                                BOM - Mumbai</Typography>
                                        </div>
                                        <Box
                                            sx={webstyle.flightDetailsCont} style={{ display: 'flex', gap: "8px", justifyContent: 'flex-start' }}>
                                            <Typography

                                                style={{ ...webstyle.flight_content, display: 'flex', alignItems: 'center', marginLeft: '3px' }}><img style={{
                                                    objectFit: 'cover',

                                                    width: '26px',
                                                    height: '26px',
                                                    marginRight: '8px',
                                                    borderRadius: '50%',
                                                }} src="https://i.pinimg.com/1200x/2d/06/2c/2d062c935dde7754fa80bf011a9dbdc7.jpg" />Indigo</Typography>
                                            <Typography style={{ ...webstyle.flight_content, display: 'flex', alignItems: 'center' }}><AccountCircleOutlined style={{ color: "#64748B", height: '28px', width: '28px', marginRight: '8px' }} /> Rahul Das</Typography>
                                        </Box>
                                    </Box>
                                    <Button style={{
                                        color: 'white',
                                        background: '#1764A0',
                                        padding: '10px, 12px, 10px, 12px',
                                        textTransform: 'none',
                                        borderRadius: '8px',
                                        fontFamily: 'Inter, sans-serif',
                                        fontWeight: 600,
                                        width: '160px',
                                        fontSize: '16px'
                                    }} variant="contained" data-test-id='reschedule-btn' onClick={this.handleModalOpen}>Reschedule</Button>
                                </Box>
                            </Box>
                            <Box className="right-container">
                                <Typography className="page-heading info-heading" style={{ ...webstyle.subTitle }}>Important Information for Date Change</Typography>
                                <Typography>
                                    Check Airline Policy:
                                    <ul>
                                        <li>Visit the airline's website or contact their customer service to understand their specific policies regarding date changes.</li>
                                        <li>Some airlines allow free changes within a certain timeframe, while others may charge a fee.</li>
                                    </ul>
                                    Ticket Type:
                                    <ul>
                                        <li>Flexible tickets often allow changes without a fee.</li>
                                        <li>Non-refundable or economy tickets typically incur a change fee.</li>
                                    </ul>
                                    Change Fees:
                                    <ul>
                                        <li>Airlines may charge a fee for changing the date of your flight.</li>
                                        <li>Fees can vary widely and may depend on how close to the departure date you request the change.</li>
                                    </ul>
                                    Fare Difference:
                                    <ul>
                                        <li>
                                            If the new flight is more expensive than the original, you'll need to pay the difference in fare.
                                        </li>
                                        <li>If the new flight is cheaper, some airlines may refund the difference or offer a credit.</li>
                                    </ul>
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                </Box>}

                {this.rescheduleModal()}
            </ParentContainer>
        )
    }
}
const ParentContainer = styled(Box)({
    "& .data-container": {
        display: 'flex',
        gap: "30px",
        marginTop: "20px",
        paddingRight: "30px",
        "@media screen and (min-width: 200px) and (max-width: 1000px)": {
            paddingRight: "13px",
            flexWrap: 'wrap'
        },
    },
    "& .fare-value": {
        padding: '14px',
        display: 'flex',
        flexDirection: 'column',
        gap: '4px'
    },
    "& .info-heading": {
        paddingLeft: "40px",
        marginBottom: '40px',
        "@media screen and (min-width: 200px) and (max-width: 1000px)": {
            paddingLeft: "0px",
            marginBottom: '20px',
        },
    },
    "& .fare-difference-head": {
        paddingLeft: "20px",
        paddingTop: '22px',
        paddingRight: "20px",
        paddingBottom: '25px',
        borderBottom: "1px solid #94A3B8"
    },
    "& .right-ui": {
        padding: "0px !important"
    },
    "& .right-container": {
        boxShadow: "0px 4px 10px 0px #00000029",
        width: '30%',
        padding: "22px",
        "@media screen and (min-width: 200px) and (max-width: 1000px)": {
            width: '100%',
            padding: '20px'
        },
    },
    "& .left-container": {
        display: "flex",
        flexDirection: "column",
        gap: '32px',
        width: '70%',
        "@media screen and (min-width: 200px) and (max-width: 1000px)": {
            width: '100%'
        },
    },
    "& .left-ui": {
        boxShadow: "0px 4px 10px 0px #00000029",
        marginLeft: "32px",
        gap: '0px !important',
        "@media screen and (min-width: 200px) and (max-width: 1000px)": {
          marginLeft:'13px'
        },
    },
    "& .flight-head-container": {
        borderBottom: "1px solid #E2E8F0",
        boxShadow: " 0px 2px 4px 0px #0000001A",
        padding: '18px 32px',
        display: "flex",
        flexDirection: "column",
        gap: "8px",
        "@media screen and (min-width: 200px) and (max-width: 1000px)": {
            padding:'16px'
        },
    },
    "& .cancel-container": {
        postion: "relative"
    },
    "& .booking-cancellation-heading": {
        "@media screen and (min-width: 200px) and (max-width: 420px)": {
            fontSize: '26px',
        },
        "@media screen and (min-width: 200px) and (max-width: 600px)": {
            marginLeft: "20px",
            fontSize: '26px',
        },
        "@media screen and (min-width: 600px) and (max-width: 768px)": {
            marginLeft: "30px",
            fontSize: '32px',
        },
        "@media screen and (min-width: 1000px) and (max-width: 1141px)": {
            marginLeft: "40px",
            fontSize: '32px',
        },
        "@media screen and (min-width: 1141px) and (max-width: 1278px)": {
            marginLeft: "56px",
        },
        marginLeft: "45px",
        fontSize: '32px',
    },
    "& .content-container": {
        position: 'absolute',
        top: "195px",
        display: 'flex',
        justifyContent: 'center',
        width: '100%'
    },
    "& .page-heading-container": {
        paddingLeft: "32px", paddingBottom: "27px", paddingTop: "42px",
        "@media screen and (min-width: 200px) and (max-width: 420px)": {
            paddingLeft: "15px",
            paddingBottom: "20px",
            paddingTop: "30px",
        },
    },
    "& .page-heading": {
        "@media screen and (min-width: 200px) and (max-width: 420px)": {
            fontSize: '16px'
        },
        "@media screen and (min-width: 420px) and (max-width: 720px)": {
            fontSize: '18px'
        },
        fontSize: '24px'
    },
})
const RescheduleModalContainer = styled(Modal)({
    "& .modal-data": {
        background: "white",
        borderRadius: "8px 8px 0px 0px",
        boxShadow: "0px 2px 4px 0px #0000001A"

    },
    "& .from-input": {
        width: "30%"
    },
    "& .calendar": {
        position: 'absolute',
        top: '30px',
        right: '0px',
        zIndex: 1000
    },
    "& .confirm-btn.Mui-disabled": {
        background: "#1764A080 !important",
    },
    "& .from": {
        position: 'absolute',
        background: 'white',
        width: 'fit-content',
        borderRadius: '8px',
        boxShadow: '0px 2px 4px 0px #00000091',
        height: 'auto',
        minHeight: 'auto',
        maxHeight: '365px',
        zIndex: 10000
    },
    "& .modal-parent": {
        width: "70%",
        "@media screen and (min-width: 200px) and (max-width: 800px)": {
            width: "95%",
        },
        borderRadius: "8px",
    },

    "& .modal-content": {
        background: 'white',
        padding: '48px',
        borderRadius: "0px 0px 8px 8px",
        display: 'flex',
        flexDirection: 'column',
        gap: '40px',
        "@media screen and (min-width: 200px) and (max-width: 1000px)": {
            padding: '23px'
        },
    },
    "& .confirm-btn-container": {
        display: 'flex',
        justifyContent: 'flex-end',
        paddingRight: '24px'
    },
    "& .parent-content": {
        display: 'flex',
        justifyContent: 'space-evenly',
        "@media screen and (min-width: 200px) and (max-width: 1240px)": {
            flexDirection: "column",
            gap: "20px"
        },
    },
    "& .location-content": {
        border: "1px solid  #CBD5E1",
        paddingTop: '10px',
        paddingRight: "8px",
        paddingBottom: "10px",
        paddingLeft: '8px',
        borderRadius: "8px",
        fontSize: '14px',
        color: '#64748B'
    },
    "& .location": {
        display: 'flex',
        flexDirection: 'column',
        gap: '4px',
        width: '30%',
        position: 'relative',
        "@media screen and (min-width: 200px) and (max-width: 1240px)": {
            width: '100%'
        },
    },
    "& .departure": {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        cursor: 'pointer'
    },
    "& .modal-head-container": {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderBottom: "1px solid #E2E8F0",
        boxShadow: "0px 2px 4px 0px #0000001A",
        padding: '26px 40px',
        "@media screen and (min-width: 200px) and (max-width: 800px)": {
            padding: '26px 25px',
        },
    },
    "& .header": {
        fontWeight: 700,
        fontSize: "24px",
        "@media screen and (min-width: 200px) and (max-width: 800px)": {
            fontSize: "16px",
        },
    },
})
const webstyle = {
    flightDetailsCont: {
        flexDirection: {
            xs: "row",
            sm: 'column',
            md: "column",
            lg: "column",
        }
    },
    flight_heading: {
        fontSize: "16px",
        fontWeight: 700
    },
    flight_content: {
        fontSize: "14px",
        fontWeight: 500
    },
    upcomingDetailsCont: {
        width: "100%",
        justifyContent: {
            xs: 'space-between',
            sm: 'space-between',
            md: '',
            lg: ''
        },
    },
    flightTypeHeading: {
        fontWeight: 700,
        fontSize: "16px",
        color: "#64748B"
    },
    headerContainer: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
        gap: "56px",
        overFlowX: 'hidden',
        height: {
            xs: '930px',
            sm: '800px',
            md: '580px',
            lg: '490px'
        },
        background: "linear-gradient(180deg, #013B68 84.25%, #0175D0 100%)",
    },
    boxContainer: {
        boxShadow: "0px 4px 10px 0px #00000029",
        borderRadius: "8px",
        width: "95%",
        background: "white",
        minHeight: "500px"
    },
    title: {
        fontWeight: 700,
        color: "white",

    },
    mainContainer: {
        width: {
            xs: "96%",
            sm: "97%",
            md: "98%",
            lg: "97%"
        },
        padding: {
            xs: "10px",
            lg: "24px"
        }
    },
    subTitle: {
        fontWeight: 700,
    },
    flightDataCont: {
        padding: {
            xs: "12px",
            md: '32px',
            lg: '32px'
        },
        marginLeft: {
            xs: '18px',
            sm: '18px',
            md: "32px",
            lg: "32px"
        },

        boxShadow: "0px 4px 10px 0px #00000029"
    },
    flightDataBookNowCont: {
        padding: {
            xs: "13px",
            md: '32px',
            lg: '32px'
        },
    }
}