import React from "react";

import {
  Box,
  Button,
  Typography,
  // Customizable Area Start
  Grid,
  CircularProgress,
  TextField,
  Popover,
  Menu
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import Loader from "../../../components/src/Loader.web";
import SingleCalender from "../../../components/src/SingleCalender";
import RemoveIcon from '@material-ui/icons/Remove';
import AddIcon from '@material-ui/icons/Add';
import FlightTakeoffIcon from '@material-ui/icons/FlightTakeoff';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SyncAltIcon from '@material-ui/icons/SyncAlt';
import HeaderMenuBlock from "../../../blocks/navigationmenu/src/HeaderMenu.web";
import { oceanbackground, umbrella, airoplane, errorIcon } from "../../../blocks/landingpage/src/assets";
import PackageSlider from "../../../components/src/PackageSlider"
import "../assets/style.css"
// Customizable Area End

import PackageSearchController, {
  Props,
} from "./PackageSearchController.web";

export default class PackageSearch extends PackageSearchController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  toInputText = () => {
    return (
      <>
        {this.state.toText.state && this.state.toText.flight ? (
          <div style={{
            position: "absolute", width: "92%",
            background:this.propsFunction(), left: "10px",
            padding: "5px 0"
          }}>
            <span className="textcalenderday" style={{ fontWeight: 700, fontSize: "32px" }}>{this.state.toText.state}</span>
            <span className="textcalendername" style={{ fontWeight: 500, fontSize: "16px", display: "block", marginTop: "10px", color: this.textLastFunc(), }}>{this.state.toText.stateCode},{this.state.toText.flight}</span>
          </div>
        ) : ''}
      </>
    )
  }
  formInputText = () => {
    return (
      <>
        {this.state.searchText.state && this.state.searchText.flight ? (
          <div style={{
            position: "absolute", width: "100%",
            background: this.propsFunction(),
            padding: "5px 0"
          }}>
            <span className="textcalenderday" style={{ fontWeight: 700, fontSize: "32px" }}>{this.state.searchText.state}</span>
            <span className="textcalendername" style={{ fontWeight: 500, fontSize: "16px", display: "block", marginTop: "10px", color: this.textLastFunc(), width: "96%" }}>{this.state.searchText.stateCode},{this.state.searchText.flight}</span>
          </div>
        ) : ''}
      </>
    )
  }
  calBoxCondition = () => {
    return (
      <Popover
        data-test-id="popover"
        open={this.state.isOpen}
        anchorEl={this.state.setAnchorAi}
        onClose={this.handleCloseCustomAi}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <div className="boxCalenderDone111" style={{ borderRadius: "10px", width: "auto", boxShadow: "rgba(0, 0, 0, 0.25) 0px 0px 10px", zIndex: 9999, background: "#fff" }}>
          <div className="datepikerTop111">
            <div className="calTitle" style={{ padding: "12px 20px", width: "auto" }}>
              <Typography variant="h4" ><span className="font14px">{this.state.startDate ? this.formatDate(this.state.startDate) : "Departure Date"}</span></Typography>
            </div>

            <div className="singleCalender calenderBoox">
              <SingleCalender state={this.state} month={1} data-test-id="calenderBlock" handleDateChange={this.handleDateChange1} />
            </div>

            <Box className="buttonBoxapply111">
              <Button style={{ color: "rgba(23, 100, 160, 1)", fontSize: "16px", textTransform: "capitalize", fontWeight: 700 }} data-test-id="handleCancel" onClick={this.handleCancel}>Cancel</Button>&nbsp;&nbsp;&nbsp;&nbsp;
              <Button style={{ background: "rgba(23, 100, 160, 1)", color: "#fff", fontSize: "16px", textTransform: "capitalize", fontWeight: 700 }} data-test-id="handleApply" onClick={this.handleApply}>&nbsp;Apply&nbsp;</Button>
            </Box>
          </div>

        </div>
      </Popover>
    )
  }
  formPackageUi = () => {
    return (
      <>
        <Grid item xs={12} sm={6} md={6} lg={3} style={{ position: "relative", }}>
          <TextField
            autoComplete="off"
            onBlur={() => setTimeout(() => {
              this.handleClose()
            }, 300)}
            name="from"
            inputRef={this.inputRef}
            onChange={this.handleInputChange}
            style={{ cursor: "pointer" }}
            label={(
              <>
                <span>From</span>
                {this.formInputText()}
              </>
            )}
            variant="outlined"
            placeholder={"Enter city/ Airport name"}
            data-test-id="inputCityFrom"
            value={this.state.inputSearch}
            onClick={this.handleClick}
            fullWidth
            InputProps={{
              className: "borderCustom",
              style: {
                borderRadius: "10px 0 0 10px",
                textAlign: "center",
                fontSize: "16px",
                paddingTop: "10px",
                cursor: "pointer",
                color:this.placehoderFunc(),
                background:this.propsFunction()
              },
              classes: {
                input: "landing-header-box",
              }
            }}
            InputLabelProps={{
              className: "fontFrom fontLabel",
              shrink: true,
              style: { transform: "translate(14px, 7px)", cursor: "pointer", color: this.propsColorFunction(), fontWeight: 700, fontSize: "16px", width: "100%" }
            }}
          />
          {(this.state.filteredItems.length > 0 && Boolean(this.state.anchorEl)) &&
            <Box className="yscrollwindow fromDropdown" sx={{ width: { xs: "94%", sm: "94%", md: "95%", lg: "96%" } }} style={{ padding: "10px" }}>
              {this.state.filteredItems.map((itemObj: any, index: number) => (
                <Box data-test-id={`fromMenu${index}`} style={{ borderBottom: "1px solid rgba(226, 232, 240, 1)" }} key={index} onClick={() => this.handleSingleClose(itemObj)}>
                  <Box className="highlight-item" style={{ paddingTop: "10px", width: "100%", cursor: 'pointer' }}>
                    <Grid container spacing={2}
                      style={{
                        display: 'flex',
                        justifyContent: "space-between",
                        padding: "12px"
                      }}>
                      <Box
                        style={{ display: 'flex', gap: '10px' }}> <Grid item> <FlightTakeoffIcon style={{ color: "rgba(148, 163, 184, 1)" }} /> </Grid>
                        <Grid item >
                          <Typography style={{ color: "#000", paddingBottom: "12px", fontWeight: 600, fontSize: "12px", }} variant="body1">{itemObj.state}</Typography>
                          <Typography style={{ color: "rgba(100, 116, 139, 1)", fontSize: "12px", paddingBottom: "10px", fontWeight: 600 }} variant="body1">{itemObj.flight}</Typography>

                        </Grid></Box>

                      <Grid item>
                        <Typography style={{ color: "rgba(100, 116, 139, 1)", fontSize: "12px", fontWeight: 600 }}
                          variant="body1">{itemObj.stateCode}</Typography>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              ))}
            </Box>
          }
          <Box data-test-id="reverseFunct" onClick={this.reverseFunct} className="reversChangeIcon">
            <SyncAltIcon style={{ color: "rgb(23, 100, 160)", cursor: "pointer" }} />
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={3} className="inputCity" style={{ position: 'relative' }}>
          <TextField
            name="to"
            onBlur={() =>

              setTimeout(() => {
                this.handleClose()
              },
                300)}

            label={(
              <>
                <span>To</span>
                {this.toInputText()}
              </>
            )}
            onChange={this.handleInputChangeTo}
            value={this.state.inputSearchTo}
            data-test-id="inputCityTo"
            onClick={this.handleClickTo}
            variant="outlined"
            autoComplete="off"
            placeholder={"Enter city/ Airport name"}
            fullWidth
            InputProps={{
              classes: {
                input: "landing-header-box",
              },
              style: {
                borderRadius: "0",
                textAlign: "center",
                fontSize: "16px",
                paddingTop: "10px",
                cursor: "pointer",
                background:this.propsFunction(),
                color:this.placehoderFunc()
              }
            }}
            InputLabelProps={{
              className: "fontFrom fontLabel",
              shrink: true,
              style: { overflow: "hidden", height: "100%", transform: "translate(14px, 7px)", color: this.propsColorFunction(), fontWeight: 700, fontSize: "16px", width: "100%" }
            }}
          />
          {(this.state.filteredItemsTo.length > 0 && Boolean(this.state.anchorElTo)) && <Box sx={{
            width: {
              xs: "94%", sm: "94%", md: "95%",                                         
              lg: "96%"
            }
          }} className="yscrollwindow fromDropdown" style={{ padding: "10px" }}>
            {this.state.filteredItemsTo.map((item: any, index: number) => (
              <Box data-test-id={`toMenu${index}`} style={{ borderBottom: "1px solid rgba(226, 232, 240, 1)" }} key={index} onClick={() => this.handleCloseTo(item)}>
                <Box style={{ width: "100%", paddingTop: "10px" }} className="highlight-item">
                  <Grid container spacing={2} style={{ display: 'flex', justifyContent: "space-between", padding: "12px" }}>
                    <Box style={{ display: 'flex', gap: '10px' }}>
                      <Grid item>
                        <FlightTakeoffIcon style={{ color: "rgba(148, 163, 184, 1)" }} />
                      </Grid>
                      <Grid item>
                        <Typography style={{ color: "#000", fontSize: "12px", paddingBottom: "12px", fontWeight: 600 }} variant="body1">{item.state}</Typography>
                        <Typography style={{ color: "rgba(100, 116, 139, 1)", fontSize: "12px", paddingBottom: "10px", fontWeight: 600 }} variant="body1">{item.flight}</Typography>
                      </Grid>
                    </Box>
                    <Grid item>
                      <Typography style={{ color: "rgba(100, 116, 139, 1)", fontSize: "12px", fontWeight: 600 }} variant="body1">{item.stateCode}</Typography>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            ))}
          </Box>}
        </Grid>
        <Grid item xs={12} style={{ position: 'relative' }} sm={12} md={6} lg={2}>

          <TextField
            name="departureDate"
            label={(
              <>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                  <span>Departure Date</span>
                  <ExpandMoreIcon style={{ color: this.textToggleFunctColor()}} />

                </div>
                {this.state.startDate ? (
                  <div style={{
                    position: "absolute",
                    background:this.propsFunction(),
                    padding: "10px 0"
                  }}>
                    <span className="textcalenderday" style={{ fontWeight: 700, fontSize: "32px" }}>{this.formatDatePart(this.state.startDate, "day")}</span>
                    <span className="textcalendermonth" style={{ fontWeight: 500, fontSize: "24px" }}>{this.formatDatePart(this.state.startDate, "month")}</span><br />
                    <span className="textcalendername" style={{ fontWeight: 500, fontSize: "16px", display: "block", marginTop: "10px", color: this.textLastFunc() }}>{this.formatDatePart(this.state.startDate, "year")}</span>
                  </div>
                ) : ''}
              </>

            )}
            onClick={(event: React.MouseEvent<HTMLDivElement>) => this.togglePicker(event, 'startDate')}

            data-test-id="startDate"
            placeholder={this.state.startDate ? "" : "Select the departure date"}
            variant="outlined"
            fullWidth
            InputProps={{
              style: {
                borderRadius: "0",
                textAlign: "center",
                fontSize: "16px",
                cursor: "pointer",
                paddingBottom: "10px",
                background:this.propsFunction(),
                color:this.placehoderFunc()
              },
              classes:
              {
                input: "landing-header-box",
              }
            }}
            InputLabelProps={{
              className: "fontLabel",
              shrink: true,
              style: { transform: "translate(14px, 7px)", color:this.propsColorFunction(), fontWeight: 700, fontSize: "16px" }
            }}
          />

        </Grid>
        {this.calBoxCondition()}

        <Grid style={{ position: 'relative' }} item xs={12} sm={12} md={6} lg={4}>
          <TextField
            name="travellers & class"

            label={(
              <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                <span>Travellers & Class</span>
                <ExpandMoreIcon style={{ color: this.textToggleFunctColor() }} />
                {this.travelInputText()}
              </div>

            )}
            placeholder={"Select no.of travellers\n& Class."}
            variant="outlined"
            fullWidth
            value={""}
            data-test-id="handleClickClass"
            onClick={this.handleClickClass}
            style={{ width: "100%", overflow: "hidden" }}
            InputProps={{
              className: "borderCustom",
              classes:
              {
                input: "landing-header-box",
              },
              style: {
                borderRadius: "0 10px 10px 0",
                textAlign: "center",
                cursor: "pointer",
                fontSize: "16px",
                paddingBottom: "10px",
                background:this.propsFunction(),
                color:this.placehoderFunc()
              }
            }}
            InputLabelProps={{
              className: "fontLabel",
              shrink: true,
              style: { transform: "translate(14px, 7px)", color: this.propsColorFunction(), cursor: "pointer", fontWeight: 700, fontSize: "16px" }
            }}
          />
          <Menu
            marginThreshold={72}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left'
            }}
            data-test-id="handleCloseClass"
            className="menuFrom"
            id="simple-menu"
            anchorEl={this.state.anchorElClass}
            PaperProps={{
              style: {
                width: 600,
                borderRadius: "10px"
              }
            }}
            open={Boolean(this.state.anchorElClass)}
            onClose={this.handleCloseClass}
            keepMounted

          >

            {this.travellerBoxUi()}
          </Menu>
        </Grid>
      </>
    )
  }
  travellerBoxUi = () => {
    return (
      <>
        <Box style={{ padding: "10px" }}>
          <Box className="classTravelHead">
            <Typography style={{ fontSize: "12px", color: "#000", fontWeight: 700 }} variant="body1">{this.state.countAdult} {this.state.countAdult <= 1 ? "Adult" : "Adults"} , {this.state.countChild} {this.state.countChild <= 1 ? "Child" : "Children"}, {this.state.countInfant}  {this.state.countInfant <= 1 ? "Infant" : "Infants"}</Typography>
            <Typography style={{ fontSize: "12px", color: "rgba(100, 116, 139, 1)", }} variant="body1">{this.state.TravelStatus}</Typography>
          </Box>
          <Box style={{ width: "100%", borderBottom: "1px solid rgba(226, 232, 240, 1)", marginTop: "10px" }}></Box>
          <Box style={{ width: "100%", }}>
            <Grid container spacing={2}>
              <Grid item xs={6} sm={4}>
                <Box sx={{ padding: { xs: "10px", sm: "10px", md: "19px 10px 0px 10px" }, textAlign: "center" }}>
                  <Typography style={{ fontSize: "24px", color: "#000", fontWeight: 700 }} variant="body1">  {this.state.countAdult} {this.state.countAdult <= 1 ? "Adult" : "Adults"}</Typography>
                  <Typography style={{ fontSize: "12px", color: "rgba(100, 116, 139, 1)", padding: "10px 0 20px 0" }} variant="body1">(Aged 18+years) </Typography>
                  <Box sx={{ padding: { xs: "6px 12px", sm: "6px 16px", md: "6px 20px" } }} className="countDivBox">
                    <button className="borderNoneCount" data-test-id="decreaseCount" onClick={this.decreaseCount}><RemoveIcon /></button>
                    <div className="countShowText">{this.state.countAdult}</div>
                    <button className="borderNoneCount" data-test-id="increaseCount" onClick={this.increaseCount}><AddIcon /></button>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={6} sm={4}>
                <Box sx={{ textAlign: "center", padding: { xs: "10px", sm: "10px", md: "19px 10px 0px 10px" } }}>
                  <Typography style={{ fontSize: "24px", color: "#000", fontWeight: 700 }} variant="body1">  {this.state.countChild} {this.state.countChild <= 1 ? "Child" : "Children"}</Typography>
                  <Typography style={{ fontSize: "12px", color: "rgba(100, 116, 139, 1)", padding: "10px 0 20px 0" }} variant="body1">(Aged 2-12 years)  </Typography>
                  <Box sx={{ padding: { xs: "6px 12px", sm: "6px 16px", md: "6px 20px" } }} className="countDivBox">
                    <button className="borderNoneCount" data-test-id="decreaseCountChild" onClick={this.decreaseCountChild}><RemoveIcon /></button>
                    <div className="countShowText">{this.state.countChild}</div>
                    <button className="borderNoneCount" data-test-id="increaseCountChild" onClick={this.increaseCountChild}><AddIcon /></button>

                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Box sx={{ textAlign: "center", padding: { xs: "10px", sm: "10px", md: "19px 10px 0px 10px" } }}>
                  <Typography style={{ fontSize: "24px", color: "#000", fontWeight: 700 }} variant="body1">  {this.state.countInfant} {this.state.countInfant <= 1 ? "Infant" : "Infants"}</Typography>
                  <Typography style={{ fontSize: "12px", color: "rgba(100, 116, 139, 1)", padding: "10px 0 20px 0" }} variant="body1">(Under 2 years)  </Typography>
                  <Box sx={{ padding: { xs: "6px 12px", sm: "6px 16px", md: "6px 20px" } }} className="countDivBox">
                    <button className="borderNoneCount" data-test-id="decreaseCountChildInfant" onClick={this.decreaseCountChildInfant}><RemoveIcon /></button>
                    <div className="countShowText">{this.state.countInfant}</div>
                    <button className="borderNoneCount" data-test-id="increaseCountChildInfant" onClick={this.increaseCountChildInfant}><AddIcon /></button>

                  </Box>
                </Box>
              </Grid>

              {
                this.state.error &&
                <Grid item sm={12}>
                  <Box className="ErrorMsg" sx={webStylePackages.errorMsgforCount}>
                    <span className="imgError" style={{ width: "5%", marginRight: "5px" }}>
                      <React.Suspense fallback={<CircularProgress />}>
                        <img src={errorIcon} />
                      </React.Suspense>
                    </span> <span style={{ width: "95%" }}>
                      If you want to add more than 9 travellers please contact email <span style={{ textDecoration: "underline" }}>info@doolwa.com</span>
                    </span>
                  </Box>
                </Grid>
              }

              <Box sx={{
                width: "100%", textAlign: "center", padding: "0px 0 0px 0",
              }}>
                <Typography style={{ fontSize: "24px", color: "#000", fontWeight: 700 }} variant="body1"> Travel Class</Typography>
              </Box>
              <Grid item sm={12}>
                {this.allButtonList()}
              </Grid>

              <Box className="buttonBoxapply" style={{ padding: "10px", margin: "0px 10px" }}>
                <Button style={{ color: "rgba(23, 100, 160, 1)", fontSize: "16px", textTransform: "capitalize", fontWeight: 700 }} data-test-id="handleCancelClassTravel" onClick={this.handleCancelTravel}>Cancel</Button>&nbsp;&nbsp;&nbsp;&nbsp;
                <Button style={{ background: "rgba(23, 100, 160, 1)", color: "#fff", fontSize: "16px", textTransform: "capitalize", fontWeight: 700 }} data-test-id="handleDone" onClick={this.handleDone}>&nbsp;Done&nbsp;</Button>
              </Box>
            </Grid>
          </Box>
        </Box>
      </>
    )
  }

  travelInputText = () => {
    return (
      <>
        {this.state.fullTravelObj.countAdult &&
          this.state.fullTravelObj.TravelStatus ? (
          <div style={{
            position: "absolute", width: "134%",
            background:this.propsFunction(), left: "0px", top: "21px",
            padding: "5px 0"
          }}>
            <span className="textcalenderday" style={{ fontWeight: 700, fontSize: "32px" }} data-test-id="formatDay">{this.state.fullTravelObj.countAdult + this.state.fullTravelObj.countChild + this.state.fullTravelObj.countInfant} </span>
            <span className="textcalendermonth" style={{ fontWeight: 500, fontSize: "24px" }} data-test-id="formatMonth">{this.state.fullTravelObj.countAdult + this.state.fullTravelObj.countChild + this.state.fullTravelObj.countInfant === 1 ? "Traveller" : "Travellers"}</span><br />
            <span className="textcalendername" style={{ fontWeight: 500, fontSize: "16px", display: "block", marginTop: "10px", color: this.textLastFunc() }} data-test-id="formatYear">{this.state.fullTravelObj.TravelStatus}</span>
          </div>
        ) : ''}
      </>
    )
  }
  textToggleFunctColor=()=>{
    return this.props.package!="AllPackage"?" rgba(23, 100, 160, 1)":"#fff"
  }
  textLastFunc=()=>{
    return this.props.package!="AllPackage"?"rgba(100, 116, 139, 1)":"#fff"
  }
  placehoderFunc=()=>{
    return this.props.package!="AllPackage"?"inherit":"#fff"
  }
  propsFunction=()=>{
    return this.props.package!="AllPackage"?"#fff":"rgb(19, 81, 130)"
  }
  propsColorFunction=()=>{
    return this.props.package!="AllPackage"?"#000":"#fff"
  }
  allButtonList = () => {
    return (
      <Box style={{ padding: "12px" }}>
        <Grid container spacing={1}>
          <Grid xs={12}>
            <div className="buttonContainer">
              <button
                className={`buttonEconomy ${this.state.TravelStatus === 'PremiumEconomy' ? 'active' : ''}`}
                data-test-id="handleClickStatus"
                onClick={() => this.handleClickStatus('PremiumEconomy')}
              >
                Premium /
                Economy
              </button>
              <button
                className={`buttonEconomy ${this.state.TravelStatus === 'PremiumFirst' ? 'active' : ''}`}
                data-test-id="handleClickStatus2"
                onClick={() => this.handleClickStatus('PremiumFirst')}
              >
                Premium /
                First
              </button>
              <button
                className={`buttonEconomy ${this.state.TravelStatus === 'First' ? 'active' : ''}`}
                data-test-id="handleClickStatus3"
                onClick={() => this.handleClickStatus('First')}
              >
                First
              </button>
              <button
                className={`buttonEconomy ${this.state.TravelStatus === 'Business' ? 'active' : ''}`}
                data-test-id="handleClickStatus4"
                onClick={() => this.handleClickStatus('Business')}
              >
                Business
              </button>
              <button
                className={`buttonEconomy ${this.state.TravelStatus === 'Economy' ? 'active' : ''}`}
                data-test-id="handleClickStatus5"
                onClick={() => this.handleClickStatus('Economy')}
              >
                Economy
              </button>
            </div>

          </Grid>
        </Grid>




      </Box>
    )
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
       <Loader loading={this.state.loading} />
        <div style={{ marginTop: "-10px", marginBottom:"20px" }} ref={this.scrollRef}>
        <PackageSlider data-test-id="oceanbackground" oceanbackground={oceanbackground} packageType={this.props.package} />
            <HeaderMenuBlock navigation={this.props.navigation} id={""} />
            <Box className={this.props.package === "AllPackage"?"boxpaddingPackagList":"boxHeaderpadding"} style={{ position: "relative", display: "flex", flexDirection: "column", alignItems: "center" }}>
            {
                  this.props.package!= "AllPackage" && 
               
              <Box className="flaticonBox" style={{
                display: "flex",
                backgroundColor: '#FFFFFF',
                position: "absolute",
                width: "35%",
                borderRadius: "15px",
                zIndex: 9992,
                justifyContent: "space-evenly",
                boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.25)",
              }}>
                
                <Box style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '130px',
                  cursor:"pointer"
                }}
                data-test-id="clickNavigate" onClick={()=>this.handleNavigate("Home")}
                >
                  <Box className="flitIcon1">
                    <React.Suspense fallback={<CircularProgress />}>
                      <img src={airoplane} />
                    </React.Suspense>
                  </Box>
                  <Typography  style={{ fontFamily: 'Inter, sans-serif', fontWeight: 700, color: 'rgba(23, 100, 160, 1)',cursor:"pointer" }}>
                    Flight Booking
                  </Typography>
                </Box>

                <Box style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '130px'
                }}>
                  <Box className="flitIcon">
                    <React.Suspense fallback={<CircularProgress />}>
                      <img src={umbrella} />
                    </React.Suspense>
                  </Box>
                  <Typography onClick={()=>this.handleNavigate("Cfitinerarymanagement")} style={{cursor:"pointer", fontFamily: 'Inter, sans-serif', fontWeight: 700, color: 'rgba(23, 100, 160, 1)' }}>
                    Packages
                  </Typography>
                </Box>
              </Box>
 }
              <Box className="boxSizeSearchDiv" style={{
                backgroundColor: this.props.package === "AllPackage" ?"none":"#fff",
                borderRadius: "10px",
                width: "83%",
                height: "50%",
                zIndex: 4,
                marginTop: 50,

                boxShadow: this.props.package === "AllPackage" ?"none":"0px 0px 10px rgba(0, 0, 0, 0.25)",
              }}>
                <Box  style={{ 
  margin: this.props.package === "AllPackage" ? "0" : "110px 20px 0 20px" 
}}>
                  <Grid container spacing={0} className="searchFrom searchInput" style={{ position: "relative" }}>
                  {
(this.state.errorMessage) &&
        <span className="imgErrorBox"> 
        {this.state.errorMessage}
       </span>
}
                    {this.formPackageUi()}
                    <Grid item xs={12}>
                      <Box style={{ display: "flex", justifyContent: "center", margin: "20px 0" }}>
                        <Button
                          data-test-id="search-btn"
                          className="buttonSubmitSearch"
                          type="submit"
                          variant="contained"
                          color="primary"
                          onClick={this.packageSearchSubmit}
                        >
                          Search Packages
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Box>
        </div>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStylePackages: any = {
  backgroundImage2: {
    background:"rgba(1, 59, 104, 1)"
  },
  backgroundImage: {
    backgroundImage: `url(${oceanbackground})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    width: "100%",
    opacity: 2.5,
  },
  errorMsgforCount: {
    margin: "0px 10px",
    borderRadius: "5px",
    fontSize: "12px",
    borderLeft: "6px #c10f0ff0 solid",
    background: " #ed7e7e3d",
    fontFamily: "Inter, sans-serif",
    color: "#c12121",
    display: "flex",
    lineHeight: "24px",
    padding: "20px 15px",
    alignItems: "center"
  },
}
// Customizable Area End
