Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.ACCOUNT_TYPE_EMAIL = "EmailAccount";
exports.ACCOUNT_TYPE_SOCIAL = "SocialAccount";
exports.ACCOUNT_TYPE_PHONE = "SmsAccount";

exports.contentTypeApiUpdateUser = "application/json";
exports.apiEndPointUpdateUser = "profile/profile";
exports.apiUpdateUserType = "PUT";

exports.urlGetValidations = "profile/validations";
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";

exports.contenttypeApiValidateMobileNo = "application/json";
exports.endPointApiValidateMobileNo = "profile/change_phone_validation";
exports.callTypeApiValidateMobileNo = "POST";

exports.endPointApiGetUserProfile = "profile/profile";
exports.contentTypeApiGetUserProfile = "application/json";
exports.methodTypeApiGetUserProfile = "GET";

// Customizable Area Start
exports.placeHolderEmail = "Email";
exports.labelHeader =
  "This is your profile, Here you can see and update your personal information.";
exports.labelFirstName = "First name";
exports.lastName = "Last name";
exports.labelArea = "Area";
exports.labelMobile = "Mobile";
exports.labelEmail = "Email";
exports.labelCurrentPassword = "Current password";
exports.labelNewPassword = "New Password";
exports.labelRePassword = "Re-Type Password";
exports.btnTextCancelPasswordChange = "Cancel";
exports.btnTextSaveChanges = "Save Changes";
exports.btnTextChangePassword = "Change Password";
exports.errorCountryCodeNotSelected = "Please select country code";
exports.errorMobileNoNotValid = "Phone number is not valid.";
exports.errorTitle = "Error";
exports.errorBothPasswordsNotSame = "Passwords must match.";
exports.errorCurrentNewPasswordMatch = "New password cannot match current password.";
exports.errorCurrentPasswordNotValid = "Current password not valid.";
exports.errorNewPasswordNotValid = "New password not valid.";
exports.errorReTypePasswordNotValid = "Re-type password not valid.";
exports.hintCountryCode = "Select Country";
exports.errorBlankField = "can't be blank";
exports.errorEmailNotValid = "Email not valid.";
exports.profile = "Profile";
exports.myProfile = "My Profile";
exports.loginDetails = "Login Details";

exports.editProfileEndpoint ="account_block/update_profile";
exports.editEmailchangeEndpoint = "account_block/send_otp_to_email"
exports.editPasswordChangeEndpoint = "account_block/change_password"
exports.enterOtpEndpoint = "account_block/verify_email_otp"
exports.getProfileApiEndpoint = "account_block/accounts/"
exports.apiEndPointGetCountry = "account/accounts/country_code_and_flag";
exports.PASSWORD_MISMATCH_ERROR = "New Password and Confirm Password do not match";
exports.Unexpected_error = "Unexpected Error occured"
exports.zipcodeEndPoint="bx_block_custom_form/locations/get_address?zip_code=";
exports.ProfileUpdate = "Profile details updated successfully"
exports.DeletAccountEndpoint =  "account_block/delete_account"
exports.DeleteMethodType = 'Delete'
exports.ImageError = "Please upload JPG or PNG upto 10 MB"
exports.editImageEndpoint = 'account_block/upload_image'
exports.ProfileUpdateError = "Couldn't update the profile details"
exports.ImageTypeError = 'Invalid image format. Please upload an image in JPG or PNG format.'
// Customizable Area End

