import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import changeMoment from "moment";
import React, { createRef,RefObject } from 'react';
interface ErrorResponse {
  errors: string[];
}

export interface Stop {
  0: number;
  1: string;
}

export interface SegmentKey {
  Key: string;
}

export interface AirSegmentRef {
  Key: string;
  FlightNumber: string;
  Origin: string;
  Destination: string;
  DepartureTime: string;
  ArrivalTime: string;
  FlightTime: string;
  Distance: string;
  ETicketability: string;
  Equipment: string;
  FlightDetailsRef: {
      Key: string;
  };
}

export interface BookingInfo {
  BookingCode: string;
  BookingCount: string;
  CabinClass: string;
  FareInfoRef: string;
  SegmentRef: string;
}

export interface TaxInfo {
  Category: string;
  Amount: string;
  Key: string;
}

export interface BaggageAllowance {
  MaxWeight: {
      Value: string;
      Unit: string;
  };
}

export interface FareSurcharge {
  Key: string;
  Type: string;
  Amount: string;
}

export interface FareInfo {
  Key: string;
  FareBasis: string;
  PassengerTypeCode: string;
  Origin: string;
  Destination: string;
  EffectiveDate: string;
  DepartureDate: string;
  Amount: string;
  NegotiatedFare: string;
  NotValidBefore: string;
  NotValidAfter: string;
  FareSurcharge: FareSurcharge;
  BaggageAllowance: BaggageAllowance;
  FareRuleKey: string;
  Brand: {
      Key: string;
      BrandID: string;
      UpSellBrandID: string;
      BrandTier: string;
      UpsellBrand: {
          FareBasis: string;
          FareInfoRef: string;
      };
  };
}

export interface AirPricingInfo {
  Key: string;
  TotalPrice: string;
  BasePrice: string;
  ApproximateTotalPrice: string;
  ApproximateBasePrice: string;
  Taxes: string;
  ApproximateTaxes: string;
  LatestTicketingTime: string;
  PricingMethod: string;
  Refundable: string;
  ETicketability: string;
  PlatingCarrier: string;
  ProviderCode: string;
  Cat35Indicator: string;
  FareInfoRef: {
      Key: string;
  }[];
  BookingInfo: BookingInfo[];
  TaxInfo: TaxInfo[];
  FareCalc: string;
  PassengerType: {
      Code: string;
  };
  ChangePenalty: {
      PenaltyApplies: string;
      Amount: string;
  };
  CancelPenalty: {
      PenaltyApplies: string;
      Amount: string;
  };
  FareInfoList: FareInfo[];
  Brand: {
      Key: string;
      BrandID: string;
      Name: string;
      BrandedDetailsAvailable: string;
      Carrier: string;
      Title: string[];
      Text: string[];
      ImageLocation: string[];
  }[];
}

export interface AirPricingSolution {
  air_pricing_key: string;
  total_price: string;
  base_price: string;
  taxes: string;
  connection: number;
  travel_time: string;
  currency_type: string;
  adult_per_price: string;
  segment_ref_key: SegmentKey[];
  air_segment_refs: AirSegmentRef[];
  air_pricing_info: AirPricingInfo;
}

export interface Flight {
  air_segment_keys: SegmentKey[];
  adult_per_price: string;
  connection: number;
  air_pricing_solution: AirPricingSolution[];
}

export interface PriceRange {
  min: number;
  max: number;
}

export interface AirData {
  stops: Stop[];
  price_range: PriceRange;
  flight_list: Flight[];
  error: ErrorSec;
  errors:string;
}
export interface ErrorSec{
  Description:string
}
interface SearchText{
  id:string,
    state:string,
    country:string,
    flight:string,
    stateCode:string
}
type DateRange = {
  selection: {
    startDate: Date;
    endDate: Date;
};
};
interface ResOffer {
  data: Offer[];
}
interface Offer {
  "id":string,
  "type": string,
  "attributes": {
      "id": number,
      "title": string,
      "tagline1":string,
      "tagline2": string,
      "offer_code":string,
      "image":string
   }
}
interface ResBenifit{
  data:BeniFitUser[]
}
interface BeniFitUser{
  "id": string,
  "type": string,
  "attributes": {
      "id": number,
      "title": string,
      "description": string,
      "image": string
  }
}
interface ResWhereToGo{
  data:WhereTo[],
  errors:WhereErrors[]
}
interface WhereErrors{
  "token":string
}
interface WhereTo{
  "id": string,
  "type": string,
  "attributes": {
      "id": number,
      "title": string,
      "destination": string,
      "root":string,
      "image": string
  }
}
interface ResPop{
  data:Desti[]
}
interface Desti{
  "id": string,
  "type": string,
  "attributes": {
    "id": number,
    "title":string,
    "name_of_destination":string,
    "created_at": string,
    "image": string,
    iata_object:{
      "Code": string,
      "Name": string,
      "CityCode":string,
      "CountryCode":string
  },
}
}
export interface ListCityDummyType{
  "id":string,"country":string, "state":string, "flight":string, "stateCode":string
}
export interface MulticityArryType{
   from:  ListCityDummyType| null , to: ListCityDummyType | null, departureDate: Date | null
}
export interface MultiCity {
  airportFrom: SearchText
  airportTo: SearchText
  departureDate: Date |null;
}
interface ResBlog{
  data:Blog[]
}
interface Blog{
  "id": string,
  "type": string,
  "attributes": {
    "id": number,
    "name":string,
    "description":string,
    "created_at": string,
    "body":string,
    "image": string
}
}
interface AirportSearch {
  id:string;
  Code: string;
  Name: string;
  CityCode: string;
  CountryCode: string;
}

interface AirportResponse {
  airport_list: AirportSearch[] | null;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  errorMessage:string;
  inputCityTo:string;
  inputCityFrom:string;
  errorResSearch:string;
  offerForYou:Offer[];
  listCityDummy:ListCityDummyType[];
  listCityDummyM:ListCityDummyType[];
  popularDestination:Desti[];
  blogs:Blog[];
  BenefitUser:BeniFitUser[];
  whereToGo:WhereTo[];
  authToken:string | null;
  isOpen: boolean;
  startDate: Date | null ;
  endDate: Date | null;
  endDate1:Date | null;
  activePicker:string;
  selectedOption:string;
  searchText:{
    state:string,
    country:string,
    flight:string,
    stateCode:string
  };
  toText:{
    state:string,
    country:string,
    flight:string,
    stateCode:string
  }
  filteredItems: SearchText[];
  filteredItemsM:SearchText[];
  filteredItemsTo:SearchText[];
  anchorEl:Element | null;
  anchorElTo:Element | null;
  searchTextClass:{
    classNumber:string;
    title:string;
    travelClass:string;
  }
  anchorElClass:Element | null;
  countAdult:number;
  countChild:number;
  countInfant:number;
  TravelStatus:string;
  fullTravelObj:{
    countAdult:number,
    countChild:number,
    countInfant:number,
    TravelStatus:string
  }
  error:string;
  multicityTrips:MultiCity[];
  searchField:string;
  currentIndex: number;
  loading:boolean;
  setAnchorAi:Element | null;
  inputSearch: string
  inputSearchTo: string
  anchorElNationalClass:any;
      selectedNationality: any,
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class LandingPageController extends BlockComponent<
  Props,
  S,
  SS
> {
   // Customizable Area Start
    wrapperRef: RefObject<HTMLDivElement>
   calendarDivRef = createRef<HTMLDivElement>();
   scrollRef: RefObject<HTMLDivElement>;
   blogApiCallId ="";
   destinationApiCallId="";
   whereToGoApiCallId="";
   benefitsApiCallId="";
   offerForYouApiCallId="";
   oneWaySearchApiCallId="";
   airportSearchApiCallId="";
   airportSearchToApiCallId="";
   roundTripSearchApiCallId : string = "";
   inputRef:React.RefObject<HTMLDivElement>
   // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.scrollRef = React.createRef();
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.wrapperRef = React.createRef();
    this.inputRef =  React.createRef();
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
    this.state = {
      anchorElNationalClass:null,
      selectedNationality:"",
      inputSearch: "",
      inputSearchTo:"",
      setAnchorAi:null,
      filteredItemsM:[],
      errorMessage:'',
      loading:false,
      inputCityTo:"",
      inputCityFrom:"",
      errorResSearch:'',
      listCityDummy:[],
      listCityDummyM:[{"id":"1","country":"india", "state":"New Delhi", "flight":" Indira Gandhi International Airport", "stateCode":"DEL"}
      , {"id":"2","country":"india", "state":"Mumbai", "flight":"Shivaji International Airport", "stateCode":"BOM"},
      {"id":"3","country":"Bangkok", "state":"Bangkok", "flight":" Indira Gandhi International Airport", "stateCode":"BKK"},
      {"id":"4","country":"india", "state":"New Delhi", "flight":" Indira Gandhi International Airport", "stateCode":"DEL"}
      , {"id":"2","country":"india", "state":"Mumbai", "flight":"Shivaji International Airport", "stateCode":"BOM"},
      {"id":"3","country":"Bangkok", "state":"Bangkok", "flight":" Indira Gandhi International Airport", "stateCode":"BKK"}],
      multicityTrips: [{
        airportFrom: {
          id: '',
          state: "",
          country: "",
          flight: "",
          stateCode: ""
        }, airportTo: {
          state: "",
          country: "",
          id: '',
          flight: "",
          stateCode: ""
        }, departureDate: null
      }],
      searchText:{
        state:"",
        country:"",
        flight:"",
        stateCode:""
      },
      searchField: "",
      currentIndex: 0,
      toText:{
        state:"",
        country:"",
        flight:"",
        stateCode:""
      },
      anchorEl:null,
      anchorElTo:null,
      filteredItems:[],
      filteredItemsTo:[],
      selectedOption:"One Way",
      activePicker:"",
      offerForYou:[],
      popularDestination:[],
      blogs:[],
      BenefitUser:[],
      whereToGo:[],
      authToken:"",
      isOpen: false,
      startDate: null,
      endDate: null,
      endDate1:null,
      searchTextClass:{
        classNumber:"",
        title:"",
        travelClass:""
      },
      anchorElClass:null,
      countAdult:1,
      countChild:0,
      countInfant:0,
      TravelStatus:"PremiumEconomy",
      fullTravelObj:{
        countChild:0,
        countAdult:1,
        countInfant:0,
      TravelStatus:"PremiumEconomy"
      },
      error:""
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  // Customizable Area Start
  receive = async (from: string, message: Message) => {

    runEngine.debugLog("Message Received", message);
    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const promoCodeData = message.getData(
        getName(MessageEnum.itaCodeOfferInfo)
      );
      this.handleDataUpdateSearch(promoCodeData.iata_object)
      }
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.responseRecive(apiRequestCallId, responseJson)
     
 if (apiRequestCallId != null) {
  switch (true) {
    case apiRequestCallId === this.blogApiCallId &&  this.blogApiCallId != null:
      this.handleGetAllBlogList(responseJson);
      break;
      case apiRequestCallId === this.destinationApiCallId &&  this.destinationApiCallId != null:
        this.handleGetAllDestinationList(responseJson);
        break;
        case apiRequestCallId === this.whereToGoApiCallId &&  this.whereToGoApiCallId != null:
          this.handleGetAllWhereToGoList(responseJson);
          break;
          case apiRequestCallId === this.benefitsApiCallId &&  this.benefitsApiCallId != null:
          this.handleGtAllBenefitsList(responseJson);
          break;
          case apiRequestCallId === this.offerForYouApiCallId &&  this.offerForYouApiCallId != null:
            this.handleGtAllOffersList(responseJson);
            break;
          case apiRequestCallId === this.oneWaySearchApiCallId && this.oneWaySearchApiCallId!=null:
            this.handleGetOneWayRes(responseJson)
            break;
            case apiRequestCallId === this.airportSearchApiCallId && this.airportSearchApiCallId!=null:
              this.handleAirportSearchRes(responseJson)
              break;
            case apiRequestCallId === this.airportSearchToApiCallId && this.airportSearchToApiCallId!=null:
              this.handleAirportSearchToRes(responseJson)
            break
    default:
}
}
    }
  };
 // Customizable Area End


  // Customizable Area Start
  async componentDidMount() {
 
    this.tokenGetAccessFunct()
    this.blogFunct()
    this.destinationFunct()
    this.benefitsFunct()
    this.offersForYouFunct()
    if (this.scrollRef.current) {
      window.scrollTo(0, 0);
    }
  }
  
 
  tokenGetAccessFunct=()=>{
    const token = localStorage.getItem('authToken');
    const userDataString: string | null = localStorage.getItem("userData");
    const userData: string | null = JSON.parse(userDataString as string);
    if(token && userData!=null){
      this.setState({authToken:token})
      this.whereToGoFunct(token)
    }else{
      this.setState({authToken:""})
      localStorage.removeItem("authToken")
    }
  }
  togglePicker = (event:React.MouseEvent<HTMLDivElement>,picker:string) => {
      this.setState({
        isOpen: true,
        activePicker: picker,
        setAnchorAi: event.currentTarget
      });
      if(picker==="endDate"){
        this.setState({selectedOption:"Roundtrip"})
      }
  };
  handleCloseCustomAi=()=>{

    this.setState({
      setAnchorAi: null,
      isOpen: false,
    });
  }

  handleApply = () => {
    this.setState({ isOpen: false,activePicker: "", });
  };
  handleMulticityApply = ()=>{
      const citiesVal = [...this.state.multicityTrips];
      if (citiesVal[this.state.currentIndex]) {
        citiesVal[this.state.currentIndex].departureDate = this.state.startDate;
      }
      this.setState({ isOpen: false, activePicker: "",currentIndex:0 });
  
  }
  handleCancel = () => {
     this.setState({ startDate: null, activePicker: "", endDate: null, endDate1:null, isOpen: false,currentIndex:0 });
  };
 handleDateChange = (ranges: DateRange) => {
  const { startDate, endDate } = ranges.selection;
  this.setState({
    startDate,
    endDate,
    endDate1:endDate
  });
};

handleDateChange1 = (ranges: DateRange) => {
  if (this.state.activePicker === 'startDate') {
    let determinedDate = ranges.selection.startDate;
  
    this.setState({
      startDate: determinedDate,
      endDate: determinedDate,
      endDate1: null,
    });
  } 
};
   
  formatDate = (date:Date) => {
    if (date) {
      
      const dayaa = date.getDate();
      const month = date.toLocaleString('default', { month: 'short' });
      const year = date.getFullYear().toString().slice(-2);
      const weekday = date.toLocaleString('default', { weekday: 'long' });
      return `${dayaa} ${month}’${year} ${weekday}`;
    } 
  };
  formatDatePart = (value:Date, part:string) => {
    let dateValue = this.formatDate(value)
    if (dateValue) {
      const parts = dateValue.split(' ');
      switch (part) {
        case "day":
          return parts[0];
        case "month":
          return parts[1];
        case "year":
          return parts[2];
      }
    } 
  };
  blogFunct = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.blogApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.blogEndPoint
    );

    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  handleGetAllBlogList=(responseJson:ResBlog)=>{
  if(responseJson.data.length>0){
    this.setState({blogs:responseJson.data})
  }else{
    this.setState({blogs:[]})
  }
  }
  destinationFunct = () => {
    this.setState({loading:true})
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.destinationApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.destinationEndPoint
    );

    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  handleGetAllDestinationList=(responseJson:ResPop)=>{
   
    if(responseJson.data.length>0){
      this.setState({popularDestination:responseJson.data})
    }else{
      this.setState({popularDestination:[]})
    }
    this.setState({loading:false})
    }
    whereToGoFunct = (tokenAuth:string) => {
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.whereToGoApiCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.whereToGoEndPoint+ `?token=${tokenAuth}`
      );
  
      const header = {
        "Content-Type": configJSON.validationApiContentType,
      };
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.validationApiMethodType
      );
  
      runEngine.sendMessage(requestMessage.id, requestMessage);
    };
    handleGetAllWhereToGoList=(responseJson:ResWhereToGo)=>{
      if(!responseJson.errors){
       this.setState({whereToGo:responseJson.data})
      }
      };
      benefitsFunct = () => {
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
        this.benefitsApiCallId = requestMessage.messageId;
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.benefitsUserEndPoint
        );
    
        const header = {
          "Content-Type": configJSON.validationApiContentType,
        };
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.validationApiMethodType
        );
    
        runEngine.sendMessage(requestMessage.id, requestMessage);
      };
    handleGtAllBenefitsList=(responseJson:ResBenifit)=>{
      if(responseJson.data.length>0){
        this.setState({BenefitUser:responseJson.data})
      }else{
        this.setState({BenefitUser:[]})
      }
      };
      offersForYouFunct = () => {
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
        this.offerForYouApiCallId = requestMessage.messageId;
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.offersForYouEndPoint
        );
    
        const header = {
          "Content-Type": configJSON.validationApiContentType,
        };
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.validationApiMethodType
        );
    
        runEngine.sendMessage(requestMessage.id, requestMessage);
      };
      handleGtAllOffersList=(responseJson:ResOffer)=>{
        if(responseJson.data.length>0){
          this.setState({offerForYou:responseJson.data})
        }else{
          this.setState({offerForYou:[]})
        }
        };
         handleRadioClick = (value:string) => {
         this.setState({selectedOption:value})
         if(value === "Multicity"){
          this.setState({  multicityTrips: [{
            airportFrom: {
              id: '',
              state: "",
              country: "",
              flight: "",
              stateCode: ""
            }, airportTo: {
              state: "",
              country: "",
              id: '',
              flight: "",
              stateCode: ""
            }, departureDate: null
          },{
            airportFrom: {
              id: '',
              state: "",
              country: "",
              flight: "",
              stateCode: ""
            }, airportTo: {
              state: "",
              country: "",
              id: '',
              flight: "",
              stateCode: ""
            }, departureDate: null
          }]});
          this.setState({startDate:null, endDate:null, endDate1:null})
         }else if(value ==="One Way" || value ==="Roundtrip") {
          this.setState({
            selectedOption: value,startDate:null, endDate:null, endDate1:null,
            multicityTrips:[{
              airportFrom: {
                id: '',
                state: "",
                country: "",
                flight: "",
                stateCode: ""
              }, airportTo: {
                state: "",
                country: "",
                id: '',
                flight: "",
                stateCode: ""
              }, departureDate: null
            }]
          });
        }
      };
      iconClose=()=>{
        this.setState({endDate:this.state.startDate,endDate1:null,})
      }

      handleClick = (event:React.MouseEvent<HTMLElement>, index = 0) => {
        this.setState({ currentIndex: index,searchText:{
          state:"",
          country:"",
          flight:"",
          stateCode:""
        }, });
      };

    
      handleClose = () => {
        this.setState({ anchorEl: null,anchorElTo:null, currentIndex: 0, searchField: "", filteredItems: [], filteredItemsTo:[] });
      };

      handleSingleClose = (option: SearchText) => {
        this.setState({ anchorEl: null, currentIndex: 0, searchText: option, inputSearch:""});
      }
     
      handleInputChange = (event: React.ChangeEvent<HTMLInputElement>,index=0) => {
        this.setState({ anchorEl: event.currentTarget, currentIndex: index });
        const { value } = event.target;
        this.setState({
          searchText: { ...this.state.searchText, state: value },inputSearch: value
        }, () => {
          if (value !== "") {
            this.airportSearch(value);
          } else {
            this.setState({
              filteredItems: [],
              searchText: {
                state: "",
                country: "",
                stateCode: "",
                flight: ""
              }
            });
          }
        });
       
      };

      handleClickTo = (event:React.MouseEvent<HTMLElement>, index = 0) => {
        this.setState({ inputSearchTo:'', toText: {
          state: "",
          country: "",
          stateCode: "",
          flight: ""
        }});
      };
    
      handleCloseTo = (selectedItem:SearchText) => {
        this.setState({ anchorElTo: null, currentIndex: 0, toText: selectedItem,inputSearchTo:"" });
      };
  handleMulticityCloseTo = (selectedItem: SearchText) => {
    const citiesVal = [...this.state.multicityTrips];
    if (citiesVal[this.state.currentIndex]) {
      citiesVal[this.state.currentIndex].airportTo = selectedItem;
    }

    this.setState({
      multicityTrips: citiesVal,
      anchorElTo: null,
      currentIndex: 0,
      toText: selectedItem,
      inputSearchTo:''
    });

  }
  handleFromDropdown = (selectedItem:SearchText)=>{
    const citiesVal = [...this.state.multicityTrips];
    if (citiesVal[this.state.currentIndex]) {
      citiesVal[this.state.currentIndex].airportFrom = selectedItem;
    }

    this.setState({
      multicityTrips: citiesVal,
      anchorEl: null,
      currentIndex: 0,
      searchText: selectedItem,
      inputSearch:''
    });
  }
  handleMulticityClickTo = (event: React.MouseEvent<HTMLElement>, index: number) => {
    this.setState({
      currentIndex: index,
      filteredItemsTo: [],
      anchorElTo: event?.currentTarget, inputSearchTo: "",toText:{state:'',stateCode:'',flight:'',country:''}
    });
    const citiesVal = [...this.state.multicityTrips];
    if (citiesVal[index].airportTo.state) {
      citiesVal[index].airportTo = {
        id: '',
        state: "",
        country: "",
        stateCode: "",
        flight: ""
      };
    }
    this.setState({ multicityTrips: citiesVal })
  }
  handleFromCity = (event: React.MouseEvent<HTMLElement>, index: number)=>{
    this.setState({
      currentIndex: index,
      filteredItems: [],
      anchorEl: event?.currentTarget, inputSearch: "",searchText:{state:'',stateCode:'',flight:'',country:''}
    });
    const citiesVal = [...this.state.multicityTrips];
    if (citiesVal[index].airportFrom.state) {
      citiesVal[index].airportFrom = {
        id: '',
        state: "",
        country: "",
        stateCode: "",
        flight: ""
      };
    }
    this.setState({ multicityTrips: citiesVal })
  }
  handleMultiCityInputChangeTo = (event:React.ChangeEvent<HTMLInputElement>,index : number)=>{
    const { value } = event.target;
    this.setState({
      anchorElTo: event.currentTarget, currentIndex: index,
      toText: { ...this.state.toText, state: value },inputSearchTo:value
    }, () => {
      if (value !== "") {
        this.airportSearchTo(value);
      } else {
        this.setState({
          filteredItemsTo: [],
          toText: {
            state: "",
            country: "",
            stateCode: "",
            flight: ""
          }
        });
      }
    });
  }  
  handleFromInputChange = (event: React.ChangeEvent<HTMLInputElement>,index: number)=>{
    const { value } = event.target;
    this.setState({
      anchorEl: event.currentTarget, currentIndex: index,
      searchText: { ...this.state.searchText, state: value },inputSearch:value
    }, () => {
      if (value !== "") {
        this.airportSearch(value);
      } else {
        this.setState({
          filteredItems: [],
          searchText: {
            state: "",
            country: "",
            stateCode: "",
            flight: ""
          }
        });
      }
    });  
  }  
      handleInputChangeTo = (event:React.ChangeEvent<HTMLInputElement>,index = 0) => {
        const { value } = event.target;
        this.setState({
          anchorElTo: event.currentTarget, currentIndex: index,
          toText: { ...this.state.toText, state: value },inputSearchTo:value
        }, () => {
          if (value !== "") {
            this.airportSearchTo(value);
          } else {
            this.setState({
              filteredItemsTo: [],
              toText: {
                state: "",
                country: "",
                stateCode: "",
                flight: ""
              }
            });
          }
        });
       
     
      };
      reverseFunct=()=>{
        this.setState({toText:this.state.searchText, searchText:this.state.toText})
      }
      reverseFunctMultiCity = (index: number) => {
        let arrayData = [...this.state.multicityTrips];
      
        if (arrayData[index]) {
     
          const tempAirportFrom = arrayData[index].airportFrom;
          arrayData[index].airportFrom = arrayData[index].airportTo;
          arrayData[index].airportTo = tempAirportFrom;
        }
    
        this.setState({
          multicityTrips:arrayData
        })
      };

      handleClickClass = (event:React.MouseEvent<HTMLElement>) => {
        this.setState({ anchorElClass: event.currentTarget });
      };
      handleCloseClass = () => {
        this.setState({ anchorElClass: null});
      };
      increaseCount = () => {
        this.setState(prevState => {
          const total = prevState.countChild +prevState.countInfant + prevState.countAdult;
          if (total < 9) {
            return {
              countAdult: prevState.countAdult + 1,
              error: ""
            };
          } else {
            this.errorFunct("If you want to add more than 9 travellers please contact email www.doolwa.com");
            return null; 
          }
        });
      };
    
      decreaseCount = () => {
        this.setState(prevState => ({
          countAdult: prevState.countAdult === 1 ? prevState.countAdult : prevState.countAdult - 1,
          error: "" 
        }));
      };
    
      increaseCountChild = () => {
        this.setState(prevState => {
          const total = prevState.countChild + prevState.countInfant  + prevState.countAdult;
          if (total < 9) {
            return {
              countChild: prevState.countChild + 1,
              error: "" 
            };
          } else {
            this.errorFunct("If you want to add more than 9 travellers please contact email www.doolwa.com");
            return null; 
          }
        });
      };
    
      decreaseCountChild = () => {
        this.setState(prevState => ({
          countChild: prevState.countChild === 0 ? prevState.countChild : prevState.countChild - 1,
          error: "" 
        }));
      };
      handleClickBookNowDetails=(iata_object:any)=>{
        if(iata_object!=null){
          const transformedData = {
            country: iata_object.CountryCode,
            state: iata_object.Code,
            flight: iata_object.Name,
            stateCode: iata_object.CityCode
          };
          this.setState({startDate:null, endDate:null})
          this.setState({ toText: transformedData, startDate:new Date() }, () => {
            this.inputRef.current?.focus();
            window.scrollTo({ top: 0, behavior: 'smooth' });
          });
        }
      }
      increaseCountChildInfant = () => {
        this.setState(prevState => {
          const total = prevState.countChild +prevState.countInfant + prevState.countAdult;
          if (total < 9) {
            return {
              countInfant: prevState.countInfant + 1,
              error: "" 
            };
          } else {
            this.errorFunct("If you want to add more than 9 travellers please contact email www.doolwa.com");
            return null; 
          }
        });
      };
    
      decreaseCountChildInfant = () => {
        this.setState(prevState => ({
          countInfant: prevState.countInfant === 0 ? prevState.countInfant : prevState.countInfant - 1,
          error: "" 
        }));
      };
    
      errorFunct = (errorMsg:string) => {
        this.setState({
          error: errorMsg
        });
      };
      handleClickStatus = (buttonName:string) => {
        this.setState({
          TravelStatus: buttonName
        });
      };
      handleCancelTravel=()=>{
        this.setState({
          TravelStatus:"PremiumEconomy", countAdult:1, countChild:0,
          fullTravelObj:{
            
            countAdult:
            1,
            countChild:0,
            countInfant:0,
            TravelStatus:"PremiumEconomy"
          },
           anchorElClass:null,
           error:""
        })
      }
      handleDone=()=>{
        this.setState({
          fullTravelObj:{
            countAdult:this.state.countAdult,
            countChild:this.state.countChild,
            countInfant:this.state.countInfant,
            TravelStatus:this.state.TravelStatus
          },
          anchorElClass:null
        })
      }
      handleClickBlogDetails = (idBlog:string) => {
        this.props.navigation.navigate("SingleBlog", { id: idBlog})
      };
      handleClickOffersDetails = (idOffers:string) => {
       if(idOffers){
        this.props.navigation.navigate("SingleOffers", { id: idOffers})
       }
      };
      addAnotherCity = () => {
        const { multicityTrips } = this.state;
        if(multicityTrips.length < 6) {
          this.setState({
            inputSearch:'',inputCityFrom:'',
              multicityTrips: [...multicityTrips, {
                airportFrom: {
                  id: '',
                  state: "",
                  country: "",
                  flight: "",
                  stateCode: ""
                }, airportTo: {
                  state: "",
                  country: "",
                  id: '',
                  flight: "",
                  stateCode: ""
                }, departureDate: null
              },]
          })
        }
      };
    

      togglePickerM = (picker:string, index:number) => {
        this.setState({
          isOpen: true,
          activePicker: picker,
          currentIndex: index 
        });
    };

    iconCloseRemoveItem=(idTem:number)=>{
     const updatedTrips = this.state.multicityTrips.filter((trip, index:number) => index !== idTem);
    this.setState({ multicityTrips: updatedTrips });
    }
    validateFields = () => {
      const { toText,searchText, startDate, fullTravelObj} = this.state;
      switch (true) {
        case !searchText.state:
          this.setState({ errorMessage: 'Origin city is required.' });
          return false;
        case !toText.state:
          this.setState({ errorMessage: 'Destination city is required.' });
          return false;
          case !startDate:
            this.setState({ errorMessage: 'Departure Date is required.' });
            return false;
        case fullTravelObj.countAdult <= 0:
          this.setState({ errorMessage: 'Travel count and class is required' });
          return false;
        default:
          this.setState({ errorMessage: '' });
          return true;
      }
    }

  roundTripValidation = () => {
    const { toText, searchText, startDate, fullTravelObj,endDate1 } = this.state
    if (!searchText.state) {
      this.setState({ errorMessage: 'Origin city is required.' });
      return false;
    } else if (!toText.state) {
      this.setState({ errorMessage: 'Destination city is required.' });
      return false;
    } else if (!startDate) {
      this.setState({ errorMessage: 'Departure Date is required.' });
      return false;
    } else if (!endDate1) {
      this.setState({ errorMessage: 'Return Date is required.' });
      return false;
    }else if (fullTravelObj.countAdult <= 0) {
      this.setState({ errorMessage: 'Travel count and class is required' });
      return false;
    } else {
      this.setState({ errorMessage: '' });
      return true;
    }
  };

    searchFlight=()=>{
      switch (this.state.selectedOption) {
        case "One Way":
          if (this.validateFields()) {
            this.OneWaySearch();
          }
          break;
          
        case "Roundtrip":
          if (this.roundTripValidation()) {
            this.roundTripSearchApi();
          }
          break;
      
        case "Multicity":
          this.props.navigation.navigate("MultiCitySearching");
          break;
      
        default:
          console.warn("Invalid option selected");
          break;
      }
      
    }
     setDateFunct = (): string | null => {
      if (this.state.startDate === null) {
        return null;
      }
    
      let year = this.state.startDate.getFullYear();
      let month = (this.state.startDate.getMonth() + 1).toString().padStart(2, '0');
      let day = this.state.startDate.getDate().toString().padStart(2, '0');
      
      const stDate = `${year}-${month}-${day}`;
      return stDate;
    }
    OneWaySearch=()=>{
      this.setState({ loading: true });
      let formattedDate = this.setDateFunct();
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.oneWaySearchApiCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.oneWayEndPoint+ `departure_date=${formattedDate}&origin=${this.state.searchText.state}&destination=${this.state.toText.state}&cabin_class=${this.state.TravelStatus}&adult=${this.state.countAdult}&children=${this.state.countChild}&infant=${this.state.countInfant}&trip_type=one_way`
      );
  
      const header = {
        "Content-Type": configJSON.validationApiContentType,
      };
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.validationApiMethodType
      );
  
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    handleGetOneWayRes=(responseJson:AirData)=>{
      this.setState({ loading: false });
      if (!responseJson.errors && !responseJson.error) {
        let formattedDate1 = this.setDateFunct();
      const url = `Catalogue?departure_date=${formattedDate1}&origin=${this.state.searchText.state}&originFlight=${this.state.searchText.flight}&destination=${this.state.toText.state}&destinationFlight=${this.state.toText.flight}&cabin_class=${this.state.TravelStatus}&adult=${this.state.countAdult}&children=${this.state.countChild}&infant=${this.state.countInfant}&trip_type=one_way`;
      window.location.href = url;
      } else {
        this.setState({ errorResSearch:responseJson.error?responseJson.error.Description:"input can't be blank" });
      }
    }
    truncateString = (str:string, maxLength:number) => {
      if (str.length > maxLength) {
        return str.slice(0, maxLength) + "...";
      }
      return str;
    };

    airportSearch=(stateName:string)=>{
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.airportSearchApiCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.airportSearchEndPoint+ stateName
      );
  
      const header = {
        "Content-Type": configJSON.validationApiContentType,
      };
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.validationApiMethodType
      );
  
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    handleAirportSearchRes=(responseJson:AirportResponse)=>{
      if(responseJson.airport_list!=null){
        const bData = responseJson.airport_list.map((item: AirportSearch, index:number) => {
       let flightName = item.Name.replace("Arpt", "Airport");
          return {
             "id":`${index+1}`,
             "country":item.CountryCode,
             "state":item.Code,
             "flight":flightName,
             "stateCode":item.CityCode 
          }
       })
       this.setState({listCityDummy:bData})
          const filteredItems = bData.filter(item =>
            item.flight.toLowerCase().includes(this.state.searchText.flight.toLowerCase())
          );
          this.setState({ 
            searchText: { 
              ...this.state.searchText, 
              state: this.state.searchText.state 
            }, 
            filteredItems 
          });
        
      }else{
        this.setState({searchText: { ...this.state.searchText, state: ""}})
      }
    }
    airportSearchTo=(stateName:string)=>{
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.airportSearchToApiCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.airportSearchEndPoint+ stateName
      );
  
      const header = {
        "Content-Type": configJSON.validationApiContentType,
      };
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.validationApiMethodType
      );
  
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    handleAirportSearchToRes=(responseJson:AirportResponse)=>{
      if(responseJson.airport_list!=null){
        const bData = responseJson.airport_list.map((item:AirportSearch, index:number) => {
          let flightName = item.Name.replace("Arpt", "Airport");
          return {
            "id":`${index+1}`,
            "country":item.CountryCode,
            "state":item.Code,
            "flight":flightName,
            "stateCode":item.CityCode 
          }
       })
       this.setState({listCityDummy:bData})
          const filteredItemsTo = this.state.listCityDummy.filter(item =>
            item.flight.toLowerCase().includes(this.state.toText.flight.toLowerCase())
          );
          this.setState({ 
            toText: { 
              ...this.state.toText, 
              state: this.state.toText.state 
            }, 
      filteredItemsTo: filteredItemsTo 
          });
        
      }else{
        this.setState({toText: { ...this.state.toText, state: ""}})
      }
       
    }

    responseRecive = (apiRequestCallId: string, responseJson: AirData & ErrorResponse) =>{
      if (responseJson && !responseJson.errors) {
        this.responseSucessCell(apiRequestCallId, responseJson)
      }
      else if (responseJson && responseJson.errors) {
        this.responseFailureCell(apiRequestCallId, responseJson)
      }
    }

    roundTripSearchApiCall = async (valueData: {
    contentType?: string;
    method?: string;
    endPoint?: string;
    body?: {};
    apiType?: string;
    type?: string;
  }) => {
    let { contentType, method, endPoint, body, apiType } = valueData;
    let header = {
      "Content-Type": contentType
    };
    let clientProfileRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    clientProfileRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    clientProfileRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    body &&
      clientProfileRequestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        apiType === "" ? JSON.stringify(body) : body
      );
    clientProfileRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(clientProfileRequestMessage.id, clientProfileRequestMessage);
    return clientProfileRequestMessage.messageId;
  };

  responseSucessCell = async (apiRequestCallId: string, responseJson: AirData) => {
    if (apiRequestCallId === this.roundTripSearchApiCallId) {
      this.roundTripSearchSucessCallBack(responseJson);
    }
  };

  responseFailureCell = async (apiRequestCallId: string, responseJson: ErrorResponse) => {
    if (apiRequestCallId === this.roundTripSearchApiCallId) {
      this.roundTripSearchFailureCallBack(responseJson);
    }
  };

  roundTripSearchApi = async () => {
    this.setState({ loading: true });
    let formattedDate = this.setDateFunct();
    this.roundTripSearchApiCallId = await this.roundTripSearchApiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: `${configJSON.oneWayEndPoint}departure_date=${formattedDate}&origin=${this.state.searchText.state}&destination=${this.state.toText.state}&cabin_class=${this.state.TravelStatus}&adult=${this.state.countAdult}&children=${this.state.countChild}&trip_type=round_trip&infant=${this.state.countInfant}&return_date=${changeMoment(this.state.endDate1).format('YYYY-MM-DD')}`,
    })
  };

  roundTripSearchSucessCallBack = (response: AirData) => {
    if (!response.errors && !response.error) {
      let formattedDate1 = this.setDateFunct();
    const urlName = `RoundTrip?departure_date=${formattedDate1}&origin=${this.state.searchText.state}&originFlight=${this.state.searchText.flight}&destination=${this.state.toText.state}&destinationFlight=${this.state.toText.flight}&cabin_class=${this.state.TravelStatus}&adult=${this.state.countAdult}&children=${this.state.countChild}&trip_type=round_trip&infant=${this.state.countInfant}&return_date=${changeMoment(this.state.endDate1).format('YYYY-MM-DD')}`;
    window.location.href = urlName;
    }else {
      this.setState({ errorResSearch:response.error?response.error.Description:"input can't be blank", loading: false });
    }
  };

  roundTripSearchFailureCallBack = (response: ErrorResponse) => {
    this.setState({ errorResSearch:response.errors[0], loading: false });
  };
  handleOffersViewAll = ()=>{
    this.props.navigation.navigate('ViewallOffers')
  }
  handleBlogsViewAll = ()=>{
    this.props.navigation.navigate('ViewallBlogs')
  }
  handlePopularDestiViewAll = ()=>{
    this.props.navigation.navigate("ViewallPopularDestination")
  }
  handleNavigate = (itemEle: string) => {
    if(itemEle){
      this.props.navigation.navigate(itemEle);
    }
  }
  handleDataUpdateSearch = (newData: any) => {
    this.setState({startDate:null, endDate:null})
    const transformedData = {
      country: newData.CountryCode,
      state: newData.Code,
      flight: newData.Name,
      stateCode: newData.CityCode
    };
   
    this.setState({ toText: transformedData , startDate:new Date()});
    this.inputRef.current?.focus();


    
  };
  handleClickNationality = (event:any) => {
    this.setState({ anchorElNationalClass: event.currentTarget });
  };


  handleNationalityClass = () => {
    this.setState({ anchorElNationalClass: null });
  };

  handleSelectNationality = (nationality:any) => {
    this.setState({ selectedNationality: nationality, anchorElNationalClass: null });
  };
  // Customizable Area End
}
