export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const imgVisbility = require("../assets/visibility.svg");
export const imgVisbilityOff = require("../assets/visibilityOff.svg");
export const plusIcon = require("../assets/Plusicon.svg")
export const BookedSeatIcon = require("../assets/Bookedseats.svg")
export const SelectedSeatByUserIcon = require("../assets/SelectedSeatByUser.svg")
export const PaidSeatIcon = require("../assets/PaidSeat.svg")
export const SelectedSeatIcon = require("../assets/SelectedSeat.svg")
export const ExitIcon = require("../assets/Exit.svg")
export const SeatExitIcon = require("../assets/SeatExit.svg")
export const SeatExitRightIcon = require("../assets/SeatExitRight.svg")