import React from "react";
// Customizable Area Start
import {
    Box, Typography, Button, Grid, FormGroup,
    Checkbox, FormControlLabel,
    FormControl, RadioGroup, Radio
} from "@material-ui/core";
import ControlPointIcon from '@material-ui/icons/ControlPoint';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import { userIcon, image_cloud } from "./assets";
import "../../landingpage/assets/style.css"
import HeaderMenuBlock from "../../../blocks/navigationmenu/src/HeaderMenu.web";
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import Footer from "../../../components/src/Footer";
import PackageBookingController, {
    Props
} from "./PackageBookingController.web";
// Customizable Area End

export default class PackageBooking extends PackageBookingController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    checkRedioGropbutton = (traveller: { id: number; Traveller?: string; }) => {
        return (
            <>
                <Grid xs={12} sm={12} md={4} lg={4} item>
                    <Box sx={{ padding: "5px 0px" }}>
                        <Typography variant="body1" style={webStyle.labelText}>
                            Gender <span style={{ color: "#ff0000", fontSize: "14px" }}>*</span>
                        </Typography>
                        <FormControl component="fieldset" style={{ width: "100%" }}>
                            <RadioGroup
                                style={{
                                    ...radioGroupStyle,
                                    border: `1px solid ${this.state.inputErrors[traveller.id]?.gender ? 'red' : '#94a3b8'}`
                                }}
                                name="gender"
                                data-test-id={`inputTextGender${traveller.id}`}
                                value={this.state.inputValues[traveller.id]?.gender || ''}
                                onChange={(event) => this.handleInputChange(event, traveller.id, 'gender')}
                            >
                                <FormControlLabel
                                    value="M"
                                    control={<Radio style={{ display: 'none' }} />}
                                    label="Male"
                                    className="redioWeight"
                                    style={{
                                        ...formControlLabelStyle, borderRight: "1px rgba(148, 163, 184, 1) solid",
                                        backgroundColor: this.state.inputValues[traveller.id]?.gender === 'M' ? '#E7F1FD' : '#fff',
                                        ...(this.state.inputValues[traveller.id]?.gender === 'M' && selectedLabelStyle),
                                    }}
                                    labelPlacement="top"
                                />
                                <FormControlLabel
                                    value="F"
                                    control={<Radio style={{ display: 'none' }} />}
                                    label="Female"
                                    className="redioWeight"
                                    style={{
                                        ...formControlLabelStyle, borderRight: "1px rgba(148, 163, 184, 1) solid",
                                        backgroundColor: this.state.inputValues[traveller.id]?.gender === 'F' ? '#E7F1FD' : '#fff',
                                        ...(this.state.inputValues[traveller.id]?.gender === 'F' && selectedLabelStyle),
                                    }}
                                    labelPlacement="top"
                                />
                                <FormControlLabel
                                    value="O"
                                    control={<Radio style={{ display: 'none' }} />}
                                    label="Other"
                                    className="redioWeight"
                                    style={{
                                        ...formControlLabelStyle,
                                        backgroundColor: this.state.inputValues[traveller.id]?.gender === 'O' ? '#E7F1FD' : '#fff',
                                        ...(this.state.inputValues[traveller.id]?.gender === 'O' && selectedLabelStyle),
                                    }}
                                    labelPlacement="top"
                                />
                            </RadioGroup>
                        </FormControl>
                    </Box>
                </Grid>
            </>
        )
    }
    errorCheckboxMsg = () => {
        return this.state.errors.checkboxAll && (
            <div style={{ color: 'red', marginTop: '10px' }}>
                {this.state.errors.checkboxAll}
            </div>
        )
    }
    gridTraveller = (traveller: any) => {
        return (
            <Box className="box" mt={2}>
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={12} md={2} lg={2}>
                        <Box sx={{ padding: "5px 0px" }}>
                            <Typography variant="body1" style={webStyle.labelText}>
                                Title <span style={{ color: "#ff0000", fontSize: "14px" }}>*</span>
                            </Typography>
                            <select
                                data-test-id={`inputTextPrefix${traveller.id}`}
                                name="prefix"
                                value={this.state.inputValues[traveller.id]?.prefix || ''}
                                onChange={(event) => this.handleInputChange(event, traveller.id, 'prefix')}
                                required
                                style={{
                                    ...webStyle.inputText, border: `1px solid ${this.state.inputErrors[traveller.id]?.prefix ? 'red' : 'rgba(148,163,184,1)'}`,
                                    borderRadius: '8px', height: "auto", padding: "21px 10px", background: "#fff",
                                    width: '100%'
                                }}
                            >
                                <option value="">Select Title</option>
                                {this.state.prefixData?.map((item: any, index: number) => (
                                    <option key={index} value={`${item}`}>
                                        {`${item}`}
                                    </option>
                                ))}
                            </select>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={3} lg={3}>
                        <Box sx={{ padding: "5px 0px" }}>
                            <Typography variant="body1" style={webStyle.labelText}>
                                First Name <span style={{ color: "#ff0000", fontSize: "14px" }}>*</span>
                            </Typography>
                            <input
                                type="text"
                                name="firstName"
                                data-test-id={`inputTextFirstName${traveller.id}`}
                                placeholder="First Name & Middle Name"
                                required
                                value={this.state.inputValues[traveller.id]?.firstName || ''}
                                onChange={(event) => this.handleInputChange(event, traveller.id, 'firstName')}
                                style={{
                                    ...webStyle.inputText,
                                    border: `1px solid ${this.state.inputErrors[traveller.id]?.firstName ? 'red' : 'rgba(148,163,184,1)'}`,
                                    borderRadius: '8px',
                                    width: '100%'
                                }}
                            />
                        </Box>
                    </Grid>
                    <Grid xs={12} sm={12} md={3} lg={3} item>
                        <Box sx={{ padding: "5px 0px" }}>
                            <Typography variant="body1" style={webStyle.labelText}>
                                Last Name <span style={{ color: "#ff0000", fontSize: "14px" }}>*</span>
                            </Typography>
                            <input
                                type="text"
                                required
                                name="lastName"
                                data-test-id={`inputTextLastName${traveller.id}`}
                                placeholder="Last Name"
                                value={this.state.inputValues[traveller.id]?.lastName || ''}
                                onChange={(event) => this.handleInputChange(event, traveller.id, 'lastName')}
                                style={{
                                    ...webStyle.inputText, border: `1px solid ${this.state.inputErrors[traveller.id]?.lastName ? 'red' : 'rgba(148,163,184,1)'}`,
                                    borderRadius: '8px',
                                    width: '100%'
                                }}
                            />
                        </Box>
                    </Grid>
                    {this.checkRedioGropbutton(traveller)}
                    <Grid xs={12} sm={12} md={4} lg={4} item>
                        <Box sx={{ padding: "5px 0px" }}>
                            <Typography variant="body1" style={webStyle.labelText}>
                                Country Code <span style={{ color: "#ff0000", fontSize: "14px" }}>*</span>
                            </Typography>
                            <select
                                data-test-id={`inputTextCountryCode${traveller.id}`}
                                name="countryCode"
                                value={this.state.inputValues[traveller.id]?.countryCode || ''}
                                onChange={(event) => this.handleInputChange(event, traveller.id, 'countryCode')}
                                required
                                style={{
                                    ...webStyle.inputText, border: `1px solid ${this.state.inputErrors[traveller.id]?.countryCode ? 'red' : 'rgba(148,163,184,1)'}`,
                                    borderRadius: '8px', height: "auto", padding: "21px 10px", background: "#fff",
                                    width: '100%'
                                }}
                            >
                                <option value="">Country Code</option>
                                {this.state.countryData?.map((itemObj: any, index: number) => (
                                    <option key={index} value={`${itemObj.attributes.name} (${itemObj.attributes.country_code})`}>
                                        {`${itemObj.attributes.name} (${itemObj.attributes.country_code})`}
                                    </option>
                                ))}
                            </select>
                        </Box>
                    </Grid>
                    <Grid xs={12} sm={12} md={4} lg={4} item>
                        <Box sx={{ padding: "5px 0px" }}>
                            <Typography variant="body1" style={webStyle.labelText}>
                                Mobile No <span style={{ color: "#ff0000", fontSize: "14px" }}>*</span>
                            </Typography>
                            <input
                                type="text"
                                name="mobileNo"
                                data-test-id={`inputTextMobileNo${traveller.id}`}
                                placeholder="Mobile No"
                                required
                                value={this.state.inputValues[traveller.id]?.mobileNo || ''}
                                onChange={(event) => this.handleInputChange(event, traveller.id, 'mobileNo')}
                                style={{
                                    ...webStyle.inputText, border: `1px solid ${this.state.inputErrors[traveller.id]?.mobileNo ? 'red' : 'rgba(148,163,184,1)'}`,
                                    borderRadius: '8px',
                                    width: '100%'
                                }}
                            />
                        </Box>
                    </Grid>
                    <Grid xs={12} sm={12} md={4} lg={4} item>
                        <Box sx={{ padding: "5px 0px" }}>
                            <Typography variant="body1" style={webStyle.labelText}>
                                Email <span style={{ color: "#ff0000", fontSize: "14px" }}>*</span>
                            </Typography>
                            <input
                                type="text"
                                required
                                name="emailId"
                                data-test-id={`inputTextEmailId${traveller.id}`}
                                placeholder="Email ID"
                                value={this.state.inputValues[traveller.id]?.emailId || ''}
                                onChange={(event) => this.handleInputChange(event, traveller.id, 'emailId')}
                                style={{
                                    ...webStyle.inputText, border: `1px solid ${this.state.inputErrors[traveller.id]?.emailId ? 'red' : 'rgba(148,163,184,1)'}`,
                                    borderRadius: '8px',
                                    width: '100%'
                                }}
                            />
                        </Box>
                    </Grid>


                    {this.checkboxSecondChildBox(traveller)}
                </Grid>
            </Box>
        )
    }
    checkBoxGrid = (traveller: { id: number; Traveller?: string; }) => {
        return (
            <>
                {this.state.checkedItems[`checkbox${traveller.id}`] && (
                    this.gridTraveller(traveller)
                )}
            </>
        )
    }
    checkboxSecondChildBox = (traveller: { id: number; Traveller?: string; }) => {
        return (
            <>

                <Grid xs={12} sm={12} md={4} lg={4} item>
                    <Box sx={{ padding: "5px 0px" }}>
                        <Typography variant="body1" style={webStyle.labelText}>
                            Passport Number
                        </Typography>
                        <input
                            type="text"
                            name="passportNo"
                            data-test-id={`inputTextPassportNo${traveller.id}`}
                            placeholder="Passport Number"
                            required
                            value={this.state.inputValues[traveller.id]?.passportNo || ''}
                            onChange={(event) => this.handleInputChange(event, traveller.id, 'passportNo')}
                            style={{
                                ...webStyle.inputText, border: `1px solid ${this.state.inputErrors[traveller.id]?.passportNo ? 'red' : 'rgba(148,163,184,1)'}`,
                                borderRadius: '8px',
                                width: '100%'
                            }}

                        />
                    </Box>
                </Grid>

                <Grid xs={12} sm={12} md={4} lg={4} item>
                    <Box sx={{ padding: "5px 0px" }}>
                        <Typography variant="body1" style={webStyle.labelText}>
                            Nationality <span style={{ color: "#ff0000", fontSize: "14px" }}>*</span>
                        </Typography>

                        <select
                            data-test-id={`inputTextNationality${traveller.id}`}
                            name="nationality"
                            value={this.state.inputValues[traveller.id]?.nationality || ''}
                            onChange={(event) => this.handleInputChange(event, traveller.id, 'nationality')}
                            required
                            style={{
                                ...webStyle.inputText, border: `1px solid ${this.state.inputErrors[traveller.id]?.nationality ? 'red' : 'rgba(148,163,184,1)'}`,
                                borderRadius: '8px', height: "auto", padding: "21px 10px", background: "#fff",
                                width: '100%'
                            }}
                        >
                            <option value="">Select Nationality</option>
                            {this.state.countryData?.map((item: any, index: number) => (
                                <option key={index} value={`${item.id}`}>
                                    {`${item.attributes.emoji_flag} ${item.attributes.name} (${item.attributes.country_code})`}
                                </option>
                            ))}
                        </select>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                    <Box sx={{ padding: "5px 0px" }}>
                        <Typography variant="body1" style={webStyle.labelText}>
                            Age <span style={{ color: "#ff0000", fontSize: "14px" }}>*</span>
                        </Typography>
                        <input
                            type="text"
                            name="age"
                            data-test-id={`inputTextAge${traveller.id}`}
                            placeholder="Enter a Age"
                            required
                            value={this.state.inputValues[traveller.id]?.age || ''}
                            onChange={(event) => this.handleInputChange(event, traveller.id, 'age')}
                            style={{
                                ...webStyle.inputText,
                                border: `1px solid ${this.state.inputErrors[traveller.id]?.age ? 'red' : 'rgba(148,163,184,1)'}`,
                                borderRadius: '8px',
                                width: '100%'
                            }}
                        />
                    </Box>
                </Grid>

                <Grid xs={12} sm={12} md={12} lg={12} item>
                    <Box style={{
                        border: `2px dashed rgba(23, 100, 160, 1)`,
                        borderRadius: '10px',
                        boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",

                    }}>

                        <div style={{ display: 'grid', placeItems: 'center', margin: '30px 0px' }}>
                            <input
                                style={{ display: 'none' }}
                                accept="image/*,application/pdf"
                                data-test-id={`upload-file-${traveller.id}`}
                                id={`upload-file-${traveller.id}`}
                                onChange={(event) => this.handleFileChange(event, traveller.id)}
                                type="file"
                            />
                            <img src={image_cloud} alt="image-upload" />
                            <Typography variant="h6" style={{ fontSize: '12px', fontWeight: 500, margin: '2px 0px' }}>Upload your passport</Typography>
                            <Typography variant="h6" style={{ fontSize: '10px', fontWeight: 400, margin: '2px 0px' }}>{this.state.inputValues[traveller.id]?.image || 'file support is PDF, png, jpg up to 10 MB'}</Typography>
                            <Button data-test-id={`inputTextImage${traveller.id}`} onClick={() => this.handleBrowseClick(traveller.id)} style={{ ...webStyle.browsebtn, padding: '10px 40px', margin: '15px 0px' }}>Browse</Button>

                        </div>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                    <Box>
                        <Button data-test-id={`handleSaveClick${traveller.id}`} onClick={() => this.handleSaveClick(traveller.id)} style={{ marginTop: '16px', textTransform: "capitalize", color: "rgba(23, 100, 160, 1)", fontWeight: 700, }}>
                            Save
                        </Button>
                    </Box>
                </Grid>
            </>
        )
    }

    // Customizable Area End

    render() {
        // Customizable Area Start
        return (
            <div className="textContent" style={{ background: '#E9F3FC', height: '100%', overflowY: 'scroll' }}>
                <Box sx={webStyle.headerContainer} data-test-id='header-menu'>
                    <HeaderMenuBlock navigation={this.props.navigation} id={""} />
                </Box>




                <Box sx={{ padding: { xs: "10px", sm: "10px", md: "50px", lg: "50px" }, marginTop: { xs: "-100px", sm: "-100px", md: "-250px", lg: "-450px" } }}>
                    <Typography variant="h2" className="typHeadingTrip">Complete your bookings</Typography>
                    <Grid container spacing={4} >
                        <Grid item sm={12} md={12} lg={8} >
                            <Box style={webStyle.BoxStyle}>

                                <Grid container spacing={4}>



                                    <Grid item lg={12} md={12} xs={12}>
                                        <Box style={{ ...webStyle.BoxStyle1, marginTop: "-15px" }}>
                                            <Box style={{ padding: "20px", }}>
                                                <Typography style={{ fontSize: "16px", fontWeight: "700", lineHeight: "16px", marginTop: "15px", marginBottom: "15px" }}>
                                                    Traveller Details
                                                </Typography>
                                                <Box style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                                                    <img src={userIcon} alt="user-icon" />
                                                    <Typography>
                                                        Adult (18 yrs+)
                                                    </Typography>
                                                </Box>
                                                <FormGroup style={{ marginTop: "20px" }}>
                                                    {this.state.checkboxData.map((traveller: any) => (
                                                        <React.Fragment key={traveller.id}>
                                                            <FormControlLabel

                                                                data-test-id={`label${traveller.id}`}
                                                                className="checkBoxTag"
                                                                control={
                                                                    <Checkbox
                                                                        data-test-id={`handleCheckboxChange${traveller.id}`}
                                                                        style={{ color: 'rgba(23, 100, 160, 1)', borderRadius: "6px" }}
                                                                        name={`checkbox${traveller.id}`}
                                                                        onChange={this.handleCheckboxChange}
                                                                    />
                                                                }
                                                                label={traveller.Traveller && traveller.firstName ? <><Box>
                                                                    <span style={{ color: "#000", fontSize: "16px" }}>{traveller.firstName}</span><br />
                                                                    <span style={{ color: "rgba(100, 116, 139, 1)", fontSize: "14px" }}>{traveller.mobile}, {traveller.email}</span>
                                                                </Box></> : traveller.Traveller}
                                                            />
                                                            {this.checkBoxGrid(traveller)}
                                                        </React.Fragment>
                                                    ))}
                                                    {this.errorCheckboxMsg()}
                                                </FormGroup>
                                            </Box>




                                        </Box>
                                    </Grid>
                                </Grid>

                                <Grid container spacing={4} >
                                    <Grid xs={12} sm={12} md={12} lg={12} item>
                                        <Box style={webStyle.BoxStyle}>
                                            <Grid container spacing={4}>
                                                <Grid xs={10} sm={10} md={10} lg={10} item>
                                                    <Typography className="font700wet" style={webStyle.detailsStyle}>Booking details will be sent to</Typography>
                                                </Grid>
                                                <Grid xs={2} sm={2} md={2} lg={2} item>
                                                    <Box style={{ textAlign: "right" }}>
                                                        <BorderColorIcon style={{ margin: "16px 0px" }} />
                                                    </Box>

                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={2}>
                                                <Grid xs={12} sm={12} md={4} lg={4} item>
                                                    <Box sx={{ padding: { xs: "0 0", sm: "0 0", md: "5px 0px", lg: "5px 0px" } }}>
                                                        <Typography variant="body1" style={webStyle.labelText}>
                                                            Country Code
                                                        </Typography>
                                                        <select
                                                            data-test-id="inputTextCountryCodes"
                                                            name="countryCode"
                                                            value={this.state.countrycode}
                                                            onChange={(e) => this.setCountryCode(e.target.value)}
                                                            required
                                                            style={{
                                                                ...webStyle.inputText,
                                                                border: `1px solid ${this.state.errors.countrycode ? "rgba(220,38,38,1)" : "rgba(148,163,184,1)"}`, height: "62px", padding: "12px 10px", background: "#fff",
                                                            }}
                                                        >
                                                            <option value="">Country Code</option>
                                                            {this.state.countryData?.map((item: any, index: number) => (
                                                                <option key={index} value={`${item.attributes.name} (${item.attributes.country_code})`}>
                                                                    {`${item.attributes.name} (${item.attributes.country_code})`}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </Box>
                                                </Grid>
                                                <Grid xs={12} sm={12} md={4} lg={4} item>
                                                    <Box sx={{ padding: { xs: "0 0", sm: "0 0", md: "5px 0px", lg: "5px 0px" } }}>
                                                        <Typography variant="body1" style={webStyle.labelText}>
                                                            Mobile No
                                                        </Typography>
                                                        <input
                                                            value={this.state.mobile}
                                                            onChange={(e) => this.setMobile(e.target.value)}
                                                            data-test-id="inputTextMobles"
                                                            placeholder="Mobile No"
                                                            type="text"
                                                            name="name"
                                                            required
                                                            style={{
                                                                ...webStyle.inputText, border: `1px solid ${this.state.errors.mobile ? "rgba(220,38,38,1)" : "rgba(148,163,184,1)"}`
                                                            }}
                                                        />
                                                    </Box>


                                                </Grid>
                                                <Grid xs={12} sm={12} md={4} lg={4} item>
                                                    <Box sx={{ padding: { xs: "0 0", sm: "0 0", md: "5px 0px", lg: "5px 0px" } }}>
                                                        <Typography variant="body1" style={webStyle.labelText}>
                                                            Email
                                                        </Typography>
                                                        <input
                                                            value={this.state.email}
                                                            onChange={(e) => this.setEmail(e.target.value)}
                                                            type="email"
                                                            name="name"
                                                            style={{
                                                                ...webStyle.inputText,
                                                                border: `1px solid ${this.state.errors.email ? "rgba(220,38,38,1)" : "rgba(148,163,184,1)"}`
                                                            }}
                                                            data-test-id="inputTextEmails"
                                                            placeholder="Email"
                                                            required

                                                        />
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                        <Box style={{ padding: "27px 0 28px 0" }}>
                                            <Button className="buttonOffer"
                                                type="submit"
                                                data-test-id="handleSubmitOrderMgmt"
                                                onClick={this.handleSubmitOrderMgmt}
                                                style={{
                                                    background: "rgba(23, 100, 160, 1)", borderRadius: "5px",
                                                    padding: "10px 35px", marginTop: "10px", color: "#fff",
                                                    fontSize: "16px", textTransform: "capitalize", fontWeight: 700
                                                }}
                                            >Continue</Button>

                                        </Box>

                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>

                        <Grid sm={12} md={12} item lg={4}>
                            <Box style={webStyle.BoxStyle2}>
                                <Typography style={webStyle.SummaryStyle}>Fare Summary</Typography>
                                <Box style={{ borderBottom: "1px solid rgba(203, 213, 225, 1)" }}>
                                    <Box style={{ display: "flex", alignItems: "center", justifyContent: "space-between", padding: "10px 17px" }}>
                                        <Box style={{ display: "flex", alignItems: "center" }}>
                                            <ControlPointIcon />&nbsp;
                                            <Typography style={webStyle.SumParagraphStyle}>Base Fare</Typography>
                                        </Box>
                                        <Typography style={webStyle.priceStyle}>$4,628</Typography>
                                    </Box>
                                </Box>
                                <Box style={{ borderBottom: "1px solid rgba(203, 213, 225, 1)" }}>
                                    <Box style={{ display: "flex", alignItems: "center", justifyContent: "space-between", padding: "10px 17px" }}>
                                        <Box style={{ display: "flex", alignItems: "center" }}>
                                            <ControlPointIcon />&nbsp;
                                            <Typography style={webStyle.SumParagraphStyle}>Taxes and Surcharges</Typography>
                                        </Box>
                                        <Typography style={webStyle.priceStyle}>$766</Typography>
                                    </Box>
                                </Box>
                                <Box>
                                    <Box style={{ display: "flex", alignItems: "center", justifyContent: "space-between", padding: "10px 17px" }}>
                                        <Box style={{ display: "flex", alignItems: "center" }}>
                                            <ControlPointIcon />&nbsp;
                                            <Typography style={webStyle.SumParagraphStyle}>Other Services</Typography>
                                        </Box>
                                        <Typography style={webStyle.priceStyle}>$399</Typography>
                                    </Box>
                                </Box>
                                <Box style={{ borderTop: "2px solid #000" }}>
                                    <Box style={{ display: "flex", alignItems: "center", justifyContent: "space-between", padding: "10px 17px" }}>
                                        <Box style={{ display: "flex", alignItems: "center" }}>

                                            <Typography style={webStyle.SumParagraphStyle1}>Total Amount</Typography>
                                            &nbsp; <InfoOutlinedIcon />
                                        </Box>
                                        <Typography style={webStyle.priceStyle1}>$ 10,655</Typography>
                                    </Box>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
                <Footer />
            </div>
        );
        // Customizable Area End
    }
}

// Customizable Area Start
const radioGroupStyle = {
    display: 'flex' as 'flex',
    flexDirection: 'row' as 'row',

    borderRadius: 8,
    overflow: 'hidden',
    height: "60px",

};

const formControlLabelStyle = {
    flex: 1,
    margin: 0,
    backgroundColor: '#fff',
    justifyContent: "center",
    fontWeight: 700
}

const selectedLabelStyle = {
    backgroundColor: 'rgba(233, 243, 252, 1)',
    color: '#000',
    borderBottom: "4px solid rgba(23, 100, 160, 1)"
};
const webStyle = {
    browsebtn: {
        fontSize: "14px",
        fontWeight: 600,
        background: 'rgba(23, 100, 160, 1)',
        color: 'white',
        borderRadius: '10px'


    },
    labelText: {
        fontSize: "16px",
        color: "rgba(51, 65, 85, 1)",
        marginBottom: "2px",
        display: "block",
        fontWeight: "Bold",

    },
    inputText: {
        width: "100%", border: `1px solid rgba(148,163,184,1)`, borderRadius: "8px",
        color: "rgba(0,0,0,1)", height: "50px", fontFamily: "Inter, sans-serif",
        fontSize: "14px", padding: "30px 10px",
        marginBottom: "10px", outline: "none"
    },
    headerContainer: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        width: "100%",
        height: {
            xs: '330px',
            sm: '395px',
            md: '500px'
        },
        background: "linear-gradient(180deg, #013B68 84.25%, #0175D0 100%)",
    },

    HeadingStyle: {
        fontSize: "24px",
        FontWeight: 700,
        lineHeight: "24px",
        marginTop: "22px",
        marginBottom: "22px",
        color: 'rgba(0, 0, 0, 1)'
    },
    SummaryStyle: {
        fontSize: "24px",
        FontWeight: 700,
        lineHeight: "24px",
        marginTop: "0px",
        marginBottom: "14px",
        padding: "17px",


    },
    SumParagraphStyle: {
        fontSize: "14px",
        FontWeight: 600,
        lineHeight: "24px",
        marginTop: "5px",
        marginBottom: "5px",


    },
    SumParagraphStyle1: {
        fontSize: "16px",
        FontWeight: 700,
        lineHeight: "24px",
        marginTop: "5px",
        marginBottom: "5px",
    },

    priceStyle: {
        fontSize: "14px",
        FontWeight: 600,
        lineHeight: "24px",
        marginTop: "5px",
        marginBottom: "5px",
        color: "grey"
    },
    priceStyle1: {
        fontSize: "16px",
        FontWeight: 700,
        lineHeight: "24px",
        marginTop: "5px",
        marginBottom: "5px",
        color: "#000"
    },

    BoxStyle: {
        padding: "20px",
        boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
        borderRadius: "15px",
        background: "white"
    },
    BoxStyle2: {
        padding: "20px 0",
        boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
        borderRadius: "15px",
        background: "white"
    },
    BoxStyle1: {

        boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
        borderRadius: "15px",
        background: "white"
    },

    importantp: {
        fontSize: "14px",
        FontWeight: 500,
        lineHeight: "24px",
        marginTop: "10px",
        marginBottom: "10px",
        textDecoration: "underline",
        color: "rgba(0, 0, 0, 1)"
    },
    detailsStyle: {
        fontSize: "16px",
        FontWeight: 700,
        lineHeight: "24px",
        marginTop: "16px",
        marginBottom: "16px",

    },

}
// Customizable Area End