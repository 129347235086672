import React from "react";
// Customizable Area Start
import { Box, Typography, Button, Grid, FormGroup, Checkbox, FormControlLabel, FormControl, RadioGroup, Radio, Snackbar, Dialog, DialogContent, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Tabs, Tab } from "@material-ui/core";
import ControlPointIcon from '@material-ui/icons/ControlPoint';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import { CheckCircleOutline } from "@material-ui/icons";
import { userIcon, SecureIcon, indigoIcon, image_Vector, image_trip, image_cloud, errorIcon } from "./assets";
import "../../landingpage/assets/style.css"
import HeaderMenuBlock from "../../../blocks/navigationmenu/src/HeaderMenu.web";
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import Footer from "../../../components/src/Footer";
import CloseIcon from '@material-ui/icons/Close';
import RemoveIcon from '@material-ui/icons/Remove';
import AddIcon from '@material-ui/icons/Add';
import "../assets/style.css"
import Loader from "../../../components/src/Loader.web";
export interface CountryArry {
    "id": string,
    "type": string,
    "attributes": {
        "name": string,
        "emoji_flag": string,
        "country_code": string
    }
}
// Customizable Area End

import OrderManagementMultiCityController, {
    Props,
} from "./OrderManagementMultiCityController.web";

export default class OrderManagementMultiCity extends OrderManagementMultiCityController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    checkRedioGropbutton = (traveller: { id: number; Traveller?: string; }) => {
        return (
            <>
                <Grid xs={12} sm={12} md={4} lg={4} item>
                    <Box sx={{ padding: "5px 0px" }}>
                        <Typography variant="body1" style={webStyle.labelText}>
                            Gender <span style={{ color: "#000", fontSize: "14px" }}>*</span>
                        </Typography>
                        <FormControl component="fieldset" style={{ width: "100%" }}>
                            <RadioGroup
                                style={{
                                    ...radioGroupStyle,
                                    border: `1px solid ${this.state.inputErrors[traveller.id]?.gender ? 'red' : '#94a3b8'}`
                                }}
                                name="gender"
                                data-test-id={`inputTextGender${traveller.id}`}
                                value={this.state.inputValues[traveller.id]?.gender || ''}
                                onChange={(event) => this.handleInputChange(event, traveller.id, 'gender')}
                            >
                                <FormControlLabel
                                    value="M"
                                    control={<Radio style={{ display: 'none' }} />}
                                    label="Male"
                                    className="redioWeight"
                                    style={{
                                        ...formControlLabelStyle, borderRight: "1px rgba(148, 163, 184, 1) solid",
                                        backgroundColor: this.state.inputValues[traveller.id]?.gender === 'M' ? '#E7F1FD' : '#fff',
                                        ...(this.state.inputValues[traveller.id]?.gender === 'M' && selectedLabelStyle),
                                    }}
                                    labelPlacement="top"
                                />
                                <FormControlLabel
                                    value="F"
                                    control={<Radio style={{ display: 'none' }} />}
                                    label="Female"
                                    className="redioWeight"
                                    style={{
                                        ...formControlLabelStyle, borderRight: "1px rgba(148, 163, 184, 1) solid",
                                        backgroundColor: this.state.inputValues[traveller.id]?.gender === 'F' ? '#E7F1FD' : '#fff',
                                        ...(this.state.inputValues[traveller.id]?.gender === 'F' && selectedLabelStyle),
                                    }}
                                    labelPlacement="top"
                                />
                                <FormControlLabel
                                    value="O"
                                    control={<Radio style={{ display: 'none' }} />}
                                    label="Other"
                                    className="redioWeight"
                                    style={{
                                        ...formControlLabelStyle,
                                        backgroundColor: this.state.inputValues[traveller.id]?.gender === 'O' ? '#E7F1FD' : '#fff',
                                        ...(this.state.inputValues[traveller.id]?.gender === 'O' && selectedLabelStyle),
                                    }}
                                    labelPlacement="top"
                                />
                            </RadioGroup>
                        </FormControl>
                    </Box>
                </Grid>
            </>
        )
    }
    checkBoxGrid = (traveller: { id: number; Traveller?: string; }) => {
        return (
            <>
                {this.state.checkedItems[`checkbox${traveller.id}`] && !this.state.savedData[traveller.id]?.isSaved && (
                    this.gridTraveller(traveller)
                )}
            </>
        )
    }
    gridTraveller = (traveller: any) => {
        return (
            <Box className="box" mt={2}>
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={12} md={2} lg={2}>
                        <Box sx={{ padding: "5px 0px" }}>
                            <Typography variant="body1" style={webStyle.labelText}>
                                Title <span style={{ color: "#000", fontSize: "14px" }}>*</span>
                            </Typography>
                            <select
                                data-test-id={`inputTextPrefix${traveller.id}`}
                                name="prefix"
                                value={this.state.inputValues[traveller.id]?.prefix || ''}
                                onChange={(event) => this.handleInputChange(event, traveller.id, 'prefix')}
                                required
                                style={{
                                    ...webStyle.inputText, border: `1px solid ${this.state.inputErrors[traveller.id]?.prefix ? 'red' : 'rgba(148,163,184,1)'}`,
                                    borderRadius: '8px', height: "auto", padding: "21px 10px", background: "#fff",
                                    width: '100%'
                                }}
                            >
                                <option value="">Select Title</option>
                                {this.state.prefixData?.map((item: any, index: number) => (
                                    <option key={index} value={`${item}`}>
                                        {`${item}`}
                                    </option>
                                ))}
                            </select>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={3} lg={3}>
                        <Box sx={{ padding: "5px 0px" }}>
                            <Typography variant="body1" style={webStyle.labelText}>
                                First Name <span style={{ color: "#000", fontSize: "14px" }}>*</span>
                            </Typography>
                            <input
                                type="text"
                                name="firstName"
                                data-test-id={`inputTextFirstName${traveller.id}`}
                                placeholder="First Name & Middle Name"
                                required
                                value={this.state.inputValues[traveller.id]?.firstName || ''}
                                onChange={(event) => this.handleInputChange(event, traveller.id, 'firstName')}
                                style={{
                                    ...webStyle.inputText,
                                    border: `1px solid ${this.state.inputErrors[traveller.id]?.firstName ? 'red' : 'rgba(148,163,184,1)'}`,
                                    borderRadius: '8px',
                                    width: '100%'
                                }}
                            />
                        </Box>
                    </Grid>
                    <Grid xs={12} sm={12} md={3} lg={3} item>
                        <Box sx={{ padding: "5px 0px" }}>
                            <Typography variant="body1" style={webStyle.labelText}>
                                Last Name <span style={{ color: "#000", fontSize: "14px" }}>*</span>
                            </Typography>
                            <input
                                type="text"
                                required
                                name="lastName"
                                data-test-id={`inputTextLastName${traveller.id}`}
                                placeholder="Last Name"
                                value={this.state.inputValues[traveller.id]?.lastName || ''}
                                onChange={(event) => this.handleInputChange(event, traveller.id, 'lastName')}
                                style={{
                                    ...webStyle.inputText, border: `1px solid ${this.state.inputErrors[traveller.id]?.lastName ? 'red' : 'rgba(148,163,184,1)'}`,
                                    borderRadius: '8px',
                                    width: '100%'
                                }}
                            />
                        </Box>
                    </Grid>
                    {this.checkRedioGropbutton(traveller)}
                    <Grid xs={12} sm={12} md={4} lg={4} item>
                        <Box sx={{ padding: "5px 0px" }}>
                            <Typography variant="body1" style={webStyle.labelText}>
                                Country Code <span style={{ color: "#000", fontSize: "14px" }}>*</span>
                            </Typography>
                            <select
                                data-test-id={`inputTextCountryCode${traveller.id}`}
                                name="countryCode"
                                value={this.state.inputValues[traveller.id]?.countryCode || ''}
                                onChange={(event) => this.handleInputChange(event, traveller.id, 'countryCode')}
                                required
                                style={{
                                    ...webStyle.inputText, border: `1px solid ${this.state.inputErrors[traveller.id]?.countryCode ? 'red' : 'rgba(148,163,184,1)'}`,
                                    borderRadius: '8px', height: "auto", padding: "21px 10px", background: "#fff",
                                    width: '100%'
                                }}
                            >
                                <option value="">Country Code</option>
                                {this.state.countryData?.map((item: CountryArry, index: number) => (
                                    <option key={index} value={`${item.attributes.name} (${item.attributes.country_code})`}>
                                        {`${item.attributes.name} (${item.attributes.country_code})`}
                                    </option>
                                ))}
                            </select>
                        </Box>
                    </Grid>
                    <Grid xs={12} sm={12} md={4} lg={4} item>
                        <Box sx={{ padding: "5px 0px" }}>
                            <Typography variant="body1" style={webStyle.labelText}>
                                Mobile No <span style={{ color: "#000", fontSize: "14px" }}>*</span>
                            </Typography>
                            <input
                                type="text"
                                name="mobileNo"
                                data-test-id={`inputTextMobileNo${traveller.id}`}
                                placeholder="Mobile No"
                                required
                                value={this.state.inputValues[traveller.id]?.mobileNo || ''}
                                onChange={(event) => this.handleInputChange(event, traveller.id, 'mobileNo')}
                                style={{
                                    ...webStyle.inputText, border: `1px solid ${this.state.inputErrors[traveller.id]?.mobileNo ? 'red' : 'rgba(148,163,184,1)'}`,
                                    borderRadius: '8px',
                                    width: '100%'
                                }}
                            />
                        </Box>
                    </Grid>
                    <Grid xs={12} sm={12} md={4} lg={4} item>
                        <Box sx={{ padding: "5px 0px" }}>
                            <Typography variant="body1" style={webStyle.labelText}>
                                Email <span style={{ color: "#000", fontSize: "14px" }}>*</span>
                            </Typography>
                            <input
                                type="text"
                                required
                                name="emailId"
                                data-test-id={`inputTextEmailId${traveller.id}`}
                                placeholder="Email ID"
                                value={this.state.inputValues[traveller.id]?.emailId || ''}
                                onChange={(event) => this.handleInputChange(event, traveller.id, 'emailId')}
                                style={{
                                    ...webStyle.inputText, border: `1px solid ${this.state.inputErrors[traveller.id]?.emailId ? 'red' : 'rgba(148,163,184,1)'}`,
                                    borderRadius: '8px',
                                    width: '100%'
                                }}
                            />
                        </Box>
                    </Grid>
                    {this.checkboxSecondChildBox(traveller)}
                </Grid>
            </Box>
        )
    }
    checkboxSecondChildBox = (traveller: { id: number; Traveller?: string; }) => {
        return (
            <>
                <Grid xs={12} sm={12} md={4} lg={4} item>
                    <Box sx={{ padding: "5px 0px" }}>
                        <Typography variant="body1" style={webStyle.labelText}>
                            Passport Number
                        </Typography>
                        <input
                            type="text"
                            name="passportNo"
                            data-test-id={`inputTextPassportNo${traveller.id}`}
                            placeholder="Passport Number"
                            required
                            value={this.state.inputValues[traveller.id]?.passportNo || ''}
                            onChange={(event) => this.handleInputChange(event, traveller.id, 'passportNo')}
                            style={{
                                ...webStyle.inputText, border: `1px solid ${this.state.inputErrors[traveller.id]?.passportNo ? 'red' : 'rgba(148,163,184,1)'}`,
                                borderRadius: '8px',
                                width: '100%'
                            }}

                        />
                    </Box>
                </Grid>

                <Grid xs={12} sm={12} md={4} lg={4} item>
                    <Box sx={{ padding: "5px 0px" }}>
                        <Typography variant="body1" style={webStyle.labelText}>
                            Nationality <span style={{ color: "#000", fontSize: "14px" }}>*</span>
                        </Typography>

                        <select
                            data-test-id={`inputTextNationality${traveller.id}`}
                            name="nationality"
                            value={this.state.inputValues[traveller.id]?.nationality || ''}
                            onChange={(event) => this.handleInputChange(event, traveller.id, 'nationality')}
                            required
                            style={{
                                ...webStyle.inputText, border: `1px solid ${this.state.inputErrors[traveller.id]?.nationality ? 'red' : 'rgba(148,163,184,1)'}`,
                                borderRadius: '8px', height: "auto", padding: "21px 10px", background: "#fff",
                                width: '100%'
                            }}
                        >
                            <option value="">Select Nationality</option>
                            {this.state.countryData?.map((item: CountryArry, index: number) => (
                                <option key={index} value={`${item.id}`}>
                                    {`${item.attributes.emoji_flag} ${item.attributes.name} (${item.attributes.country_code})`}
                                </option>
                            ))}
                        </select>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                    <Box sx={{ padding: "5px 0px" }}>
                        <Typography variant="body1" style={webStyle.labelText}>
                            Date Of Birth <span style={{ color: "#000", fontSize: "14px" }}>*</span>
                        </Typography>
                        <input
                            type="date"
                            name="age"
                            data-test-id={`inputTextAge${traveller.id}`}
                            max={new Date().toISOString().split('T')[0]}
                            required
                            value={this.state.inputValues[traveller.id]?.age || ''}
                            onChange={(event) => this.handleInputChange(event, traveller.id, 'age')}
                            style={{
                                ...webStyle.inputText,
                                border: `1px solid ${this.state.inputErrors[traveller.id]?.age ? 'red' : 'rgba(148,163,184,1)'}`,
                                borderRadius: '8px',
                                width: '100%'
                            }}
                        />
                    </Box>
                </Grid>

                <Grid xs={12} sm={12} md={12} lg={12} item>
                    <Box style={{
                        border: `2px dashed rgba(23, 100, 160, 1)`,
                        borderRadius: '10px',
                        boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",

                    }}>

                        <div style={{ display: 'grid', placeItems: 'center', margin: '30px 0px' }}>
                            <input
                                style={{ display: 'none' }}
                                accept="image/*,application/pdf"
                                data-test-id={`upload-file-${traveller.id}`}
                                id={`upload-file-${traveller.id}`}
                                onChange={(event) => this.handleFileChange(event, traveller.id)}
                                type="file"
                            />
                            <img src={image_cloud} alt="image-upload" />
                            <Typography variant="h6" style={{ fontSize: '12px', fontWeight: 500, margin: '2px 0px' }}>Upload your passport</Typography>
                            <Typography variant="h6" style={{ fontSize: '10px', fontWeight: 400, margin: '2px 0px' }}>{this.state.inputValues[traveller.id]?.image || 'file support is PDF, png, jpg up to 10 MB'}</Typography>
                            <Button data-test-id={`inputTextImage${traveller.id}`} onClick={() => this.handleBrowseClick(traveller.id)} style={{ ...webStyle.browsebtn, padding: '10px 40px', margin: '15px 0px' }}>Browse</Button>

                        </div>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}>
                    <Box>
                        <Button data-test-id={`handleSaveClick${traveller.id}`} onClick={() => this.handleSaveClick()} style={{ marginTop: '16px', textTransform: "capitalize", color: "rgba(23, 100, 160, 1)", fontWeight: 700, }}>
                            Save
                        </Button>
                    </Box>
                </Grid>
            </>
        )
    }
    continueError = () => {
        return this.state.errorInfo && (
            <div style={{ color: 'red', marginTop: '10px' }}>
                {this.state.errorInfo}
            </div>
        )
    }
    errorCheckboxMsg = () => {
        return this.state.errors.checkboxAll && (
            <div style={{ color: 'red', marginTop: '10px' }}>
                {this.state.errors.checkboxAll}
            </div>
        )
    }
    genderBlock = (item: any) => {
        return (
            <Box sx={{ px: "20", py: "5" }}><Typography style={{ color: "rgba(0, 0, 0, 1)", fontSize: "14px", fontWeight: "600", fontFamily: "Inter, sans-serif", textTransform: "capitalize" }}>{item.gender === "M" ? "Male" : "Female"}</Typography></Box>
        )
    }
    topErrorMessageForm = () => {
        return (
            this.state.topErrorMessage && (
                <Box className="ErrorMsg" sx={webStyle.errorMsgStyle}>
                    <img src={errorIcon} /> &nbsp; {this.state.topErrorMessage}
                </Box>
            )
        )
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        const items = [
            { name: 'Additional 3 KG', price: '$1515', image: image_Vector },
            { name: 'Additional 3 KG', price: '$1515', image: image_Vector },
            { name: 'Additional 3 KG', price: '$1515', image: image_Vector },
        ];
        return (
            <>
                <Loader loading={this.state.loading} />
                <div className="textContent" style={{ background: '#E9F3FC', height: '100%', overflowY: 'scroll' }}>
                    <Box sx={webStyle.headerContainer} data-test-id='header-menu'>
                        <HeaderMenuBlock navigation={this.props.navigation} id={""} />
                    </Box>
                    <Snackbar open={this.state.toastOpen} anchorOrigin={{ vertical: 'top', horizontal: 'right' }} onClose={() => this.setState({ toastOpen: false })} autoHideDuration={3000}>
                        <Typography style={{ padding: '8px', background: "rgb(23, 100, 160)", color: 'white', borderRadius: '4px', display: 'flex', alignItems: 'center', gap: '10px' }}>
                            <CheckCircleOutline /> {this.state.ApiResponseMessage}
                        </Typography>
                    </Snackbar>
                    <Box sx={{ position: "relative", padding: { xs: "10px", sm: "10px", md: "30px", lg: "30px" }, marginTop: { xs: "-280px", sm: "-280px", md: "-400px", lg: "-450px" } }}>
                        <Typography variant="h2" className="typHeadingTrip">Complete your bookings</Typography>
                        <Grid container spacing={2} >
                            <Grid item xs={12} sm={12} md={12} lg={8}>
                                <Box sx={{ padding: { xs: "10px", sm: "10px", md: "20px", lg: "20px" }, }} style={webStyle.BoxStylew} >
                                    {this.topErrorMessageForm()}
                                    <>
                                        <Box>
                                            <Grid container style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                                                <Grid xs={12} sm={9} md={6} lg={8} item>

                                                    <Typography style={webStyle.HeadingStyle}>New Delhi - Mumbai - Bangaluru - Delhi</Typography>
                                                    <Typography style={{ ...webStyle.SumParagraphStyle, fontWeight: 500 }}>24 May, Friday  1 Stop. 2 h 30 mins</Typography>
                                                    <Box style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                                                        <img src={indigoIcon} style={{ borderRadius: "50px" }} alt="indigo-icon" />
                                                        <Typography style={webStyle.SumParagraphStyle}>Indigo</Typography>

                                                    </Box>
                                                </Grid>
                                                <Grid xs={12} sm={6} md={4} lg={4} item>
                                                    <Box style={{ textAlign: "right", display: 'flex' }} sx={{ flexDirection: 'column', alignItems: { xs: 'flex-start', sm: 'flex-start', md: 'end', lg: 'end' } }}>
                                                        <Button data-test-id="cancelationFessApply" onClick={() => this.cancelationFessApply()} style={{ ...webStyle.ButtonStyle, paddingLeft: "18px", paddingRight: '18px', paddingTop: '8px', paddingBottom: '8px' }}>CANCELLATION FEES APPLY</Button>
                                                        <Typography style={webStyle.saverStyle}>{this.state.bookingDataAll?.importantData.brand_name[0]} {`Economy > Xpress Value`}</Typography>
                                                    </Box>
                                                </Grid>
                                            </Grid>

                                            <Grid container spacing={2}>
                                                <Grid item lg={12} md={12} xs={12} >
                                                    {this.state.flightCardsArr.map((item: { start: string, destination: string }, index: number) => (
                                                        <Box style={{ ...webStyle.BoxStyle1, marginTop: '29px' }} className="boxChfgfgsss">
                                                            <Box style={{ padding: "20px", }}>
                                                                <Grid xs={12} sm={8} md={9} lg={8} item style={{ marginBottom: "20px" }}>

                                                                    <Typography style={webStyle.HeadingStyle}>New Delhi - Mumbai</Typography>
                                                                    <Typography style={{ ...webStyle.SumParagraphStyle, fontSize: "16px", fontWeight: 400 }}>24 May, Friday  1 Stop. 2 h 30 mins</Typography>
                                                                    <Box style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                                                                        <img src={indigoIcon} style={{ borderRadius: "50px", width: "20px", height: "20px" }} alt="indigo-icon" />
                                                                        <Typography style={{ ...webStyle.SumParagraphStyle, fontWeight: 700 }}>Indigo</Typography>

                                                                    </Box>
                                                                </Grid>
                                                                <Box className="boxChfgfg">
                                                                    <Box className="defulhh" style={styles.flightInfo}>
                                                                        <Typography variant="h6" className="typlogyOrderMg textSizeBggag" style={styles.time}>
                                                                            12:20 PM
                                                                        </Typography>
                                                                        <Box style={styles.airportInfo}>
                                                                            <Typography variant="body1"><span className="textSizeBggag" style={{ fontWeight: 700, marginRight: "15px" }}> New Delhi </span> <span className="textSizeBggag" style={{ wordBreak: "break-all" }}>. Indira Gandhi International Airport, Terminal T2  </span></Typography>
                                                                            <br />
                                                                            <span style={{ fontSize: "12px", display: "block", marginTop: "-5px" }}>1 h 30 min</span>
                                                                        </Box>
                                                                    </Box>
                                                                    <Box className="defulhh" style={styles.flightInfo}>

                                                                        <Typography variant="h6" className="typlogyOrderMg textSizeBggag" style={styles.time}>
                                                                            2:20 PM
                                                                        </Typography>
                                                                        <Box style={styles.airportInfo}>
                                                                            <Typography variant="body1"><span className="textSizeBggag" style={{ fontWeight: 700, marginRight: "15px" }}>Ahmedabad</span>  <span className="textSizeBggag" style={{ wordBreak: "break-all" }}> . Sardar Patel International Airport, Terminal T2  </span></Typography>
                                                                        </Box>
                                                                    </Box>
                                                                </Box>


                                                            </Box>


                                                            <Box style={{ borderTop: "1px solid rgba(100, 116, 139, 1)" }}>
                                                                <Grid container spacing={4}>
                                                                    <Grid item xs={12} sm={12} md={12} lg={10}>
                                                                        <Box className="boxPieceSize" style={webStyle.boxOneWayTrip}>
                                                                            <Typography variant="body1" style={{ alignItems: "center", display: "flex" }}><img src={image_Vector} /> &nbsp;<span className="textSizeBggag" style={{ fontSize: "14px", fontWeight: 700, }}>Cabin Baggage: </span>
                                                                                <span className="textSizeBggag" style={{ fontSize: "14px", fontWeight: 400, marginLeft: "3px" }}>7 kgs (1 piece only)/Adult </span></Typography>
                                                                            &nbsp; &nbsp;
                                                                            <Typography variant="body1" style={{ alignItems: "center", display: "flex" }}><img src={image_trip} /> &nbsp;<span className="textSizeBggag" style={{ fontSize: "14px", fontWeight: 700, }}>Check-In Baggage:</span>
                                                                                <span className="textSizeBggag" style={{ fontSize: "14px", fontWeight: 400, marginLeft: "3px" }}>15 kgs (1 piece only)/Adult </span></Typography>
                                                                        </Box>

                                                                    </Grid>
                                                                    <Grid item xs={12} sm={12} md={12} lg={2}>
                                                                        <Box sx={{ textAlign: { xs: "left", sm: "left", md: "right" }, padding: { xs: "0  20px", sm: "0  20px", md: "17px 15px 0 0" } }}>
                                                                            <span onClick={this.handleAddModel}><Typography data-test-id={`addApply${index}`} style={{ color: "rgba(23, 100, 160, 1)", fontSize: "14px", fontWeight: 700, cursor: 'pointer' }} variant="h6" >
                                                                                +ADD
                                                                            </Typography></span>
                                                                        </Box>
                                                                    </Grid>
                                                                </Grid>
                                                            </Box>
                                                        </Box>
                                                    ))}

                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={4}>
                                                <Grid item lg={12} md={12} xs={12} >
                                                    {this.state.flightCardsArr.map((item: { start: string, destination: string }, index: number) => (
                                                        <Box style={{ ...webStyle.BoxStyle1, marginTop: '10px' }} className="boxChfgfgsss">
                                                            <Box style={{ padding: "20px", background: "#E9F3FC" }}>
                                                                <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                                                    <Typography style={{ fontWeight: 700, fontSize: '16px' }}>Cancellation Policy</Typography>
                                                                    <Button style={{ color: "#1764A0", fontSize: '12px', fontWeight: 700, textTransform: 'capitalize' }}>View Policy</Button>
                                                                </Box>
                                                                <Box style={{ borderRight: "2px solid #1764A0", marginTop: "20px", paddingRight: '16px', display: 'flex', flexDirection: 'column', gap: '16px' }}>
                                                                    <div style={{ display: "flex", alignItems: 'center', justifyContent: "space-between" }}>
                                                                        <Typography><span style={{ fontSize: '14px', fontWeight: 500 }}>Now - 3 May ,</span><span style={{ fontSize: '14px', fontWeight: 300 }}>12:20 PM</span></Typography>
                                                                        <Typography style={{ fontSize: '14px', fontWeight: 600 }}>$3500</Typography>
                                                                    </div>
                                                                    <div style={{ display: "flex", alignItems: 'center', justifyContent: "space-between" }}>
                                                                        <Typography><span style={{ fontSize: '14px', fontWeight: 500 }}>Now - 3 May ,</span><span style={{ fontSize: '14px', fontWeight: 300 }}>12:20 PM</span></Typography>
                                                                        <Typography style={{ fontSize: '14px', fontWeight: 600 }}>$3500</Typography>
                                                                    </div>
                                                                </Box>
                                                            </Box>
                                                        </Box>
                                                    ))}

                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={4}>
                                                <Grid item lg={12} md={12} xs={12}>
                                                    <Box style={webStyle.BoxStyle}>
                                                        <Box style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                                                            <img src={SecureIcon} alt="SecureIcon" />
                                                            <Typography style={{ fontSize: "16px", fontWeight: "700", lineHeight: "16px", marginTop: "15px", marginBottom: "15px" }}>Important Information</Typography>
                                                        </Box>
                                                        <ul style={{ lineHeight: "30px", marginLeft: "-24px", marginTop: '0px' }}>
                                                            <li style={{ fontWeight: 500, textDecoration: 'underline', textUnderlineOffset: '4px' }}>Check travel guidelines and baggage information below:</li>
                                                            <li style={{ fontWeight: 300 }}>Carry no more than 1 check-in baggage and 1 hand baggage per passenger. If violated airline may levy extra charges.</li>
                                                        </ul>
                                                    </Box>
                                                </Grid>

                                                <Grid item lg={12} md={12} xs={12}>
                                                    <Box style={{ ...webStyle.BoxStyle1, marginTop: "-15px" }}>
                                                        <Box style={{ padding: "20px", }}>
                                                            <Typography style={{ fontSize: "16px", fontWeight: "700", lineHeight: "16px", marginTop: "15px", marginBottom: "15px" }}>
                                                                Traveller Details
                                                            </Typography>
                                                            <Box style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                                                                <img src={userIcon} alt="user-icon" />
                                                                <Typography>
                                                                    Adult (18 yrs+)
                                                                </Typography>
                                                            </Box>
                                                            <FormGroup style={{ marginTop: "20px" }}>
                                                                {this.state.checkboxData.map((traveller: any, index: number) => (
                                                                    <React.Fragment key={traveller.id}>
                                                                        <Box style={{ borderBottom: "1px solid #CBD5E1", display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                                            <FormControlLabel
                                                                                data-test-id={`label${traveller.id}`}
                                                                                className="checkBoxTag"
                                                                                control={
                                                                                    <Checkbox
                                                                                        data-test-id={`handleCheckboxChange${traveller.id}`}
                                                                                        style={{ color: 'rgba(23, 100, 160, 1)', borderRadius: "6px" }}
                                                                                        name={`checkbox${traveller.id}`}
                                                                                        onChange={this.handleCheckboxChange}
                                                                                        checked={this.state.checkedItems[`checkbox${traveller.id}`]}
                                                                                    />
                                                                                }
                                                                                label={traveller.Traveller && traveller.firstName ? <><Box>
                                                                                    <span style={{ color: "#000", fontSize: "16px" }}>{traveller.firstName}</span><br />
                                                                                    <span style={{ color: "rgba(100, 116, 139, 1)", fontSize: "14px" }}>{traveller.mobile}, {traveller.email}</span>
                                                                                </Box></> : traveller.Traveller}
                                                                            />
                                                                        </Box>
                                                                        {this.checkBoxGrid(traveller)}
                                                                    </React.Fragment>
                                                                ))}
                                                                {this.errorCheckboxMsg()}
                                                            </FormGroup>
                                                        </Box>
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={4} >
                                                <Grid xs={12} sm={12} md={12} lg={12} item>
                                                    <Box style={webStyle.BoxStyle}>
                                                        <Grid container spacing={4}>
                                                            <Grid xs={10} sm={10} md={10} lg={10} item>
                                                                <Typography className="font700wet" style={webStyle.detailsStyle}>Booking details will be sent to</Typography>
                                                            </Grid>
                                                            <Grid xs={2} sm={2} md={2} lg={2} item>
                                                                <Box style={{ textAlign: "right" }}>
                                                                    <BorderColorIcon style={{ margin: "16px 0px" }} />
                                                                </Box>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid container spacing={2}>
                                                            <Grid xs={12} sm={12} md={4} lg={4} item>
                                                                <Box sx={{ padding: { xs: "0 0", sm: "0 0", md: "5px 0px", lg: "5px 0px" } }}>
                                                                    <Typography variant="body1" style={webStyle.labelText}>
                                                                        Country Code
                                                                    </Typography>
                                                                    <select
                                                                        data-test-id="inputTextCountryCodes"
                                                                        name="countryCode"
                                                                        value={this.state.countrycode}
                                                                        onChange={(e) => this.setCountryCode(e.target.value)}
                                                                        required
                                                                        style={{
                                                                            ...webStyle.inputText,
                                                                            border: `1px solid ${this.state.errors.countrycode ? "rgba(220,38,38,1)" : "rgba(148,163,184,1)"}`, height: "62px", padding: "12px 10px", background: "#fff",
                                                                        }}
                                                                    >
                                                                        <option value="">Country Code</option>
                                                                        {this.state.countryData?.map((item: CountryArry, index: number) => (
                                                                            <option key={index} value={`${item.attributes.name} (${item.attributes.country_code})`}>
                                                                                {`${item.attributes.name} (${item.attributes.country_code})`}
                                                                            </option>
                                                                        ))}
                                                                    </select>
                                                                </Box>
                                                            </Grid>
                                                            <Grid xs={12} sm={12} md={4} lg={4} item>
                                                                <Box sx={{ padding: { xs: "0 0", sm: "0 0", md: "5px 0px", lg: "5px 0px" } }}>
                                                                    <Typography variant="body1" style={webStyle.labelText}>
                                                                        Mobile No
                                                                    </Typography>
                                                                    <input
                                                                        value={this.state.mobile}
                                                                        onChange={(e) => this.setMobile(e.target.value)}
                                                                        data-test-id="inputTextMobles"
                                                                        placeholder="Mobile No"
                                                                        type="text"
                                                                        name="name"
                                                                        required
                                                                        style={{
                                                                            ...webStyle.inputText, border: `1px solid ${this.state.errors.mobile ? "rgba(220,38,38,1)" : "rgba(148,163,184,1)"}`
                                                                        }}
                                                                    />
                                                                </Box>
                                                            </Grid>
                                                            <Grid xs={12} sm={12} md={4} lg={4} item>
                                                                <Box sx={{ padding: { xs: "0 0", sm: "0 0", md: "5px 0px", lg: "5px 0px" } }}>
                                                                    <Typography variant="body1" style={webStyle.labelText}>
                                                                        Email
                                                                    </Typography>
                                                                    <input
                                                                        value={this.state.email}
                                                                        onChange={(e) => this.setEmail(e.target.value)}
                                                                        type="email"
                                                                        name="name"
                                                                        style={{
                                                                            ...webStyle.inputText,
                                                                            border: `1px solid ${this.state.errors.email ? "rgba(220,38,38,1)" : "rgba(148,163,184,1)"}`
                                                                        }}
                                                                        data-test-id="inputTextEmails"
                                                                        placeholder="Email"
                                                                        required
                                                                    />
                                                                </Box>
                                                            </Grid>
                                                        </Grid>
                                                    </Box>
                                                    {this.continueError()}
                                                    <Box style={{ padding: "27px 0 28px 0" }}>
                                                        <Button className="buttonOffer"
                                                            type="submit"
                                                            data-test-id="handleSubmitOrderMgmtSeat"
                                                            onClick={this.handleSubmitOrderMgmt}
                                                            style={{
                                                                background: "rgba(23, 100, 160, 1)", borderRadius: "5px",
                                                                padding: "10px 35px", marginTop: "10px", color: "#fff",
                                                                fontSize: "16px", textTransform: "capitalize", fontWeight: 700
                                                            }}
                                                        >Continue</Button>
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </>
                                    
                                </Box>
                            </Grid>
                            <Grid xs={12} sm={12} md={12} item lg={4}>
                                <Box style={webStyle.BoxStyle2}>
                                    <Typography style={webStyle.SummaryStyle}>Fare Summary</Typography>
                                    <Box style={{ borderBottom: "1px solid rgba(203, 213, 225, 1)" }}>
                                        <Box style={{ display: "flex", alignItems: "center", justifyContent: "space-between", padding: "10px 17px" }}>
                                            <Box style={{ display: "flex", alignItems: "center" }}>
                                                <ControlPointIcon />&nbsp;
                                                <Typography style={webStyle.SumParagraphStyle}>Base Fare</Typography>
                                            </Box>
                                            <Typography style={webStyle.priceStyle}>$4,628</Typography>
                                        </Box>
                                    </Box>
                                    <Box style={{ borderBottom: "1px solid rgba(203, 213, 225, 1)" }}>
                                        <Box style={{ display: "flex", alignItems: "center", justifyContent: "space-between", padding: "10px 17px" }}>
                                            <Box style={{ display: "flex", alignItems: "center" }}>
                                                <ControlPointIcon />&nbsp;
                                                <Typography style={webStyle.SumParagraphStyle}>Taxes and Surcharges</Typography>
                                            </Box>
                                            <Typography style={webStyle.priceStyle}>$766</Typography>
                                        </Box>
                                    </Box>
                                    <Box style={{ borderBottom: "1px solid rgba(203, 213, 225, 1)" }}>
                                        <Box style={{ display: "flex", alignItems: "center", justifyContent: "space-between", padding: "10px 17px" }}>
                                            <Box style={{ display: "flex", alignItems: "center" }}>
                                                <ControlPointIcon />&nbsp;
                                                <Typography style={webStyle.SumParagraphStyle}>Other Services</Typography>
                                            </Box>
                                            <Typography style={webStyle.priceStyle}>$399</Typography>
                                        </Box>
                                    </Box>
                                    <Box style={{ borderTop: "2px solid #000" }}>
                                        <Box style={{ display: "flex", alignItems: "center", justifyContent: "space-between", padding: "10px 17px" }}>
                                            <Box style={{ display: "flex", alignItems: "center" }}>

                                                <Typography style={webStyle.SumParagraphStyle1}>Total Amount</Typography>
                                                &nbsp; <InfoOutlinedIcon />
                                            </Box>
                                            <Typography style={webStyle.priceStyle1}>$ 23,342</Typography>
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                    <Dialog
                        data-testid="ConfirmCancelModal"
                        maxWidth={false}
                        open={this.state.isVisibleModal}
                        onClose={this.resetModal}
                        PaperProps={{
                            style: {
                                width: '50%',
                                maxWidth: 'none',
                            },
                            className: 'modalFareRules',
                        }}
                    >
                        <DialogContent style={{ padding: "0", overflow: "hidden" }}>
                            <div>
                                <Box style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.1)" }}>
                                    <Typography style={{ display: "flex", justifyContent: "space-between", padding: "15px" }}>
                                        <span style={{ display: "block", width: "100%", fontSize: "24px", fontWeight: 700 }}>Fare rules</span>
                                        <span data-test-id="resetModal" onClick={this.resetModal} style={{ display: "block", width: "100%", textAlign: "right", cursor: "pointer" }}><CloseIcon /></span>
                                    </Typography>
                                </Box>

                                <Box p={2} className="p2res">
                                    <Tabs
                                        data-test-id="handleChangeTab"
                                        value={this.state.valueTab}
                                        onChange={this.handleChangeTab}
                                        aria-label="simple tabs example"
                                        className="custom-tabs"
                                        TabIndicatorProps={{ style: { backgroundColor: 'rgb(23, 100, 160)' } }}
                                    >
                                        <Tab
                                            label="Cancellation charges"
                                            style={{ background: this.state.valueTab === 0 ? "rgba(233, 243, 252, 1)" : "", color: "#000" }}
                                            className={`tabButtonRes custom-tab ${this.state.valueTab === 0 ? 'custom-tab-selected' : ''}`}
                                        />
                                        <Tab
                                            label="Date Change charges"
                                            style={{ background: this.state.valueTab === 1 ? "rgba(233, 243, 252, 1)" : "", color: "#000" }}
                                            className={`tabButtonRes custom-tab ${this.state.valueTab === 1 ? 'custom-tab-selected' : ''}`}
                                        />
                                    </Tabs>
                                    {this.state.valueTab === 0 && (
                                        <Box className="yscrollwindow medialContent">
                                            {
                                                this.state.flightCardsArr.map((flight, index) => (
                                                    <Box style={{ border: "1px solid rgba(148, 163, 184, 1)", borderRadius: "7px", marginTop: '10px' }}>
                                                        <Box display="flex" alignItems="center" style={{ justifyContent: 'space-between', background: "rgba(233, 243, 252, 1)", borderRadius: this.state.currentCancelIndex === index ? "7px 7px 0px 0px" : "7px", padding: "15px" }}>
                                                            <div style={{ display: 'flex', alignItems: "center", gap: "8px" }}>
                                                                <img src={indigoIcon} />
                                                                <Typography variant="h6" style={{ marginLeft: 8, fontSize: "16px", fontWeight: 700 }}>{flight.start} - {flight.destination}</Typography>
                                                            </div>
                                                            {this.state.currentCancelIndex !== index && <span style={{ cursor: 'pointer' }} onClick={() => this.handleShowCancelCharge(index)} data-test-id={`cancel-charge${index}`}>{console.log("dddd", index)} <Typography style={{ fontWeight: 700, fontSize: "14px", color: "#1764A0" }}>SHOW</Typography></span>}
                                                        </Box>
                                                        {this.state.currentCancelIndex === index && <Box style={{ padding: "15px" }} >
                                                            {console.log("ffffffff")}
                                                            <TableContainer component={Box}>
                                                                <Table aria-label="simple table" className="custom-table">
                                                                    <TableHead>
                                                                        <TableRow>
                                                                            <TableCell style={{ fontSize: "12px" }} align="center"><span style={{ fontWeight: 700 }}>Time Frame</span> <br /> <span style={{ color: "rgba(100, 116, 139, 1)" }}>(from Schduled flight departure)</span></TableCell>
                                                                            <TableCell style={{ fontSize: "12px" }} align="center"><span style={{ fontWeight: 700 }}>Airline Fee</span> <br /> <span style={{ color: "rgba(100, 116, 139, 1)" }}>(per passenger)</span></TableCell>
                                                                        </TableRow>
                                                                    </TableHead>
                                                                    <TableBody>
                                                                        <TableRow>
                                                                            <TableCell align="center" style={{ fontSize: "12px", fontWeight: 700 }} component="th" scope="row">0 hours to 3 hours</TableCell>
                                                                            <TableCell align="center" style={{ fontSize: "12px" }}><span style={{ color: "rgba(100, 116, 139, 1)" }}>ADULT:</span> <span style={{ fontWeight: 700 }}>Non-Refundable</span></TableCell>
                                                                        </TableRow>
                                                                        <TableRow>
                                                                            <TableCell align="center" style={{ fontSize: "12px", fontWeight: 700 }} component="th" scope="row">3 hours to 4 days</TableCell>
                                                                            <TableCell align="center" style={{ fontSize: "12px" }}><span style={{ color: "rgba(100, 116, 139, 1)" }}>ADULT:</span> <span style={{ fontWeight: 700 }}>$3,500 + $400</span></TableCell>
                                                                        </TableRow>
                                                                        <TableRow>
                                                                            <TableCell align="center" style={{ fontSize: "12px", fontWeight: 700 }} component="th" scope="row">4 days to 365 days</TableCell>
                                                                            <TableCell align="center" style={{ fontSize: "12px" }}><span style={{ color: "rgba(100, 116, 139, 1)" }}>ADULT:</span> <span style={{ fontWeight: 700 }}>$3,000 + $400</span></TableCell>
                                                                        </TableRow>
                                                                    </TableBody>
                                                                </Table>
                                                            </TableContainer>
                                                            <Typography style={{ fontSize: "14px", marginTop: "10px", marginBottom: "-3px", color: "rgba(100, 116, 139, 1)" }} variant="caption" display="block" gutterBottom>*From the time of Departure</Typography>
                                                        </Box>}
                                                    </Box>
                                                ))
                                            }
                                        </Box>
                                    )}
                                    {this.state.valueTab === 1 && (
                                        <Box className="yscrollwindow medialContent">
                                            {
                                                this.state.flightCardsArr.map((flight, index) => (
                                                    <Box style={{ border: "1px solid rgba(148, 163, 184, 1)", borderRadius: "7px", marginTop: '10px' }}>
                                                        <Box display="flex" alignItems="center" style={{ justifyContent: 'space-between', background: "rgba(233, 243, 252, 1)", borderRadius: this.state.currentDateChangeIndex === index ? "7px 7px 0px 0px" : "7px", padding: "15px" }}>
                                                            <div style={{ display: 'flex', alignItems: "center", gap: "8px" }}>
                                                                <img src={indigoIcon} />
                                                                <Typography variant="h6" style={{ marginLeft: 8, fontSize: "16px", fontWeight: 700 }}>{flight.start} - {flight.destination}</Typography>
                                                            </div>
                                                            {this.state.currentDateChangeIndex !== index && <span data-test-id={`show_change_date${index}`} style={{ cursor: 'pointer' }} onClick={() => this.handleShowDateChange(index)}> <Typography style={{ fontWeight: 700, fontSize: "14px", color: "#1764A0" }}>SHOW</Typography></span>}
                                                        </Box>
                                                        {this.state.currentDateChangeIndex === index && <Box style={{ padding: "15px" }} >
                                                            <TableContainer component={Box}>
                                                                <Table aria-label="simple table" className="custom-table">
                                                                    <TableHead>
                                                                        <TableRow>
                                                                            <TableCell style={{ fontSize: "12px" }} align="center"><span style={{ fontWeight: 700 }}>Time Frame</span> <br /> <span style={{ color: "rgba(100, 116, 139, 1)" }}>(from Schduled flight departure)</span></TableCell>
                                                                            <TableCell style={{ fontSize: "12px" }} align="center"><span style={{ fontWeight: 700 }}>Airline Fee + Fare differences</span> <br /> <span style={{ color: "rgba(100, 116, 139, 1)" }}>(per passenger)</span></TableCell>
                                                                        </TableRow>
                                                                    </TableHead>
                                                                    <TableBody>
                                                                        <TableRow>
                                                                            <TableCell align="center" style={{ fontSize: "12px", fontWeight: 700 }} component="th" scope="row">0 hours to 3 hours</TableCell>
                                                                            <TableCell align="center" style={{ fontSize: "12px" }}><span style={{ color: "rgba(100, 116, 139, 1)" }}>ADULT:</span> <span style={{ fontWeight: 700 }}>Non-Changeable</span></TableCell>
                                                                        </TableRow>
                                                                        <TableRow>
                                                                            <TableCell align="center" style={{ fontSize: "12px", fontWeight: 700 }} component="th" scope="row">2 hours to 365 days</TableCell>
                                                                            <TableCell align="center" style={{ fontSize: "12px" }}><span style={{ color: "rgba(100, 116, 139, 1)" }}>ADULT:</span> <span style={{ fontWeight: 700 }}>$3,500 + $400 + Fare Difference</span></TableCell>
                                                                        </TableRow>
                                                                    </TableBody>
                                                                </Table>
                                                            </TableContainer>
                                                            <Typography style={{ fontSize: "14px", marginTop: "10px", marginBottom: "-3px", color: "rgba(100, 116, 139, 1)" }} variant="caption" display="block" gutterBottom>*From the time of Departure</Typography>
                                                        </Box>}
                                                    </Box>
                                                ))
                                            }
                                        </Box>
                                    )}
                                </Box>
                            </div>

                        </DialogContent>
                    </Dialog>

                    <Dialog
                        data-testid="ConfirmCancelModal"
                        maxWidth={false}
                        open={this.state.isAddmodel}
                        onClose={this.resetaddModal}
                        PaperProps={{
                            style: {
                                width: '50%',
                                maxWidth: 'none',
                            },
                            className: 'modalFareRules',
                        }}
                    >
                        <DialogContent style={{ padding: "0" }}>
                            <div>
                                <Box style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.1)" }}>
                                    <Typography style={{ display: "flex", justifyContent: "space-between", padding: "15px" }}>
                                        <span style={{ display: "block", width: "100%", fontWeight: 700 }} className="extra-baggage-title">Add Extra Baggage</span>
                                        <span data-test-id="resetaddModal" onClick={this.resetaddModal} style={{ display: "block", width: "100%", textAlign: "right", cursor: "pointer" }}><CloseIcon /></span>
                                    </Typography>
                                </Box>
                                <Box style={{ margin: '20px', flexWrap: 'wrap', display: 'flex', alignItems: 'center', gap: '8px' }}>
                                    <Typography className="trip-details-heading" style={{ background: 'rgba(23, 100, 160, 1)', color: 'white', padding: '5px 16px ', borderRadius: '2px' }}>
                                        <Typography
                                            variant="body1"
                                            style={{ alignItems: 'center', flexDirection: 'column', display: 'flex', }}
                                        >
                                            <span>Trip 1</span>
                                            <div>
                                                <span style={webStyle.modeltyphead}>  New Delhi</span>
                                                <span style={webStyle.modeltyphead}> - Mumbai</span>
                                            </div>
                                        </Typography>
                                    </Typography>
                                    <Typography className="trip-details-heading" style={{ background: '#E9F3FC', color: 'white', padding: '5px 16px ', borderRadius: '2px' }}>
                                        <Typography
                                            variant="body1"
                                            style={{ color: "#1764A0", flexDirection: 'column', alignItems: 'center', display: 'flex', }}
                                        >
                                            <span>Trip 2</span>
                                            <div>
                                                <span style={{ ...webStyle.modeltyphead, color: "#1764A0" }}>  New Delhi</span>
                                                <span style={{ ...webStyle.modeltyphead, color: "#1764A0" }}> - Mumbai</span>
                                            </div>
                                        </Typography>
                                    </Typography>
                                    <Typography className="trip-details-heading" style={{ background: '#E9F3FC', color: 'white', padding: '5px 16px ', borderRadius: '2px' }}>
                                        <Typography
                                            variant="body1"
                                            style={{ color: '#1764A0', flexDirection: 'column', alignItems: 'center', display: 'flex', }}
                                        >
                                            <span>Trip 3</span>
                                            <div>
                                                <span style={{ ...webStyle.modeltyphead, color: "#1764A0" }}>  New Delhi</span>
                                                <span style={{ ...webStyle.modeltyphead, color: "#1764A0" }}> - Mumbai</span>
                                            </div>
                                        </Typography>
                                    </Typography>


                                </Box>
                                <Box className="yscrollwindow medialContent">
                                    {items.map((item, index) => (
                                        <Box
                                            className="baggage-info-container"
                                            style={webStyle.modelmainBox}
                                            key={index}
                                        >
                                            <Typography
                                                variant="body1"
                                                style={{ alignItems: 'center', display: 'flex', gap: '3px' }}
                                            >
                                                <img src={item.image} alt="icon" /> &nbsp;
                                                <Typography style={webStyle.modeltypo}>{item.name}</Typography>
                                            </Typography>
                                            <Box className="add-btn-container" style={{ alignItems: 'center', display: 'flex', gap: '12px' }}>
                                                <Typography style={{ ...webStyle.modelPricestyle, fontWeight: 600 }}>{item.price}</Typography>
                                                <Box
                                                    sx={{ padding: { xs: '6px 12px', sm: '6px 16px', md: '6px 20px' } }}
                                                    className="countDivBox"
                                                >
                                                    {
                                                        this.state.countsEle[index] === 0 ? <><span style={{ width: "27px" }}></span>
                                                        </> :
                                                            <button
                                                                className="borderNoneCount"
                                                                data-test-id={`decreaseCount${index}`}
                                                                onClick={() => this.decreaseCount(index)}
                                                            >
                                                                <RemoveIcon />
                                                            </button>
                                                    }

                                                    {this.state.countsEle[index] === 0 ? <Typography style={{ fontWeight: 700 }}>Add</Typography> : <div className="modelcountShowText">{this.state.countsEle[index]}</div>}
                                                    <button
                                                        className="borderNoneCount"
                                                        data-test-id={`increaseCount${index}`}
                                                        onClick={() => this.increaseCount(index)}
                                                    >
                                                        <AddIcon />
                                                    </button>
                                                </Box>
                                            </Box>
                                        </Box>
                                    ))}
                                    <Box
                                        style={{
                                            padding: '20px',
                                            display: 'flex',
                                            justifyContent: 'end',
                                        }}
                                    >
                                        <Button
                                            style={webStyle.savebtnmodel} >
                                            Save
                                        </Button>
                                    </Box>
                                </Box>
                            </div>
                        </DialogContent>
                    </Dialog>
                    <Dialog
                        data-testid="review-modal"
                        maxWidth={false}
                        open={this.state.reviewModal}
                        onClose={this.resetReviewModal}
                        PaperProps={{
                            style: {
                                width: '70%',
                                maxWidth: 'none',
                            },
                            className: 'modalFareRules',
                        }}
                    >
                        <DialogContent style={{ padding: "0" }}>
                            <div>
                                <Box style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.1)" }}>
                                    <Typography style={{ display: "flex", justifyContent: "space-between", padding: "15px" }}>
                                        <span style={{ display: "block", width: "100%", fontSize: "24px", fontWeight: 700 }}>Review Details</span>
                                        <span data-test-id="resetReviewModal" onClick={this.resetReviewModal} style={{ display: "block", width: "100%", textAlign: "right", cursor: "pointer" }}><CloseIcon /></span>
                                    </Typography>
                                </Box>

                                <Box className="yscrollwindowOrder">
                                    <Box>
                                        <p style={{ padding: "20px", color: "#000", fontSize: "16px", fontWeight: "400", fontFamily: "Inter, sans-serif" }}>Please ensure that the spelling of your name and other details match with you travel document. As these cannot be changed later. Errors might leads to cancellation penalities.</p>
                                    </Box>
                                    {this.state.travellerInfoData.map((item: any) => (
                                        <Box sx={{ flexGrow: 1, boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px", borderRadius: "5px", margin: "4% 15%" }}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12}>
                                                    <Box sx={{ px: "20", py: "5" }}><Typography style={{ color: "#000", fontSize: "16px", fontWeight: "700", fontFamily: "Inter, sans-serif" }}>ADULT {item.traveler_id}</Typography></Box>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Box sx={{ px: "20", py: "5" }}><Typography style={{ color: "rgba(100, 116, 139, 1)", fontSize: "14px", fontWeight: "600", fontFamily: "Inter, sans-serif" }}>First Name & Middle Name</Typography></Box>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Box sx={{ px: "20", py: "5" }}><Typography style={{ color: "rgba(0, 0, 0, 1)", fontSize: "14px", fontWeight: "600", fontFamily: "Inter, sans-serif", textTransform: "capitalize" }}>{item.first_name}</Typography></Box>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Box sx={{ px: "20", py: "5" }}><Typography style={{ color: "rgba(100, 116, 139, 1)", fontSize: "14px", fontWeight: "600", fontFamily: "Inter, sans-serif" }}>Last Name</Typography></Box>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Box sx={{ px: "20", py: "5" }}><Typography style={{ color: "rgba(0, 0, 0, 1)", fontSize: "14px", fontWeight: "600", fontFamily: "Inter, sans-serif", textTransform: "capitalize" }}>{item.last_name}</Typography></Box>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Box sx={{ px: "20", py: "5" }}><Typography style={{ color: "rgba(100, 116, 139, 1)", fontSize: "14px", fontWeight: "600", fontFamily: "Inter, sans-serif" }}>Gender</Typography></Box>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    {this.genderBlock(item)}
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    ))}
                                    <Box
                                        style={{
                                            padding: "0px 20px 20px 0px",
                                            display: 'flex',
                                            justifyContent: 'end',
                                        }}
                                    >
                                        <Button onClick={this.resetReviewModal}
                                            style={webStyle.saveEdit} >
                                            Edit
                                        </Button>&nbsp;&nbsp;
                                        <Button
                                            data-test-id="confirmSeatSelection"
                                            onClick={this.confirmSeatSelection}
                                            style={webStyle.saveConfirm} >
                                            Confirm
                                        </Button>
                                    </Box>
                                </Box>
                            </div>
                        </DialogContent>
                    </Dialog>
                    <Footer />
                </div>
            </>
        );
        // Customizable Area End
    }
}

// Customizable Area Start
const webStyle = {
    modelmainBox: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '15px 30px',
        borderBottom: '1px solid rgba(226, 232, 240, 1)',
    },
    saveEdit: {
        padding: '10px 16px',
        color: 'rgba(23, 100, 160, 1)',
        fontWeight: 700,
        fontSize: '16px',
        textTransform: "capitalize" as "capitalize"
    },
    saveConfirm: {
        textTransform: "capitalize" as "capitalize",
        padding: '10px 16px',
        background: 'rgba(23, 100, 160, 1)',
        borderRadius: '8px',
        color: 'rgba(255, 255, 255, 1)',
        fontWeight: 700,
        fontSize: '16px',
    },
    savebtnmodel: {
        background: 'rgba(23, 100, 160, 1)',
        borderRadius: '8px',
        padding: '10px 16px',
        color: 'rgba(255, 255, 255, 1)',
        fontWeight: 700,
        fontSize: '16px',
    },
    labelText: {
        fontSize: "16px",
        color: "#000",
        marginBottom: "2px",
        display: "block",
        fontWeight: 600,
    },
    browsebtn: {
        fontSize: "14px",
        fontWeight: 600,
        background: 'rgba(23, 100, 160, 1)',
        color: 'white',
        borderRadius: '10px'
    },
    modeltypo: {
        fontSize: "16px",
        color: "rgba(0, 0, 0, 1)",
        fontWeight: 400,
    },
    modeltyphead: {
        fontSize: "16px",
        color: "white",
        fontWeight: 400,
    },
    modelPricestyle: {
        fontSize: "16px",
        fontWeight: 400,
    },
    inputText: {
        width: "100%", border: `1px solid rgba(148,163,184,1)`, borderRadius: "8px",
        color: "rgba(0,0,0,1)", height: "50px", fontFamily: "Inter, sans-serif",
        fontSize: "14px", padding: "30px 10px",
        marginBottom: "10px", outline: "none"
    },
    headerContainer: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        width: "100%",
        height: {
            xs: '330px',
            sm: '395px',
            md: '500px'
        },
        background: "linear-gradient(180deg, #013B68 84.25%, #0175D0 100%)",
    },

    HeadingStyle: {
        fontSize: "24px",
        fontWeight: 700,
        lineHeight: "24px",
        marginTop: "22px",
        marginBottom: "22px",
        color: 'rgba(0, 0, 0, 1)'
    },
    CancellStyle: {
        fontSize: "16px",
        fontWeight: 700,
        lineHeight: "24px",
        marginTop: "5px",
        marginBottom: "20px",
    },
    ButtonStyle: {
        fontSize: "14px",
        fontWeight: 700,
        lineHeight: "18px",
        marginTop: "22px",
        marginBottom: "22px",
        color: "white",
        borderRadius: "0",
        background: "rgba(23, 100, 160, 1)"
    },
    SummaryStyle: {
        fontSize: "24px",
        fontWeight: 700,
        lineHeight: "24px",
        marginTop: "0px",
        marginBottom: "14px",
        padding: "17px",
    },

    SumParagraphStyle: {
        fontSize: "14px",
        fontWeight: 600,
        lineHeight: "24px",
        marginTop: "5px",
        marginBottom: "5px",
    },
    SumParagraphStyle1: {
        fontSize: "16px",
        fontWeight: 700,
        lineHeight: "24px",
        marginTop: "5px",
        marginBottom: "5px",
    },
    saverStyle: {
        fontSize: "14px",
        fontWeight: 600,
        lineHeight: "24px",
        marginTop: "3px",
        marginBottom: "5px",
        display: "flex",
        justifyContent: "end"

    },
    priceStyle: {
        fontSize: "14px",
        fontWeight: 600,
        lineHeight: "24px",
        marginTop: "5px",
        marginBottom: "5px",
        color: "grey"
    },
    priceStyle1: {
        fontSize: "16px",
        fontWeight: 700,
        lineHeight: "24px",
        marginTop: "5px",
        marginBottom: "5px",
        color: "#000"
    },

    BoxStyle: {
        padding: "20px",
        boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
        borderRadius: "15px",
        background: "white"
    },
    BoxStylew: {
        boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
        borderRadius: "15px",
        background: "white"
    },
    BoxStyle2: {
        padding: "20px 0",
        boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
        borderRadius: "15px",
        background: "white"
    },
    errorMsgStyle: {
        lineHeight: "24px",
        fontSize: "16px",
        borderLeft: "6px #c10f0ff0 solid",
        background: " #ed7e7e3d",
        padding: "20px 15px",
        borderRadius: "5px",
        color: "#c12121",
        display: "flex",
    },
    BoxStyle1: {
        marginTop: '10px',
        boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
        borderRadius: "15px",
        background: "white"
    },
    CancelStyle: {
        padding: "20px",
        boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
        borderRadius: "15px",
        background: "rgba(233, 243, 252, 1)",
    },

    importantp: {
        fontSize: "14px",
        fontWeight: 500,
        lineHeight: "24px",
        marginTop: "10px",
        marginBottom: "10px",
        textDecoration: "underline",
        color: "rgba(0, 0, 0, 1)"
    },
    AddStyle: {
        padding: '10px 5px 15px 20px',
        color: 'rgba(23, 100, 160, 1)',
        fontSize: "14px",
        fontWeight: 700,
        lineHeight: "24px",
        marginTop: "5px",
        marginBottom: "5px",
    },
    importantpargraph: {
        fontSize: "14px",
        fontWeight: 300,
        lineHeight: "24px",
        marginTop: "10px",
        marginBottom: "10px",
    },
    detailsStyle: {
        fontSize: "16px",
        fontWeight: 700,
        lineHeight: "24px",
        marginTop: "16px",
        marginBottom: "16px",

    },
    boxOneWayTrip: {
        display: "flex",
        justifyContent: "start",
        padding: "17px 0 0px 20px",
        alignItems: "center"
    }

}
const styles = {
    root: {
        display: 'flex',
        flexDirection: 'column' as 'column',
        padding: '16px',
    },
    flightInfo: {
        display: 'flex',
        marginBottom: '8px',
        position: 'relative' as 'relative',
    },
    time: {
        fontWeight: 'bold',
        marginRight: '16px',
        position: 'relative' as 'relative',
        width: "80px"
    },
    airportInfo: {
        marginLeft: '17px',
    },
    duration: {
        margin: '16px 0',
        textAlign: 'center' as 'center',
        color: '#555',
    },
    circle: {
        position: 'absolute',
        left: '-30px',
        width: '12px',
        height: '12px',
        borderRadius: '50%',
        border: "1px #000 solid",
    },
    line: {
        position: 'absolute',
        left: '-24px',
        top: '16px',
        height: 'calc(100% - 32px)',
        width: '2px',
        backgroundColor: '#000',
    },
};
const radioGroupStyle = {
    display: 'flex' as 'flex',
    flexDirection: 'row' as 'row',
    borderRadius: 8,
    overflow: 'hidden',
    height: "60px",

};

const formControlLabelStyle = {
    flex: 1,
    margin: 0,
    backgroundColor: '#fff',
    justifyContent: "center",
    fontWeight: 700
}

const selectedLabelStyle = {
    backgroundColor: 'rgba(233, 243, 252, 1)',
    color: '#000',
    borderBottom: "4px solid rgba(23, 100, 160, 1)"
};
// Customizable Area End
