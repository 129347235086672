import React from "react";

import {
  Box,
  Button,
  Typography,
  // Customizable Area Start
  Grid,
  CircularProgress
  // Customizable Area End


} from "@material-ui/core";
// Customizable Area Start
import PackageLazyLoader from "../../../components/src/PackageLazyLoader";
export const baseURL = require("../../../framework/src/config.js").baseURL;
// Customizable Area End

import ViewAllNationalController, {
  Props,
} from "./ViewAllNationalController.web";
import PackageSearch from "../../cfitinerarymanagement/src/PackageSearch.web";

export default class ViewAllNational extends ViewAllNationalController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start




  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
        <PackageSearch data-test-id="PackageSearch" package={"AllPackage"} navigation={this.props.navigation} id={""} onSendData={this.handleChildData} />
        <Box style={{ position: "relative" }}>
          <div className="content-container" style={{    top: '-100px',
            width: '100%',
            position: 'absolute',
        
          }}>
            <Box sx={{ padding: { xs: "10px 10px", sm: "10px 10px", md: "10px 30px", lg: "10px 30px" } }}>
              <Grid spacing={2} container >
                <Grid item xs={12} sm={12} md={12}>
                  <Box className="boxShadowDetail" style={{ background: "#fff" }}>
                    <Grid item className="textH5" style={webStyleInPackage.textAlignStart}>
                      <Typography className="marginleft10px" variant="h5">International Destinations</Typography>
                    </Grid>
                    <Grid container spacing={2}>
                      {this.state.inDestinationData.map((itemSell: any, index: number) => (
                        <Grid item xs={12} sm={6} md={4}>

                          <div className="hover-card-box" key={index} data-test-id={`handleNavigateXPackage${index}`} 
                          onClick={() => this.handleClickBestDetails(itemSell.id)} 
                          style={{ cursor: "pointer" }}>
                            <div style={{overflow: "hidden", margin: "10px" , position: "relative", }}>

                              <React.Suspense fallback={<CircularProgress />}>
                                <div className="imgPkSelling">
                                  <PackageLazyLoader type="packageSelling" src={baseURL + itemSell.image}  />
                                </div>

                              </React.Suspense>
                              <div style={{ marginTop: "10px",bottom: "25px", left: "18px", position: "absolute",  }}>
                                <Typography style={webStyleInPackage.secTwoTitle}> {itemSell.title}</Typography>

                                <Typography style={webStyleInPackage.secTwoTitle2}>Starting at {itemSell.price.toLocaleString()}</Typography>
                                <Typography style={webStyleInPackage.secTwoTitle3}>{itemSell.other}</Typography>
                                <Button data-test-id="handleClickBlogDetails" style={webStyleInPackage.secTwoTitle4} >Book Now</Button>
                              </div>
                            </div>
                          </div>
                        </Grid>
                      ))}


                    </Grid>
                  </Box>
                </Grid>
              </Grid>

            </Box>
          </div>
        </Box>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const webStyleInPackage: any = {
  textAlignStart: {
    marginTop: "30px",
    marginBottom: "30px",
    fontWeight: 700,
    textAlign: "start" as "start",
   
  },
  secTwoTitle3: {
    color: "#fff",
   
    display: "flex",
    padding: "5px 10px",
    fontSize: "14px",
    alignItems: "center"
  },
  secTwoTitle4: {
    fontSize: "14px",
    width: "fit-content",
    background: "white",
    borderRadius: "8px",
   
    marginLeft: "5px",
    padding: "10px 12px 10px 12px",
    fontWeight: "700",
    color: "rgba(23, 100, 160, 1)",
    textTransform: "capitalize" as "capitalize",
    marginTop: "10px",
    cursor: "pointer"
  },
  secTwoTitle: {
    padding: "5px 10px",
    color: "#fff",
    fontWeight: "700",
    fontSize: "24px",
   
  },
  secTwoTitle2: {
   
    display: "flex",
    alignItems: "center",
    color: "#fff",
    padding: "5px 10px",
    fontSize: "14px",
    fontWeight: "700",
  },
};
// Customizable Area End

