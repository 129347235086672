import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

export interface MainRoot {
    contentType: string
    method: string
    endPoint: string
    body: Body
}

export interface Body {
    hyperpay_order_id: string
    entity_id: string
    amount: number | string
    currency: string
    payment_type: string
}

export interface Roots {
    id: string
    referencedId: string
    paymentType: string
    amount: string
    currency: string
    descriptor: string
    result: Result
    resultDetails: ResultDetails
    buildNumber: string
    timestamp: string
    ndc: string
}

export interface Result {
    code: string
    description: string
}

export interface ResultDetails {
    ExtendedDescription: string
    ProcStatus: string
    clearingInstituteName: string
    AuthCode: string
    ConnectorTxID1: string
    ConnectorTxID3: string
    ConnectorTxID2: string
    AcquirerResponse: string
    EXTERNAL_SYSTEM_LINK: string
    OrderID: string
    TermID: string
}

export interface ListApiCall {
    contentType: string
    method: string
    endPoint: string
    token: string
}

export interface ItemAttribute {
    id: string
    type: string
    attributes: Attributes
}

export interface Root {
    data: Root[];
    id: string
    type: string
    attributes: Attributes
}

export interface Attributes {
    customer_name: string
    order_id: number | string
    amount_paid: number
    remaining_balance: number
    transaction_id: string
    transaction_status: string
    orders_status: string
    request_refund: string
    reason_of_refund: null
    created_at: string
    updated_at: string
}

interface S {
    txtInputValue: string;
    txtSavedValue: string;
    enableField: boolean;
    data: Array<ItemAttribute>
    isInvalidEmail: boolean;
    amount: string;
    leftamount: number;
    isInvalidAmt: boolean;
    isValue: boolean;
    ismsgShow: boolean;
    acceptButon: boolean;
    item: ItemAttribute;
    // Customizable Area Start
    selectedTab: string
    isRefundModalOpen: boolean
    refundOption: string
    // Customizable Area End
}

interface SS {
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

export default class RefundManagementController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    listofOrdersApiCallID: string = "";
    orderUpdateApiCallID: string = "";
    RefundApiCallID: string = "";
    HYPER_PAY_ACCESS_TOKEN = "OGFjN2E0Y2E4MTIxNGNiZTAxODEyMzkxNjU1NzEwOGV8YTNtZUZDZTY4dA==";
    entityId = "8ac7a4ca81214cbe0181239c3ff010b6";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            txtInputValue: "",
            txtSavedValue: "A",
            enableField: false,
            isInvalidEmail: false,
            data: [],
            amount: "",
            leftamount: 0,
            isInvalidAmt: false,
            isValue: false,
            ismsgShow: false,
            acceptButon: false,
            item: {} as ItemAttribute,
            selectedTab:'Review',
            isRefundModalOpen: false,
            refundOption:'orginal-mode'
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End

    }

    // Customizable Area Start
    // Customizable Area End

    // Customizable Area Start
    // Customizable Area End

    // Customizable Area Start
    async receive(from: string, message: Message) {
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
        }
    }

    async componentDidMount() {
    }

    handleMyTrips = ()=>{
        this.props.navigation.navigate("MyTrips")
    }
    handleCloseRefundModal = ()=>{
        this.setState({isRefundModalOpen: false})
    }
    handleShowModal = ()=>{
        this.setState({isRefundModalOpen:true})
    }
    handleConfirmCancel = ()=>{
        this.setState({selectedTab:'Confirmation',isRefundModalOpen:false})
    }
    handleRefundOptionSelect = (event:React.ChangeEvent<HTMLInputElement> )=>{
        this.setState({refundOption:event.target.value})
    }
    // Customizable Area End

}