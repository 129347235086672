import React from "react";

// Customizable Area Start
import ImageWithSkeleton from "../../../components/src/ImageWithSkeleton";
import { Box ,Grid,IconButton} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { ChevronLeft,ChevronRight } from "@material-ui/icons";
import { slider1, slider2, slider3 } from "./assets";
import Carousel from "react-material-ui-carousel";
// Customizable Area End

import CarouselDisplayController, {
  Props,
  configJSON,
} from "./CarouselDisplayController";

export default class CarouselDisplay extends CarouselDisplayController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderThumbnails() {
    return (
      <Box style={webStyle.indicatorIconWrap}>
        {this.state.imgData.map((item: any, index: any) => {
          return (
            <Box
              key={index}
              onClick={() => this.setState({ index: index })}
              style={webStyle.indicatorIcon}
            >
              <Box
                style={{
                  flex: 1,
                  backgroundImage: `url(${item.img})`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  border: `${
                    index === this.state.index
                      ? "3px solid #007aff"
                      : "3px solid #fff"
                  }`,
                  borderRadius: 100,
                }}
              />
            </Box>
          );
        })}
      </Box>
    );
  }
  // Customizable Area End

  render() {
    console.log(slider1);
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Grid container>
          <div style={{position:"relative"}} className="sliderBox">
            <div className="imgSliderheight"><ImageWithSkeleton data-test-id="imgSliderheight" src={this.state.imgData[this.state.index].img} type={"slider"} /></div>
          <div style={{ textAlign: 'center', display:"flex", justifyContent:"center", marginTop: '1rem',position:"absolute",bottom:0,left:0,width:"100%" }}>
          <div className="iconBgColor">
          <IconButton data-test-id={"PrevIconButton"} style={{color:"rgb(255,255,255,1)", padding:"7px"}} onClick={this.handlePrev} disabled={this.state.index === 0}>
                  <ChevronLeft />
                </IconButton>
                {this.state.imgData.map((image:any, index:any) => (
                  <span
                    data-test-id={"indicatorButton"}
                    key={index}
                    style={{
                      display: 'inline-block',
                      height: '14px',
                      width: '14px',
                      borderRadius: '50%',
                      backgroundColor: this.state.index === index ? 'rgba(255,255,255,1)' : '#ccc',
                      margin: '0 5px -4px 5px',
                      cursor: 'pointer',
                    }}
                    onClick={() => this.handleIndicatorClick(index)}
                  />
                ))}
                <IconButton data-test-id={"NextIconButton"} style={{color:"rgb(255,255,255,1)", padding:"7px"}} onClick={this.handleNext} disabled={this.state.index === this.state.imgData.length - 1}>
                  <ChevronRight />
                </IconButton>  
                </div>      
          </div>
          </div>
        </Grid>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  carouselImage: {
    width: "100%",
  },
  carousel: {
    flex: 1,
    maxHeight: 400,
    overflow: "hidden",
  },
  indicatorIconWrap: {
    display: "flex",
    flexDirection: "row" as "row",
    justifyContent: "center",
    transform: "translateY(-120%)",
  },
  indicatorIcon: {
    width: 35,
    height: 35,
    marginLeft: 5,
    marginRight: 5,
    overflow: "hidden",
    display: "flex",
  },
};
// Customizable Area End
