import { Box, Typography, Modal, Grid, Button, CircularProgress, List, ListItem, ListItemText } from "@material-ui/core";
import { CheckCircleRounded, Close } from "@material-ui/icons";
import { Flight, FlightData } from "../..//blocks/catalogue/src/CatalogueController";
const CheckIcon = require('./ClientGlobals')
import React, { FC } from "react";
import moment from 'moment';
const Included = require("./successCheck.svg")
const NotIncluded = require("./notIncluded.svg")

import Loader from "../../components/src/Loader.web";
const Modalstyle = {
    modal: {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: "95%",
        bgcolor: 'background.paper',
        boxShadow: 24,
        borderRadius:"8px"
    },
    heading: {
        fontSize: '16px',
        fontWeight: '700',
        paddingBottom: '32px',
        paddingTop: '24px'
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        gap: '19px'
    },
    imgContainer: {
        display: 'flex',
        alignItems: 'start',
        gap: '16px',

    },
    itemContainer: {
        display: "flex",
        overflowX: "auto",
        gap: "8px",
    }
};
interface FlightProps {
    from: {
        place: string;
        code: string;
        time: string;
    };
    to: {
        place: string;
        code: string;
        time: string;
    };
    date: string;
    duration: string;
    flight: string;
    ticketType: string;
    ticket: string;
    price: string;
}
interface OptionType {
    id: string,
    price: string,
    optionType: string,
    cabinType: {
        cabinWeight: number,
        cabinBaggageAllow: boolean,
    }
    checkinType: {
        checkinWeight: number,
        checkinbaggageAllow: boolean,
    }
    cancellationType: {
        cancellation: boolean,
        cancellationMsg: string
    }
    dateChangeType: {
        dateChange: boolean,
        dateChangeMsg: string
    }
    seatType: {
        seat: string,
        aboutSeatType: boolean
    }
    mealType: {
        meal: string,
        aboutMealType: boolean
    }
}
interface PriceModalProps {
    state: {
        priceIndex: number | null
        loading: boolean;
        errorPriceInfo: string;
        priceModal: boolean;
        selectedFlightPrice: FlightData | null
        fareOptionSelected: number | null
        priceListCard: {
            adult_per_price: string;
            currency_type: string;
            BrandName: string;
            BaggageInfo: string[];
            AirItenanry: any;
        }[]
    };
    handleClosePriceModal: () => void;
    handleFareOptionSelect: (item: ViewPrice, index: number) => void;
    hanldeSubmitPrice: (item: ViewPrice,priceIndex: number) => void;
    navigateScreens: (item: string) => void;
    convertFormatTime: (item: moment.MomentInput) => void
    DateComponent: (item: string | number | Date) => void
}
interface ViewPrice {
    adult_per_price: string; currency_type: string; BrandName: string; BaggageInfo: string[]; AirItenanry: any;
}[]

const PriceModal = ({ state, handleClosePriceModal, handleFareOptionSelect, convertFormatTime, DateComponent, hanldeSubmitPrice }: PriceModalProps) => {
    const formattedData = (dataBg: string[]) => {
        let combinedData = Array.isArray(dataBg) ? (dataBg.length > 0 ? dataBg.join(' ') :dataBg[0]) : '';
        if(combinedData){
            const { heading, cleared_content } = SeparateBullets(combinedData);
            const listItems = cleared_content.map((item, index) => (
                <ListItem 
                key={index} 
                style={{ display: 'flex', alignItems: 'flex-start', gap: '8px', paddingLeft: '0px' }}
            >
                <img src={Included} alt="Bullet point icon" />
                <Typography style={{fontWeight: 500, fontSize: "14px"}}>
                    {item}
                </Typography>
            </ListItem>
            ));
            return [heading, listItems];
        }
        else{
            let listItem = (
                <ListItem
                    style={{ display: 'flex', alignItems: 'flex-start', gap: '8px', paddingLeft: '0px' }}
                >
                </ListItem>)

            return ['No data available', listItem];
        }
        
    };
    
    function  SeparateBullets(data: string) {
        const lines = data.split('\n');
        let heading = lines[0].trim();
        let content = lines.slice(1).map(line => line.trim().replace('"', ''));
      
        let cleared_content = content
        .map(char => char.includes("•") ? char.replace("•", "").trim() : char.trim())
        .filter(char => char !== "");
    
        if(cleared_content[0].toLowerCase().includes("includes:")){
          let text = cleared_content[0]
          cleared_content.shift()
          heading = heading+text
        }
      
    
        return {
            heading,
            cleared_content
        };
    }
    return (
        <>
            <Modal open={state.priceModal} onClose={handleClosePriceModal}>

                <Box sx={Modalstyle.modal}>
                    <Box className="price-head-container" style={{ boxShadow: "0px 2px 4px 0px #0000001A", paddingLeft: "55px", paddingRight: "30px", paddingTop: '30px', paddingBottom: '30px', display: 'flex', justifyContent: 'space-between' }}>
                        <Typography className="price-options-heading " style={{ fontSize: '24px', fontWeight: "700" }}>{state.priceListCard.length} Fare options available for your trip</Typography>
                        <div style={{ cursor: 'pointer' }} onClick={handleClosePriceModal}> <Close /></div>
                    </Box>
                    <Box>
                        {
                            state.errorPriceInfo &&
                            <span className="imgErrorBox" style={{ fontFamily: 'Inter, sans-serif', fontSize: '14px', fontWeight: '600', padding: "10px 15px", margin: "0 0px 0px 14px" }}>
                                {state.errorPriceInfo}
                            </span>
                        }
                    </Box>

                    <Box className="option-container" style={{ display: 'flex', flexDirection: 'column', paddingLeft: '32px', paddingTop: '32px', paddingRight: '32px' }}>
                        <Typography className="flight-details-head" style={{ fontWeight: "700", paddingLeft: '23px', paddingBottom: "40px", fontSize: "24px", display: 'flex', alignItems: 'center', flexDirection: 'row', flexWrap: 'wrap', gap: '10px' }}>{state.selectedFlightPrice?.origin} - {state.selectedFlightPrice?.destination} <img style={{
                            objectFit: 'cover', width: '24px',
                            height: '24px',
                            marginRight: '8px',
                            borderRadius: '50%',
                        }} src={"https://i.pinimg.com/1200x/2d/06/2c/2d062c935dde7754fa80bf011a9dbdc7.jpg"} />Indigo |{state.selectedFlightPrice ? DateComponent(state.selectedFlightPrice.arrival_time) : ""} | Departure at {convertFormatTime(state.selectedFlightPrice?.departure_time)} - Arrival at {convertFormatTime(state.selectedFlightPrice?.endArrival_time)}   </Typography>

                        <Grid container  >
                            <div style={{
                                display: "flex",
                                overflowX: "auto",
                                gap: "8px",
                                paddingBottom: '24.65px', paddingLeft: '10px', paddingRight: '10px',alignItems:'stretch'
                            }}>
                                {state.priceListCard.map((item: ViewPrice, index: number) => (
                                    <div onClick={() => handleFareOptionSelect(item, index)} className={state.fareOptionSelected === index ? "selectedOption" : ""}>
                                        <Grid className="option-card" key={index} style={{ boxShadow: "0px 4px 10px 0px #00000029", width: '446px', borderRadius: '8px', cursor: 'pointer', flex: "0 0 auto",height:"100%" }} >
                                            <div className="price-block" style={{ borderBottom: '1px solid #94A3B8', paddingLeft: '24px', paddingTop: '10px', paddingBottom: '10px' }}>
                                                <Typography className="text" style={{ display: 'flex', gap: '6px', fontSize: '16px', fontWeight: '700' }}>{item.currency_type} {item.adult_per_price}  <Typography className="text" style={{ fontWeight: "500", fontSize: '14px', color: '#64748B' }}>per adult</Typography></Typography>
                                                <Typography className="option-type" style={{ fontWeight: '700', fontSize: '14px', textTransform: 'uppercase' }}>{item.BrandName}</Typography>
                                            </div>
                                            <div className="option-details-container" style={{height:'auto', paddingLeft: '24px', paddingBottom: '20px',display:'flex',flexDirection:'column' }}>
                                                <div style={{display:'flex',marginTop:"24px", justifyContent:'flex-start',alignItems:'flex-start',flexDirection:'column',}}>
                                                <Typography
                                                        style={{
                                                            fontWeight: 700,
                                                            fontSize: "16px",
                                                            width: "100%",
                                                            minHeight:72,
                                                            textAlign:
                                                                item.BaggageInfo && formattedData(item.BaggageInfo)[0].toString() !== "No data available"
                                                                    ? "left"
                                                                    : "center",
                                                        }}
                                                    >
                                                        {item.BaggageInfo && formattedData(item.BaggageInfo)[0].toString() !== "No data available"
                                                            ? formattedData(item.BaggageInfo)[0]
                                                            : "No data available"}
                                                    </Typography>
                                                    {item.BaggageInfo ? <List id="options-modal" className="medialContent">
                                                       { formattedData(item.BaggageInfo)[1]}
                                                    </List> : <></>}
                                                </div>
                                                <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '24px' }}>
                                                    <Button disabled={state.loading} onClick={() => {
                                                        hanldeSubmitPrice(item,index)
                                                    }} className="book-now-btn"
                                                        variant="contained" style={{
                                                            height: '56px', backgroundColor: '#1764A0', color: 'white', fontWeight: '600',
                                                            fontSize: '16px'
                                                        }}>
                                                        {state.priceIndex === index ? (
                                                            <>
                                                                <CircularProgress size={24} color="inherit" style={{ marginRight: '8px', textTransform: "capitalize" }} />
                                                                Loading...
                                                            </>
                                                        ) : (
                                                            "Book now"
                                                        )}
                                                    </Button>
                                                </div>
                                            </div>

                                        </Grid>
                                    </div>
                                ))}
                            </div>
                        </Grid>
                    </Box>
                </Box>
            </Modal>
        </>

    );
};
export default PriceModal;
