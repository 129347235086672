export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const editWhiteIcon = require("../assets/editWhiteIcon.svg");
export const editIcon = require("../assets/EditIcon.svg");
export const wallet = require("../assets/wallet.svg");
export const deleteIcon = require("../assets/delete.svg");
export const logout = require("../assets/logout.svg");
export const profileicon = require("../assets/profileicon.svg");
export const cancleIcon = require("../assets/cancleIcon.svg");
export const errorIcon = require("../assets/error.png")
export const DeleteSuccessIcon = require("../assets/DeleteSuccess.svg")
export const UploadSvgIcon = require('../assets/upload.svg')
