import React from "react";
// Customizable Area Start
import {
    Box,
    Button,
    Typography,
    Toolbar,
    AppBar,
    Grid,
    IconButton,
    Drawer,
    ListItem,
    ListItemText,
    MenuItem,
    Popover, Badge, styled
} from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import HeaderMenuController, { Props } from "./HeaderMenuController.web";
import { logo, userIcon, logout } from "./assets";

import MenuIcon from '@material-ui/icons/Menu';
import { NotificationsNone, NotificationsOffOutlined } from "@material-ui/icons";
import "../assets/style.css"
interface MenuType {
    "label": string;
    "href": string;
}
const menuItems = [
    { label: 'Home', href: 'Home' },
    { label: 'My Trip', href: 'MyTrips' },
    { label: 'Offers', href: 'ViewallOffers' },

];
// Customizable Area End
// Customizable Area Start
export default class HeaderMenuBlock extends HeaderMenuController {
    constructor(props: Props) {
        super(props);
    }

    render() {
        return (
            <Box style={{ position: "relative", width: '100%' }}>
                <Box style={{ zIndex: -1 }}>
                    <AppBar className="AppBarHeader" position="static" style={{ backgroundColor: "transparent", boxShadow: "none", }}>
                        <Toolbar>
                            <Typography variant="h6" style={{ flexGrow: 1, cursor: "pointer" }}>
                                <Box>
                                    <img data-test-id="handleHome" onClick={() => this.handleHome("Home")} src={logo} alt="logo" />
                                </Box>
                            </Typography>
                            <Grid item className="displatHeader" style={{marginRight:'10px'}}>
                                    <span onClick={this.handleMobileNotification}><Badge badgeContent={this.state.notificationCount} color="error"><NotificationsNone style={{fontSize:"26px",cursor:"pointer"}} /></Badge></span>
                                </Grid>
                                <NotificationBox
                                    anchorEl={this.state.notificationMobileEL}
                                    open={Boolean(this.state.notificationMobileEL)}
                                    data-test-id="notification-popup-mobile"
                                   
                                    onClose={this.handleNotificationClose}
                                    style={{width:'400px'}}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'right',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                        
                                    }}
                                >
                                    <Box className="notification-container ">
                                        <header className="notification-head">
                                            <Typography className="notification-title">Notification</Typography>
                                            {this.state.notifications.length > 0 && <span onClick={this.handleMarkAllRead}><Typography className="read-mark">Mark all as read</Typography></span>}
                                        </header>
                                        {this.state.notifications.length > 0 ?
                                            <Box className="medialContent notification-list">
                                                {this.state.notifications.map((notification, index) => (
                                                    <Box className="notification-list-item" onClick={()=> this.handleReadNotification(notification.id)}>
                                                        {notification.attributes.notifiable.image_url && 
                                                        <img style={{width:'40px',
                                                        height:"40px",
                                                        borderRadius:'50%'}} 
                                                        src={notification.attributes.notifiable.image_url} 
                                                        alt="notification-image" />}
                                                        <Box 
                                                        className="notification-list-subitem">
                                                           { notification.attributes.headings && 
                                                           <Typography className="notifcation-title">
                                                            {notification.attributes.headings}
                                                            </Typography>}
                                                            <Typography 
                                                                className="notification-content">
                                                                {this.truncateString(notification.attributes.contents, 60)}
                                                                 {this.checkNotificationType(notification) && <span
                                                                    data-test-id={`-web${index}`}
                                                                    onClick={() => this.handleLearnMoreClick(notification)}
                                                                    className="notification-learnMore"
                                                                >Learn More</span>}
                                                                </Typography>
                                                        </Box>
                                                    </Box>
                                                ))}
                                            </Box> : 
                                            <Box className="notification-no-container">
                                                <NotificationsOffOutlined className="notification-off" />
                                                <Typography className="no-notification-text">No Notification Found</Typography>
                                            </Box>
                                        }
                                    </Box>
                                </NotificationBox>
                            <IconButton
                                edge="start"
                                color="inherit"
                                aria-label="menu"
                                data-test-id="handleMenuToggle"
                                onClick={this.handleMenuToggle}
                                className="displatHeader"
                            >
                                <MenuIcon />
                            </IconButton>
                            
                            <Drawer
                                anchor="right"
                                open={this.state.menuOpen}
                                onClose={this.handleMenuToggle}
                            >
                                <Box
                                    sx={{ width: 250 }}
                                    role="presentation"
                                    onClick={this.handleMenuToggle}
                                    onKeyDown={this.handleMenuToggle}
                                >
                                    <Box p={2}>
                                        {menuItems.map((item: MenuType, index: number) => (
                                            <ListItem
                                                button
                                                data-test-id={`nav-links${index}`}
                                                key={index}
                                                onClick={() => this.handleHome(item.href)}
                                                style={{ color: "#000", fontWeight: 700, fontSize: "20px", marginBottom: "10px" }}
                                            >
                                                <ListItemText primary={item.label} />
                                            </ListItem>
                                        ))}
                                        <ListItem >

                                            <Badge badgeContent={4} color="primary">sample</Badge>
                                        </ListItem>
                                        {this.state.authToken != "" ? <>
                                            <ListItem
                                                button
                                                href="#"
                                                style={{ color: "#000", fontWeight: 700, fontSize: "20px", marginBottom: "10px" }}
                                            >
                                                <ListItemText primary={this.state.userName} />
                                            </ListItem>
                                            <ListItem
                                                button
                                                onClick={this.logoutFunct}
                                                href="#"
                                                style={{ color: "#000", fontWeight: 700, fontSize: "20px", marginBottom: "10px" }}
                                            >
                                                <ListItemText primary={"Logout"} />
                                            </ListItem>

                                        </> : <>
                                            <ListItem
                                                button
                                                data-test-id="loginRedirect"
                                                onClick={this.loginRedirect}
                                                href="#"
                                                style={{ color: "#000", fontWeight: 700, fontSize: "20px", marginBottom: "10px" }}
                                            >
                                                <ListItemText primary={"Login / Sign Up"} />
                                            </ListItem>
                                        </>
                                        }

                                    </Box>
                                </Box>
                            </Drawer>
                           
                            <Grid ref={this.scrollRef} container justifyContent="flex-end"  style={{gap:'10px'}} alignItems="center"
                                className="menuItemHeader"
                            >
                                {menuItems.map((item: MenuType, index: number) => (
                                    <Grid item key={index}>
                                        <Button data-test-id={`nav-btn${index}`} className="textItemHesader" onClick={() => this.handleHome(item.href)} style={{ color: "#FFFFFF", fontWeight: 700, fontSize: "20px", textTransform: "capitalize" }}>{item.label}</Button>
                                    </Grid>
                                ))}
                                <Grid item >
                                    <span className="textItemHesader" data-test-id="notification-icon-btn" onClick={this.handleNotification}><Badge data-test-id="notificaton-badge" className="" badgeContent={this.state.notificationCount} color="error"><NotificationsNone style={{fontSize:"26px",cursor:"pointer"}} /></Badge></span>
                                </Grid>
                                <NotificationBox
                                    anchorEl={this.state.notificationEL}
                                    open={Boolean(this.state.notificationEL)}
                                    data-test-id="notification-popup-web"
                                    PaperProps={{
                                        className: 'web-notification',
                                      }}
                                    onClose={this.handleNotificationClose}
                                    style={{width:'400px'}}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'right',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                        
                                    }}
                                >
                                    <Box className="notification-container ">
                                        <header className="notification-head">
                                            <Typography className="notification-title">Notification</Typography>
                                            {this.state.notifications.length > 0 && <span data-test-id='read-all-btn-web' onClick={this.handleMarkAllRead}><Typography className="read-mark">Mark all as read</Typography></span>}
                                        </header>
                                        {this.state.notifications.length > 0 ?
                                            <Box className="medialContent notification-list">
                                                {this.state.notifications.map((notification, index) => (
                                                    <Box className="notification-list-item" data-test-id={`read-single${index}`} onClick={()=> this.handleReadNotification(notification.id)}>
                                                        {notification.attributes.notifiable.image_url && <img style={{width:'40px',height:"40px",borderRadius:'50%'}} src={notification.attributes.notifiable.image_url} alt="notification-image" />}
                                                        <Box className="notification-list-subitem">
                                                           { notification.attributes.headings && <Typography className="notifcation-title">{notification.attributes.headings}</Typography>}
                                                            <Typography className="notification-content">{this.truncateString(notification.attributes.contents, 60)} {this.checkNotificationType(notification) && <span
                                                                onClick={() => this.handleLearnMoreClick(notification)}
                                                                    data-test-id={`learn-more-web${index}`}
                                                                  
                                                                    className="notification-learnMore"
                                                                >
                                                                    Learn More </span>}</Typography>
                                                        </Box>
                                                    </Box>
                                                ))}
                                            </Box> : 
                                            <Box className="notification-no-container">
                                                <NotificationsOffOutlined className="notification-off" />
                                                <Typography className="no-notification-text">No Notification Found</Typography>
                                            </Box>
                                        }
                                    </Box>
                                </NotificationBox>
                                {this.state.authToken != "" ? <>
                                    <Button data-test-id="buttonUser" className="textItemHesader" style={{ color: "#FFFFFF", fontWeight: 700, fontSize: "20px", textTransform: "capitalize" }} onClick={this.handleMenuOpen}>
                                        {this.state.userName} <ExpandMoreIcon />

                                    </Button>
                                    <Popover

                                        anchorEl={this.state.anchorEl}
                                        open={Boolean(this.state.anchorEl)}
                                        data-test-id="buttonClose"
                                        onClose={this.handleMenuClose}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'left',
                                        }}
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'left',
                                        }}
                                    >
                                        <MenuItem data-test-id="profile-menu" onClick={this.handleProfile} style={webHeaderStyle.userDrop}><img src={userIcon} /> &nbsp;Profile</MenuItem>
                                        <MenuItem data-test-id="logoutBox" style={webHeaderStyle.userDrop} onClick={this.logoutFunct}><img src={logout} /> &nbsp;Logout</MenuItem>
                                    </Popover>
                                </> :
                                    <Grid item >
                                        <Button data-test-id="loginRedirect" onClick={this.loginRedirect} className="loginButton textItemHesader" href="#" style={{ color: "#FFFFFF", fontWeight: 700, fontSize: "16px", textTransform: "capitalize" }}>{"Login/Signup"}</Button>
                                    </Grid>
                                }

                            </Grid>
                        </Toolbar>
                    </AppBar>
                    <Box style={{ borderBottom: "1px #fff solid" }}></Box>
                </Box>
            </Box>
        )
    }
}
// Customizable Area End

// Customizable Area Start
const webHeaderStyle = {
    userDrop: {
        width: '300px', padding: '15px 20px', borderBottom: "1px rgb(226, 232, 240) solid", fontSize: "16px"
    }
}
const NotificationBox = styled(Popover)({
    "& .notification-head":{
        display: "flex",
        justifyContent: "space-between",
        padding:"24px 16px"
    },
    "& .web-notification":{
        "@media screen and (max-width: 768px) ":{
          display:"none"
        },
    },
    "& .notification-no-container":{
        display:'flex',
        flexDirection:'column',
        gap:'20px',
        alignItems:'center',
        height:'200px',
        justifyContent:'center',
        marginBottom:'30px'
    },
    "& .no-notification-text ":{
        fontWeight:700,
        fontSize:"14px"
    },
    "&   .notification-off":{
        width:"52px",
        height:'52px',
        color:'#1764A0'
    },
    "& .notification-container":{
        maxWidth:"400px !important",
        minHeight:"200px",
        width:'400px',
        "@media screen and (min-width: 200px) and (max-width: 260px)":{
            width:'200px',
        },
        "@media screen and (min-width: 260px) and (max-width: 360px)":{
            width:'280px',
        },
        "@media screen and (min-width: 360px) and (max-width: 400px)":{
            width:'340px',
        },
        "@media screen and (min-width: 400px) and (max-width: 450px)":{
            width:'360px',
        },
    },
    "& .notification-title":{
        fontSize:"14px",
        color:"#64748B",
        textTransform:'uppercase',
        fontWeight:700
    },
    "& .read-mark":{
        fontSize:"14px",
        color:"#1764A0",
        fontWeight:700,
        cursor:'pointer'
    },
    "& .notification-list":{
        maxHeight:"400px",
        overflowY:'auto',
        display:'flex',
        flexDirection:'column',
    },
    "& .notification-list-item":{
        display:'flex',
        cursor:'pointer',
        gap:'16px',
        justifyContent:'flex-start',
        alignItems:'flex-start',
        borderBottom: "2px solid #CBD5E1",
        padding: "16px 24px 16px 24px",
    },
    "& .notification-list-subitem":{
        display:'flex',
        gap:'8px',
        flexDirection:"column",
        justifyContent:'flex-start',
        alignItems:'flex-start',
    },
    "& .notifcation-title":{
        fontSize:"14px",
        fontWeight:600,
    },
    "& .notification-content":{
        fontSize:"14px",
        fontWeight:500,
 
    },
    "& .notification-learnMore":{
        fontSize:"10px",
        color:"#1764A0",
        fontWeight:700,
        cursor:'pointer',
    },
    "& .MuiPopover-paper":{
        maxWidth:"100%"
    }
})
// Customizable Area End

