import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  appliedCoupon: any;

  inDestinationData: any;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ViewAllNationalController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  packageInApiCallId = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      inDestinationData: [],
      appliedCoupon: null,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }
  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(

        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId != null) {
        if (apiRequestCallId === this.packageInApiCallId && this.packageInApiCallId != null) {
          this.GetInAllPackagesList(responseJson);
        }

      }
    }
    // Customizable Area End
  }

  async componentDidMount() {

    this.packageInlistDataApi();

  }

  handleChildData = (data: any) => {
    data.map((item: any) => {
      return {
        "image": item.attributes.image,
        "price": item.attributes.price,
        "package_type": item.attributes.package_type,
        "name": item.attributes.name,
      
      }
    })
  };
  GetInAllPackagesList = (responseJson: any) => {
    if (responseJson.data.length > 0) {
      let dataYY = responseJson.data.map((item: any) => {
        return {
          "title": item.attributes.name,
          "id": item.id,
          "image": item.attributes.image,
          "other": "Per Person",
          "price": item.attributes.price,
      
        }
      })
      this.setState({ inDestinationData: dataYY })
    }
  }
  packageInlistDataApi = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.packageInApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.apiEndPointGetInPackage
    );

    const header = {
      "Content-Type": configJSON.exampleApiContentType,
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),

      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  handleClickBestDetails = (item:string) => {
    this.props.navigation.navigate("PackageDetail", { id: item})
  };
  // Customizable Area End
}
