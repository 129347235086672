import React, { useState, useEffect } from "react";
import Skeleton from "react-loading-skeleton";
import "../../blocks/landingpage/assets/style.css"
import { Box } from "@material-ui/core";

const OfferBanner = ({ oceanbackground }: any) => {
  const [backgroundLoaded, setBackgroundLoaded] = useState(false);
  useEffect(() => {
    const img = new Image();
    img.src = oceanbackground;

    img.onload = () => {
      setBackgroundLoaded(true);
    };
  }, [oceanbackground]);
  return (
    <div>
      {!backgroundLoaded && (
        <Skeleton
          style={{
            backgroundColor: " #bfbdbd",
            backgroundImage: "linear-gradient( 90deg,#bfbdbd,#eceaea,#bfbdbd)",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center",
            width: "100%",
            height: "650px",
            opacity: 1,
            position: "absolute"
          }} />
      )}

      {backgroundLoaded && (
        <img src={oceanbackground} style={{
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          objectFit: 'cover',
          filter: 'brightness(0.7)',
          zIndex: -1,
        }} />
      )}
    </div>
  );
};

export default OfferBanner;
