import React from "react";

// Customizable Area Start
import {
  Box,
  Button,
  FormControlLabel,
  Grid,
  Menu,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  CircularProgress,
  Popover,
  MenuItem
} from "@material-ui/core";
import Loader from "../../../components/src/Loader.web";
import ImageWithSkeleton from "../../../components/src/ImageWithSkeleton";
import BGComponent from "../../../components/src/BackgroundHeader"
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import FlightTakeoffIcon from '@material-ui/icons/FlightTakeoff';
import {oceanbackground, umbrella,airoplane,errorIcon,noOffers, noBenifit, noBlog,noDestination,whereToGo} from "./assets";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SyncAltIcon from '@material-ui/icons/SyncAlt';
import CloseIcon from '@material-ui/icons/Close';
import "../assets/style.css"
import RemoveIcon from '@material-ui/icons/Remove';
import AddIcon from '@material-ui/icons/Add';
import { MultiCity } from "./LandingPageController.web";
interface OfferFor {
  "id":string,
  "type": string,
  "attributes": {
      "id": number,
      "title": string,
      "tagline1":string,
      "tagline2": string,
      "offer_code":string,
      "image":string
   }
}
interface PDesti{
  "id": string,
  "type": string,
  "attributes": {
    "id": number,
    "title":string,
    "name_of_destination":string,
    "created_at": string,
    "image": string,
    iata_object:{
      "Code": string,
      "Name": string,
      "CityCode":string,
      "CountryCode":string
  },
}
}
interface SearchTextObj{
  id:string,
  state:string,
  country:string,
  flight:string,
  stateCode:string
}
interface BeniFitUserWeb{
  "id": string,
  "type": string,
  "attributes": {
      "id": number,
      "title": string,
      "description": string,
      "image": string
  }
}
interface WhereToWeb{
  "id": string,
  "type": string,
  "attributes": {
      "id": number,
      "title": string,
      "destination": string,
      "root":string,
      "image": string
  }
}
interface BlogType{
  "id": string,
  "type": string,
  "attributes": {
    "id": number,
    "name":string,
    "description":string,
    "created_at": string,
    "body":string,
    "image": string
}
}
const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
    slidesToSlide: 3, 
    partialVisibilityGutter: 180,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    slidesToSlide: 2,
    partialVisibilityGutter:0,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1,
    partialVisibilityGutter:0,
  }
};
export interface SearchTextM{
  id:string,
  state:string,
  country:string,
  flight:string,
  stateCode:string
}
import SingleCalender from "../../../components/src/SingleCalender";
import MultiCityHeaderLandingPage from "../../../components/src/MultiCityHeaderLandingPage";
// Customizable Area End

import LandingPageController, {
  MulticityArryType,
  Props,
} from "./LandingPageController.web";
import HeaderMenuBlock from "../../../blocks/navigationmenu/src/HeaderMenu.web";
import Calender from "../../../components/src/Calender";
import Footer from "../../../components/src/Footer";
import MultiTravel from "../../../components/src/MultiCityTravel";


export default class LandingPageBlock extends LandingPageController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start

    // Customizable Area End
  }
  // Customizable Area Start
  formInputMText = (trip:SearchTextM | null) => {
    return (
      <>
        {trip ? (
          <div style={{ position: 'absolute', width: '100%', background: '#fff', padding: '5px 0' }}>
            <span className="textcalenderday" style={{ fontWeight: '700', fontSize: '32px' }}>{trip.state}</span>
            <span className="textcalendername" style={{ fontWeight: '500', fontSize: '16px', display: 'block', marginTop: '10px', color: 'rgba(100, 116, 139, 1)' }}>{trip.stateCode},{trip.flight}</span>
          </div>
        ) : ''}
      </>
    );
  };
  toInputMText=(tripTo:SearchTextM | null)=>{
    return(
      <>
       {tripTo ? (
                                        <div style={{position: "absolute",width:"92%",
                                          background: "#fff",left:"10px",
                                          padding: "5px 0"}}>
                                          <span className="textcalenderday" style={{fontWeight:"700", fontSize: "32px" }}>{tripTo.state}</span>
                                          <span className="textcalendername" style={{fontWeight:"500", fontSize: "16px", display:"block", marginTop:"10px",color:"rgba(100, 116, 139, 1)" }}>{tripTo.stateCode},{tripTo.flight}</span>
                                        </div>
                                      ) : ''}
      </>
    )
  }
  formInputText=()=>{
    return(
      <>
        {this.state.searchText.state && this.state.searchText.flight ? (
                                        <div style={{position: "absolute",width:"100%",
                                          background: "#fff",
                                          padding: "5px 0"}}>
                                          <span className="textcalenderday" style={{fontWeight:"700", fontSize: "32px" }}>{this.state.searchText.state}</span>
                                          <span className="textcalendername" style={{fontWeight:"500", fontSize: "16px", display:"block", marginTop:"10px",color:"rgba(100, 116, 139, 1)", width:"96%" }}>{this.state.searchText.stateCode},{this.state.searchText.flight}</span>
                                        </div>
                                      ) : ''}
      </>
    )
  }
  toInputText=()=>{
    return(
      <>
       {this.state.toText.state && this.state.toText.flight ? (
                                        <div style={{position: "absolute",width:"85%",
                                          background: "#fff",left:"10px",
                                          padding: "5px 0"}}>
                                          <span className="textcalenderday" style={{fontWeight:"700", fontSize: "32px" }}>{this.state.toText.state}</span>
                                          <span className="textcalendername" style={{fontWeight:"500", fontSize: "16px", display:"block", marginTop:"10px",color:"rgba(100, 116, 139, 1)", }}>{this.state.toText.stateCode},{this.state.toText.flight}</span>
                                        </div>
                                      ) : ''}
      </>
    )
  }
  MultiCitytoInputText=(item:MultiCity)=>{
    return(
      <>
       {item.airportTo.state && item.airportTo.flight ? (
                                        <div style={{position: "absolute",width:"92%",
                                          background: "#fff",left:"10px",
                                          padding: "5px 0"}}>
                                          <span className="textcalenderday" style={{fontWeight:"700", fontSize: "32px" }}>{item.airportTo.state}</span>
                                          <span className="textcalendername" style={{fontWeight:"500", fontSize: "16px", display:"block", marginTop:"10px",color:"rgba(100, 116, 139, 1)", }}>{item.airportTo.stateCode},{item.airportTo.flight}</span>
                                        </div>
                                      ) : ''}
      </>
    )
  }
  MultiCityFromInputText = (item: MultiCity)=>{
 
        return(
          <>
            {item.airportFrom.state && item.airportFrom.flight ? (
                                            <div style={{position: "absolute",width:"100%",
                                              background: "#fff",
                                              padding: "5px 0"}}>
                                              <span className="textcalenderday" style={{fontWeight:"700", fontSize: "32px" }}>{item.airportFrom.state}</span>
                                              <span className="textcalendername" style={{fontWeight:"500", fontSize: "16px", display:"block", marginTop:"10px",color:"rgba(100, 116, 139, 1)", width:"96%" }}>{item.airportFrom.stateCode},{item.airportFrom.flight}</span>
                                            </div>
                                          ) : ''}
          </>
        )

  }
  travelInputText=()=>{
    return(
      <>
       {this.state.fullTravelObj.countAdult && 
                                              this.state.fullTravelObj.TravelStatus ? (
                                        <div style={{position: "absolute",width:"134%",
                                          background: "#fff",left:"0px",top:"21px",
                                          padding: "5px 0"}}>
                                          <span className="textcalenderday" style={{fontWeight:"700", fontSize: "32px" }} data-test-id="formatDay">{this.state.fullTravelObj.countAdult+this.state.fullTravelObj.countChild+this.state.fullTravelObj.countInfant} </span>
                                          <span className="textcalendermonth" style={{fontWeight:"500", fontSize: "24px" }} data-test-id="formatMonth">{this.state.fullTravelObj.countAdult + this.state.fullTravelObj.countChild + this.state.fullTravelObj.countInfant === 1? "Traveller":"Travellers"}</span><br />
                                          <span className="textcalendername" style={{fontWeight:"500", fontSize: "16px", display:"block", marginTop:"10px",color:"rgba(100, 116, 139, 1)" }} data-test-id="formatYear">{this.state.fullTravelObj.TravelStatus}</span>
                                        </div>
                                      ) : ''}
      </>
    )
  }
  nationalInputText=()=>{
    return(
      <>
      {this.state.selectedNationality?(
                                       <div style={{position: "absolute",width:"134%",
                                         background: "#fff",left:"0px",top:"35px",
                                         padding: "5px 0"}}>
                                         <span className="textcalendermonth" style={{fontWeight:"500", fontSize: "24px" }} data-test-id="formatMonth">{this.state.selectedNationality}</span><br />
                                       </div>
                                     ) : ''}
     </>
    )
  }
allButtonList=()=>{
  return(
    <Box style={{padding:"12px"}}>
    <Grid container spacing={1}>
      <Grid xs={12}>
      <div className="buttonContainer">
  <button
    className={`buttonEconomy ${this.state.TravelStatus === 'PremiumEconomy' ? 'active' : ''}`}
    data-test-id="handleClickStatus"
    onClick={() => this.handleClickStatus('PremiumEconomy')}
  >
    Premium /
    Economy
  </button>
  <button
    className={`buttonEconomy ${this.state.TravelStatus === 'PremiumFirst' ? 'active' : ''}`}
    data-test-id="handleClickStatus2"
    onClick={() => this.handleClickStatus('PremiumFirst')}
  >
    Premium /
    First
  </button>
  <button
    className={`buttonEconomy ${this.state.TravelStatus === 'First' ? 'active' : ''}`}
    data-test-id="handleClickStatus3"
    onClick={() => this.handleClickStatus('First')}
  >
    First
  </button>
  <button
    className={`buttonEconomy ${this.state.TravelStatus === 'Business' ? 'active' : ''}`}
    data-test-id="handleClickStatus4"
    onClick={() => this.handleClickStatus('Business')}
  >
    Business
  </button>
  <button
    className={`buttonEconomy ${this.state.TravelStatus === 'Economy' ? 'active' : ''}`}
    data-test-id="handleClickStatus5"
    onClick={() => this.handleClickStatus('Economy')}
  >
    Economy
  </button>
</div>

      </Grid>
    </Grid>
 



  </Box>
  )
}



                   
                    

userBenifit=()=>{
  return(
    <>
    {this.state.BenefitUser.length>0?<>
    {this.state.BenefitUser.map((item:BeniFitUserWeb ,index:number)=>(
              <Grid item xs={12} md={6} lg={3}>
               <Box className="hover-card" style={webStyleLanding.cardStyle} key={index}>
               <Grid style={webStyleLanding.imgBox}>
                 <Box style={{textAlign:"center", padding:"10px"}}>
                 <React.Suspense fallback={<CircularProgress />}>
                <img src={item.attributes.image} style={webStyleLanding.imgWight300} />
              </React.Suspense>
                     <Typography variant="h3" style={webStyleLanding.titleh3}>{item?.attributes?.title}</Typography>
                     <Typography variant="body1" style={webStyleLanding.titlebody}>{item?.attributes?.description}</Typography>
                 </Box>
               </Grid>
           </Box>
           </Grid>
            ))}
    </>:
     <>
     <Box sx={{display:"flex", justifyContent:"center", width:"100%"}}>
     <Box sx={{display:"block", textAlign:"center"}}>
              <React.Suspense fallback={<CircularProgress />}>
                <img src={noBenifit} />
              </React.Suspense>
        <Typography variant="body1" className="fontSizeNoData" style={{margin:"20px 0", fontSize:"24px", fontWeight:700}}>No Travelling Benefit is Available</Typography>
      </Box>
     </Box>
      </>
    }
           
    </>
  )
}
offerForUUI=()=>{
  return(
    <>
    {
      this.state.offerForYou.length>0?
      <Carousel
      responsive={responsive}
      infinite={true}
      partialVisbile={false}
       arrows
      removeArrowOnDeviceType={['tablet', 'mobile']}
      className={this.state.offerForYou.length>2?"":"coustomSlider"}
    >
      {this.state.offerForYou.map((item: OfferFor, index: number) => (
        <div className="hover-card" key={index} onClick={()=>this.handleClickOffersDetails(item.id)} style={{cursor:"pointer"}}>
          <Box style={{...webStyleLanding.cardStyle, ...webStyleLanding.cardStyle1}}>
            <Grid container spacing={2} style={webStyleLanding.imgBox}>
              <Grid item xs={6} md={6} lg={6}>
              <React.Suspense fallback={<CircularProgress />}>
                <div className="imgOffersCustom">
                <ImageWithSkeleton src={item.attributes.image} type={"offers"} />
                </div>
             
              </React.Suspense>
              </Grid>
              <Grid item xs={6} md={6} lg={6}>
                <Typography style={webStyleLanding.Typographytitle}>
                 {item.attributes.tagline1}
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <div style={{ marginTop: "10px", marginBottom: "10px", }}>
                <Typography className="TypographyHead" style={webStyleLanding.TypographyHead}>{this.truncateString(item.attributes.title, 20)}</Typography>
                <Typography style={webStyleLanding.TypographyrowII}>{item.attributes.tagline2}</Typography>
                <Typography style={webStyleLanding.TypographyStylelast}>{item.attributes.offer_code}</Typography>
              </div>
            </Grid>
            <div style={{ border: "1px dashed rgb(23, 100, 160)", margin: "18px 0 18px 0", width: "100%" }}></div>
            <Grid item xs={12} md={12} lg={12} >
              <Typography data-test-id="handleClickOffersDetails" onClick={()=>this.handleClickOffersDetails(item.id)} style={webStyleLanding.DetailsStyle}  >
                View More Details
              </Typography>
            </Grid>
          </Box>
        </div>
      ))}
    </Carousel>
      :
      <>
      <Box sx={{display:"flex", justifyContent:"center", width:"100%"}}>
      <Box sx={{display:"block", textAlign:"center"}}>
                <React.Suspense fallback={<CircularProgress />}>
                  <img src={noOffers} />
                </React.Suspense>
         <Typography variant="body1" className="fontSizeNoData" style={{margin:"20px 0", fontSize:"24px", fontWeight:700}}>No Offer is Available</Typography>
       </Box>
      </Box>
       </>
    }
    </>
  )
}
conditionMulticityButton=(cityIndex:number)=>{
 return(
  <Box  style={ {border: "rgba(0, 0, 0, 0.23) 1px solid",
  borderRadius: "0 10px 10px 0", height:"89%",
  paddingBottom:"10px", textAlign: "center",position:"relative",
  fontSize: "16px", display:"flex", justifyContent:"center",alignItems:"center"
}} className="borderCustom">
   <Button
                          onClick={this.addAnotherCity}
                          data-test-id={`addAnotherCity`}
                          variant="outlined"
                          style={{ marginTop: "10px", fontSize:"16px", fontWeight:700, color:"rgba(23, 100, 160, 1)", border:"1px solid rgba(23, 100, 160, 1)", width:"70%", padding:"10px" }}
                        >
                          + Add Another City
                        </Button>
                        {
                            cityIndex>1 && <div data-test-id={`iconCloseCale`+cityIndex} className="iconCloseCale" onClick={()=>this.iconCloseRemoveItem(cityIndex)}>
                            <CloseIcon />
                          </div>
                           }
</Box>
 )
}
  destinationUI = () => {
    return (
      <>
        {
          this.state.popularDestination.length > 0 ? 
          <Carousel
            responsive={responsive}
            infinite={true}
            removeArrowOnDeviceType={['tablet', 'mobile']}
            containerClass="carousel-container"
            partialVisbile={false}
            arrows
            className={this.state.popularDestination.length > 2 ? "" : "coustomSlider"}
          >
            {this.state.popularDestination.map((item: PDesti, index: number) => (
              <div key={index} >
                <div className="hover-card imgDesinationRes" data-test-id={`popular-destination${index}`} onClick={() => this.handleClickBookNowDetails(item.attributes.iata_object)} style={{ position: "relative", overflow: "hidden", margin: "10px" }}>
                  <React.Suspense fallback={<CircularProgress />}>
                  <ImageWithSkeleton src={item.attributes.image} type={"destination"} />
                  </React.Suspense>
                  <div style={{ paddingLeft: '21px', paddingRight: "10px", paddingTop: '10px', position: "absolute", bottom: "30px" }}>
                    <Typography style={webStyleLanding.secTwoTitle}> {item.attributes.title}</Typography>
              
                  </div>
                </div>
              </div>
            ))}
          </Carousel> :
            <>
              <Box sx={{ display: "flex", justifyContent: "center", width: "100%" }}>
                <Box sx={{ display: "block", textAlign: "center" }}>
                  <React.Suspense fallback={<CircularProgress />}>
                    <img src={noDestination} />
                  </React.Suspense>
                  <Typography variant="body1" className="fontSizeNoData" style={{ margin: "20px 0", fontSize: "24px", fontWeight: 700 }}>No Destination is Available</Typography>
                </Box>
              </Box>
            </>
        }
      </>
    )
  }
  blogPostUi = () => {
    return (
      <>
        {this.state.blogs.length > 0 ?
          <Carousel
            removeArrowOnDeviceType={['tablet', 'mobile']}
            containerClass="carousel-container"
            partialVisbile={false}
            responsive={responsive}
            infinite={true}
            arrows
            className={this.state.blogs.length > 2 ? "" : "coustomSlider"}
          >
            {this.state.blogs.map((item: BlogType, index: number) => (
              <div key={index} >
                
                <div className="imgBlogRes hover-card" onClick={() => this.handleClickBlogDetails(item.id)}  data-test-id="handleClickBlogDetailsBtn"  style={{ position: "relative", overflow: "hidden", margin: "10px" }}>
                  <React.Suspense fallback={<CircularProgress />}>
                  <ImageWithSkeleton src={item.attributes.image} type={"blogs"} />
                  </React.Suspense>
                  <div style={{ position: "absolute", bottom: "40px", paddingLeft: "21px", paddingRight: "10px", paddingBottom: "10px" }}>
                    <Typography style={webStyleLanding.secTwoTitleBlog}>{this.truncateString(item.attributes.name, 25)}</Typography>
                    <Typography style={webStyleLanding.secTwoTitle3}  >Read More</Typography>
                  </div>
                </div>
              </div>
            ))}
          </Carousel>
          :
          <>
            <Box sx={{ display: "flex", justifyContent: "center", width: "100%" }}>
              <Box sx={{ display: "block", textAlign: "center" }}>
                <React.Suspense fallback={<CircularProgress />}>
                  <img src={noBlog} />
                </React.Suspense>
                <Typography className="fontSizeNoData" variant="body1" style={{ margin: "20px 0", fontSize: "24px", fontWeight: 700 }}>No Blog is Available</Typography>
              </Box>
            </Box>
          </>
        }
      </>
    )
  }
userWhereTogo=()=>{
  return(
    <>
    {this.state.whereToGo.length>0?
    <>{this.state.whereToGo.map((item:WhereToWeb)=>(
      <Grid item xs={12} md={6} lg={6}>
        <Box className="hover-card img_space"  style={{...webStyleLanding.imgBox,}}>
        <React.Suspense fallback={<CircularProgress />}>
              <div className="imageWheretogo overflowXA">
                <ImageWithSkeleton src={item.attributes.image} type={"whereToGo"} />
              </div>
            </React.Suspense>
            <Box style={{ margin: "10px" }}>
              <Typography variant="h3" style={webStyleLanding.titleh3w} className="fontArtical">{item?.attributes?.destination}</Typography>
              <Typography variant="body1" style={webStyleLanding.titlebodyw} className="fontArtical">{item?.attributes?.root}</Typography>
            </Box>
        
        </Box>
    </Grid>
    ))}</>:
    <>
    <Box sx={{display:"flex", justifyContent:"center", width:"100%"}}>
    <Box sx={{display:"block", textAlign:"center"}}>
              <React.Suspense fallback={<CircularProgress />}>
                <img src={whereToGo} />
              </React.Suspense>
       <Typography className="fontSizeNoData" variant="body1" style={{margin:"20px 0", fontSize:"24px", fontWeight:700}}>No Where to Go is Available</Typography>
     </Box>
    </Box>
     </>}
    </>
  )
}
multicityFunctUi=()=>{
  return(
    <>
     {this.state.selectedOption === 'Multicity' ? 
           <>{this.multicityInputBox()}</>:<>{this.inisialInputBox()}</>
               }
    </>
  )
}
multicityInputBox=()=>{
  return(
    <MultiCityHeaderLandingPage data-test-id='multi-city-header' state={this.state}
    handleClose={this.handleClose}
    handleCloseCustomAi={this.handleCloseCustomAi}
    decreaseCount={this.decreaseCount}
    decreaseCountChild={this.decreaseCountChild}
    increaseCountChildInfant={this.increaseCountChildInfant}
    decreaseCountChildInfant={this.decreaseCountChildInfant}
    handleClickStatus={this.handleClickStatus}
    handleCancelTravel={this.handleCancelTravel}
    addAnotherCity={this.addAnotherCity}
    handleClickClass={this.handleClickClass}
    handleCloseClass={this.handleCloseClass}
    handleDone={this.handleDone}
    increaseCountChild={this.increaseCountChild}
    increaseCount={this.increaseCount}
    handleCancel={this.handleCancel}
    iconCloseRemoveItem={this.iconCloseRemoveItem}
    handleMulticityApply={this.handleMulticityApply}
    handleDateChange1={this.handleDateChange1}
    inputRef={this.inputRef}
    handleFromInputChange={this.handleFromInputChange}
    handleFromCity={this.handleFromCity}
    handleMultiCityInputChangeTo={this.handleMultiCityInputChangeTo}
    handleMulticityClickTo={this.handleMulticityClickTo}
    handleFromDropdown={this.handleFromDropdown}
    handleMulticityCloseTo={this.handleMulticityCloseTo}
    reverseFunct={this.reverseFunctMultiCity}
    togglePickerM={this.togglePickerM}
     handleClickNationality={this.handleClickNationality} 
     handleNationalityClass={this.handleNationalityClass }
     handleSelectNationality={this.handleSelectNationality} 
      />
  )
}


inisialInputBox=()=>{
  return(
    <>
    <Grid item xs={12} sm={6} md={6} lg={this.state.selectedOption==='Roundtrip'?2:3} style={{position:"relative",}}>
        <TextField
        autoComplete="off"
          onBlur={()=> setTimeout(()=>{
            this.handleClose()
          },300)}
          name="from"
          inputRef={this.inputRef}
          onChange={this.handleInputChange}
          style={{ cursor: "pointer" }}
          label={(
            <>
              <span>From</span>
              {this.formInputText()}
            </>
          )}
          variant="outlined"
          placeholder={"Enter city/ Airport name"}
          data-test-id="inputCityFrom"
          value={this.state.inputSearch}
          onClick={this.handleClick}
          fullWidth
          InputProps={{
            className: "borderCustom",
            style: {
              borderRadius: "10px 0 0 10px",
              textAlign: "center",
              fontSize: "16px",
              paddingTop: "10px",
              cursor: "pointer"
            },
            classes: {
              input: "landing-header-box",
            }
          }}
          InputLabelProps={{
            className: "fontFrom fontLabel",
            shrink: true,
            style: { transform: "translate(14px, 7px)", cursor: "pointer", color: "#000", fontWeight: "700", fontSize: "16px", width: "100%" }
          }}
        />
        { (this.state.filteredItems.length> 0 && this.state.inputSearch && Boolean(this.state.anchorEl)) &&
         <Box className="yscrollwindow fromDropdown" sx={{ width: { xs: "94%", sm: "94%", md: "95%", lg:this.state.selectedOption==="Roundtrip"?"130%":"96%" } }}         style={{ padding: "10px" }}>
          {this.state.filteredItems.map((itemObj: SearchTextObj, index: number) => (
            <Box data-test-id = {`fromMenu${index}`} style={{ borderBottom: "1px solid rgba(226, 232, 240, 1)" }} key={index} onClick={() => this.handleSingleClose(itemObj)}>
              <Box className="highlight-item" style={{ paddingTop: "10px", width: "100%",cursor:'pointer' }}>
                <Grid container spacing={2} 
                style={{display:'flex',
                justifyContent:"space-between",
                padding:"12px"}}>
                  <Box 
                  style={{display:'flex',gap:'10px'}}> <Grid item> <FlightTakeoffIcon style={{ color: "rgba(148, 163, 184, 1)" }} /> </Grid>
                  <Grid item >
                    <Typography style={{ color: "#000", paddingBottom: "12px", fontWeight: "600", fontSize: "12px", }} variant="body1">{itemObj.state}</Typography>
                    <Typography style={{ color: "rgba(100, 116, 139, 1)", fontSize: "12px", paddingBottom: "10px", fontWeight: "600" }} variant="body1">{itemObj.flight}</Typography>

                  </Grid></Box>
                 
                  <Grid item>
                    <Typography style={{ color: "rgba(100, 116, 139, 1)", fontSize: "12px", fontWeight: "600" }}
                      variant="body1">{itemObj.stateCode}</Typography>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          ))}
        </Box>
         } 
        <Box data-test-id="reverseFunct" onClick={this.reverseFunct} className="reversChangeIcon">
              <SyncAltIcon style={{color:"rgb(23, 100, 160)", cursor:"pointer"}} />
        </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={this.state.selectedOption==='Roundtrip'?2:3} className="inputCity" style={{position:'relative'}}>
          <TextField
            name="to"
            onBlur={()=> 
              
              setTimeout(()=>{this.handleClose()
            },
            300)}
            
            label={(
              <>
              <span>To</span>
             {this.toInputText()}
              </>
            )}
            onChange={this.handleInputChangeTo}
            value={this.state.inputSearchTo}
            data-test-id="inputCityTo"
            onClick={this.handleClickTo}
            variant="outlined"
            autoComplete = "off"
            placeholder={"Enter city/ Airport name"}
            fullWidth
            InputProps={{
              classes:{
                input: "landing-header-box",
              },
              style: {
                borderRadius: "0",
                textAlign: "center",
                fontSize: "16px",
                paddingTop:"10px",
                cursor:"pointer"
              }
            }}
            InputLabelProps={{
              className:"fontFrom fontLabel",
              shrink: true,
              style: {overflow:"hidden", height:"100%", transform: "translate(14px, 7px)", color:"#000", fontWeight:"700",fontSize: "16px",width:"100%"}
          }}
          />
          {(this.state.filteredItemsTo.length >0 && this.state.inputSearchTo && Boolean(this.state.anchorElTo)) &&  <Box sx={{ width: { 
            xs: "94%", sm: "94%", md: "95%",
           lg:this.state.selectedOption==="Roundtrip"?"130%":"96%" } }} className="yscrollwindow fromDropdown" style={{ padding: "10px" }}>
            {this.state.filteredItemsTo.map((item: SearchTextObj, index: number) => (
              <Box data-test-id = {`toMenu${index}`} style={{ borderBottom: "1px solid rgba(226, 232, 240, 1)" }} key={index} onClick={() => this.handleCloseTo(item)}>
                <Box style={{ width: "100%", paddingTop: "10px" }} className="highlight-item">
                  <Grid container spacing={2} style={{display:'flex',justifyContent:"space-between",padding:"12px"}}>
                    <Box style={{display:'flex',gap:'10px'}}>
                      <Grid item>
                        <FlightTakeoffIcon style={{ color: "rgba(148, 163, 184, 1)" }} />
                      </Grid>
                      <Grid item>
                        <Typography style={{ color: "#000", fontSize: "12px", paddingBottom: "12px", fontWeight: "600" }} variant="body1">{item.state}</Typography>
                        <Typography style={{ color: "rgba(100, 116, 139, 1)", fontSize: "12px", paddingBottom: "10px", fontWeight: "600" }} variant="body1">{item.flight}</Typography>
                      </Grid>
                    </Box>
                    <Grid item>
                      <Typography style={{ color: "rgba(100, 116, 139, 1)", fontSize: "12px", fontWeight: "600" }} variant="body1">{item.stateCode}</Typography>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            ))}
          </Box>}
        </Grid>
        <Grid item xs={12} style={{position:'relative'}} sm={12} md={this.state.selectedOption==='Roundtrip'?4:6} lg={2}>
          
          <TextField
            name="departureDate"
            label={(
              <>
               <div style={{ display: "flex", alignItems: "center",justifyContent:"space-between" }}>
                <span>Departure Date</span>
                  <ExpandMoreIcon style={{color:"rgba(23, 100, 160, 1)"}} /> 
                  
              </div>
              {this.state.startDate ? (
            <div style={{position: "absolute",
              background: "#fff",
              padding: "10px 0"}}>
              <span className="textcalenderday" style={{fontWeight:"700", fontSize: "32px" }}>{this.formatDatePart(this.state.startDate, "day")}</span>
              <span className="textcalendermonth" style={{fontWeight:"500", fontSize: "24px" }}>{this.formatDatePart(this.state.startDate, "month")}</span><br />
              <span className="textcalendername" style={{fontWeight:"500", fontSize: "16px", display:"block", marginTop:"10px",color:"rgba(100, 116, 139, 1)" }}>{this.formatDatePart(this.state.startDate, "year")}</span>
            </div>
          ) : ''}
              </>
             
          )}
          onClick={(event:React.MouseEvent<HTMLDivElement>) => this.togglePicker(event,'startDate')}

data-test-id="startDate"
            placeholder={this.state.startDate?"":"Select the departure date"}
            variant="outlined"
            fullWidth
            InputProps={{
              style: {
                borderRadius: "0",
                textAlign: "center",
                fontSize: "16px",
                cursor:"pointer",
                paddingBottom:"10px"
              },
              classes:
              {
                  input: "landing-header-box",
              }
            }}
            InputLabelProps={{
              className:"fontLabel",
              shrink: true,
              style: { transform: "translate(14px, 7px)", color:"#000", fontWeight:"700",fontSize: "16px" }
          }}
          />
         
        </Grid>
     {this.calenderBoxCondition()}
{(this.state.selectedOption !== 'Multicity' && this.state.selectedOption!=='One Way')  && (
        <Grid item xs={12} sm={12} md={4} lg={2} style={{position:"relative"}}>
          <TextField
            name="returnDate"
            label={(
              <>
              <div style={{ display: "flex", alignItems: "center",justifyContent:"space-between" }}>
                <span>Return Date</span>
                  <ExpandMoreIcon style={{color:"rgba(23, 100, 160, 1)"}} /> 
                  
              </div>
              {this.state.endDate1 ? (
           <div>
             <div style={{position: "absolute",
              background: "#fff",
              padding: "10px 0"}}>
              <span className="textcalenderday" style={{fontWeight:"700", fontSize: "32px" }} data-test-id="formatDay">{this.formatDatePart(this.state.endDate1, "day")}</span>
              <span className="textcalendermonth" style={{fontWeight:"500", fontSize: "24px" }} data-test-id="formatMonth">{this.formatDatePart(this.state.endDate1, "month")}</span><br />
              <span className="textcalendername" style={{fontWeight:"500", fontSize: "16px", display:"block", marginTop:"10px",color:"rgba(100, 116, 139, 1)" }} data-test-id="formatYear">{this.formatDatePart(this.state.endDate1, "year")}</span>
            </div>
           
           </div>
          ) : ''}
              </>
          )}
          onClick={(event) => this.togglePicker(event,'endDate')}
          data-test-id="endDate"
            placeholder={this.state.endDate1?"":`Select the return date
            (save more on two ways)`}
            variant="outlined"
            fullWidth
            InputProps={{
              style: {
                cursor:"pointer",
                borderRadius: "0",
                textAlign: "center",
                fontSize: "16px",
                paddingBottom:"10px"
              },classes:{
                input: "landing-header-box",
              }
            }}
            InputLabelProps={{
              className:"fontLabel",
              shrink: true,
              style: { transform: "translate(14px, 7px)", color:"#000", fontWeight:"700",fontSize: "16px", cursor:"pointer" }
          }}
          />
           {
            this.state.endDate1 && <div data-test-id="iconClose" className="iconCloseCale" onClick={this.iconClose}>
            <CloseIcon />
          </div>
           }
        </Grid>
)}

 <Grid style={{position:'relative'}} item xs={12} sm={12} md={this.state.selectedOption==='One Way'?6:4} lg={2}>
          <TextField
            name="traveller"
            label={(
              <div style={{ display: "flex", alignItems: "center",justifyContent:"space-between" }}>

                <span>Nationality</span>
                  <ExpandMoreIcon style={{color:"rgba(23, 100, 160, 1)"}} /> 
                 {this.nationalInputText()}
              </div>
              
          )}
            placeholder={"Select Nationality"}
            value={""}
            data-test-id="handleClickNationality"
            variant="outlined"
            fullWidth
           
            onClick={this.handleClickNationality}
            style={{ width: "100%", overflow:"hidden" }}
            InputProps={{
              className:"borderCustom",
              classes:{
                input: "landing-header-box",
              },
              style: {
               
                cursor:"pointer",
                borderRadius: "0 0",
                textAlign: "center",
                fontSize: "16px",
                paddingBottom:"10px"
              }
            }}
            InputLabelProps={{
              className:"fontLabel",shrink: true,
              style: { transform: "translate(14px, 7px)", color:"#000", cursor:"pointer", fontWeight:"700",fontSize: "16px" }
          }}
          />
        <Menu
          marginThreshold={72}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left'
          }}
          data-test-id="anchorElNationalClass"
          id="simple-menu"
          anchorEl={this.state.anchorElNationalClass}
          PaperProps={{
            style: {
              width: 300,
              borderRadius: "10px",
              marginTop: 40,
            },

          }}
          open={Boolean(this.state.anchorElNationalClass)}
          onClose={this.handleNationalityClass}
          keepMounted

        >
          <MenuItem onClick={() => this.handleSelectNationality('Indian')}>Indian</MenuItem>
          <MenuItem onClick={() => this.handleSelectNationality('Nepalese')}>Nepalese</MenuItem>
          <MenuItem onClick={() => this.handleSelectNationality('Other')}>Others</MenuItem>
        </Menu>
        </Grid>
        <Grid style={{position:'relative'}} item xs={12} sm={12} md={12} lg={2}>
          <TextField
            name="travellers & class"

            label={(
              <div style={{ display: "flex", alignItems: "center",justifyContent:"space-between" }}>
                <span>Travellers & Class</span>
                  <ExpandMoreIcon style={{color:"rgba(23, 100, 160, 1)"}} /> 
                 {this.travelInputText()}
              </div>
              
          )}
            placeholder={"Select no.of travellers\n& Class."}
            variant="outlined"
            fullWidth
            value={""}
            data-test-id="handleClickClass"
            onClick={this.handleClickClass}
            style={{ width: "100%", overflow:"hidden" }}
            InputProps={{
              className:"borderCustom",
              classes:
              {
                input: "landing-header-box",
              },
              style: {
                borderRadius: "0 10px 10px 0",
                textAlign: "center",
                cursor:"pointer",
                fontSize: "16px",
                paddingBottom:"10px"
              }
            }}
            InputLabelProps={{
              className:"fontLabel",
              shrink: true,
              style: { transform: "translate(14px, 7px)", color:"#000", cursor:"pointer", fontWeight:"700",fontSize: "16px" }
          }}
          />
          <Menu
marginThreshold={72}
transformOrigin={{
vertical: 'top',
horizontal: 'left'
}}
data-test-id="handleCloseClass"
className="menuFrom"
id="simple-menu"
anchorEl={this.state.anchorElClass}
PaperProps={{
style: {
width: 600,
borderRadius:"10px"
}
}}
open={Boolean(this.state.anchorElClass)}
onClose={this.handleCloseClass}
keepMounted

>

{this.travellerBoxUi()}
</Menu>
        </Grid>
      </>
  )
}
calenderBoxCondition=()=>{
  return (
      <Popover
        data-test-id="popover"
        open={this.state.isOpen}
        anchorEl={this.state.setAnchorAi}
        onClose={this.handleCloseCustomAi}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
          {this.state.selectedOption==="One Way"?
        <div className="boxCalenderDone111" style={{borderRadius:"10px", width:"auto", boxShadow:"rgba(0, 0, 0, 0.25) 0px 0px 10px", zIndex: 9999, background:"#fff" }}>
<div className="datepikerTop111">
<div className="calTitle" style={{padding:"12px 20px", width:"auto"}}>
<Typography  variant="h4" ><span className="font14px">{this.state.startDate?this.formatDate(this.state.startDate):"Departure Date"}</span></Typography>
</div>

<div className="singleCalender calenderBoox">
<SingleCalender state={this.state} month={1} data-test-id="calenderBlock" handleDateChange={this.handleDateChange1} />
</div>

<Box className="buttonBoxapply111">
<Button style={{color:"rgba(23, 100, 160, 1)", fontSize:"16px", textTransform:"capitalize", fontWeight:"700"}} data-test-id="handleCancel" onClick={this.handleCancel}>Cancel</Button>&nbsp;&nbsp;&nbsp;&nbsp;
<Button style={{background:"rgba(23, 100, 160, 1)", color:"#fff", fontSize:"16px", textTransform:"capitalize",fontWeight:"700"}} data-test-id="handleApply" onClick={this.handleApply}>&nbsp;Apply&nbsp;</Button>
</Box>
</div>

  </div>
  : <div className="boxCalenderDone111" style={{borderRadius:"10px", width:"auto", boxShadow:"rgba(0, 0, 0, 0.25) 0px 0px 10px", background:"#fff" }}>
<div className="datepikerTop111">
<div className="calTitle" style={{width:"auto"}}>
<Typography  variant="h4" ><span className="font14px">{this.state.startDate?this.formatDate(this.state.startDate):"Departure Date"}</span>&nbsp;-&nbsp;<span className="font14px">{this.state.endDate1?this.formatDate(this.state.endDate1):<>Select Return Date <span className="fontWee400">(save more on two ways)</span></>}</span></Typography>
</div>

<div className="calenderBoox">
<Calender state={this.state} data-test-id="calenderBlock" handleDateChange={this.handleDateChange} />
</div>

<Box className="buttonBoxapply111">
<Button style={{color:"rgba(23, 100, 160, 1)", fontSize:"16px", textTransform:"capitalize", fontWeight:"700"}} data-test-id="handleCancel" onClick={this.handleCancel}>Cancel</Button>&nbsp;&nbsp;&nbsp;&nbsp;
<Button style={{background:"rgba(23, 100, 160, 1)", color:"#fff", fontSize:"16px", textTransform:"capitalize",fontWeight:"700"}} data-test-id="handleApply" onClick={this.handleApply}>&nbsp;Apply&nbsp;</Button>
</Box>
</div>

</div> }
      </Popover>
  )
}
travellerBoxUi=()=>{
  return(
    <>
    <Box style={{padding:"10px"}}>
<Box className="classTravelHead">
<Typography style={{fontSize:"12px", color:"#000",fontWeight:700}} variant="body1">{this.state.countAdult} {this.state.countAdult<=1?"Adult":"Adults"} , {this.state.countChild} {this.state.countChild<=1?"Child":"Children"}, {this.state.countInfant}  {this.state.countInfant<=1?"Infant":"Infants"}</Typography>
<Typography style={{fontSize:"12px", color:"rgba(100, 116, 139, 1)",}} variant="body1">{this.state.TravelStatus}</Typography>
</Box>
<Box style={{width:"100%",borderBottom:"1px solid rgba(226, 232, 240, 1)", marginTop:"10px"}}></Box>
<Box style={{width:"100%",}}>
<Grid container spacing={2}>
<Grid item xs={6} sm={4}>
<Box sx={{padding:{xs:"10px", sm:"10px",md:"19px 10px 0px 10px"}, textAlign:"center"}}>
<Typography style={{fontSize:"24px", color:"#000",fontWeight:"700"}} variant="body1">  {this.state.countAdult} {this.state.countAdult<=1?"Adult":"Adults"}</Typography>
<Typography style={{fontSize:"12px", color:"rgba(100, 116, 139, 1)",padding:"10px 0 20px 0"}} variant="body1">(Aged 18+years) </Typography>
<Box sx={{padding:{xs:"6px 12px", sm:"6px 16px",md:"6px 20px"}}} className="countDivBox">
<button className="borderNoneCount" data-test-id="decreaseCount" onClick={this.decreaseCount}><RemoveIcon/></button>
<div className="countShowText">{this.state.countAdult}</div>
<button className="borderNoneCount" data-test-id="increaseCount" onClick={this.increaseCount}><AddIcon /></button>
</Box>
</Box>
</Grid>
<Grid item xs={6} sm={4}>
<Box sx={{ textAlign:"center",padding:{xs:"10px", sm:"10px",md:"19px 10px 0px 10px"}}}>
<Typography style={{fontSize:"24px", color:"#000",fontWeight:"700"}} variant="body1">  {this.state.countChild} {this.state.countChild<=1?"Child":"Children"}</Typography>
<Typography style={{fontSize:"12px", color:"rgba(100, 116, 139, 1)",padding:"10px 0 20px 0"}} variant="body1">(Aged 2-12 years)  </Typography>
<Box sx={{padding:{xs:"6px 12px", sm:"6px 16px",md:"6px 20px"}}} className="countDivBox">
<button className="borderNoneCount" data-test-id="decreaseCountChild" onClick={this.decreaseCountChild}><RemoveIcon/></button>
<div className="countShowText">{this.state.countChild}</div>
<button className="borderNoneCount" data-test-id="increaseCountChild" onClick={this.increaseCountChild}><AddIcon /></button>

</Box>
</Box>
</Grid>
<Grid item xs={12} sm={4}>
<Box sx={{ textAlign:"center",padding:{xs:"10px", sm:"10px",md:"19px 10px 0px 10px"}}}>
<Typography style={{fontSize:"24px", color:"#000",fontWeight:"700"}} variant="body1">  {this.state.countInfant} {this.state.countInfant<=1?"Infant":"Infants"}</Typography>
<Typography style={{fontSize:"12px", color:"rgba(100, 116, 139, 1)",padding:"10px 0 20px 0"}} variant="body1">(Under 2 years)  </Typography>
<Box sx={{padding:{xs:"6px 12px", sm:"6px 16px",md:"6px 20px"}}} className="countDivBox">
<button className="borderNoneCount" data-test-id="decreaseCountChildInfant" onClick={this.decreaseCountChildInfant}><RemoveIcon/></button>
<div className="countShowText">{this.state.countInfant}</div>
<button className="borderNoneCount" data-test-id="increaseCountChildInfant" onClick={this.increaseCountChildInfant}><AddIcon /></button>

</Box>
</Box>
</Grid>

{
this.state.error &&
<Grid item sm={12}>
        <Box className="ErrorMsg" sx={webStyleLanding.errorMsgforCount}>
        <span className="imgError" style={{width:"5%", marginRight:"5px"}}>
                          <React.Suspense fallback={<CircularProgress />}>
                            <img src={errorIcon} />
                          </React.Suspense>
          </span> <span style={{width:"95%"}}>
        If you want to add more than 9 travellers please contact email <span style={{textDecoration:"underline"}}>info@doolwa.com</span>
        </span>
        </Box>
        </Grid>
}

<Box sx={{width:"100%",textAlign:"center", padding:"0px 0 0px 0",
}}>
<Typography style={{fontSize:"24px", color:"#000",fontWeight:"700"}} variant="body1"> Travel Class</Typography>
</Box>
<Grid item sm={12}>
{this.allButtonList()}
</Grid>

<Box className="buttonBoxapply" style={{padding:"10px",margin:"0px 10px"}}>
<Button style={{color:"rgba(23, 100, 160, 1)", fontSize:"16px", textTransform:"capitalize", fontWeight:"700"}} data-test-id="handleCancelClassTravel" onClick={this.handleCancelTravel}>Cancel</Button>&nbsp;&nbsp;&nbsp;&nbsp;
<Button style={{background:"rgba(23, 100, 160, 1)", color:"#fff", fontSize:"16px", textTransform:"capitalize",fontWeight:"700"}} data-test-id="handleDone" onClick={this.handleDone}>&nbsp;Done&nbsp;</Button>
</Box>
</Grid>
</Box>
</Box>
    </>
  )
}
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      <>
      <Loader loading={this.state.loading} />
      <div style={{marginTop: "-10px"}} ref={this.scrollRef}>
        <BGComponent data-test-id="oceanbackground" oceanbackground={oceanbackground} />
      
      <HeaderMenuBlock navigation={this.props.navigation} id={""} />
     <Box className="boxHeaderpadding" style={{position:"relative", display:"flex",flexDirection:"column", alignItems:"center"}}>
                            <Box className="flaticonBox" style={{
                                display: "flex",
                               backgroundColor: '#FFFFFF',
                                position:"absolute",
                                width: "35%",
                                borderRadius: "15px",
                                zIndex: 9992,
                                justifyContent:"space-evenly",
                                boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.25)",
                            }}>
                                <Box style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    height: '130px',
                                    cursor:"pointer"
                                }}>
                                    <Box className="flitIcon">
                  <React.Suspense fallback={<CircularProgress />}>
                    <img src={airoplane} />
                  </React.Suspense>
                                    </Box>
                                    <Typography style={{ fontFamily: 'Inter, sans-serif', fontWeight: 700, color: 'rgba(23, 100, 160, 1)' }}>
                                        Flight Booking
                                    </Typography>
                                </Box>

                                <Box style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    height: '130px',
                                    cursor:"pointer",
                                    width:"113.8px"
                                }}
                                 onClick={()=>this.handleNavigate("Cfitinerarymanagement")} data-test-id="handleNavigateX">
                                    <Box className="flitIcon1">
                  <React.Suspense fallback={<CircularProgress />}>
                    <img src={umbrella} />
                  </React.Suspense>
                                    </Box>
                                    <Typography style={{ fontFamily: 'Inter, sans-serif', fontWeight: 700, color: 'rgba(23, 100, 160, 1)' }}>
                                        Packages
                                    </Typography>
                                </Box>
                            </Box>
                            
                            <Box className="boxSizeSearchDiv" style={{
                                backgroundColor: "#fff",
                                borderRadius: "10px",
                                width: "91%",
                                height: "50%",
                                zIndex: 4,
                                marginTop: 50,

                                boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.25)",
                            }}>
                                <Box style={{ marginTop: "83px", padding: "23px" }}>
                                    <RadioGroup aria-label="options" name="options" style={{ display: "flex", flexDirection: "row" }} value={this.state.selectedOption}>
                                        <FormControlLabel data-test-id="handleRadioClick" style={{color:"#000"}} onClick={() => this.handleRadioClick("One Way")} value={"One Way"} control={<Radio style={{color: 'rgba(23, 100, 160, 1)'}}/>} label="One Way" />
                                        <FormControlLabel data-test-id="handleRadioClick2" style={{color:"#000"}} onClick={() => this.handleRadioClick("Roundtrip")} value={"Roundtrip"} control={<Radio style={{color: 'rgba(23, 100, 160, 1)'}}/>} label="Roundtrip" />
                                        <FormControlLabel data-test-id="handleRadioClick3" style={{color:"#000"}}
                                         
                                         onClick={() => this.handleRadioClick("Multicity")} value={"Multicity"} control={<Radio style={{color: 'rgba(23, 100, 160, 1)'}}/>} label="Multicity" />
                                    </RadioGroup>
                                </Box>
                                {
(this.state.errorMessage || this.state.errorResSearch) &&
        <span className="imgErrorBox"> 
        {this.state.errorMessage || this.state.errorResSearch}
       </span>
}
                                <Box style={{margin:"0 20px"}}>
                                <Grid container spacing={0} data-test-id="searchInputGrid" className="searchFrom searchInput" style={{position:"relative"}}>
                                  {this.multicityFunctUi()}
                                    <Grid item xs={12}>
                                      <Box style={{display:"flex", justifyContent:"center", margin:"20px 0"}}>
                                      <Button
                                      data-test-id="searchFlight"
                                      className="buttonSubmitSearch"
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        onClick={this.searchFlight}
                                      >
                                        Search Flights
                                      </Button>
                                      </Box>
                                    </Grid>
                                  </Grid>
                               </Box>
                            </Box>
                           
                        </Box>
        <Box sx={{ padding:{xs:"10px 10px",sm:"10px 10px",md:"10px 30px",lg:"10px 60px" }, }}>
          <Grid container>
            <Grid item xs={6} md={6} lg={6} className="textH5" style={webStyleLanding.textAlignStart}>
              <Typography data-test-id="offerForYouText" className="marginleft10px" variant="h5"> Offers For You</Typography>
            </Grid>
            <Grid item xs={6} md={6} lg={6} className="textH5" style={webStyleLanding.viewAllStyle}>
              <div data-test-id="view-all-offers-button" onClick={()=> this.handleOffersViewAll()}>
                <Typography variant="h5"  className="fontViewAll" style={{marginRight: "20px",color: "rgb(23, 100, 160)", fontSize:"20px",cursor:"pointer"}}>View All</Typography>
              </div>
            </Grid>
            <Grid item xs={12}>
              {
                this.offerForUUI()
              }
            </Grid>
          </Grid>
          <Grid container>
           <div style={{display:"flex",justifyContent:"space-between",alignItems:"center",width:"100%"}}>
            <Grid item className="textH5" style={webStyleLanding.textAlignStart}>
                <Typography className="marginleft10px" variant="h5">Popular Destination</Typography>
              </Grid>
              <Grid item className="textH5" style={webStyleLanding.viewAllStyle}>
                  <span data-test-id="view-all-popularDesti" onClick={() => this.handlePopularDestiViewAll()}>
                    <Typography variant="h5" className="fontViewAll" style={{ marginRight: "20px", color: "rgb(23, 100, 160)", fontSize: "20px",cursor:"pointer" }}>View All</Typography>
                  </span>
              </Grid>
           </div>
            <Grid item xs={12}>
              {this.destinationUI()}
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} md={12} lg={12} className="textH5" style={webStyleLanding.textAlignStart}>
              <Box style={webStyleLanding.blogContent}>
                <Typography variant="h5" style={{marginBottom:"18px"}}>Blogs</Typography>
                <Box style={{ display: 'flex', justifyContent: 'center' }}>
                  <Typography 
                    style={{ 
                      ...webStyleLanding.blogDesc, 
                    }} 
                    className="fontArtical"
                    variant="body1">
                    Dive into our world of thoughts, insights, and stories. Explore our collection of captivating blogs, where every post is a journey worth exploring.
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
          <div style={{display:"flex",justifyContent:"flex-end",width:"100%"}}>
            <div data-test-id="view-all-blogs" className="textH5" onClick={()=> this.handleBlogsViewAll()}>
                <Typography variant="h5" className="fontViewAll " style={{marginRight: "20px",color: "rgb(23, 100, 160)", fontSize:"20px",cursor:"pointer"}}>View All</Typography>
            </div>
          </div>
          <Grid item xs={12}>
          {this.blogPostUi()}
            </Grid>
            {this.state.authToken!=""?<>
            <Grid container >
              <Grid item xs={12} md={12} lg={12} className="textH5" style={webStyleLanding.textAlignStart}>
              <Box style={webStyleLanding.blogContent}>
                  <Typography variant="h5" style={{marginBottom:"18px"}}>Where To Go</Typography>
                <Box style={{ display: 'flex', justifyContent: 'center' }}>
                    <Typography className="fontArtical"
                  style={{ 
                    ...webStyleLanding.blogDesc, wordBreak:"break-word"
                    }} 
                  variant="body1">
                      We live in a wonderful world that is full of beauty, charm and adventure.
                    </Typography>
                </Box>
              </Box>
            </Grid>
            <Box style={{margin:"10px",width:"100%" }}>
            <Grid spacing={4} container>
            
            {this.userWhereTogo()}

            </Grid>
            </Box>

            </Grid>
            </>:<>
            <Grid container>
              <Grid item xs={12} md={12} lg={12} className="textH5" style={webStyleLanding.textAlignStart}>
                <Box style={webStyleLanding.blogContent}>
                    <Typography variant="h5" style={{marginBottom:"18px"}}>Travelling Benefit for User</Typography>
                  <Box style={{ display: 'flex', justifyContent: 'center' }}>
                    <Typography 
                    style={{ 
                    ...webStyleLanding.blogDesc, 
                    }} 
                    className="fontArtical"
                    variant="body1">
                      Unlock the world with exclusive travel benefits curated just for you. Explore, experience, and save more with our tailored perks designed to elevate your journeys.
                    </Typography>
                </Box>
              </Box>
            </Grid>
          <Box style={{margin:"10px", width:"100%" }}>
          <Grid spacing={4} container>
              
              {this.userBenifit()}
          
          </Grid>
          </Box>
        
            </Grid>
            </>}
        </Box>
        <Footer />
      </div>
      </>
      // Customizable Area End
    );
  }

}
// Customizable Area Start

const webStyleLanding = {
  errorMsgforCount:{
    margin:"0px 10px",
    borderRadius: "5px",
    fontSize: "12px",
    borderLeft: "6px #c10f0ff0 solid",
    background:" #ed7e7e3d",
    fontFamily:"Inter, sans-serif",
    color: "#c12121",
    display:"flex",
    lineHeight:"24px",
    padding: "20px 15px",
    alignItems: "center"
},
  whareImg:{
    width:"100%",
    borderRadius: "50%",
    border: "6px rgb(240, 229, 255) solid",
    height:"150px"
  },
  titleh3w:{
    fontSize:"24px" as "24px",
    fontWeight:"700"
  },
  titlebodyw:{
    fontSize:"16px",
    color:"rgba(100, 116, 139, 1)",
    marginTop:"0px",
    wordBreak:"break-all" as "break-all"
  },
  Typographytitle: {
    textAlign: "start" as "start",
    fontWeight: "500",
    fontSize: "16px"
  },
  titleh3:{
    fontSize:"16px",
    fontWeight:"700",
    padding:"16px 0"
  },
  titlebody:{
    fontSize:"16px",
    fontWeight:"400",
    minHeight:"48px" 
  },
  imgWight300:{
    width:"123px",
    height:"108px"
  },
  textAlignStart: {
    textAlign: "start" as "start",
    marginTop: "30px",
    marginBottom: "30px",
    fontWeight:700,


  },
  viewAllStyle: {
    textAlign: "end" as "end",
    marginTop: "30px",
    marginBottom: "30px",
    fontWeight:700,

  },
  TypographyHead: {
    fontSize: "16px" as "16px",
    fontFamily: "Inter, sans-serif",
    textAlign: "center" as "center",
    backgroundColor: "rgba(250, 224, 224, 1)",
    padding: "5px",
    fontWeight: "700",
    margin: "0px 60px",
    textTransform: "lowercase" as "lowercase"
  },
  TypographyrowII: {
    fontSize: "14px" as "14px",
    textAlign: "center" as "center",
    fontWeight: "700",
    padding: "5px",
    fontFamily: "Inter, sans-serif"
  },
  cardStyle1:{
    margin: "12px",
  },
  cardStyle: {
    boxShadow: "rgba(0, 0, 0, 0.25) 0px 0px 10px",
    borderRadius: "15px",
    margin: "7px",
    overflow:"hidden",

  },
  TypographyStylelast: {
    fontSize: "14px" as "14px",
    textAlign: "center" as "center",
    fontWeight: "400",
    fontFamily: "Inter, sans-serif"
  },
  imgBox: {
    ustifyContent: "center",
    alignItems: "center",
    padding: "20px"
  },

  DetailsStyle: {
    fontSize: "16px" as "16px",
    color: "rgba(23, 100, 160, 1)",
    textAlign: "center" as "center",
    fontWeight: "700",
    fontFamily: "Inter, sans-serif",
    paddingBottom:"15px",
    cursor:"pointer"
  },
  secTwoTitle3:{
fontSize:"14px",
width:"fit-content",
background:"white",
borderRadius:"2px",
padding:"2px 20px",
fontWeight:"700",
color:"black",
textTransform: "uppercase" as "uppercase",
marginTop:"10px",
cursor:"pointer"
  },
  secTwoTitleBlog:{
    fontSize:"24px",
    fontWeight:"700",
    color:"#fff",
  },
secTwoTitle:{
  background:"#fff",
padding: "1px 10px",
fontSize: "16px",
fontWeight: "700",
width:"fit-content",
},
secTwoTitle2:{
  color:"#fff",
  padding: "7px 0",
  fontSize: "14px",
  fontWeight: "700",
  display: "flex",
alignItems: "center"
},
blogDesc:{
fontSize:"24px",
width:"800px",
}
,blogContent:{
  textAlign:"center" as "center"
},
backgroundImage: {
  backgroundImage: `url(${oceanbackground})`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  backgroundPosition: "center",
  width: "100%",
  opacity: 2.5,
},
}



// Customizable Area End
