import React from "react";
import { Box, Button, Grid, Menu, MenuItem, Popover, TextField, Typography } from "@material-ui/core";
import SyncAltIcon from "@material-ui/icons/SyncAlt";
import { errorIcon } from '../../blocks/landingpage/src/assets'
import FlightTakeoffIcon from "@material-ui/icons/FlightTakeoff";
import { SearchText } from '../../blocks/catalogue/src/MultiCitySearchingController';
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import SingleCalender from "./SingleCalender";
import MultiTravel from './MultiCityTravel'
import { MultiCity } from "../../blocks/landingpage/src/LandingPageController.web";

interface DateRange {
    selection: {
        startDate: Date;
        endDate: Date;
    };
}
interface MultiCityHeaderProps {
    state: {
        selectedNationality: any;
        anchorElNationalClass: any;
        inputSearch: string
        inputSearchTo: string
        multicityTrips: MultiCity[]
        currentIndex: number
        filteredItems: SearchText[];
        filteredItemsTo: SearchText[];
        anchorEl: Element | null;
        anchorElTo: Element | null;
        isOpen: boolean
        setAnchorAi: Element | null
        TravelStatus: string;
        fullTravelObj: {
            countAdult: number,
            countChild: number,
            countInfant: number,
            TravelStatus: string
        }
        anchorElClass: Element | null;
        countAdult: number;
        countChild: number;
        countInfant: number;
        error: string
        startDate: Date | null
    };
    handleClose: () => void
    handleCloseCustomAi: () => void
    decreaseCount: () => void
    handleClickNationality:any
    handleNationalityClass:()=> void
    handleSelectNationality:any;
    decreaseCountChild: () => void
    increaseCountChildInfant: () => void
    decreaseCountChildInfant: () => void
    handleClickStatus: (name: string) => void
    handleCancelTravel: () => void
    addAnotherCity: () => void
    handleClickClass: (event: React.MouseEvent<HTMLElement>) => void
    handleCloseClass: () => void
    handleDone: () => void
    increaseCountChild: () => void
    increaseCount: () => void
    handleCancel: () => void
    iconCloseRemoveItem: (id: number) => void
    handleMulticityApply: (index: number) => void
    handleDateChange1: (ranges: DateRange) => void
    inputRef: React.RefObject<HTMLDivElement>
    handleFromInputChange: (e: React.ChangeEvent<HTMLInputElement>, index: number) => void
    handleFromCity: (e: React.MouseEvent<HTMLElement>, index: number) => void
    handleMultiCityInputChangeTo: (e: React.ChangeEvent<HTMLInputElement>, index: number) => void
    handleMulticityClickTo: (e: React.MouseEvent<HTMLElement>, index: number) => void
    handleFromDropdown: (item: SearchText) => void
    handleMulticityCloseTo: (item: SearchText) => void
    reverseFunct: (index: number) => void
    togglePickerM: (picker: string, index: number) => void

}

const MultiCityHeaderLandingPage = ({ state, decreaseCount,handleClickNationality,handleNationalityClass,handleSelectNationality,
    decreaseCountChild,
    handleDateChange1,
    handleCancel,
    handleMulticityApply,
    increaseCountChildInfant,
    iconCloseRemoveItem,
    decreaseCountChildInfant,
    handleClickStatus,
    handleCancelTravel,
    addAnotherCity,
    handleClickClass,
    handleCloseClass,
    handleDone, increaseCount, increaseCountChild, handleCloseCustomAi, togglePickerM, handleClose, inputRef, handleFromInputChange, handleMultiCityInputChangeTo, handleMulticityCloseTo, handleMulticityClickTo, handleFromCity, reverseFunct, handleFromDropdown }: MultiCityHeaderProps) => {
    const MultiCitytoInputText = (item: MultiCity) => {

        return (
            <>
                {item.airportTo.state && item.airportTo.flight ? (
                    <div style={{
                        position: "absolute", width: "92%",
                        background: "#fff", left: "10px",
                        padding: "5px 0"
                    }}>
                        <span className="textcalenderday" style={{ fontWeight: 700, fontSize: "32px" }}>{item.airportTo.state}</span>
                        <span className="textcalendername" style={{ fontWeight: 500, fontSize: "16px", display: "block", marginTop: "10px", color: "rgba(100, 116, 139, 1)", }}>{item.airportTo.stateCode},{item.airportTo.flight}</span>
                    </div>
                ) : ''}
            </>
        )
    }
    const MultiCityFromInputText = (item: MultiCity) => {

        return (
            <>
                {item.airportFrom.state && item.airportFrom.flight ? (
                    <div style={{
                        position: "absolute", width: "100%",
                        background: "#fff",
                        padding: "5px 0"
                    }}>
                        <span className="textcalenderday" style={{ fontWeight: 700, fontSize: "32px" }}>{item.airportFrom.state}</span>
                        <span className="textcalendername" style={{ fontWeight: 500, fontSize: "16px", display: "block", marginTop: "10px", color: "rgba(100, 116, 139, 1)", width: "96%" }}>{item.airportFrom.stateCode},{item.airportFrom.flight}</span>
                    </div>
                ) : ''}
            </>
        )

    }
    const formatDatePart = (value: Date, part: string) => {
        let dateValue: any = formatDate(value)
        if (dateValue) {
            const parts = dateValue.split(' ');
            switch (part) {
                case "day":
                    return parts[0];
                case "month":
                    return parts[1];
                case "year":
                    return parts[2];
            }
        }
    };

    const formatDate = (date: Date) => {
        if (date) {
            const dayaa = date.getDate();
            const month = date.toLocaleString('default', { month: 'short' });
            const year = date.getFullYear().toString().slice(-2);
            const weekday = date.toLocaleString('default', { weekday: 'long' });
            return `${dayaa} ${month}'${year} ${weekday}`;
        }
    };

    return (
        <React.Fragment>
            {state.multicityTrips.map((trip: MultiCity, cityIndex: number) => (
                <React.Fragment key={cityIndex}>
                    <Grid item xs={12} sm={6} md={6} lg={3} style={{ position: "relative", }}>
                        <TextField
                            autoComplete="off"
                            onBlur={() => setTimeout(() => {
                                handleClose()
                            }, 300)}
                            name="from"
                            inputRef={inputRef}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleFromInputChange(e, cityIndex)}
                            style={{ cursor: "pointer" }}
                            label={(
                                <>
                                    <span>From</span>
                                    {MultiCityFromInputText(trip)}
                                </>
                            )}
                            variant="outlined"
                            placeholder={"Enter city/ Airport name"}
                            data-test-id="inputCityFrom"
                            value={state.currentIndex === cityIndex && state.currentIndex !== null ? state.inputSearch : ''}
                            onClick={(e: React.MouseEvent<HTMLElement>) => handleFromCity(e, cityIndex)}
                            fullWidth
                            InputProps={{
                                className: "borderCustom",
                                style: {
                                    borderRadius: "10px 0 0 10px",
                                    textAlign: "center",
                                    fontSize: "16px",
                                    paddingTop: "10px",
                                    cursor: "pointer"
                                },
                                classes: {
                                    input: "landing-header-box",
                                }
                            }}
                            InputLabelProps={{
                                className: "fontFrom fontLabel",
                                shrink: true,
                                style: { transform: "translate(14px, 7px)", cursor: "pointer", color: "#000", fontWeight: 700, fontSize: "16px", width: "100%" }
                            }}
                        />
                        {(state.filteredItems.length > 0 && state.inputSearch && Boolean(state.anchorEl)) && cityIndex === state.currentIndex &&
                            <Box className="yscrollwindow fromDropdown" sx={{ width: { xs: "94%", sm: "94%", md: "95%", lg: "96%" } }} style={{ padding: "10px" }}>
                                {state.filteredItems.map((itemObj: SearchText, index: number) => (
                                    <Box data-test-id={`fromMenu${index}`} style={{ borderBottom: "1px solid rgba(226, 232, 240, 1)" }} key={index} onClick={() => handleFromDropdown(itemObj)}>
                                        <Box className="highlight-item" style={{ paddingTop: "10px", width: "100%", cursor: 'pointer' }}>
                                            <Grid container spacing={2}
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: "space-between",
                                                    padding: "12px"
                                                }}>
                                                <Box
                                                    style={{ display: 'flex', gap: '10px' }}> <Grid item> <FlightTakeoffIcon style={{ color: "rgba(148, 163, 184, 1)" }} /> </Grid>
                                                    <Grid item >
                                                        <Typography style={{ color: "#000", paddingBottom: "12px", fontWeight: 600, fontSize: "12px", }} variant="body1">{itemObj.state}</Typography>
                                                        <Typography style={{ color: "rgba(100, 116, 139, 1)", fontSize: "12px", paddingBottom: "10px", fontWeight: 600 }} variant="body1">{itemObj.flight}</Typography>

                                                    </Grid></Box>

                                                <Grid item>
                                                    <Typography style={{ color: "rgba(100, 116, 139, 1)", fontSize: "12px", fontWeight: 600 }}
                                                        variant="body1">{itemObj.stateCode}</Typography>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Box>
                                ))}
                            </Box>
                        }
                        <Box data-test-id="reverseFunct" onClick={() => reverseFunct(cityIndex)} className="reversChangeIcon">
                            <SyncAltIcon style={{ color: "rgb(23, 100, 160)", cursor: "pointer" }} />
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={3} className="inputCity" style={{ position: 'relative' }}>
                        <TextField
                            name="to"
                            onBlur={() =>

                                setTimeout(() => {
                                    handleClose()
                                },
                                    300)}

                            label={(
                                <>
                                    <span>To</span>
                                    {MultiCitytoInputText(trip)}
                                </>
                            )}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleMultiCityInputChangeTo(event, cityIndex)}
                            value={state.currentIndex === cityIndex && state.currentIndex !== null ? state.inputSearchTo : ''}
                            data-test-id="inputCityTo"
                            onClick={(e: React.MouseEvent<HTMLElement>) => handleMulticityClickTo(e, cityIndex)}
                            variant="outlined"
                            autoComplete="off"
                            placeholder={"Enter city/ Airport name"}
                            fullWidth
                            InputProps={{
                                classes: {
                                    input: "landing-header-box",
                                },
                                style: {
                                    borderRadius: "0",
                                    textAlign: "center",
                                    fontSize: "16px",
                                    paddingTop: "10px",
                                    cursor: "pointer"
                                }
                            }}
                            InputLabelProps={{
                                className: "fontFrom fontLabel",
                                shrink: true,
                                style: { overflow: "hidden", height: "100%", transform: "translate(14px, 7px)", color: "#000", fontWeight: 700, fontSize: "16px", width: "100%" }
                            }}
                        />
                        {(state.filteredItemsTo.length > 0 && state.inputSearchTo && Boolean(state.anchorElTo)) && cityIndex === state.currentIndex && <Box sx={{
                            width: {
                                xs: "94%", sm: "94%", md: "95%",
                                lg: "96%"
                            }
                        }} className="yscrollwindow fromDropdown" style={{ padding: "10px" }}>
                            {state.filteredItemsTo.map((item: SearchText, index: number) => (
                                <Box data-test-id={`toMenu${index}`} style={{ borderBottom: "1px solid rgba(226, 232, 240, 1)" }} key={index} onClick={() => handleMulticityCloseTo(item)}>
                                    <Box style={{ width: "100%", paddingTop: "10px" }} className="highlight-item">
                                        <Grid container spacing={2} style={{ display: 'flex', justifyContent: "space-between", padding: "12px" }}>
                                            <Box style={{ display: 'flex', gap: '10px' }}>
                                                <Grid item>
                                                    <FlightTakeoffIcon style={{ color: "rgba(148, 163, 184, 1)" }} />
                                                </Grid>
                                                <Grid item>
                                                    <Typography style={{ color: "#000", fontSize: "12px", paddingBottom: "12px", fontWeight: 600 }} variant="body1">{item.state}</Typography>
                                                    <Typography style={{ color: "rgba(100, 116, 139, 1)", fontSize: "12px", paddingBottom: "10px", fontWeight: 600 }} variant="body1">{item.flight}</Typography>
                                                </Grid>
                                            </Box>
                                            <Grid item>
                                                <Typography style={{ color: "rgba(100, 116, 139, 1)", fontSize: "12px", fontWeight: 600 }} variant="body1">{item.stateCode}</Typography>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Box>
                            ))}
                        </Box>}
                    </Grid>
                    <Grid className="textBoxMargin" item xs={12} sm={12} md={6} lg={2}>

                        <TextField
                            name="departureDate"
                            label={(
                                <>
                                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                        <span>Departure Date</span>
                                        <ExpandMoreIcon style={{ color: "rgba(23, 100, 160, 1)" }} />


                                    </div>
                                    {trip.departureDate ? (
                                        <div style={{
                                            position: "absolute",
                                            background: "#fff",
                                            padding: "10px 0"
                                        }}>
                                            <span className="textcalenderday" style={{ fontWeight: 700, fontSize: "32px" }}>{formatDatePart(trip.departureDate, "day")}</span>
                                            <span className="textcalendermonth" style={{ fontWeight: 500, fontSize: "24px" }}>{formatDatePart(trip.departureDate, "month")}</span><br />
                                            <span className="textcalendername" style={{ fontWeight: 500, fontSize: "16px", display: "block", marginTop: "10px", color: "rgba(100, 116, 139, 1)" }}>{formatDatePart(trip.departureDate, "year")}</span>
                                        </div>
                                    ) : ''}
                                </>

                            )}
                            onClick={() => togglePickerM('startDate', cityIndex)}
                            data-test-id={"startDateM" + cityIndex}
                            placeholder={trip.departureDate ? "" : "Select the departure date"}
                            variant="outlined"
                            fullWidth

                            InputLabelProps={{
                                className: "fontLabel",
                                shrink: true,
                                style: { transform: "translate(14px, 7px)", color: "#000", fontWeight: 700, fontSize: "16px" }
                            }}
                            InputProps={{
                                style: {
                                    borderRadius: "0",
                                    textAlign: "center",
                                    fontSize: "16px",
                                    paddingBottom: "10px"
                                }
                            }}
                        />

                    </Grid>
                    {state.isOpen && (
                        <Popover
                            data-test-id="popover"
                            open={state.isOpen}
                            anchorEl={state.setAnchorAi}
                            onClose={handleCloseCustomAi}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                            }}
                        >
                            <div className="boxCalenderDone111" style={{ borderRadius: "10px", width: "auto", boxShadow: "rgba(0, 0, 0, 0.25) 0px 0px 10px", zIndex: 9999, background: "#fff" }}>
                                <div className="datepikerTop111">
                                    <div className="calTitle" style={{ padding: "12px 20px", width: "auto" }}>
                                        <Typography variant="h4" ><span className="font14px">{state.startDate ? formatDate(state.startDate) : "Departure Date"}</span></Typography>
                                    </div>

                                    <div className="singleCalender calenderBoox">
                                        <SingleCalender state={state} month={1} data-test-id="calenderBlock" handleDateChange={handleDateChange1} />
                                    </div>

                                    <Box className="buttonBoxapply111">
                                        <Button style={{ color: "rgba(23, 100, 160, 1)", fontSize: "16px", textTransform: "capitalize", fontWeight: 700 }} data-test-id="handleCancel" onClick={handleCancel}>Cancel</Button>&nbsp;&nbsp;&nbsp;&nbsp;
                                        <Button style={{ background: "rgba(23, 100, 160, 1)", color: "#fff", fontSize: "16px", textTransform: "capitalize", fontWeight: 700 }} data-test-id="handleApply" onClick={() => handleMulticityApply(cityIndex)}>&nbsp;Apply&nbsp;</Button>
                                    </Box>
                                </div>

                            </div>
                        </Popover>
                    )}

<Grid item xs={12} sm={12} md={4} lg={2} style={cityIndex > 1 ? { overflow: "inherit",position:'relative' } : { overflow: "hidden",position:'relative' }}>
         {
            cityIndex===0?
            <>
             <TextField
            name="travellers & class"

            label={(
              <div style={{ display: "flex", alignItems: "center",justifyContent:"space-between" }}>
                <span>Nationality</span>
                  <ExpandMoreIcon style={{color:"rgba(23, 100, 160, 1)"}} /> 
                  {state.selectedNationality?(
                                               <div style={{position: "absolute",width:"134%",
                                                 background: "#fff",left:"0px",top:"35px",
                                                 padding: "5px 0"}}>
                                                 <span className="textcalendermonth" style={{fontWeight:500, fontSize: "24px" }} data-test-id="formatMonth">{state.selectedNationality}</span><br />
                                               </div>
                                             ) : ''}
              </div>
              
          )}
            placeholder={"Select Nationality"}
            variant="outlined"
            fullWidth
            value={""}
            data-test-id="handleClickClass"
            onClick={handleClickNationality}
            style={{ width: "100%", overflow:"hidden" }}
            InputProps={{
              className:"borderCustom",
              classes:
              {
                input: "landing-header-box",
              },
              style: {
                borderRadius: "0 0",
                textAlign: "center",
                cursor:"pointer",
                fontSize: "16px",
                paddingBottom:"10px"
              }
            }}
            InputLabelProps={{
              className:"fontLabel",
              shrink: true,
              style: { transform: "translate(14px, 7px)", color:"#000", cursor:"pointer", fontWeight:700,fontSize: "16px" }
          }}
          />
          <Menu
marginThreshold={72}
transformOrigin={{
vertical: 'top',
horizontal: 'left'
}}
data-test-id="handleCloseClass"
className="menuFrom"
id="simple-menu"
anchorEl={state.anchorElNationalClass}
PaperProps={{
style: {
width: 300,
borderRadius:"10px",
marginTop: 40,
}
}}
open={Boolean(state.anchorElNationalClass)}
onClose={handleNationalityClass}
keepMounted

>
<MenuItem onClick={() =>handleSelectNationality('Indian')}>Indian</MenuItem>
          <MenuItem onClick={() =>handleSelectNationality('Nepalese')}>Nepalese</MenuItem>
          <MenuItem onClick={() => handleSelectNationality('Other')}>Others</MenuItem>
</Menu>
            </>:
           <><Box style={{
            border: "rgba(0, 0, 0, 0.23) 1px solid",
            borderRadius: "0 0", height: "77.5%",
            textAlign: "center",
            fontSize: "16px",
            paddingBottom: "10px"
          }} className="borderCustom heigintdd "></Box></>
         }
        </Grid>
                    <Grid item className="textBoxMargin" xs={12} sm={12} md={6} lg={2} style={cityIndex > 1 ? { overflow: "inherit" } : { overflow: "hidden" }}>
                        <MultiTravel state={state} cityIndex={cityIndex} decreaseCount={decreaseCount} errorIcon={errorIcon}
                            decreaseCountChild={decreaseCountChild} increaseCountChildInfant={increaseCountChildInfant} decreaseCountChildInfant={decreaseCountChildInfant}
                            increaseCountChild={increaseCountChild} handleClickStatus={handleClickStatus} handleCancelTravel={handleCancelTravel}
                            increaseCount={increaseCount} handleDone={handleDone} addAnotherCity={addAnotherCity} iconCloseRemoveItem={iconCloseRemoveItem}
                            handleCloseClass={handleCloseClass} handleClickClass={handleClickClass} />

                    </Grid>
                </React.Fragment>))}
        </React.Fragment>
    )

}

export default MultiCityHeaderLandingPage