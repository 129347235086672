import React from "react";
// Customizable Area Start
import {
    Box,
    Typography,
    styled,
    Button
} from "@material-ui/core";
import CfapiintegrationforhimalayanbankapiController, { Props } from "./CfapiintegrationforhimalayanbankapiController";
import HeaderMenuBlock from "../../../blocks/navigationmenu/src/HeaderMenu.web";
import { failure } from "./assets";
const configJSON = require("./config");
// Customizable Area End

export default class ClientViewProfile extends CfapiintegrationforhimalayanbankapiController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    renderFailure = () => {
        return (
            <Box sx={webStyle.errorcontentContainer}>
                <Box className="errorSubContentContainer">
                    <Box className="failureBox">
                        <img src={failure} className="errorImg" />
                        <Typography className="errorText">{configJSON.errorText}</Typography>
                        <Button className="retryButton" data-testId="retryTestId" onClick={() => this.handleBackButton("LandingPage")} >Retry</Button>
                    </Box>
                </Box>
            </Box>
        );
    };
    // Customizable Area End

    render() {
        // Customizable Area Start
        return (
            <FailureContainer>
                <Box sx={webStyle.headerContainer}>
                    <HeaderMenuBlock navigation={this.props.navigation} id={""} />
                    <Typography className="bookTitle">{configJSON.bookTitle}</Typography>
                </Box>
                {this.renderFailure()}
            </FailureContainer>
        )
        // Customizable Area End
    }
}

// Customizable Area Start
const FailureContainer = styled(Box)({
    background: "#E9F3FC",
    height: "100%",
    overflowY: "scroll",
    "& .errorSubContentContainer": {
        position: "absolute",
        top: "-190px",
        width: "97%",
        display: "flex",
        gap: "21px",
        justifyContent: "center",
        background: "#FFFFFF",
        height: "658px",
        borderRadius: "8px",
        boxShadow: "0px 4px 10px 0px #00000029",
    },
    "& .retryButton": {
        fontSize: "16px",
        fontWeight: 700,
        fontFamily: "Inter",
        color: "#FFFFFF",
        background: "#1764A0",
        height: "56px",
        width: "327px",
        borderRadius: "8px",
        cursor: "pointer"
    },
    "& .errorText": {
        fontSize: "24px",
        fontWeight: 700,
        fontFamily: "Inter",
        lineHeight: "36px",
        textAlign: "center",
        "@media screen and (min-width: 360px) and (max-width: 414px)": {
            width: "100%",
            fontSize: "16px",
        },
        "@media screen and (min-width: 415px) and (max-width: 600px)": {
            width: "100%",
            fontSize: "20px",
        }
    },
    "& .failureBox": {
        width: "40%",
        margin: "auto",
        textAlign: "center",
        height: "350px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-between",
        "@media screen and (min-width: 360px) and (max-width: 414px)": {
            width: "95%",
        },
        "@media screen and (min-width: 415px) and (max-width: 600px)": {
            width: "85%",
        }
    },
    "& .errorImg": {
        textAlign: "center"
    },
    "& .bookTitle": {
        fontSize: "32px",
        fontWeight: 700,
        fontFamily: "Inter",
        lineHeight: "24px",
        color: "#FFFFFF",
        marginTop: "58px",
        marginLeft: "50px",
        boxSizing: "border-box"
    }
});

const webStyle = {
    headerContainer: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        width: "100%",
        background: "linear-gradient(180deg, #013B68 84.25%, #0175D0 100%)",
        paddingBottom: "250px",
    },
    errorcontentContainer: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        justifyContent: "space-around",
        width: "100%",
        height: "100%",
        background: "#E9F3FC",
        gap: "21px",
        position: "relative",
    },
};

// Customizable Area End
