import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { ChangeEvent } from 'react';
interface TimePrice {
  time: string;
  price: string;
}

interface Airline {
  id: number;
  AirlineName: string;
  Count:number;
  checked:boolean;
}
interface PopularFilterProps {
  checked: boolean;
  name: string;
}
interface AirlinsType{
  id: number;
  AirlineName: string;
  Count:number;
  checked:boolean;
}
interface FlightData{
  flight_number: string;
  origin: string;
  destination: string;
  connection: number;
  departure_time: string;
  arrival_time: string;
  per_adult_price: string;
  duration: string;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    price_range:{
      "min":number,
      "max": number
    }
    selectedPrice: number,
    minPrice: number,
    maxPrice: number,
    shownPopularFilters: PopularFilterProps[]
    selectedPopularFilter: PopularFilterProps[]
    onPriceRangeChange: (maxValue: number) => void;
    nonStopFunct:(maxValue: PopularFilterProps)=> void;
    timePriceArray:TimePrice[]
    handleArrivalTimeSelect:(time:TimePrice)=>void;
    handleDepartureTimeSelect:(time:TimePrice)=>void
    arrivalTimePrice: TimePrice | null
    departureTimePrice:TimePrice | null;
    airLinesData:AirlinsType[];
    airlinsFunct:(maxValue: AirlinsType)=> void;
    inputCityFrom:string;
    inputCityTo:string;
    selectedTripType:string;
    clearAllFunct:any;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    popularFilters: PopularFilterProps[]
    timePriceArray: TimePrice[]
    airlinesList: Airline[]
    shownPopularFilters: PopularFilterProps[]
    selectedPopularFilter: PopularFilterProps[] 
    selectedAirlines: Airline[] 
    minPrice: number | number[]
    maxPrice: number | number[]
    selectedPrice: number |number[]
    departureTimePrice: TimePrice | null
    arrivalTimePrice: TimePrice | null
    showPopularMore: boolean
    showAirlineMore: boolean
    shownAirLineList: Airline[]
    // Customizable Area End
}

interface SS {
    id: any;
}

export default class FilteritemsController extends BlockComponent<
    Props,
    S,
    SS
> {
    getProductApiCallId: string = "";

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage)
        ];

        this.state = {
            minPrice: 5440,
            maxPrice: 25000,
            selectedPrice: 5440,
            selectedPopularFilter: [],
            departureTimePrice: null,
            arrivalTimePrice: null,
            selectedAirlines: [],
            showAirlineMore:false,
            showPopularMore:false,
            shownPopularFilters: [
              { checked: false, name: "Non-stop" },
              { checked: false, name: "1 Stop"},
              { checked: false, name: "2 Stop"},
              { checked: false, name: "Morning Departures"},
              { checked: false, name: "Late Departures"},
            ], 
            popularFilters: [
              { checked: false, name: "Non-stop" },
              { checked: false, name: "Indigo", },
              { checked: false, name: "Morning Departures"},
              { checked: false, name: "Late Departures"},
              { checked: false, name: "On time",},
              { checked: false, name: "Air India"},
              { checked: false, name: "One-way"},
              { checked: false, name: "Late Departures"}
            ],  
            timePriceArray: [
              { time: "Before 6AM", price: "$4200" },
              { time: "6AM to 12PM", price: "$5200" },
              { time: "12PM to 6PM", price: "$6200" },
              { time: "After 6PM", price: "$6500" }
            ],
            shownAirLineList:[
      
              { checked: false, AirlineName: "Indigo", Count: 12,id:1},
              { checked: false, AirlineName: "Indigo", Count: 12,id:2},
            ],
            airlinesList: [
              { checked: false, AirlineName: "Indigo", Count: 12,id:1},
              { checked: false, AirlineName: "Indigo", Count: 12,id:2},
            ],
        }
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }
    async componentDidMount() {
        // Customizable Area Start
        super.componentDidMount();
        this.getSessionToken();
        // Customizable Area End
    }
    
    // Customizable Area Start
    getSessionToken = () => {
        const messageValue: Message = new Message(
            getName(MessageEnum.SessionRequestMessage)
        );
        this.send(messageValue);
    };
    // Customizable Area End

    async receive(from: string, message: Message) {
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    showMorePopularFiltersList = ()=>{
        this.setState({shownPopularFilters: this.state.popularFilters})
        this.setState({showPopularMore: !this.state.showPopularMore})
      }
      showLessPopularFiltersList = ()=>{
        const { popularFilters } = this.state;
        const reducedPopularList =  popularFilters.slice(0, 4);
        this.setState({shownPopularFilters: reducedPopularList})
        this.setState({showPopularMore: false})
      }
      showMoreAirlineFiltersList = ()=>{
        this.setState({shownAirLineList: this.state.airlinesList})
        this.setState({showAirlineMore: true})
      }
      showLessAirlineFiltersList = ()=>{
        const { airlinesList } = this.state;
        const reducedPopularList =  airlinesList.slice(0, 4);
        this.setState({shownAirLineList: reducedPopularList})
        this.setState({showAirlineMore: false})
      }
      handlePopularFilterSelected = (value:PopularFilterProps)=>{
        this.props.nonStopFunct(value);
      }
     
      handlePriceChange = (event:ChangeEvent<{}>, value: number | number[]) => {
        this.props.onPriceRangeChange(value as number);
      };
      handleDepartureTimeSelect(timeData: TimePrice) {
        this.props.handleDepartureTimeSelect(timeData)
      }
    
      handleArrivalTimeSelect(time: TimePrice) {
        this.props.handleArrivalTimeSelect(time)
      }
      handleSelecedtAirLines = (value: Airline) => {
        this.props.airlinsFunct(value);
    };
    // Customizable Area End
}