import React from "react";

// Customizable Area Start
import {
  Box,
  Button,
  Grid,
  Typography,
  IconButton,
  Checkbox,
  CircularProgress,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import Loader from "../../../components/src/Loader.web";
import CarouselDisplay from "../../carouseldisplay/src/CarouselDisplay.web";
import { logo, errorIcon } from "./assets";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import { Alert } from "@material-ui/lab";
// Customizable Area End

import EmailAccountRegistrationController, {
  Props,
} from "./EmailAccountRegistrationController";
import SignupNavigation from "../../../components/src/SignupHeader";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

export default class EmailAccountRegistration extends EmailAccountRegistrationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
}
serverErrorMsg=()=>{
  return(this.state.serverError && 
        <Box className="ErrorMsg" sx={webStyles.errorMsgReset}>
        <img src={errorIcon} /> &nbsp; {this.state.serverError}
        </Box>
    )
}
errorMsg=()=>{
    return (this.state.errors && (this.state.errors.name || this.state.errors.email || this.state.errors.password || this.state.errors.confirmPassword)) && (
        <>
            <Box className="ErrorMsg" sx={webStyles.errorMsgReset}>
            <span className="imgError" style={{width:"5%", marginRight:"5px"}}><img src={errorIcon} /></span> <span style={{width:"95%"}}>{this.state.errors.name || this.state.errors.email || this.state.errors.password || this.state.errors.confirmPassword}</span>
            </Box>
        </>
        )
}
renderPasswordCondition = (condition:any, iconStyle:any, yesIconStyle:any) => {
  let icon;

  if (this.state.password !== "") {
    if (condition) {
      icon = <CheckCircleIcon style={{ ...iconStyle, color: "rgba(23, 100, 160, 1)" }} />;
    } else {
      icon = <CancelIcon style={webStyles.cancelIconCss} />;
    }
  } else {
    icon = <CheckCircleIcon style={yesIconStyle} />;
  }

  return icon;
};
conditionPassword=(hasCapitalLetter:boolean, hasLowerCase:boolean, hasNumber:boolean,hasSpacialChar:boolean, meetsMinLength:boolean)=>{
    return(
        <Box sx={webStyles.conditionReset}>
          <Typography style={webStyles.conditionPre} variant="body1">
      {this.renderPasswordCondition(hasCapitalLetter, webStyles.fontSize13px, webStyles.yesIcon)}
      At least one capital letter
      </Typography>
          <Typography style={webStyles.conditionPre} variant="body1">
      {this.renderPasswordCondition(hasLowerCase, webStyles.fontSize13px, webStyles.yesIcon)}
      At least one lowercase letter
    </Typography>
           <Typography style={webStyles.conditionPre} variant="body1">
      {this.renderPasswordCondition(hasNumber, webStyles.fontSize13px, webStyles.yesIcon)}
      At least one number
    </Typography>
           <Typography style={webStyles.conditionPre} variant="body1">
      {this.renderPasswordCondition(hasSpacialChar, webStyles.fontSize13px, webStyles.yesIcon)}
      At least one special character
    </Typography>
    <Typography style={webStyles.conditionPre} variant="body1">
      {this.renderPasswordCondition(meetsMinLength, webStyles.fontSize13px, webStyles.yesIcon)}
      Minimum character length is 8 characters
    </Typography>
      </Box>
    )
}
submitFunct=()=>{
  return(
    <Button
    data-test-id={"btnEmailRegistration"}
    variant="contained"
    fullWidth
    disabled={this.state.name==="" || this.state.email === "" || this.state.password === "" || this.state.confirmPassword==="" || this.state.checkedRememberMe===false?true:false}
    style={{
      backgroundColor: this.state.name==="" || this.state.email === "" || this.state.password === "" || this.state.confirmPassword==="" || this.state.checkedRememberMe===false ? "rgba(23, 100, 160, 0.5)" : "rgba(23,100,160,1)",
      fontSize: "16px",
      fontWeight: 700,
      textTransform:"capitalize",
      color: "#FFFFFF",
      height: "56px",
      borderRadius: "8px",
      fontFamily:"Inter, sans-serif",
      marginTop:"35px"
    }}
    type="submit"
    onClick={this.handleSubmit}
  >
    Signup {/*UI Engine::From Sketch*/}
  </Button>
  )
}
passwordForm=()=>{
  return(
    <>
    <Box sx={{ padding: "5px 0px" }}>
                  <Typography variant="body1" style={webStyles.labelText}>
                    Password
                  </Typography>
                  <div style={{ position: "relative", width: "100%" }}>
                    <input
                      data-test-id="txtInputPassword"
                      type={this.state.enablePasswordField ? "text" : "password"}
                      name="password"
                      placeholder="Your password"
                      value={this.state.password}
                      onChange={(e) => this.setPassword(e.target.value)}
                      required
                      style={{
                        ...webStyles.inputText,
                        border: this.state.errors.password ? '1px solid red' : '1px solid rgba(148,163,184,1)'
                      }}
                      />
                    <IconButton
                    data-test-id="enablePassword"
                      aria-label="toggle password visibility"
                      onClick={this.handleClickShowPassword}
                      edge="end"
                      style={{ position: "absolute", right: "7px" }}
                    >
                      {this.state.enablePasswordField ? (
                        <Visibility />
                      ) : (
                        <VisibilityOff />
                      )}
                    </IconButton>
                  </div>
                </Box>
                <Box sx={{ padding: "5px 0px" }}>
                  <Typography variant="body1" style={webStyles.labelText}>
                    Confirm Password
                  </Typography>
                  <div style={{ position: "relative", width: "100%" }}>
                    <input
                      data-test-id="txtInputConfirmPassword"
                      onChange={(e) => this.setConfirmPassword(e.target.value)}
                      type={this.state.enableConfirmPasswordField ? "text" : "password"}
                      name="confirmPassword"
                      placeholder="Your confirm password"
                      value={this.state.confirmPassword}
                      required
                      style={{
                        ...webStyles.inputText,
                        border: this.state.errors.confirmPassword ? '1px solid red' : '1px solid rgba(148,163,184,1)'
                      }} />
                    <IconButton
                      
                      onClick={this.handleClickShowConfirmPassword}
                      edge="end"
                      style={{ position: "absolute", 
                      right: "7px" }}
                      aria-label="toggle password visibility"
                      data-test-id="enableConfirmPassword"
                    >
                      {this.state.enableConfirmPasswordField ? (
                        <Visibility />
                      ) : (
                        <VisibilityOff />
                      )}
                    </IconButton>
                  </div>
                </Box>
    </>
  )
}
  // Customizable Area Start

  // Customizable Area End
  render() {
    // Customizable Area Start
    const hasNumber = this.checkForNumber(this.state.password);
    const meetsMinLength = this.checkMinLength(this.state.password);
    const hasSpacialChar = this.checkForSpecialChar(this.state.password);
    const hasCapitalLetter = this.checkForCapitalLetter(this.state.password);
    const hasLowerCase = this.checkLowerCaseLetter(this.state.password)
    // Customizable Area End
    return (
      // Customizable Area Start
      
     
      <ThemeProvider theme={theme}>
          <Loader loading={this.state.loading} />
        <Grid container>
          <Grid item xs={12} md={12} lg={6} style={{ display: "flex",
           justifyContent: "center", height: "fit-content" }}>
            <CarouselDisplay navigation={""} id={"CarouselDisplay"} />
          </Grid>
          <Grid item xs={12} md={12} lg={6} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <Box
              sx={{
                width: "75%",
                padding: 20
              }}
            >

             {
              !this.state.tarmsConditionCheck?<>
               <Box sx={{ width: "100%", paddingTop: "20px" }}>
                <div style={{ display: "flex", "justifyContent": "center" }}>
                  <img src={logo} data-test-id="navigateScreens"  onClick={()=>this.navigateScreens("LandingPage")} style={{cursor:"pointer"}} alt="logo"></img>
                </div>
                <Box>
                <SignupNavigation
                      state={this.state}
                      goToRegistration={()=>this.navigateScreens("EmailAccountRegistration")}
                      goToDetails={this.goToDetails}
                    />
                    {this.serverErrorMsg()}
                     {this.state.successMsg && <Alert severity="success">{this.state.successMsg}</Alert>}
                {
                   this.errorMsg() 
                }
                </Box>
                <Box sx={{ padding: "5px 0px" }}>
                  <Typography variant="body1" style={webStyles.labelText}>
                    Full Name
                  </Typography>
                  <input
                    type="text"
                    name="name"
                    data-test-id="inputText"
                    placeholder="Your full name"
                    value={this.state.name}
                    onChange={(e) => {if(this.state.name.length < 42) this.setName(e.target.value)}}
                    required
                    style={{
                      ...webStyles.inputText,
                      border: this.state.errors.name ? '1px solid red' : '1px solid rgba(148,163,184,1)'
                    }}
                  />
                </Box>
                <Box sx={{ padding: "5px 0px" }}>
                  <Typography variant="body1" style={webStyles.labelText}>
                    Email
                  </Typography>
                  <input
                    data-test-id="txtInputEmail"
                    type="email"
                    name="email"
                    placeholder="Your email"
                    value={this.state.email}
                    onChange={(e) => this.setEmail(e.target.value)}
                    required
                    style={{
                      ...webStyles.inputText,
                      border: this.state.errors.email ? '1px solid red' : '1px solid rgba(148,163,184,1)'
                    }}
                  />
                </Box>
                {this.passwordForm()}
                <Box>
                  {this.state.password && <>
                    <Typography style={webStyles.conditionPre} variant="body1">Your password must contain</Typography>
                  {this.conditionPassword(hasCapitalLetter, hasLowerCase, hasNumber, hasSpacialChar, meetsMinLength)}
                  </>}
                 
                </Box>
                <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: 16 }}>
                  <Box
                    sx={webStyles.checkBoxText}
                  >
                    <Checkbox
                      data-test-id={"btnRememberMe"}
                      onClick={() =>
                        this.setRememberMe(!this.state.checkedRememberMe)
                      }
                      style={{
                        padding: 0,
                        fontFamily:"Inter, sans-serif",
                        color:this.state.checkedRememberMe ? 'rgba(23, 100, 160, 1)' : 'rgba(148,163,184,1)',
                        borderRadius: "6px",
                      }}
                      checked={this.state.checkedRememberMe}
                    />{" "}
                    I agree with the <span data-test-id="termAndConditionsBtn" style={webStyles.textConditions} onClick={this.tarmsConFunct}>Terms and Conditions</span>
                  </Box>

                </Box>
                <Button
                    fullWidth
                    variant="contained"
    data-test-id={"btnEmailRegistration"}

    onClick={this.handleSubmit}
    disabled={this.state.name==="" 
    || this.state.email === "" ||
     this.state.password === "" || 
     this.state.confirmPassword==="" || 
     this.state.checkedRememberMe===false?true:false}
    style={{
      fontWeight: 700,
      textTransform:"capitalize",
      color: "#FFFFFF",
      height: "56px",
      borderRadius: "8px",
      backgroundColor: this.state.name==="" 
      || this.state.email === "" || 
      this.state.password === "" || 
      this.state.confirmPassword==="" || 
      this.state.checkedRememberMe===false ? "rgba(23, 100, 160, 0.5)" : "rgba(23,100,160,1)",
      fontSize: "16px",
      
      fontFamily:"Inter, sans-serif",
      marginTop:"35px"
    }}
    type="submit"
  
  >
    {this.state.signUploading
     && <CircularProgress size={24} color="inherit" style={{ marginRight: '8px', textTransform: "capitalize" }} />}Signup {/*UI Engine::From Sketch*/}
  </Button>
                <div style={{ display: "flex", justifyContent: "center", gap: "5px" }}>
                  <Typography style={{ marginTop:"10px",color: "#0F172A", fontSize: "14px", fontFamily:"Inter, sans-serif", fontWeight: 400, textAlign: "center" }}>Already have an account?{" "}</Typography>
                  <Box
                    data-test-id={"btnLoginRe"}
                    style={{marginTop:"10px",textDecorationLine: "underline",fontSize: "14px",cursor:"pointer",color: "rgba(23,100,160,1)", fontWeight: 700,}}
                    onClick={() => this.navigateScreens("EmailAccountLoginBlock")}
                  >
                    Login
                  </Box> 
                </div>
              </Box>
              </>
              :<>
               <Box sx={{ width: "100%", paddingTop: "20px" }}>
                <div style={{ display: "flex", "justifyContent": "center" }}>
                  <img data-test-id="navigateScreens" onClick={()=>this.navigateScreens("LandingPage")} style={{cursor:"pointer"}} src={logo} alt="logo"></img>
                </div>
                      <Box>
                        <Typography style={webStyles.termsHeder} variant="h4">Terms and Conditions</Typography>
                        <Box
                          component="div"
                          dangerouslySetInnerHTML={{ __html: this.state.termsAndConditionText }}
                        />
                </Box>
                <Box>
                <Button
                  data-test-id={"btnCancel"}
                  variant="contained"
                  fullWidth
                  style={{
                    textTransform:"capitalize",
                    fontSize: "16px",
                    fontWeight: 700,
                    color: "rgba(23,100,160,1)",
                    backgroundColor:"#fff",
                   marginBottom:"10px",
                    height: "56px",
                    borderRadius: "8px",
                    cursor:"pointer",
                    boxShadow:"none",
                    fontFamily:"Inter, sans-serif"
                  }}
                  type="button"


                  onClick={this.cancelBtn}
                >
                  Cancel 
                </Button>
                <Button
                  data-test-id={"btnAgree"}
                  variant="contained"
                  fullWidth
                  disabled={this.state.checkedRememberMe === true ? true : false}
                  style={{
                    fontSize: "16px",
                    fontWeight: 700,
                    color: "#FFFFFF",
                    backgroundColor:this.state.checkedRememberMe === false? "rgba(23,100,160,1)" : "rgba(23, 100, 160, 0.5)",
                   textTransform:"capitalize",
                    height: "56px",
                    borderRadius: "8px",
                    cursor:"pointer"
                  }}
                  type="button"


                  onClick={this.agreeBtn}
                >
                  Agree
                </Button>
                </Box>
                </Box>
              </>
             }
            </Box>
          </Grid>
        </Grid>
      </ThemeProvider>




      // Customizable Area End
    );
  }
}
// Customizable Area Start
const webStyles = {
    labelText:{
        fontSize: "16px",
        fontWeight: "Bold",
        color: "rgba(51, 65, 85, 1)",
        marginBottom: "2px",
        display: "block",
    },
    inputText:{
        width: "100%", border: `1px solid rgba(148,163,184,1)`, borderRadius: "8px",
        color: "rgba(0,0,0,1)", height: "50px", fontFamily:"Inter, sans-serif", fontSize: "14px", padding: "4%",
        marginBottom: "10px",outline:"none"
    },
    checkBoxText:{
        fontFamily:"Inter, sans-serif",
        fontSize: "14px",
        color: "rgba(15,23,42,1)",
        fontWeight: 400,
        marginTop: "5px",
    },
    conditionPre:{
        display:"flex",
        fontSize:"14px",
        alignItems:"center",
        margin:"10px 0"
    },
    conditionReset:{
        margin:"20px 0"
    },
    cancelIconCss:{
        color:"red",
        fontSize:"15px",
        marginRight:"3px"
      },
      fontSize13px:{
        fontSize:"15px",
        marginRight:"3px"
      },
      yesIcon:{
        color:"rgba(136, 153, 136, 0.5)",
        fontSize:"15px",
        marginRight:"3px"
      },
      errorMsgReset:{
        borderRadius: "5px",
        color: "#c12121",
        display:"flex",
        lineHeight:"24px",
        fontSize: "16px",
        borderLeft: "6px #c10f0ff0 solid",
        background:" #ed7e7e3d",
        padding: "20px 15px",
        alignItems: "center"
    },
    termsHeder:{
      fontSize:"22px",
      fontWeight: "bold",
      margin: "18px 0"
    },
    termsTitle:{
      fontSize:"16px",
      margin: "10px 0",
      color:"rgba(0, 0, 0, 0.5)"
    },
    textConditions:{
      textDecoration:"underline",
fontWeight:"bold",
cursor:"pointer"
    }
  };
  // Customizable Area End
