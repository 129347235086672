import React from "react";

// Customizable Area Start
import {
  Box,
  Button,
  Grid,
  Typography,
  IconButton,
  Checkbox,
  CircularProgress,
} from "@material-ui/core";
import { StyleSheet } from "react-native";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import CarouselDisplay from "../../carouseldisplay/src/CarouselDisplay.web";
import { logoImage ,errorIcon} from "./assets";
const webStyles = {
  errorMsgResetLogin:{
    lineHeight:"24px",
    fontSize: "16px",
    borderLeft: "6px #c10f0ff0 solid",
    background:" #ed7e7e3d",
    padding: "20px 15px",
    borderRadius: "5px",
    color: "#c12121",
    display:"flex",
  }
}
// Customizable Area End

import EmailAccountLoginController, {
  Props,
} from "./EmailAccountLoginController";

export default class EmailAccountLoginBlock extends EmailAccountLoginController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  loginButtonUi=()=>{
    return(
      <Button
      
                  data-test-id={"btnEmailLogIn"}
                  variant="contained"
                  disabled={this.state.email==="" ||this.state.password=="" ||this.state.error!=""}
                  fullWidth
                  style={{
                    backgroundColor:this.state.email==="" ||this.state.password=="" ||this.state.error!=""?"rgba(23,100,160,0.5)":"rgba(23,100,160,1)",
                    fontSize: "16px",
                    fontWeight: 700,
                    color: "#FFFFFF",
                    height: "56px",
                    borderRadius: "8px",
                    fontFamily: "Inter, sans-serif",
                    textTransform:"capitalize",
                    marginTop:"20px"
                  }}
                  type="submit"
                  onClick={this.handleSubmitLogin}
                >
                 {this.state.loading && <CircularProgress size={24} color="inherit" style={{ marginRight: '8px', textTransform: "capitalize" }} />} Login 
                </Button>
    )
  }
  errorMsgServer=()=>{
    return (this.state.serverErrorMsg && 
            <Box className="ErrorMsg" sx={webStyles.errorMsgResetLogin}>
            <img src={errorIcon} /> &nbsp; {this.state.serverErrorMsg}
            </Box>
        )
}
  errorMsg=()=>{
    return (this.state.errors && (this.state.errors.email || this.state.errors.password)) && (
        <>
            <Box className="ErrorMsg" sx={webStyles.errorMsgResetLogin}>
            <img src={errorIcon} /> &nbsp; {this.state.errors.email || this.state.errors.password}
            </Box>
        </>
        )
}

  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      // Required for all blocks
        <Grid container>
          <Grid item xs={12} md={12} lg={6}
          
          style={{ display: "flex", justifyContent: "center",
           height: "fit-content" }}>
            <CarouselDisplay navigation={""} id={"CarouselDisplay"} />
          </Grid>
          <Grid item 
          xs={12} md={12} lg={6}
           style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <Box
              sx={{
                width: "75%",
                padding: 20
              }}
            >
              <Box sx={{ width: "100%", paddingTop: "20px" }}>
                <div style={{ display: "flex", "justifyContent": "center" }}>
                  <img data-test-id="navigateScreens"  onClick={()=>this.navigateScreens("LandingPage")} style={{cursor:"pointer"}} src={logoImage}></img>
                </div>
                {this.errorMsgServer()}
{this.errorMsg()}
                <Typography onClick={this.gotoLandingPage} style={{ fontWeight: 700, fontSize: "16px", color: "#1764A0", textAlign: "right",cursor:"pointer", textDecorationLine: "underline" }}>Skip</Typography>
                <Box sx={{ padding: "20px 0px" }}>
                  <label style={{
                    fontFamily: "Inter, sans-serif",
                    fontSize: "16px",
                    fontWeight: 700,
                    letterSpacing: "0em",
                    textAlign: "left",
                    color: "rgba(51, 65, 85, 1)",
                    marginBottom: "2px",
                    display: "block",
                  }}>
                    Email
                  </label>
                  <input
                    data-test-id={"txtInputEmail"}
                    type="email"
                    name="email"
                    placeholder="Your Email"
                    value={this.state.email}
                    onChange={(e) => this.setEmail(e.target.value)}
                    required
                    style={{
                      width: "100%", border: `1px solid ${this.state.errors.email?"rgba(220,38,38,1)":"rgba(148,163,184,1)"}`, borderRadius: "8px",
                      color: "rgba(0,0,0,1)", height: "50px", boxSizing: "border-box", fontFamily: "Inter, sans-serif", fontSize: "14px", padding: "4%",
                      marginBottom: "10px",outline:"none"
                    }}
                  />
                </Box>
                <Box sx={{ padding: "20px 0px" }}>
                  <label style={{
                    fontFamily: "Inter, sans-serif",
                    fontSize: "16px",
                    fontWeight: 700,
                    letterSpacing: "0em",
                    textAlign: "left",
                    color: "rgba(51, 65, 85, 1)",
                    marginBottom: "2px",
                    display: "block",
                  }}>
                    Password
                  </label>
                  <div style={{position:"relative",width:"100%"}}>
                  <input
                    data-test-id="txtInputPassword"
                    type={this.state.enablePasswordField?"text":"password"}
                    name="password"
                    placeholder="Your Password"
                    value={this.state.password}
                    onChange={(e) => this.setPassword(e.target.value)}
                    required
                    style={{
                      width: "100%",border: `1px solid ${this.state.errors.password?"rgba(220,38,38,1)":"rgba(148,163,184,1)"}`, borderRadius: "8px",
                      color: "rgba(0,0,0,1)", height: "50px", boxSizing: "border-box", fontFamily: "Inter, sans-serif", fontSize: "14px", padding: "4%",
                      marginBottom: "10px"
                    }} />
                    <IconButton
                          aria-label="toggle password visibility"
                          onClick={this.handleClickShowPassword}
                          edge="end"
                          style={{position:"absolute",right:"7px"}}
                        >
                          {this.state.enablePasswordField ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                        </div>
                </Box>
                <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: 16 }}>
                  <Box
                    sx={{
                      fontFamily: "Inter, sans-serif",
                      fontSize: "14px",
                      color: "rgba(15,23,42,1)",
                      fontWeight: 400,
                      marginTop: "5px",
                    }}
                  >
                    <Checkbox
                      data-test-id={"btnRememberMe"}
                      onClick={() =>
                        this.setRememberMe(!this.state.checkedRememberMe)
                      }
                      style={{
                        padding: 0,
                        color:this.state.checkedRememberMe ? 'rgba(23, 100, 160, 1)' : 'rgba(148,163,184,1)',
                        borderRadius: "6px"
                      }}
                      checked={this.state.checkedRememberMe}
                    />{" "}
                    Remember Me
                  </Box>
                  <Box
                    data-test-id={"btnForgotPassword"}
                    style={{cursor:"pointer"}}
                    sx={{
                      fontSize: "14px",
                      color: "rgba(23,100,160,1)",
                      fontWeight: 700,
                    }}
                    onClick={() => this.goToForgotPassword()}
                  >
                    Forgot Password ?
                  </Box>
                  </Box>
                {this.loginButtonUi()}
                <div style={{display:"flex",justifyContent:"center",gap:"5px"}}>
                <Typography style={{color:"#0F172A", marginTop:"10px",fontSize:"14px",fontFamily:"Inter, sans-serif",fontWeight:400,textAlign:"center"}}>Don’t have an account?{" "}</Typography>       
                <Box
                    data-test-id={"btnSignUp"}
                    sx={{
                      fontSize: "14px",
                      color: "rgba(23,100,160,1)",
                      fontWeight: 700,
                      marginTop:"10px",
                    }}
                    style={{textDecorationLine: "underline",cursor:"pointer",}}
                    onClick={() => this.gotoSignUp()}
                  >
                    Sign Up
                  </Box>  
                  </div>
              </Box>
            </Box>
          </Grid>
        </Grid>
      // Customizable Area End
    );
  }

}

const styles = StyleSheet.create({
  main: {
    display: "flex",
    alignItems: "center"
  },
  body: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "10px",
    width: "50%",
  },
  textFont: {
    fontFamily: "Helvetica"
  },
  formWrapper: {
    display: "flex",
    width: "100%",
    paddingTop: "20px" 
  },
  inputWrapper: {
    flex: 1,
    padding: "20px"
  },
  removeBackground: {
    backgroundColor: "transparent" 
  },
  checkBoxText: {
    fontSize: 18,
    color: "#6200EE",
    fontWeight: "bold",
  },
  buttonsWrapper: {
    flex: 1,
    padding: "10px",
  },
  OrWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "10px",
  }
});
