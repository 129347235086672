import React from "react";
// Customizable Area Start
import {
    Box,
    Button,
    Grid,
    Typography
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import CarouselDisplay from "../../carouseldisplay/src/CarouselDisplay.web";
import { logoImage,errorIcon } from "./assets";
import OtpInput from 'react-otp-input';
// Customizable Area End
import OTPInputAuthController, {
    Props,
} from "./ForgetPasswordOTPController.web";
const theme = createTheme({
    palette: {
        primary: {
            main: "#0000ff",
            contrastText: "#fff",
        },
    },
});
export default class ForgotPasswordOTP extends OTPInputAuthController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    // Customizable Area End
    render() {
        return (
            <ThemeProvider theme={theme}>
                <Grid container>
                    <Grid item xs={12} md={12} lg={6} style={{ display: "flex",  height: "fit-content",justifyContent: "center", }}>
                        <CarouselDisplay navigation={""} id={"CarouselDisplay"} />
                    </Grid>
                    <Grid item xs={12} md={12} lg={6} style={{  alignItems: "flex-start",display: "flex", justifyContent: "center" }}>
                        <Box sx={{ width: "75%", padding: 20 }}>
                            <div style={Webstyle.logoDiv}>
                                <img src={logoImage}></img>
                            </div>
                            <Box sx={{ width: "100%" }}>
                           {
                            this.state.error &&
                                    <Box className="ErrorMsg" sx={Webstyle.errorMsgforOtp}>
                                    <span className="imgError" style={{width:"5%", marginRight:"5px"}}><img src={errorIcon} /></span> <span style={{width:"95%"}}>{this.state.error}</span>
                                    </Box>
                           }
                            <Box sx={{ padding: "20px 0px" }}>
                                <Typography
                                    style={{ ...Webstyle.Typography, textAlign: "left", fontSize: "24px",fontWeight:700  }}
                                >
                                    Verify email addresss
                                </Typography>
                                <Typography
                                    style={{ ...Webstyle.Typography, textAlign: "left", fontSize: "16px", marginTop:"10px" }}
                                >
                                    We have sent an OTP to <span style={{fontWeight:"700", textDecoration: "underline"}}>{this.state.email}</span>.
                                </Typography>
                            </Box>
                            <Box className="otpPasswordSize" sx={{ padding: "20px 0px", }}>
                                <Box sx={{marginLeft: "-11px", }}>
                                <OtpInput
                                    data-test-id={"otpInput"}
                                    value={this.state.forgotOtp}
                                    placeholder="----"
                                    onChange={this.setOtp}
                                    numInputs={4}
                                    inputType="tel"
                                    renderInput={(props) => <input {...props} />}
                                    inputStyle={{ ...Webstyle.OtpInput, textAlign: "center", border: this.state.otpLength ? "2px solid rgba(23, 100, 160, 1)" : "2px solid rgba(148, 163, 184, 1)" }}
                                />
                                </Box>
                                {this.state.Seconds > 0 ? (
                                    <Typography style={{ ...Webstyle.CodeTypography, textAlign: "left" }}>
                                        Didn’t get a code?
                                        <Button disabled={this.state.Seconds > 0} style={Webstyle.ResendButton1}>
                                            Resend OTP in
                                        </Button>
                                        <span style={{ color: "rgba(148, 163, 184, 1)", textDecoration: "underline" }}>
                                            {`0${this.state.Mintues}`}:
                                            {this.state.Seconds < 10 ? `0${this.state.Seconds}` : this.state.Seconds}
                                        </span>
                                    </Typography>
                                ) : (
                                    <Typography style={{ ...Webstyle.CodeTypography, textAlign: "left" }}>
                                        Didn’t get a code?
                                        <Button data-test-id="ressetOtpTimer" onClick={this.ressetOtpTimer} style={Webstyle.ResendButton}>
                                            Resend OTP
                                        </Button>
                                    </Typography>
                                )

                                }

                            </Box>
                            <Button
                                data-test-id={"btnEmailLogIn"}
                                variant="contained"
                                disabled={!this.state.otpLength}
                                fullWidth
                                onClick={this.onSubmitVerify}
                                style={{
                                    ...Webstyle.SendButton,
                                    backgroundColor: this.state.otpLength ? "rgba(23, 100, 160, 1)" : "rgba(23,100,160,0.5)",
                                }}
                                type="submit"
                            >
                                Verify OTP {/*UI Engine::From Sketch*/}
                            </Button>
                            <div className="forgetpwdfootertext" style={{ display: "flex", justifyContent: "center", gap: "5px", paddingTop: "1rem" }}>
                                <Typography style={{ ...Webstyle.Typography, fontSize: "14px", textAlign: "center" }}>Don’t Receive Email?{" "}</Typography>
                                <Box
                                    data-test-id={"anotherEmail"}
                                    sx={{
                                        fontSize: "14px",
                                        color: "rgba(23,100,160,1)",
                                        fontWeight: 700,
                                    }}
                                    style={{ textDecorationLine: "underline",cursor: "pointer" }}
                                     onClick={this.goToForgotPasswordPage}
                                >
                                    Try another email address
                                </Box>
                            </div>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </ThemeProvider>
        )
    }
}

// Customizable Area Start
const Webstyle = {
    logoDiv: {
        display: "flex",
        justifyContent: "center",
        padding: "2rem"
    },
    errorMsgforOtp:{
        borderRadius: "5px",
        fontSize: "16px",
        borderLeft: "6px #c10f0ff0 solid",
        background:" #ed7e7e3d",
        color: "#c12121",
        display:"flex",
        lineHeight:"24px",
        padding: "20px 15px",
        alignItems: "center"
    },
    Typography: {
        color: "rgba(15, 23, 42, 1)",
        fontFamily: "Inter, sans-serif",
        fontWeight: 400,
    },
    CodeTypography: {
        color: "rgba(0, 0, 0, 1)",
        fontFamily: "Inter, sans-serif",
        fontWeight: 400,
        fontSize: "14px",
    },
    Label: {
        fontFamily: "Inter, sans-serif",
        fontSize: "16px",
        fontWeight: 700,
        letterSpacing: "0em",
        color: "rgba(51, 65, 85, 1)",
        marginBottom: "2px",
        display: "block",
    },
    Input: {
        width: "100%",
        borderRadius: "8px",
        color: "rgba(0,0,0,1)",
        height: "50px",
        fontFamily: "Inter, sans-serif",
        fontSize: "14px",
        padding: "4%",
        marginBottom: "10px",
        outline: "none"
    },
    SendButton: {
        fontSize: "16px",
        fontWeight: 700,
        color: "#FFFFFF",
        height: "56px",
        borderRadius: "8px",
        fontFamily: "Inter, sans-serif",
        textTransform: "capitalize" as "capitalize"
    },
    ResendButton: {
        color: "rgba(0, 0, 0, 1)",
        backgroundColor: "#fff",
        border: "none",
        fontWeight: 600,
        textDecoration: "underline",
        cursor: "pointer",
    },
    ResendButton1: {
        color: "rgba(100, 116, 139, 1)",
        backgroundColor: "#fff",
        border: "none",
        fontWeight: 600,
        textDecoration: "underline",
        cursor: "pointer",
    },
    OtpInput: {
        margin:"-4px 10px 10px 10px",
        fontSize: "18px",
        borderRadius: "8px",
        width: "62px",
        height: "62px",
        fontFamily: 'Inter, sans-serif',
        outline: "none",
        fontWeight: 400,
        color: 'rgb(0,0,0,1)',
    }
}

// Customizable Area End