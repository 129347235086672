import React from "react";

// Customizable Area Start
import {
  Box,
  Button,
  Grid,
  Typography,
  IconButton,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import CarouselDisplay from "../../carouseldisplay/src/CarouselDisplay.web";
import { logoImage, errorIcon,successMsgIcon } from "./assets";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import { Alert } from "@material-ui/lab";
import "../assets/style.css"
// Customizable Area End

import ChangePasswordController, {
  Props,
} from "./ChangePasswordController.web";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

export default class ChangePasswordBlock extends ChangePasswordController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
}
serverErrorMsg=()=>{
  return(this.state.serverError && 
        <Box className="ErrorMsg" sx={webStylesPwd.errorMsgResetPwd}>
        <img src={errorIcon} /> &nbsp; {this.state.serverError}
        </Box>
    )
}
errorMsg=()=>{
    return (this.state.errors && ( this.state.errors.password || this.state.errors.confirmPassword)) && (
        <>
            <Box className="ErrorMsg" sx={webStylesPwd.errorMsgResetPwd}>
            <img src={errorIcon} /> &nbsp; { this.state.errors.password || this.state.errors.confirmPassword}
            </Box>
        </>
        )
}
renderPasswordCondition = (condition:any, iconStyle:any, yesIconPwdStyle:any) => {
  let icon;

  if (this.state.password !== "") {
    if (condition) {
      icon = <CheckCircleIcon style={{ ...iconStyle, color: "rgba(23, 100, 160, 1)" }} />;
    } else {
      icon = <CancelIcon style={webStylesPwd.cancelIconCssPwd} />;
    }
  } else {
    icon = <CheckCircleIcon style={yesIconPwdStyle} />;
  }

  return icon;
};
conditionPassword=(hasCapitalLetter:boolean, hasLowerCase:boolean, hasNumber:boolean,hasSpacialChar:boolean, meetsMinLength:boolean)=>{
    return(
        <Box sx={{...webStylesPwd.conditionResetPwd, margin:"0 0"}}>
          <Typography style={webStylesPwd.conditionPrePwd} variant="body1">
      {this.renderPasswordCondition(hasCapitalLetter, webStylesPwd.fontSize13pxPwd, webStylesPwd.yesIconPwd)}
      At least one capital letter
      </Typography>
          <Typography style={webStylesPwd.conditionPrePwd} variant="body1">
      {this.renderPasswordCondition(hasLowerCase, webStylesPwd.fontSize13pxPwd, webStylesPwd.yesIconPwd)}
      At least one lowercase letter
    </Typography>
           <Typography style={webStylesPwd.conditionPrePwd} variant="body1">
      {this.renderPasswordCondition(hasNumber, webStylesPwd.fontSize13pxPwd, webStylesPwd.yesIconPwd)}
      At least one number
    </Typography>
           <Typography style={webStylesPwd.conditionPrePwd} variant="body1">
      {this.renderPasswordCondition(hasSpacialChar, webStylesPwd.fontSize13pxPwd, webStylesPwd.yesIconPwd)}
      At least one special character
    </Typography>
    <Typography style={webStylesPwd.conditionPrePwd} variant="body1">
      {this.renderPasswordCondition(meetsMinLength, webStylesPwd.fontSize13pxPwd, webStylesPwd.yesIconPwd)}
      Minimum character length is 8 characters
    </Typography>
      </Box>
    )
}
submitFunct=()=>{
  return(
    <Button
    data-test-id={"btnEmailRegistration"}
    variant="contained"
    fullWidth
    disabled={ this.state.password === "" || this.state.confirmPassword===""?true:false}
    style={{
      backgroundColor: this.state.password === "" || this.state.confirmPassword===""? "rgba(23, 100, 160, 0.5)" : "rgba(23,100,160,1)",
      fontSize: "16px",
      fontWeight: 700,
      textTransform:"capitalize",
      color: "#FFFFFF",
      height: "56px",
      borderRadius: "8px",
      fontFamily:"Inter, sans-serif",
      marginTop:"25px"
    }}
    type="submit"
    onClick={this.handleSubmit}
  >
    Confirm Password 
  </Button>
  )
}
passwordForm=()=>{
  return(
    <>
    <Box sx={{ padding: "5px 0px" }}>
                  <Typography variant="body1" style={webStylesPwd.labelTextPwd}>
                   New Password
                  </Typography>
                  <div style={{ position: "relative", width: "100%" }}>
                    <input
                      data-test-id="txtInputPassword"
                      type={this.state.enablePasswordField ? "text" : "password"}
                      name="password"
                      placeholder="Your password"
                      value={this.state.password}
                      onChange={(e) => this.setPassword(e.target.value)}
                      required
                      style={{
                        ...webStylesPwd.inputTextPwd,
                        border: this.state.errors.password ? '1px solid red' : '1px solid rgba(148,163,184,1)'
                      }}
                      />
                    <IconButton
                    data-test-id="enablePassword"
                      aria-label="toggle password visibility"
                      onClick={this.handleClickShowPassword}
                      edge="end"
                      style={{ position: "absolute", right: "7px" }}
                    >
                      {this.state.enablePasswordField ? (
                        <Visibility />
                      ) : (
                        <VisibilityOff />
                      )}
                    </IconButton>
                  </div>
                </Box>
                <Box sx={{ padding: "5px 0px" }}>
                  <Typography variant="body1" style={webStylesPwd.labelTextPwd}>
                    Confirm Password
                  </Typography>
                  <div style={{ position: "relative", width: "100%" }}>
                    <input
                      data-test-id="txtInputConfirmPassword"
                      type={this.state.enableConfirmPasswordField ? "text" : "password"}
                      name="confirmPassword"
                      placeholder="Your confirm password"
                      value={this.state.confirmPassword}
                      onChange={(e) => this.setConfirmPassword(e.target.value)}
                      required
                      style={{
                        ...webStylesPwd.inputTextPwd,
                        border: this.state.errors.confirmPassword ? '1px solid red' : '1px solid rgba(148,163,184,1)'
                      }} />
                    <IconButton
                      aria-label="toggle password visibility"
                      data-test-id="enableConfirmPassword"
                      onClick={this.handleClickShowConfirmPassword}
                      edge="end"
                      style={{ position: "absolute", right: "7px" }}
                    >
                      {this.state.enableConfirmPasswordField ? (
                        <Visibility />
                      ) : (
                        <VisibilityOff />
                      )}
                    </IconButton>
                  </div>
                </Box>
    </>
  )
}
  // Customizable Area Start

  // Customizable Area End
  render() {
    // Customizable Area Start
    const hasCapitalLetter = this.checkForCapitalLetter(this.state.password);
    const hasLowerCase = this.checkLowerCaseLetter(this.state.password)
    const hasNumber = this.checkForNumber(this.state.password);
    const meetsMinLength = this.checkMinLength(this.state.password);
    const hasSpacialChar = this.checkForSpecialChar(this.state.password);
    // Customizable Area End
    return (
      // Customizable Area Start
      // Required for all blocks
     
      <ThemeProvider theme={theme}>
        <Grid container>
          <Grid item xs={12} md={12} lg={6} style={{ display: "flex", justifyContent: "center", height: "fit-content" }}>
            <CarouselDisplay navigation={""} id={"CarouselDisplay"} />
          </Grid>
          <Grid item xs={12} md={12} lg={6} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <Box
              sx={{
                width: "75%",
                padding: 20
              }}
            >

<Box sx={{ width: "100%", paddingTop: "20px" }}>
                <div style={{ display: "flex", "justifyContent": "center" }}>
                  <img src={logoImage} alt="logo"></img>
                </div>
                {!this.state.successPage? <>
                    <Box>
                    {this.serverErrorMsg()}
                     {this.state.successMsg && <Alert severity="success">{this.state.successMsg}</Alert>}
                {
                   this.errorMsg() 
                }
                </Box>
                <Box sx={{ padding: "20px 0px" }}>
                                    <Typography
                                        style={{ ...webStylesPwd.TypographyText, textAlign: "left", fontSize: "24px",fontWeight:700, marginBottom:"10px" }}
                                    >
                                        Set New password
                                    </Typography>
                                    <Typography
                                        style={{ ...webStylesPwd.TypographyText, textAlign: "left", fontSize: "16px",fontWeight:400 }}
                                    >
                                        Please enter a new password
                                    </Typography>
                                </Box>
                {this.passwordForm()}
                <Box >
                  <Typography style={{...webStylesPwd.conditionPrePwd, margin:"-6px 0 -6px 0"}} variant="body1">Your password must contain</Typography>
                  {this.conditionPassword(hasCapitalLetter, hasLowerCase, hasNumber, hasSpacialChar, meetsMinLength)}
                </Box>
               
               {this.submitFunct()}
                <div style={{ display: "flex", justifyContent: "center", gap: "5px" }}>
                  <Typography style={{ marginTop:"10px",color: "#0F172A", fontSize: "14px", fontFamily:"Inter, sans-serif", fontWeight: 400, textAlign: "center" }}>Already an existing user?{" "}</Typography>
                  <Box
                    data-test-id={"btnLoginRe"}
                    style={{marginTop:"10px",textDecorationLine: "underline",fontSize: "14px",cursor:"pointer",color: "rgba(23,100,160,1)", fontWeight: 700,}}
                     onClick={this.handleContinue}
                  >
                    Login
                  </Box> 
                </div></>:<>
                <div style={{ display: "flex", "justifyContent": "center" , marginTop:"30px"}}>
                  <img src={successMsgIcon} alt="logo"></img>
                </div>
                <Box className="paddingarrount4" sx={{ textAlign:"center" }}>
                                    <Typography
                                        style={{ ...webStylesPwd.TypographyText, fontSize: "24px",fontWeight:700 }}
                                    >
                                       Password successfully Reset
                                    </Typography>
                                    <Typography
                                        style={{ ...webStylesPwd.TypographyText, fontSize: "16px",fontWeight:400 }}
                                    >
                                        Congratulations! Your password has been successfully reset.
                                    </Typography>
                                </Box>
                                <Button
                                data-test-id={"btnContinue"}
                                variant="contained"
                                fullWidth
                                style={{
                                    textTransform:"capitalize",
                                    color: "#FFFFFF",
                                    height: "56px",
                                    borderRadius: "8px",
                                backgroundColor: "rgba(23,100,160,1)",
                                fontSize: "16px",
                                fontWeight: 700,
                                fontFamily:"Inter, sans-serif",
                                marginTop:"35px"
                                }}
                                type="submit"
                                onClick={this.handleContinue}
                            >
                                Continue
                            </Button>
                </> }
                
              </Box>
            </Box>
          </Grid>
        </Grid>
      </ThemeProvider>




      // Customizable Area End
    );
  }
}
// Customizable Area Start
const webStylesPwd = {
    labelTextPwd:{
        fontSize: "16px",
        fontWeight: "Bold",
        color: "rgba(51, 65, 85, 1)",
        marginBottom: "2px",
        display: "block",
    },
    inputTextPwd:{
        width: "100%", border: `1px solid rgba(148,163,184,1)`, borderRadius: "8px",
        color: "rgba(0,0,0,1)", height: "50px", fontFamily:"Inter, sans-serif", fontSize: "14px", padding: "4%",
        marginBottom: "10px",outline:"none"
    },
    conditionPrePwd:{
        display:"flex",
        fontSize:"14px",
        alignItems:"center",
        margin:"10px 0"
    },
    conditionResetPwd:{
        margin:"20px 0"
    },
    cancelIconCssPwd:{
        color:"red",
        fontSize:"15px",
        marginRight:"3px"
      },
      fontSize13pxPwd:{
        fontSize:"15px",
        marginRight:"3px"
      },
      yesIconPwd:{
        color:"rgba(136, 153, 136, 0.5)",
        fontSize:"15px",
        marginRight:"3px"
      },
      errorMsgResetPwd:{
        borderRadius: "5px",
        color: "#c12121",
        display:"flex",
        lineHeight:"24px",
        fontSize: "16px",
        borderLeft: "6px #c10f0ff0 solid",
        background:" #ed7e7e3d",
        padding: "20px 15px",
    },
    TypographyText: {
        color: "rgba(15, 23, 42, 1)",
        fontFamily: "Inter, sans-serif",
    },
  };
  // Customizable Area End
