import React from "react";

// Customizable Area Start
import {
  Box,
  Button,
  Grid,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import CarouselDisplay from "../../carouseldisplay/src/CarouselDisplay.web";
import { logo, errorIcon } from "./assets";
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-input-2'
import "../assets/style.css"



// Customizable Area End

import EmailAccountDetailController, {
  CountryArry,
  Props,
  StateDataType,
} from "./EmailAccountDetailController.web";
import SignupNavigation from "../../../components/src/SignupHeader";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});
const selectedCountry = {
  countryCode: 'ZZ',
  countryName: 'International',
};
const countryCode = selectedCountry ? selectedCountry.countryCode : '';
export default class EmailAccountDetail extends EmailAccountDetailController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
}

errorMsg=()=>{
  return (this.state.errors && (this.state.errors.mobileNo || this.state.errors.pincode || this.state.errors.addressLine1 || this.state.errors.addressLine2)) && (
    <>
        <Box className="ErrorMsg" sx={webStyles.errorMsgResetD}>
        <span className="imgError" style={{width:"5%", marginRight:"5px"}}><img src={errorIcon} /></span> <span style={{width:"95%"}}>{this.state.errors.mobileNo || this.state.errors.pincode || this.state.errors.addressLine1 || this.state.errors.addressLine2}</span>
        </Box>
    </>
    )
}

  // Customizable Area Start
  validateRequired=()=>{
    return(
      !this.state.mobileNo || !this.state.pincode || !this.state.addressLine1 
                    || !this.state.gender || !this.state.dateOfBirth || !this.state.country ||
                    !this.state.stateCode || !this.state.cityName || !this.state.passportNo || !this.state.passportExDate
    )
  }
saveProfileBtnUi=()=>{
  return(
    <Button
                  data-test-id={"btnEmailRegistrationDetail"}
                  variant="contained"
                  fullWidth
                  disabled={this.validateRequired() ?true:false}
                  style={{
                    backgroundColor:this.validateRequired()? "rgba(23, 100, 160, 0.5)" : "rgba(23,100,160,1)",
                    fontSize: "16px",
                    fontWeight: 700,
                    textTransform:"capitalize",
                    color: "#FFFFFF",
                    height: "56px",
                    borderRadius: "8px",
                    fontFamily:"Inter, sans-serif",
                    cursor:"pointer"
                  }}
                  type="submit"
                  onClick={this.handleSubmitSave}
                >
                  Save
                </Button>
  )
}
  // Customizable Area End
  render() {
    // Customizable Area Start

    // Customizable Area End
    return (
      // Customizable Area Start
     
      <ThemeProvider theme={theme}>
        <Grid container ref={this.scrollRef}>
          <Grid item xs={12} md={12} lg={6} 
          style={{ display: "flex",justifyContent: "center",height: "fit-content"}}>
            <CarouselDisplay navigation={""} id={"CarouselDisplay"} />
          </Grid>
          <Grid style={{ display: "flex",justifyContent: "center",alignItems: "center" }} item xs={12} md={12} lg={6}>
            <Box
              sx={{ padding: 20,
                width: "75%",
              }}
            >
<Box sx={{ width: "100%", paddingTop: "20px" }}>
                <div style={{ display: "flex", "justifyContent": "center" }}>
                  <img data-test-id="navigateScreens"  onClick={()=>this.navigateScreens("LandingPage")} style={{cursor:"pointer"}} src={logo} alt="logo"></img>
                </div>
                <SignupNavigation
                      state={this.state}
                      goToRegistration={this.goToRegistration}
                      goToDetails={this.goToDetails}
                    />
                <Box>
                {
                   this.errorMsg() 
                }
                </Box>
                <Box sx={{ padding: "5px 0px" }}>
             <Typography variant="body1" style={webStyles.labelText}>
               Gender
            </Typography>
             <select
               name="gender"
               data-test-id="inputGender"
              value={this.state.gender}
               onChange={(event) => this.setGender(event.target.value)}
               
               required
               style={{
                 ...webStyles.inputText,
                 border: this.state.errors.gender ? '1px solid red' : '1px solid rgba(148,163,184,1)'
               }}
              >
                  <option value="" disabled hidden>Select your gender</option>
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                  <option value="other">Other</option>
                </select>
                </Box>
                <Box sx={{ padding: "5px 0px" }}>
                  <Typography variant="body1" style={webStyles.labelText}>
                    Date Of Birth
                  </Typography>
                  <input
                    type="date"
                    name="date"
                    data-test-id="inputDob"
                    max={new Date().toISOString().split('T')[0]}
                    value={this.state.dateOfBirth}
                    onChange={(event) => this.setDob(event.target.value)}
                    required
                    style={{
                      ...webStyles.inputText,
                      border: this.state.errors.dateOfBirth ? '1px solid red' : '1px solid rgba(148,163,184,1)'
                    }}
                  />
                </Box>
                <Box sx={{ padding: "5px 0px" }}>
                  <Typography variant="body1" style={webStyles.labelText}>
                  Address Line 1
                  </Typography>
                  <input
                    data-test-id="addressLine"
                    type="text"
                    name="Address"
                    placeholder="House/Flat Number, Street Number"
                    value={this.state.addressLine1}
                    onChange={(event) => this.setAdd1(event.target.value)}
                    required
                    style={{
                      ...webStyles.inputText,
                      border: this.state.errors.addressLine1 ? '1px solid red' : '1px solid rgba(148,163,184,1)'
                    }}
                  />
                </Box>
                <Box sx={{ padding: "5px 0px" }}>
                  <Typography variant="body1" style={webStyles.labelText}>
                  Address Line 2
                  </Typography>
                  <input
                    data-test-id="addressLine1"
                    type="text"
                    name="Address"
                    placeholder="Landmark, Street Number"
                    value={this.state.addressLine2}
                    onChange={(event) => this.setAdd2(event.target.value)}
                    required
                    style={{
                      ...webStyles.inputText,
                      border: this.state.errors.addressLine2 ? '1px solid red' : '1px solid rgba(148,163,184,1)'
                    }}
                  />
                </Box>
                <Box sx={{ padding: "5px 0px" }}>
                  <Typography variant="body1" style={webStyles.labelText}>
                  Zip/Pin Code
                  </Typography>
                  <input
                    data-test-id="zipcode"
                    type="text"
                    name="ZipCode"
                    placeholder="Enter your Pin code"
                    value={this.state.pincode}
                    onBlur={(event)=>this.zipcodeFunct(event.target.value)}
                    onChange={(event) => this.setPincode(event.target.value)}
                    required
                    style={{
                      ...webStyles.inputText,
                      border: this.state.errors.pincode ? '1px solid red' : '1px solid rgba(148,163,184,1)'
                    }}
                  />
                </Box>
                <Box sx={{ padding: "5px 0px",position:'relative' }}>
             <Typography variant="body1" style={webStyles.labelText}>
             Country
            </Typography>
            {!this.state.country  && <span style={{
                    color: "gray", zIndex: 100,
                    pointerEvents: 'none', cursor: "pointer",
                    position: "absolute", top: "50%", left: '20px'
                  }}>Select your country</span>}
                  <Select
                    variant="outlined"
                    data-test-id="country"
                    name="countryCode"
                    value={this.state.country}
                    onChange={(event) => {
                      const { value } = event.target as { value: string; name?: string };
                      {
                        this.setCountry(value)
                        this.statelistFunct(value)
                       }
                    }}
                    required
                    style={{
                      ...webStyles.inputText,
                      border: this.state.errors.country ? '1px solid red' : '1px solid rgba(148,163,184,1)'
                    }}
                    MenuProps={{
                      classes: { paper: "menuCountries medialContent" },
                      PaperProps: {
                        style: {
                          maxHeight: 200,
                          overflowY: "auto",
                          marginLeft: -10,
                          marginTop: 40,

                        },
                      },
                    }}
                  >
                    {this.state.countryData.map((items, index) => (
                      <MenuItem
                        key={index}
                        value={`${items.attributes.name}`}
                      >
                        {`${items.attributes.name}`}
                      </MenuItem>
                    ))}
                  </Select>
                </Box>
                <Box sx={{ padding: "5px 0px",position:'relative' }}>
             <Typography variant="body1" style={webStyles.labelText}>
              State
            </Typography>
            {!this.state.stateCode  && <span style={{
                    color: "gray", zIndex: 100,
                    pointerEvents: 'none', cursor: "pointer",
                    position: "absolute", top: "50%", left: '20px'
                  }}>Select your state</span>}
                  <Select
                    variant="outlined"
                    data-test-id="state"
                    name="State"
              value={this.state.stateCode}
                    onChange={(event) => {
                      const { value } = event.target as { value: string; name?: string };
                      {
                        this.setStateCode(value)
                        this.cityListFunct(value, this.state.country)
                       }
                    }}
                    required
                    style={{
                      ...webStyles.inputText,
                      border: this.state.errors.stateCode ? '1px solid red' : '1px solid rgba(148,163,184,1)'
                    }}
                    MenuProps={{
                      classes: { paper: "menuCountries medialContent" },
                      PaperProps: {
                        style: {
                          maxHeight: 200,
                          overflowY: "auto",
                          marginLeft: -10,
                          marginTop: 40,

                        },
                      },
                    }}
                  >
                    {this.state.stateCodeData.map((items, index) => (
                      <MenuItem
                        key={index}
                        value={`${items.name}`}
                      >
                        {`${items.name}`}
                      </MenuItem>
                    ))}
                  </Select>
                </Box>
                <Box sx={{ padding: "5px 0px",position:'relative' }}>
             <Typography variant="body1" style={webStyles.labelText}>
               City
            </Typography>
            {!this.state.cityName  && <span style={{
                    color: "gray", zIndex: 100,
                    pointerEvents: 'none', cursor: "pointer",
                    position: "absolute", top: "50%", left: '20px'
                  }}>Select your city</span>}
                  <Select
                    variant="outlined"
                    data-test-id="city"
                    name="city"
                   value={this.state.cityName}
                    onChange={(event) => {
                      const { value } = event.target as { value: string; name?: string };
                      {
                        this.setCityName(value)
                      
                       }
                    }}
                    required
                    style={{
                      ...webStyles.inputText,
                      border: this.state.errors.country ? '1px solid red' : '1px solid rgba(148,163,184,1)'
                    }}
                    MenuProps={{
                      classes: { paper: "menuCountries medialContent" },
                      PaperProps: {
                        style: {
                          maxHeight: 200,
                          overflowY: "auto",
                          marginLeft: -10,
                          marginTop: 40,

                        },
                      },
                    }}
                  >
                    {this.state.cityData.map((items, index) => (
                      <MenuItem
                        key={index}
                        value={`${items}`}
                      >
                        {`${items}`}
                      </MenuItem>
                    ))}
                  </Select>
                </Box>
               
                <Box sx={{ padding: "5px 0px" }}>
                  <Typography variant="body1" style={webStyles.labelText}>
                  Contact Details
                  </Typography>
                  <Box style={{ display: 'flex', alignItems: 'center', position:"relative" }}>
                    <PhoneInput
                    autoFormat={true}
                    enableLongNumbers={true}
                    country={'in'}
                      data-test-id="setMobileNo"
                      placeholder="Enter your mobile number"
                      value={this.state.mobileNo}
                      onChange={(value: string, countryObj: { dialCode: string; format: string; name: string }) => {
                        this.setMobileNo(value)
                        this.setState({countryObject:countryObj}) 
                      }}
                      inputStyle={{
                        ...webStyles.inputContactText,
                        flex: 1,
                        marginRight: '10px',
                        border: this.state.errors.mobileNo ? '1px solid red' : '1px solid rgba(148,163,184,1)',
                      }}
                    />
                  </Box>
                </Box>
                <Box sx={{ padding: "5px 0px" }}>
                  <Typography variant="body1" style={webStyles.labelText}>
                  Passport Number
                  </Typography>
                  <input
                    data-test-id="passportNo"
                    type="text"
                    name=" PassportNumber"
                    placeholder="Enter your passport number"
                    value={this.state.passportNo}
                    onChange={(event) => this.setPassportNumber(event.target.value)}
                    required
               style={{
                 ...webStyles.inputText,
                 border: this.state.errors.passportNo ? '1px solid red' : '1px solid rgba(148,163,184,1)'
               }}
                  />
                </Box>
                <Box sx={{ padding: "5px 0px" }}>
                  <Typography variant="body1" style={webStyles.labelText}>
                  Passport Expiry Date
                  </Typography>
                  <input
                    type="date"
                    name="Expiry Date"
                    data-test-id="passportExp"
                    min={new Date().toISOString().split('T')[0]}
                    value={this.state.passportExDate}
                    onChange={(event) => this.setPassportExDate(event.target.value)}
                    required
               style={{
                 ...webStyles.inputText,
                 border: this.state.errors.passportExDate ? '1px solid red' : '1px solid rgba(148,163,184,1)'
               }}
                  /> 
                </Box>
                {this.saveProfileBtnUi()}
                <div style={{ display: "flex", justifyContent: "center", gap: "5px" }}>
                  <Box
                  onClick={this.gotoLandingPageHome}
                    data-test-id={"btnLoginRe"}
                    style={{marginTop:"10px",cursor:"pointer",fontFamily:"Inter, sans-serif",textDecorationLine: "underline",fontSize: "14px",color: "rgba(23,100,160,1)", fontWeight: 700,}}
                   
                  >
                    Skip for Now?
                  </Box> 
                </div>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </ThemeProvider>




      // Customizable Area End
    );
  }
}
// Customizable Area Start
const webStyles = {
    labelText:{
        fontSize: "16px",
        fontWeight: "Bold",
        color: "rgba(51, 65, 85, 1)",
        marginBottom: "2px",
        display: "block",
    },
    inputText:{
        width: "100%", border: `1px solid rgba(148,163,184,1)`, borderRadius: "8px",
        color: "rgba(0,0,0,1)", height: "50px", fontSize: "14px", padding: "10px",
        marginBottom: "10px",outline:"none"
    },
    inputContactText:{
      height: "56px",
      fontFamily: "Inter, sans-serif",
      fontSize: "14px",
      marginBottom: "10px",
      outline: "none",
      width: "100%",
      border: `1px solid rgba(148,163,184,1)`,
      borderRadius: "8px",
      color: "rgba(0,0,0,1)",
     
  },

    errorMsgResetD:{
        borderRadius: "5px",
        color: "#c12121",
        fontSize: "16px",
        borderLeft: "6px #c10f0ff0 solid",
        background:" #ed7e7e3d",
        padding: "20px 15px",
        display:"flex",
        lineHeight:"24px",
        alignItems: "center"
       
    },



  };
  // Customizable Area End
