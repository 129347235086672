import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { ChangeEvent } from "react";

export interface CountryType {
    "data": CountryArray[]
}
export interface CountryArray {

    "id": string,
    "type": string,
    "attributes": {
        "name": string,
        "emoji_flag": string,
        "country_code": string
    }
}
// Customizable Area End

export const configJSON = require("./config");



export interface Props {
    // Customizable Area Start
    navigation: object;
    id: string;
    // Customizable Area End
}
interface S {
    // Customizable Area Start
    checkboxData: any;
    inputValues: { [key: string]: { prefix: string; firstName: string; lastName: string; gender: string; emailId: string; age: string; passportNo: string; nationality: string; countryCode: string; image: string; mobileNo: string } };
    inputErrors: { [key: string]: { prefix: string; firstName: string; lastName: string; gender: string; emailId: string; age: string; passportNo: string; nationality: string; countryCode: string; image: string; mobileNo: string } };
    checkedItems: { [key: string]: boolean };
    savedData: any;
    setFile: string;
    setFileName: string;
    errors: any;
    prefixData: any;
    countryData: CountryArray[];
    countrycode: string;
    mobile: string;
    email: string;
    reviewModal: boolean;
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    id: string | number;
    // Customizable Area End
}

export default class PackageBookingController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    pdfApiCallId: string | Message = "";
    countryCodeApiCallId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        // Customizable Area End
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            // Customizable Area Start
            getName(MessageEnum.NavigationPayLoadMessage)
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            reviewModal: false,
            countrycode: "",
            mobile: "",
            email: "",
            checkboxData: [],
            inputValues: {},
            inputErrors: {},
            checkedItems: {},
            savedData: [],
            setFile: "",
            setFileName: "",
            errors: {
                countryCode: "",
                mobile: "",
                email: '',
                checkboxAll: ''
            },
            prefixData: [
                'Mr', 'Ms', 'Master', 'Miss', 'Mrs'
            ],
            countryData: []
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        // Customizable Area Start


        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        runEngine.debugLog("Message Received", message);

        if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
            const promoCodeDataRound = message.getData(
                getName(MessageEnum.OrderManagementDataInfo)
            );
            localStorage.setItem('promoCodeData', JSON.stringify(promoCodeDataRound));

        }
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(

                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (apiRequestCallId != null) {
                if (apiRequestCallId === this.countryCodeApiCallId && this.countryCodeApiCallId != null) {
                    this.GetAllCountryList(responseJson);
                }
            }
        }
        // Customizable Area End
    }

    // Customizable Area Start
    async componentDidMount() {

        this.countrylistDataApi();

        setTimeout(() => {
            this.gettravellerData({ "adult": 1, "childrens": 0, "infant": 0 })

        }, 1000);

    }
    gettravellerData = (item: any) => {
        let totalcount = item.adult + item.childrens + item.infant;
        let newTravellers = [];
        let newCheckedItems: any = {};

        for (let i = 1; i <= totalcount; i++) {
            newTravellers.push({
                id: i,
                Traveller: `ADD TRAVELLER ${i}`
            });
            newCheckedItems[`checkbox${i}`] = false;
        }



        this.setState({ checkboxData: newTravellers })
    }


    handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const { name, checked } = event.target;
        this.setState((prevState) => ({
            checkedItems: {
                ...prevState.checkedItems,
                [name]: checked,
            },

        }));
    };
    handleFileChange = (event: ChangeEvent<HTMLInputElement>, idFile: number) => {
        const file = event.target.files?.[0];
        if (file) {
            this.setState((prevState) => ({
                inputValues: {
                    ...prevState.inputValues,
                    [idFile]: { ...prevState.inputValues[idFile], image: file.name },
                },
                inputErrors: {
                    ...prevState.inputErrors,
                    [idFile]: { ...prevState.inputErrors[idFile], image: false },
                },
                setFileName: file.name,
            }));
        }
    };

    handleBrowseClick = (idElemt: number): void => {
        const element = document.getElementById(`upload-file-${idElemt}`) as HTMLInputElement | null;
        if (element) {
            element.click();
        }
    };
    validateEmail = (email: string) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[a-zA-Z]+$/;
        if (!email.trim() || !emailRegex.test(email)) {
            return false
        }

        return true;
    };
    validateMobile = (mobile: string) => {
        const numericPattern = /^\d+$/;
        return mobile && numericPattern.test(mobile);
    };

    validateAge = (agekey: string) => {
        const numericPattern = /^\d+$/;
        return agekey && numericPattern.test(agekey);
    };

    validateCountryCode = (countrycode: string) => {
        return countrycode ? "" : "Country code is required";
    };
    handleSaveClick = (idSave: number) => {
        const { inputValues } = this.state;
        const {
            prefix = '',
            firstName = '',
            lastName = '',
            image = '',
            emailId = '',
            age = '',
            passportNo = '',
            nationality = '',
            gender = '',
            countryCode = '',
            mobileNo = '',

        } = inputValues[idSave] || {};

        const isEmpty = (value: string) => !value || value.trim() === '';

        const inputErrors = {
            prefix: isEmpty(prefix),
            firstName: isEmpty(firstName),
            countryCode: isEmpty(countryCode),
            mobileNo: isEmpty(mobileNo) || !this.validateMobile(mobileNo),
            emailId: isEmpty(emailId) || !this.validateEmail(emailId),
            age: isEmpty(age) || !this.validateAge(age),
            lastName: isEmpty(lastName),
            gender: isEmpty(gender),
            nationality: isEmpty(nationality),
        };

        if (Object.values(inputErrors).some((error) => error)) {
            this.setState((prevState) => ({
                inputErrors: {
                    ...prevState.inputErrors,
                    [idSave]: inputErrors,
                },
            }));
        } else {
            this.setState((prevState) => ({
                inputErrors: {
                    ...prevState.inputErrors,
                    [idSave]: {
                        prefix: false, firstName: false,
                        image: false, emailId: false, age: false,
                        mobileNo: false, lastName: false, countryCode: false,
                        passportNo: false, nationality: false
                    },
                },
                savedData: {
                    ...prevState.savedData,
                    [idSave]: {
                        mobileNo,
                        image,
                        emailId,
                        prefix,
                        firstName,
                        lastName,
                        gender,
                        countryCode,
                        age,
                        passportNo,
                        nationality,
                    },
                },
            }), () => {
                const updatedCheckboxData1 = this.state.checkboxData.map((traveller: any, index: number) => ({
                    ...traveller,
                    firstName: this.state.savedData[traveller.id]?.firstName || '',
                    email: this.state.savedData[traveller.id]?.emailId || '',
                    mobile: this.state.savedData[traveller.id]?.mobileNo || ''
                }));

                this.setState({ checkboxData: updatedCheckboxData1 });
                this.setState(prevState => ({
                    checkedItems: {
                        ...prevState.checkedItems, [`checkbox${idSave}`]: false
                    }
                }));
            });


        }
    };
    handleInputChange = (event: ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>, idIput: number, inputType: string) => {
        const { value } = event.target;

        this.setState((prevState) => ({


            inputValues: {
                ...prevState.inputValues, [idIput]: { ...prevState.inputValues[idIput], [inputType]: value },
            },
            inputErrors: {
                ...prevState.inputErrors, [idIput]: { ...prevState.inputErrors[idIput], [inputType]: value.trim() === '' },
            },
        }));
    };
    validateAllCheckboxes = () => {
        const areAllChecked1 = this.state.checkboxData.every((traveller: any) => traveller.firstName != "" && traveller.firstName != undefined);
        return areAllChecked1 ? "" : "All travellers must be selected.";
    };
    GetAllCountryList = (responseJson: CountryType) => {
        if (responseJson) {

            this.setState({ countryData: responseJson.data })
        }
    }

    countrylistDataApi = () => {
        const requestMessageCountry = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );


        this.countryCodeApiCallId = requestMessageCountry.messageId;

        requestMessageCountry.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),

            configJSON.apiEndPointGetCountry
        );

        const header = {
            "Content-Type": configJSON.apiContentType,
        };

        requestMessageCountry.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessageCountry.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),

            configJSON.listOfOrdersMethod
        );

        runEngine.sendMessage(requestMessageCountry.id, requestMessageCountry);
    };
    setMobile = (text: string) => {
        this.setState({
            mobile: text, errors: { ...this.state.errors, mobile: '' },
        });
    };

    setCountryCode = (text: string) => {
        this.setState({
            countrycode: text, errors: { ...this.state.errors, countrycode: '' },
        });
    };

    setEmail = (text: string) => {
        this.setState({
            email: text, errors: { ...this.state.errors, email: '' },
        });
    };

    handleSubmitOrderMgmt = () => {
        const { email, countrycode, mobile } = this.state;
        const errors: any = {

            email: this.validateEmailSec(email),
            mobile: this.validateMobileSec(mobile),
            checkboxAll: this.validateAllCheckboxes(),

            countrycode: this.validateCountryCode(countrycode),

        };
        const isValid1 = Object.values(errors).every((error) => !error);

        if (isValid1) {
            this.confirmReview()
        } else {
            this.setState({ errors });
        }
    }
    validateEmailSec = (email: string) => {


        const emailRegex = /^[^\s@]+@[^\s@]+\.[a-zA-Z]+$/;

        return emailRegex.test(email) ? "" : "Invalid email format";
    }
    validateMobileSec = (mobile: string) => {


        const numericPattern = /^\d+$/;
        return numericPattern.test(mobile) ? "" : "Invalid mobile number";
    };
    confirmReview = () => {
        this.setState({ reviewModal: true })
    }
    // Customizable Area End
}
