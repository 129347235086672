import React from "react";
// Customizable Area Start
import {
  Box,
  Typography,
  styled,
  Button
} from "@material-ui/core";
import CfapiintegrationforhimalayanbankapiController, { Props } from "./CfapiintegrationforhimalayanbankapiController";
import HeaderMenuBlock from "../../../blocks/navigationmenu/src/HeaderMenu.web";
import { success, emoji } from "./assets";
const configJSON = require("./config");
// Customizable Area End

export default class ClientViewProfile extends CfapiintegrationforhimalayanbankapiController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderSuccess = () => {
    return (
      <Box sx={webStyle.contentContainer}>
        <Box className="SuccessContentContainer">
          <Box className="successBox">
            <img src={success} className="successImg" />
            <Typography className="congrateText">{configJSON.successText}<img src={emoji} style={{marginLeft:"15px"}}/></Typography>
            <Button className="viewDetails" data-testId = "ticketDetailsTestId" onClick={() => this.handleBackButton("LandingPage")}>View Ticket Details</Button>
          </Box>
        </Box>
      </Box>
    );
  };
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <SuccessContainer>
        <Box sx={webStyle.headerContainer}>
          <HeaderMenuBlock navigation={this.props.navigation} id={""} />
          <Typography className="bookTitle1">{configJSON.bookTitle}</Typography>
        </Box>
        {this.renderSuccess()}
      </SuccessContainer>
    )
    // Customizable Area End
  }
}

// Customizable Area Start
const SuccessContainer = styled(Box)({
  background: "#E9F3FC",
  height: "100%",
  overflowY: "scroll",
  "& .bookTitle1": {
    fontFamily: "Inter",
    lineHeight: "24px",
    color: "#FFFFFF",
    marginTop: "58px",
    fontSize: "32px",
    fontWeight: 700,
    marginLeft: "50px",
    boxSizing: "border-box"
},
  "& .SuccessContentContainer": {
    width: "97%",
    height: "658px",
    gap: "21px",
    justifyContent: "center",
    background: "#FFFFFF",
    position: "absolute",
    top: "-190px",
    display: "flex",
    borderRadius: "8px",
    boxShadow: "0px 4px 10px 0px #00000029",
  },
  "& .viewDetails": {
    fontSize: "16px",
    height: "56px",
    width: "327px",
    fontWeight: 700,
    fontFamily: "Inter",
    cursor: "pointer",
    color: "#FFFFFF",
    background: "#1764A0",
    borderRadius: "8px",
  },
  "& .congrateText": {
    fontFamily: "Inter",
    lineHeight: "36px",
    fontSize: "24px",
    fontWeight: 700,
    textAlign: "center",
    "@media screen and (min-width: 360px) and (max-width: 414px)":{
      width: "100%",
      fontSize: "16px",
    },
    "@media screen and (min-width: 415px) and (max-width: 600px)": {
      fontSize: "20px",
      width: "100%",
  }
  },
  "& .successBox": {
    margin: "auto",
    height: "350px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "45%",
    textAlign: "center",
    flexDirection: "column",
    "@media screen and (min-width: 360px) and (max-width: 414px)":{
      width: "95%",
    },
    "@media screen and (min-width: 415px) and (max-width: 600px)": {
      width: "85%",
  }
  },
  "& .successImg": {
    textAlign: "center"
  }
});

const webStyle = {
  headerContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    background: "linear-gradient(180deg, #013B68 84.25%, #0175D0 100%)",
    display: "flex",
    width: "100%",
    paddingBottom: "250px",
  },
  contentContainer: {
    width: "100%",
    height: "100%",
    flexWrap: "wrap",
    justifyContent: "space-around",
    background: "#E9F3FC",
    gap: "21px",
    display: "flex",
    flexDirection: "row",
    position: "relative",
  },
};
// Customizable Area End
