import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
interface OtpAgainType{
  "data": {
    "id": string;
    "type": string;
},
"meta": {
    "token": string
    "message": string
}
"errors": [
  {
      "otp": string
  }
]
}
interface OtpConfirmType{
  "messages": [
    {
        "otp": string
    }
],
"meta": {
    "token": string
}
"errors": [
  {
      "otp": string
      "token":string
  }
]
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

export interface S {
  // Customizable Area Start
  Seconds: number;
  Mintues: number;
  email: string;
  otpLength: boolean;
  forgotOtp: string;
  InvalidOtp: string;
  error: string;
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmailAccountDetailController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  requestEmailOtpCallId:string ="";
  requestOTPverifyCallId:string="";
  stateCodeApiCallId="";
  cityCodeApiCallId="";
  createAccountProfileApiCallId="";
  interval: NodeJS.Timeout = setTimeout(() => {}, 0);;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
      Seconds: 20,
      Mintues: 0,
      email: "",
      otpLength: false,
      forgotOtp: "",
      InvalidOtp: "",
      error:""
      // Customizable Area End
    };

    // Customizable Area Start
    

    // Customizable Area End
  }

  receive = async (from: string, message: Message) => {
  // Customizable Area Start
    runEngine.debugLog("Message Received", message);

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
     
 if (apiRequestCallId != null) {
  switch (true) {
    case apiRequestCallId === this.requestEmailOtpCallId &&  this.requestEmailOtpCallId != null:
     this.sendEmailOtpAgain(responseJson);
      break;
    case apiRequestCallId === this.requestOTPverifyCallId && this.requestOTPverifyCallId!=null:
      this.responseOTP(responseJson);
      break;
    default:
}
}
    }
  };
  // Customizable Area End
  // Customizable Area Start
  async componentDidMount() {
    this.getEmail()
    this.setTimerDefault()
    
  }
  setTimerDefault=()=>{
    this.interval=setInterval(()=>{
        const {Seconds}=this.state
        if(Seconds>0){
          this.setState({Seconds:Seconds-1})
        }
        else{
          clearInterval(this.interval)  
        }
      },1000)
  }
  ressetOtpTimer=()=>{
    this.setState({Seconds:20})
    this.setTimerDefault()
    this.goToOtpResentSubmit()
  }
  getEmail=()=>{
    const emailValue= localStorage.getItem("forgotEmail")
    if (emailValue) {
      this.setState({email:emailValue})      
    }
    else{
      this.props.navigation.navigate("ForgotPassword")
    }
  } 
  setOtp=(otpValue:string)=>{
      this.setState({forgotOtp:otpValue,otpLength:(otpValue.length===4), error:""})
    } 
  goToForgotPasswordPage=()=>{
    this.props.navigation.navigate("ForgotPassword")
    localStorage.removeItem("authToken")
  }
  goToOtpResentSubmit=()=>{
  const header = {
    "Content-Type": configJSON.apiVerifyOtpContentType,
  };
  const dataResent = {
    type:"email_account",
    attributes: {
      email: this.state.email
    },
  };

  const httpBody = {
    data: dataResent,
  };
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  //GO TO REQUEST STATE
  this.requestEmailOtpCallId = requestMessage.messageId;
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.forgetPwdEndPoint
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.apiVerifyOtpMethod
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    JSON.stringify(httpBody)
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);
}
sendEmailOtpAgain=(responseJson:OtpAgainType)=>{
  if(!responseJson.errors){
    localStorage.setItem("forgotEmail",this.state.email)
    localStorage.setItem("authToken",responseJson.meta.token)
  }
  
}
responseOTP=(responseJson:OtpConfirmType)=>{
  if(!responseJson.errors){
    localStorage.setItem("forgotEmail",this.state.email)
    localStorage.setItem("authToken",responseJson.meta.token)
    this.props.navigation.navigate("ChangePassword")
  }else{
    this.setState({error:responseJson.errors[0].otp || responseJson.errors[0].token})
  }
  
}
onSubmitVerify=()=>{
  const header = {
    "Content-Type": configJSON.apiVerifyOtpContentType,
     "token":localStorage.getItem("authToken"),
  };
  const dataVerify = {
    otp_code:this.state.forgotOtp
  };

  const httpBody = {
    data: dataVerify,
  };
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  //GO TO REQUEST STATE
  this.requestOTPverifyCallId = requestMessage.messageId;
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.verifyOtpEndPoint
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.apiVerifyOtpMethod
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    JSON.stringify(httpBody)
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);
}
  // Customizable Area End
}
