import { Box, Grid, Typography } from "@material-ui/core"
import React, { CSSProperties } from "react";
import {logoFooter,} from "../../blocks/landingpage/src/assets";
const Footer = () =>  {
return(
    <Box style={{background:"rgba(0, 60, 106, 1)"}} sx={{ padding:{xs:"10px 10px",sm:"10px 10px",md:"10px 30px",lg:"10px 60px" } ,marginTop:"45px"}}>
<Grid container style={{margin:"37px 0px"}} >
  <Grid item xs={12} sm={3} md={2} lg={2}>
    <div className="footerlogo">
      <img src={logoFooter} alt="logo" />
    </div>
  </Grid>
  <Grid item  xs={12} sm={3} md={2} lg={2}>
   <Typography variant="h4" style={footerStyle.headings}>
    Products
   </Typography>
   <li style={footerStyle.listyle}><span style={footerStyle.listItem}>Domestic Flights</span></li>
   <li style={footerStyle.listyle}><span style={footerStyle.listItem}> International Flights</span></li>
   <li style={footerStyle.listyle}><span style={footerStyle.listItem}>Multicity Flights</span></li>
  </Grid>
  <Grid item  xs={12} sm={3} md={2} lg={2}>
   <Typography variant="h4"style={footerStyle.headings}>
   Services
   </Typography>
   <li style={footerStyle.listyle}><span style={footerStyle.listItem}>Travel Blog</span></li>
  </Grid>
  <Grid item  xs={12} sm={3} md={3} lg={3}>
   <Typography variant="h4" style={footerStyle.headings}>
   Company
   </Typography>
   <li style={footerStyle.listyle}><span style={footerStyle.listItem}>About</span></li>
   <li style={footerStyle.listyle}><span style={footerStyle.listItem}>Terms & Conditions</span></li>
   <li style={footerStyle.listyle}><span style={footerStyle.listItem}>Booking Deals</span></li>
  </Grid>
  <Grid item  xs={12} sm={12} md={3} lg={3}>
   <Typography variant="h4"style={footerStyle.headings}>
   Contact
   </Typography>
   <Typography variant="h4" style={{...footerStyle.addresStyle, wordBreak: "break-word", paddingRight:"20px"}}>
    Address- <br/>
    Parijat-44,Lane no-10
    Gomti Nagar,Lucknow
   </Typography>
   <Typography variant="h4" style={footerStyle.addresStyle}>
   Contact No.- <br/>
   07771-241234
   </Typography>
  <Typography variant="h4" style={footerStyle.addresStyle}>
   Email ID- <br/>
   <li style={footerStyle.weblink}>info@doolwa.com</li> 
   </Typography>
  </Grid>
</Grid>
</Box>
)
}
const footerStyle={
    headings:{
      fontSize:"24px",
      fontWeight:700,
      color:"rgba(255, 255, 255, 1)",
      fontFamily: "Inter, sans-serif",
    } as CSSProperties,
    listyle:{
      margin:"16px 0px",
    },
    listItem:{
      fontSize:"16px",
      fontWeight:300,
      color:"rgba(255, 255, 255, 1)",
      cursor:"pointer",
      fontFamily: "Inter, sans-serif",
      textDecoration:"none"
    },
    addresStyle:{
      margin:"17px 0px",
      fontSize:"16px",
      fontWeight:300,
      color:"rgba(255, 255, 255, 1)",
      fontFamily: "Inter, sans-serif",
    } as CSSProperties,
    weblink:{
      textDecoration:"underline",
      margin:"5px 0px",
      cursor:"pointer",
      color:"rgba(255, 255, 255, 1)",
      fontFamily: "Inter, sans-serif",
    } as CSSProperties
  }
  export default Footer;